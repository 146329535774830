import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

// Assets
import heroImage from '../assets/mansion-4.jpg'
import divideEvenlyImage from '../assets/circles-orbiting-g-sq-trans.png'
import strengthenFamilyImage from '../assets/circles-connected-g-sq-trans.png'
import dividedCircle from '../assets/divided-circle-g-sq-trans.png'

// Components
import PriceCard from './pricing/PriceCard'
import FAQ from './faq/FAQ'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Helpers
import { standardButton, socialMediaButtons } from '../helpers/buttons.js'
import { positionChangeWidthSm, positionChangeWidthMd, navbarHeight, sheirGreen, sheirBlue, sheirYellow, iconImageDimension } from '../helpers/variableDefaults'
import useWindowDimensions from '../helpers/windowDimensions'
import { videoInsert } from '../helpers/videos'

import { seoPageTags, customAnalyticsEvent } from '../helpers/analytics'

// Home Page
const Home = () => {

  // use Naviage
  const navigate = useNavigate()

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Navigate to /create-estate when Create Estate button Clicked
  const handleCreateEstatePressed = async (e) => {
    e.preventDefault()
    navigate('/create-estate')
  }

  // Navigate to /demo when Demo button Clicked
  const handleDemo = async (e) => {
    e.preventDefault()
    navigate('/demo')

  }

  // Graphics Plus Text for 'Organize Assets; Divide Them Evently; Strengthen the Family'
  const iconImagePlusText = (image, text) => {
    return (
      <>
        <Box
          sx={{
            // backgroundColor: 'whitesmoke',
            // boxShadow: 3,
            height: '100%',
            width: width > positionChangeWidthSm ? '25%' : '80%',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Image */}
          <Box
            sx={{
              height: iconImageDimension,
              width: iconImageDimension,
              backgroundImage: `url(${image})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              display: 'inline-block',
            }}
          />

          {/* Text */}
          <Typography
            sx={{
              mt: 1,
              height: '20%',
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            {text}
          </Typography>
        
        </Box>
      </>
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Home'
      )}

      {/* Body */}
      <Box 
        sx={{ 
          height: `calc(100% - ${navbarHeight})`, width: '100vw', 
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
        }}
      >
        
        {/* Hero */}
        <Box 
          sx={{
            width: '100%', height: width > positionChangeWidthSm ? '60vh' : '70vh',
            backgroundImage: `url(${heroImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            overflow: 'hidden',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Header */}
          <Typography 
            variant="h1"
            textAlign={'center'}
            sx={{  
              // backgroundColor: 'yellow',
              width: '90%',
              fontFamily: 'Satisfy',
              fontSize: '35px',
              lineHeight: '35px',
              fontWeight: 'bold',
            }}
          >
            Divide Your Estate Evenly
          </Typography>

          {/* Subheader */}
          <Typography 
            variant="h2"
            textAlign={'center'}
            sx={{  
              mt: 2,
              width: '90%',
              fontFamily: 'Satisfy',
              fontSize: '20px',
              lineHeight: '20px',
              fontWeight: 'bold',
            }}
          >
            Maximize Family, Minimize Drama
          </Typography>

          {/* Create Estate Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Create Estate', 
              'button',
              'contained',
              false,
              'warning', 
              2,
              0,
              0,
              '150px', 
              '45px', 
              handleCreateEstatePressed
            )}
          </Box>

          {/* Demo Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Demo', 
              'button',
              'contained',
              false,
              'secondary', 
              2,
              0,
              0,
              '150px', 
              '45px', 
              handleDemo
            )}
          </Box>
        </Box>

        {/* Graphics with Text Underneath */}
        <Box 
          sx={{
            mt: 2, mb: 2,
            width: '100%', height: width > positionChangeWidthSm ? '225px' : '600px',
            // backgroundColor: 'yellow',
            display: 'flex', flexDirection: width > positionChangeWidthSm ? 'row' : 'column', justifyContent: 'space-around', alignItems: 'center',
          }}
        >
          {/* Organize Assets Image + Text */}
          {iconImagePlusText(dividedCircle, 'Organize Assets')}

          {/* Divide Them Evenly Image + Text */}
          {iconImagePlusText(divideEvenlyImage, 'Divide Them Evenly')}

          {/* Strengthen the Family Image + Text */}
          {iconImagePlusText(strengthenFamilyImage, 'Strengthen the Family')}
        </Box>



        {/* Introduction Video */}
        <Box
          sx={{
            mt: 0, 
            // mb: 4,
            width: '100%',
            // maxWidth: 'md',
            backgroundColor: 'whitesmoke',
            boxShadow: 3,
            // borderRadius: '5px',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <Box
            sx={{
              pb: 6, 
              pt: 3,
              pl: 0,
              width: '100%',
              maxWidth: '600px',
            }}
          >
            {videoInsert('https://www.youtube.com/embed/3cylhJ7iICM', 'Introducing sHeir', '100%', width)}
          </Box>
        </Box>

        {/* Pricing */}
        <Box
          sx={{
            backgroundColor: sheirBlue,
            boxShadow: 3,
            width: '100%',
            pb: 6, pt: 6,
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <PriceCard price='Start Free Trial' />
        </Box>

        {/* FAQ */}
        <FAQ fromComponent='home' role='NA' transparencyLevel='NA' distributionMethod='NA' />

      </Box>
    </>
  )
}

export default Home