/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams, Link } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
// import { Link } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import SendIcon from '@mui/icons-material/Send'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import circlesSpinningGIF from '../../assets/spinner-circles-orbiting-g.gif'
import { ExpandMore } from '../../helpers/cardHelpers'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { getHeirAllotmentsTotal, getHeirShareTotal } from '../../helpers/getValuationTotals'
import { positionChangeHeightSm, positionChangeWidthSm, sheirGreen, sheirBlue } from '../../helpers/variableDefaults'
import useWindowDimensions from '../../helpers/windowDimensions'
import { standardButton } from '../../helpers/buttons'
import { dummyBox } from '../../helpers/globalHelpers'

const ItemsIndexCard = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { item, estate, userCanWrite, isTestEstate, viewIndex, setViewIndex, setTestItemSelected } = props
  // console.log('item ->', item)

  // Payload
  const payload = getPayload()

  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId, personId } = useParams()

  // URL Path
  const urlPath = window.location.pathname

  // Tracks whether the card is expanded
  const [expanded, setExpanded] = useState(false)

  // Changes the card expanded state
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  // useEffect(() => {
  
  // }, [])

  // Edit Pressed button handler for people with 'write' or 'admin' privileges
  const handleEdit = (e) => {
    e.preventDefault()

    if (isTestEstate) {
      setTestItemSelected(item)
      setViewIndex(5)
    } else {
      navigate(`/estate/${estateId}/${item._id}/edit`)
    }
  }

  // Edit Icon Button JSX
  const editItemIconJSX = () => {
    return (
      <Box
        sx={{
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
        }}
      >
        <IconButton
          onClick={handleEdit} 
          sx={{ 
            width: '30px', height: '30px', 
            mb: 1, mt: 1, mr: width < positionChangeWidthSm ? 1 : 2, ml: 2,bottom: 0, left: 0, 
            border: 0, borderColor: 'white', boxShadow: 0, 
          }} 
        >
          <EditIcon 
            fontSize="small" 
            color="primary"
            sx={{ 

            }} 
          />
        </IconButton>
      </Box>
    )
  }


  return (
    <Card 
      align="left" 
      sx={{ 
        backgroundColor: 'whitesmoke',
        width: width < positionChangeWidthSm ? '80%' : '95%', boxShadow: 3, borderRadius: 3,
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
      }}
    >
      {/* Displays as a column on mobile and row on desktop */}
      <Box
        sx={{
          width: '100%',
          display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: width < positionChangeWidthSm ? 'flex-start' : 'space-between', alignItems: 'center',
        }}
      >
        <Box
          sx={{
            // height: width < positionChangeWidthSm ? '300px' : '200px',
            width: '100%',
            display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: width < positionChangeWidthSm ? 'center' : 'flex-start', alignItems: 'center',
          }}
        >
          {/* If Mobile, edit button appears at top right corner of column */}
          {userCanWrite && width < positionChangeWidthSm && 
            <Box 
              sx={{
                mb: -5,
                width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
              }}
            >
              {editItemIconJSX()}
            </Box>
          }

          {/* Image */}
          <CardMedia
            component="img"
            height={width < positionChangeWidthSm ? '150px' : '150px'}
            image={item.imageURL}
            alt={item.itemName}
            sx={{ 
              m: 1,
              borderRadius: 3,
              boxShadow: 3,
              display: 'inline-block',
              width: '150px',
            }}
          />

          {/* Title, Category, Valuation */}
          <Box
            sx={{
              // backgroundColor: 'yellow',
              ml: width < positionChangeWidthSm ? 0 : 1,
              width: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {/* Item Name */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                // backgroundColor: 'yellow',
                width: '95%',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {item.itemName}
            </Typography>

            {/* Category */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                width: '95%',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
              }}
            >
              {item.category}
            </Typography>

            {/* Valuation */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                width: '95%',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                color: sheirBlue,
              }}
            >
              ${Math.floor(item.valuation).toLocaleString('en-US', { minimumFractionDigits: 0 })}
            </Typography>

          </Box>

        </Box>

        {/* Allotted To */}
        <Box
          sx={{
            height: width < positionChangeWidthSm ? '' : '150px',
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
          }}
        >
          {/* If user can write and it's not a small screen, edit button appears here; otherwise it's a dummy box */}
          {userCanWrite && width >= positionChangeWidthSm ? 
            width >= positionChangeWidthSm && editItemIconJSX()
            :
            width >= positionChangeWidthSm && dummyBox('30px')
          }

          {/* Allotted to Name */}
          <Typography
            textAlign={width < positionChangeWidthSm ? 'center' : 'right'}
            sx={{
              // backgroundColor: 'yellow',
              // width: '200px',
              mr: width < positionChangeWidthSm ? 0 : 2,
              fontSize: width < positionChangeWidthSm ? '16px' : '18px',
              color: estate.people.filter(person => person.userGivenAndFamilyName === item.allottedToName).length > 0 ? sheirBlue : 'black',
              fontWeight: 'bold',
            }}
          >
            {!isTestEstate && estate.people.filter(person => person.userGivenAndFamilyName === item.allottedToName).length > 0 ?
              <Link to={`/estate/${estate.people.filter(person => person.userGivenAndFamilyName === item.allottedToName)[0].estateId}/${estate.people.filter(person => person.userGivenAndFamilyName === item.allottedToName)[0]._id}`} sx={{ color: sheirBlue }}>
                {item.allottedToName}
              </Link>
              :
              item.allottedToName
            }
          </Typography>

          {/* if it's a gift, the giftFor name appears; otherwise a dummy box is there if the screen is larger than mobile-size*/}
          {item.allottedToName === 'Gift' && item.hasOwnProperty('giftFor') ? 
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'right'}
              sx={{
                // backgroundColor: 'yellow',
                width: '200px',
                mr: width < positionChangeWidthSm ? 0 : 2,
                fontSize: width < positionChangeWidthSm ? '16px' : '16px',
                // color: sheirBlue,
                // fontWeight: 'bold',
                fontStyle: 'italic',
              }}
            >
              *{item.giftFor}
            </Typography>
            :
            width >= positionChangeWidthSm && dummyBox('30px')
          }
        </Box>
      </Box>
      
      {/* Expand More Icon */}
      <CardActions disableSpacing
        sx={{
          // backgroundColor: 'yellow',
          width: '100%',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >

        {/* Expand More */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon 
            // color='primary' 
          />
        </ExpandMore>

      </CardActions>

      {/* Longer Description — Part that expands when Expand More is Pressed */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            component="pre"
            sx={{
              fontFamily: 'Lato',
              fontSize: '16px',
            }}
          >
            {item.description}
          </Typography>
        </CardContent>
      </Collapse>
      
    </Card>
  )
}

export default ItemsIndexCard