/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import useWindowDimensions from '../../helpers/windowDimensions'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { auctionTimerMinutes, auctionTimerSeconds, positionChangeWidthAuction, sheirBlue } from '../../helpers/variableDefaults'
import { scrollToBottomOfMessages } from '../../helpers/sharedDraftAuctionJSX'
import { isNumeric } from '../../helpers/globalHelpers'
import { getTotalAllottedPointsByEstate, getUnassignedItems, getHighestBid, getUserBid, getUserTargetBid, getHighestBidderId, getPersonInEstateFromId, getEventResults, getRemainingGreaterThanValuation, getPointsRemainingGreaterThanBid, getPointsRemainingGreaterThanBidCustom, getPersonInEstateFromName } from '../../helpers/getValuationTotals'
import { pauseCountDown, resetCountDown, startTimeout } from '../../helpers/timer'
import { socket, initiateSocketIO, initateLiveEventConnection } from '../../helpers/socket'
import { computerAuctionJSX, mobileAuctionJSX } from '../../helpers/auctionEventJSX'
import { couldNotLoadPageError } from '../../helpers/errors'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Live Auction Page
const LiveAuction = () => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId } = useParams()

  // Payload
  const payload = getPayload()

  // Estate
  const [estate, setEstate] = useState({}) //estate object
  const [userPerson, setUserPerson] = useState({}) //person object for the user
  const [onTheBlockItem, setOnTheBlockItem] = useState({}) //Item that is being bid upon

  const [autoSelect, setAutoSelect] = useState(false) //Boolean — auto select on is true; auto select off is false
  const [customBid, setCustomBid] = useState('') //Custom bid value
  const [resultsName, setResultsName] = useState('') //the name of the person appearing in the results tab on the right side of the computer view
  const [openResultsMenu, setOpenResultsMenu] = useState(false) // Boolean — results menu open is true; closed is false
  const [heirNames, setHeirNames] = useState([]) // Names of heirs; these are the menu options for the results select
  const [heirPersonIds, setHeirPersonIds] = useState([]) // Heir person Ids array
  const [heirPersons, setHeirPersons] = useState([])
  const [connectedPersons, setConnectedPersons] = useState([]) // persons connected to the socket room

  const [messages, setMessages] = useState([]) // Messages for the message board
  const [messageText, setMessageText] = useState('') // Text for a new message

  // Timer
  const [ eventHasBegun, setEventHasBegun ] = useState(false) // Boolean — event has begun is true; not yet begun is false
  const [ eventHasFinished, setEventHasFinished ] = useState(false) // Boolean — event has finished is true; not yet finished is false
  const [ minutes, setMinutes ] = useState(-1) // Number of minutes remaining to bid on an item
  const [ seconds, setSeconds ] =  useState(-1) // Number of seconds remaining to bid on an item
  const [ isPaused, setIsPaused ] = useState(false) // Boolean — the timer is paused is true; not paused is false

  // Loading
  const [loading, setLoading] = useState(false) // Boolean — loading the view is true; not loading is false

  // Errors
  const [errors, setErrors] = useState(false) // Boolean — true if there are errors; false if there are not any errors

  // For scrolling to bottom of message display automatically
  const messagesEndRef = useRef(null)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {

    if (!userIsAuthenticated()) {

      // If there's no authenticated User, return to the homepage
      navigate('/')

    } else {

      // Get the necessary data
      const getData = async () => {
        setLoading(true)
        try {

          // Retrieve the Estate
          const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved Estate ->', retrievedEstate)

          // If event has Finished, navigate to auction results page
          if (retrievedEstate.event[0].hasFinished) {
            // console.log('should redirect to draft results')
            // console.log('estateId ->', estateId)
            navigate(`/estate/${estateId}/auction-results`)
          
          } else if (!retrievedEstate.event[0].hasStarted) {
            
            navigate(`/estate/${estateId}/auction-practice`)
          
          } else {

            // Find the user's Person in the Estate
            const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)

            // If the userPerson exists, continue to assigning states; if not, navigate to the User's profile
            if (userIsInEstate.length > 0 && retrievedEstate.subscriptionIsActive) {
              // console.log('userIsInEstate people ->', retrievedEstate.people)
              
              // Set States
              setUserPerson(userIsInEstate[0])
              setEstate(retrievedEstate)
              setOnTheBlockItem(getUnassignedItems(retrievedEstate).sort((a, b) => b['valuation'] - a['valuation'])[0])
              setHeirNames(retrievedEstate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage).map(person => person.userGivenAndFamilyName))
              setHeirPersonIds(retrievedEstate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage).map(person => person._id))
              setResultsName(retrievedEstate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage)[0].userGivenAndFamilyName)
              setHeirPersons(retrievedEstate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage))
              
              // Connect to socket
              if (!socket.connected) {
                // Initiate Live Event Connection
                const liveEventConnection = await initateLiveEventConnection(estateId)
                // console.log('live event connection ->', liveEventConnection)

                // Initiate Socket IO Connection
                const joinRoom = await initiateSocketIO(userIsInEstate[0], estateId, socket)
                // console.log('join room ->', joinRoom)
              } else {
                // If socket connection already exists, must refresh to disconnect in order to make a fresh new connection
                window.location.reload()
              }

              // Set states if necessary
              if (retrievedEstate.event[0].hasBegunTimer) {
                setEventHasBegun(true)
              }
              if (retrievedEstate.event[0].timer.minutes > -1) {
                setMinutes(retrievedEstate.event[0].timer.minutes)
              }
              if (retrievedEstate.event[0].timer.seconds > -1) {
                setSeconds(retrievedEstate.event[0].timer.seconds)
              }

              setErrors(false)
              setLoading(false)
            } else {
              // if user is not in Estate, navigate to the User's profile
              navigate(`/user/${payload.sub}`)

              setErrors(false)
              setLoading(false)
            }
          }

        } catch (error) {
          // console.log(error)

          // If there's an error retrieving the estate data, navigate to the User's profile
          navigate(`/user/${payload.sub}`)

          setErrors(true)
          setLoading(false)
        }
      }

      // Call getData()
      getData()
    }

  }, [])

  useEffect(() => {
    // console.log('socket useEffect runs')

    // socket.onAny((event, ...args) => {
    //   console.log('socket onAny ->', event, args)
    // })

    // New Connection Listener
    socket.on('new_connection', (data) => {
      // console.log('new connection ->', data)
      // console.log('data connected people ->', data.connectedPeople)
      // console.log('data messageBoard ->', data.messageBoard)
      setConnectedPersons(data.connectedPeople)
      // setMessages(data.messageBoard)
    })

    // New Disconnection Listener
    socket.on('new_disconnection', (data) => {
      // console.log('new disconnection ->', data)
      // console.log('data connected people ->', data.connectedPeople)
      // console.log('data messageBoard ->', data.messageBoard)
      setConnectedPersons(data.connectedPeople)
      // setMessages(data.messageBoard)
    })

    // Receive Message Listener
    socket.on('receive_message', (data) => {
      // console.log('receive message ->', data)
      setMessages(data)
    })

    // Timer Listener
    socket.on('timer', (data) => {
      // console.log('timer socket data ->', data)

      // If event has begun is false, set event has begun to true
      if (!eventHasBegun) {
        setEventHasBegun(true)
      }

      // Update the minutes and seconds
      setMinutes(data.minutes)
      setSeconds(data.seconds === seconds ? data.seconds + 1 : data.seconds)
    
    })

    // Item Bid Received Listener
    socket.on('new_bid_received’', async (data) => {
      // console.log('new bid received ->', data)

      // Set the estate
      setEstate(data.newEstate)

      // Reset clock
      setMinutes(data.minutes)
      setSeconds(data.seconds === seconds ? data.seconds + 1 : data.seconds)
    })

    // Item Assigned Listener
    socket.on('item_assigned’', async (data) => {
      // console.log('item assigned’ ->', data)

      // Set custom bid to empty
      setCustomBid('')

      // Set the estate and messages
      setEstate(data.newEstate)
      setMessages(data.messageBoard)

      // console.log('has finished ->', data.hasFinished)
      // If hasFinished is true, set eventHasFinished to true
      if (data.hasFinished) {
        setEventHasFinished(data.hasFinished)
      } 

      // Reset timer on client side
      setMinutes(data.minutes)
      setSeconds(data.seconds === seconds ? data.seconds + 1 : data.seconds)
      
    })

  }, [socket])

  // Refresh view when state changes
  useEffect(() => {
    // console.log('connectedPersons ->', connectedPersons)
  }, [onTheBlockItem, connectedPersons, eventHasFinished, openResultsMenu])

  useEffect(() => {
    setOpenResultsMenu(false)
  }, [resultsName])

  // Timer
  useEffect(() => {
    // console.log('seconds ->', seconds)
    // console.log('seconds useEffect eventHasBegun ->', eventHasBegun)
    // console.log('seconds useEffect eventHasFinished ->', eventHasFinished)

    // Decrement the timer if the event is in progress
    if (eventHasBegun && !eventHasFinished) {
      startTimeout(decrementTimer)
    }
  }, [seconds])

  useEffect(() => {
    // console.log('estate items ->', estate.items)
    // console.log('unassigned items ->', estate.hasOwnProperty('items') && getUnassignedItems(estate))

    // Set the onTheBlockItem whenever estate changes
    if (estate.hasOwnProperty('items') && getUnassignedItems(estate).length > 0) {
      setOnTheBlockItem(getUnassignedItems(estate).sort((a, b) => b['valuation'] - a['valuation'])[0])
    } else if (estate.hasOwnProperty('event') && getEventResults(estate).length > 0) {
      setOnTheBlockItem(getEventResults(estate)[getEventResults(estate).length - 1])
    }
  }, [estate])

  useEffect(() => {
    // console.log('messages useEffect')
    // console.log('messages ->', messages)

    // Scroll to bottom of messages whenever a new message arrives
    if (width >= positionChangeWidthAuction) {
      scrollToBottomOfMessages(messagesEndRef)
    }

  }, [messages])

  // Timer Functions
  const decrementTimer = () => {
    // console.log('decrementTimer runs')
    // console.log('isPaused ->', isPaused)

    // If seconds is greater than 0 and the timer is not paused, decrement the timer or execute an auto bid
    if (seconds > 0 && !isPaused) {
      if (estate.hasOwnProperty('event') && autoSelect && userPerson.role === 'Heir' && seconds <= (auctionTimerSeconds - 5)) {
        // If the userPerson has autoSelect on, if the userPerson is able to bid higher, if the userPerson does not have the highest bid, and if the userPerson's target bid is higher than the highest bid — then execute an autobid
        if (getRemainingGreaterThanValuation(onTheBlockItem, estate, userPerson) && getPointsRemainingGreaterThanBidCustom(getHighestBid(onTheBlockItem) + 1, estate, userPerson, onTheBlockItem) && ((getUserBid(onTheBlockItem, userPerson._id) < getHighestBid(onTheBlockItem) && getHighestBid(onTheBlockItem) < getUserTargetBid(onTheBlockItem, userPerson._id)) || (getHighestBid(onTheBlockItem) === 0 && getUserTargetBid(onTheBlockItem, userPerson._id) > 0))) {
          if ((getHighestBid(onTheBlockItem) + 50 <= getUserTargetBid(onTheBlockItem, userPerson._id)) && (getTotalAllottedPointsByEstate(estate, userPerson) + 50 <= 1000)) {
            executeBid(getHighestBid(onTheBlockItem) + 50, userPerson)
          } else if ((getHighestBid(onTheBlockItem) + 10 <= getUserTargetBid(onTheBlockItem, userPerson._id)) && (getTotalAllottedPointsByEstate(estate, userPerson) + 10 <= 1000)) {
            executeBid(getHighestBid(onTheBlockItem) + 10, userPerson)
          } else if ((getHighestBid(onTheBlockItem) + 5 <= getUserTargetBid(onTheBlockItem, userPerson._id)) && (getTotalAllottedPointsByEstate(estate, userPerson) + 5 <= 1000)) {
            executeBid(getHighestBid(onTheBlockItem) + 5, userPerson)
          } else {
            executeBid(getHighestBid(onTheBlockItem) + 1, userPerson)
          }
        } else {
          // Decrement timer
          setSeconds(seconds - 1)
        }
      } else {
        // Decrement timer
        setSeconds(seconds - 1)
      }
      
    } else if (seconds === 0 && !isPaused) {
      if (minutes === 0) {
        // Allot items happens on backend
        // console.log('allot item occurs on the backend')
        
      } else {
        // Decrement timer
        setMinutes(minutes - 1)
        setSeconds(59)
      }
    } else if (!isPaused) {
      // Reset timer
      setMinutes(auctionTimerMinutes)
      setSeconds(auctionTimerSeconds)
    }
  }

  // Execute bidding on an Item
  const executeBid = (newBid, biddingPerson) => {
    // console.log('onTheBlockItem ->', onTheBlockItem)

    // Send the new bid to the backend
    socket.emit(
      'new_bid_sent', 
      { 
        biddingPersonId: userPerson._id,
        itemId: onTheBlockItem._id,
        newBid: newBid,
      }
    )
  }

  // Returns a Boolean — if the user can bid, it's true; if not, then false
  const userCanBid = (newBid) => {
    return (eventHasBegun && !eventHasFinished && !autoSelect && !(seconds === 0 && minutes === 0) && (getRemainingGreaterThanValuation(onTheBlockItem, estate, getPersonInEstateFromId(estate, userPerson._id)) && getPointsRemainingGreaterThanBidCustom(newBid, estate, getPersonInEstateFromId(estate, userPerson._id), onTheBlockItem)))
  }

  // Handle a submitted bid
  const handleSubmitBidPressed = async (e, type) => {
    e.preventDefault()
    // console.log('handleSubmitBidPressed')
    // console.log('e.target.text', Math.floor(e.target.innerText.replace('+','')))
    // console.log('onTheBlockItem ->', onTheBlockItem)

    // Create a variable for the new bid
    const newBid = type === 'custom' ? Math.floor(customBid) : Math.floor(getHighestBid(onTheBlockItem) + Math.floor(e.target.innerText.replace('+','')))

    // If the user can bid, execute the bid
    if (userCanBid(newBid) && (type === 'plus' || (type === 'custom' && Math.floor(customBid) > getHighestBid(onTheBlockItem)))) {
      
      executeBid(newBid, userPerson)
      setCustomBid('')
      
    }
  }

  // Start the timer when Start Timer button is pressed
  const handleStartTimer = () => {
    // console.log('handleStartTimer runs')

    // Set event has begun to true
    setEventHasBegun(true)

    // Emit to start_timer
    socket.emit('start_timer', { eventId: estate.event[0]._id })

  }

  // Exit the live event when the Exit button is pressed
  const handleExitDraft = () => {
    // console.log('handleExitDraft runs')

    navigate(`/estate/${estateId}/family-hq`)
    // navigate(-1)
  }

  // Set the custom bid text
  const handleCustomBidText = async (e) => {
    e.preventDefault()
    // console.log('handleCustomBidText')
    // console.log('e.target.value ->', e.target.value)
    if (isNumeric(e.target.value)) {
      // console.log('is number !', e.target.value)

      setCustomBid(e.target.value)
      
    } else if (e.target.value === '') {
      setCustomBid('')
    } else {
      // console.log('is NOT number !', e.target.value)
    }

  }

  // Execute sending a message
  const handleSendMessage = (e) => {
    e.preventDefault()
    // console.log('handleSendMessage runs')
    // console.log('e.target.value ->', e.target.value)

    // Create the new message object
    const newMessage = {
      timestamp: new Date(),
      // fromName: userPerson.userGivenAndFamilyName,
      // fromName: userPerson.userGivenAndFamilyName.substring(0, userPerson.userGivenAndFamilyName.indexOf(' ') + 2),
      fromName: userPerson.userGivenAndFamilyName.substring(0, userPerson.userGivenAndFamilyName.indexOf(' ')),
      message: messageText,
    }

    // Send the message with socket.io
    socket.emit('send_message', { newMessage, estateId })

    // Reset the messageText to nothing
    setMessageText('')
  }

  // Update the message text as the user types
  const handleChangeMessageText = (e) => {
    e.preventDefault()
    // console.log('handleChangeMessageText runs')
    // console.log('e.target.value ->', e.target.value)
    setMessageText(e.target.value)
  }

  // Update the Person who's results are shown on the right side
  const handleResultsSelect = (e, type) => {
    e.preventDefault()
    // console.log('handleResultsSelect')
    // console.log('e.target.value ->', e.target.value)
    // console.log('e.target.innerText ->', e.target.innerText)
    // console.log('resultsName ->', resultsName)
    // console.log('type ->', type)
    // console.log('openResultsMenu before ->', openResultsMenu)

    // Update setResults if the name changes
    // setResultsName(e.target.value)

    if (type === 'change' || type === 'click' || (type === 'close') || (type === 'open')) {
      if (type === 'close') {
        // console.log('close runs')
        // setOpenResultsMenu(!openResultsMenu)
        if (e.target.innerText) {
          setResultsName(e.target.innerText)
        }
      } else if (type === 'change') {
        // console.log('change runs')
        setOpenResultsMenu(false)
        setResultsName(e.target.value)
      } else if (type === 'click') {
        // console.log('click runs')
        setOpenResultsMenu(true)
        setOpenResultsMenu(!openResultsMenu)
      } else if (type === 'open') {
        // console.log('open runs')
        setOpenResultsMenu(true)
      }
      // setResultsName(type === 'change' ? e.target.value : e.target.innerText)
      // setResultsName(type === 'change' ? e.target.value : e.target.innerText)
    }

  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Live Auction'
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          height: '100vh',
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        errors ? 
          <Box
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              height: '100vh',
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
            }}
          >
            {couldNotLoadPageError()}
          </Box>
          :
          width >= positionChangeWidthAuction ?

            // Computer view for wider screens
            computerAuctionJSX(
              'live',
              estate, 
              userPerson, 
              autoSelect, setAutoSelect, 
              eventHasBegun, eventHasFinished, 
              handleStartTimer, handleExitDraft, minutes, seconds, 
              width, 
              onTheBlockItem, 
              handleSubmitBidPressed, customBid, handleCustomBidText, 
              messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef, 
              heirNames, resultsName, handleResultsSelect, openResultsMenu, setOpenResultsMenu,
              heirPersons, connectedPersons
            )

            :

            // Mobile view for narrower screens
            mobileAuctionJSX(
              'live',
              estate, 
              userPerson, 
              autoSelect, 
              eventHasBegun, eventHasFinished, 
              handleStartTimer, handleExitDraft, minutes, seconds, 
              width, 
              onTheBlockItem, 
              handleSubmitBidPressed, customBid, handleCustomBidText, 
              messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef,
              heirPersons, connectedPersons
            )
          
      }
    </>
  )
}

export default LiveAuction