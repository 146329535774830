/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'

import profPicDefault from '../../../assets/seed-profpic-default-4.jpg'

import useWindowDimensions from '../../../helpers/windowDimensions'
import { getPayload } from '../../../helpers/storage'
import { navbarHeight, footerHeight, positionChangeWidthSm, subscriptionTypes, sheirBlue, monthlyRate, yearlyRate, oneTimeRate } from '../../../helpers/variableDefaults'
import { standardSubmitButton } from '../../../helpers/buttons'
import { standardSpinner } from '../../../helpers/spinners'
import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'

// Stripe
import { PaymentElement } from '@stripe/react-stripe-js'


// Create Estate Pay
const Pay = (props) => {

  // Destructure Props
  const { options, setOptions, loading } = props

  // Payload
  const payload = getPayload()
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()



  useEffect(() => {
    
    // Collect Analytics
    customAnalyticsEvent(
      `onboarding_5_pay`, 
      `onboarding_5_pay`, 
      `onboarding_5_pay`, 
      `onboarding_5_pay`, 
      'Onboarding Step 5 — Pay'
    )

  }, [])

  // Handle subscription type selection
  const handleSelectSubscriptionType = async (subscriptionType) => {
    // console.log('handleSelectSubscriptionType runs')

    if (options.metadata.subscriptionType !== subscriptionType.interval) {

      const newOptionsMetadata = { ...options.metadata }

      newOptionsMetadata.subscriptionType = subscriptionType.interval
      
      // if (subscriptionType.interval === 'one-time') {

      setOptions({ ...options, amount: subscriptionType.rate * 100, mode: 'payment', metadata: newOptionsMetadata })

      // } else if (subscriptionType.interval === 'year' || subscriptionType.interval === 'month') {

      //   setOptions({ ...options, amount: 0, mode: 'subscription', metadata: newOptionsMetadata })

      // } 

    }

  }

  // Handle Card Holder Name Change
  const handleCardholderNameChange = (e) => {
    e.preventDefault()
    // console.log('handleCardholderNameChange')
    // console.log('e.target.value ->', e.target.value)

    const newOptionsMetadata = { ...options.metadata } 

    newOptionsMetadata.cardholderName = e.target.value

    setOptions({ ...options, metadata: newOptionsMetadata })
  }

  // Handle Card Holder Name Change
  const handlePromoCodeChange = (e) => {
    e.preventDefault()
    // console.log('handlePromoCodeChange')
    // console.log('e.target.value ->', e.target.value)

    const newOptionsMetadata = { ...options.metadata } 

    newOptionsMetadata.promoCode = e.target.value

    setOptions({ ...options, metadata: newOptionsMetadata })

    // console.log('options ->', options)
  }

  // When credit card info is all added, switch paymentMethodIsValid to true
  const handlePaymentElementChange = (e) => {
    // console.log('handlePaymentElementChange runs')
    // console.log('handlePaymentElementChange event ->', e)

    if (e.complete !== options.metadata.paymentMethodIsValid) {
      
      const newOptionsMetadata = { ...options.metadata }

      newOptionsMetadata.paymentMethodIsValid = e.complete

      setOptions({ ...options, metadata: newOptionsMetadata })

    } 
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {seoPageTags(
        '404'
      )} */}

      {/* Body */}
      <Box
        sx={{
          width: '100%',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >

        {/* Subscription Type */}
        <Box 
          sx={{ 
            mt: 4, mb: 2, py: 2,
            backgroundColor: 'whitesmoke',
            width: '100%',
            maxWidth: '600px', 
            boxShadow: 3,
            borderRadius: width < positionChangeWidthSm ? 0 : 5,
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
          }}
        >
          {/* Title */}
          {/* <Box 
            sx={{ 
              mb: 2,
              width: '95%',
              fontSize: '20px',
              fontWeight: 'bold',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            Subscription Type
          </Box> */}

          {/* Subscription Types */}
          <Box 
            sx={{ 
              width: '95%',
              display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: width < positionChangeWidthSm ? 'space-around' : 'space-around', alignItems: 'center',
              // flexWrap: 'wrap',
            }}
          >
            {subscriptionTypes.length > 0 && subscriptionTypes.map((subscriptionType, subscriptionTypeIndex) => (
              <Box
                key={subscriptionTypeIndex}
                onClick={() => handleSelectSubscriptionType(subscriptionType)}
                sx={{
                  my: 1, mx: 0,
                  py: 3,
                  backgroundColor: 'whitesmoke',
                  width: '100%',
                  maxWidth: '175px',
                  boxShadow: 3,
                  borderRadius: 5,
                  cursor: 'pointer',
                  border: options.metadata.subscriptionType === subscriptionType.interval ? '2px solid #2979ff' : '0px solid #2979ff',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >

                {/* Rate */}
                <Box
                  textAlign={'center'}
                  sx={{
                    // backgroundColor: 'yellow',
                    pt: 1, pb: 1,
                    width: '95%',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                  }}
                >
                  {/* {console.log('title ->', subscriptionType.interval === 'one-time' ? `$${subscriptionType.rate} (${subscriptionType.interval})` : `$${subscriptionType.rate}/${subscriptionType.interval}`)} */}
                  {/* {subscriptionType.interval === 'one-time' ? `$${subscriptionType.rate} (${subscriptionType.interval})` : `$${subscriptionType.rate}/${subscriptionType.interval}`} */}
                  {`$${subscriptionType.rate}`}
                </Box>

                {/* Interval */}
                <Box
                  textAlign={'center'}
                  sx={{
                    // backgroundColor: 'yellow',
                    pt: 0, pb: 1,
                    width: '95%',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                  }}
                >
                  {subscriptionType.interval === 'one-time' ? `(${subscriptionType.interval})` : `(per ${subscriptionType.interval})`}
                </Box>

                {/* Free Trial */}
                {/* <Typography 
                  textAlign={'center'}
                  sx={{
                    width: '90%',
                    color: sheirBlue,
                  }}
                >
                  {subscriptionType.freeTrial ?
                    <em>*7-day Free Trial</em>
                    : 
                    <em>No Free Trial</em> 
                  }
                </Typography> */}

              </Box>
            ))}

          </Box>


        </Box>


        {/* Payment Method */}
        <Box 
          sx={{ 
            my: 2, py: 2,
            backgroundColor: 'whitesmoke',
            width: '100%',
            maxWidth: '600px', 
            boxShadow: 3,
            borderRadius: width < positionChangeWidthSm ? 0 : 5,
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
          }}
        >
          {/* Title */}
          <Box 
            sx={{ 
              mb: 0.5,
              width: '95%',
              fontSize: '20px',
              fontWeight: 'bold',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            Payment Details
          </Box>

          {/* Advisory */}
          <Typography 
            component='pre'
            textAlign={'center'}
            sx={{
              mb: 2,
              width: '90%',
              fontStyle: 'italic',
              color: sheirBlue,
            }}
          >
            {options.metadata.subscriptionType !== 'one-time' ?
              `*Card not charged until end of 7-day free trial`.replace(/ {4} +/g, '')
              : 
              `*Card will be charged immediately`.replace(/ {4} +/g, '')
            }
          </Typography>

          {/* Card Information */}
          <Box 
            sx={{ 
              width: '95%',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}
          >
            <Box 
              // component='form' 
              // onSubmit={(e) => handleSubmitLogin(e, fromComponent, loginForm, setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)} 
              // noValidate 
              sx={{ 
                // mt: 1, mb: 1,
                display: 'flex', flexDirection: 'column',
              }}
            >
              {/* Cardholder Name */}
              <Typography
                sx={{
                  fontSize: '15px',
                  fontWeight: 400,
                  color: '#36454F',
                }}
              >
                Cardholder Name
              </Typography>

              <TextField
                focused={false}
                size={'small'}
                value={options.metadata.cardholderName}
                onChange={(e) => handleCardholderNameChange(e)}
                sx={{
                  mb: 1.5,
                }}
              />

              <PaymentElement onChange={(e) => handlePaymentElementChange(e)} />


              {/* Cardholder Name */}
              <Typography
                sx={{
                  mt: 1.5,
                  fontSize: '15px',
                  fontWeight: 400,
                  color: '#36454F',
                }}
              >
                Promo Code
              </Typography>

              <TextField
                focused={false}
                size={'small'}
                value={options.metadata.promoCode}
                onChange={(e) => handlePromoCodeChange(e)}
                sx={{
                  // mb: 1.5,
                }}
              />
              
              {loading &&
                <Box
                  sx={{
                    width: '100%',
                    minHeight: '100px',
                    height: '100px',
                    maxHeight: '100px',
                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                  }}
                >
                  {standardSpinner()}
                </Box>
              }

              {loading && 
                <Typography 
                  component='pre'
                  textAlign={'center'}
                  sx={{
                    mt: 2, mb: 2,
                    width: '90%',
                    fontStyle: 'italic',
                    color: sheirBlue,
                  }}
                >
                  
                  {
                    `*Payment Processing 
                    *Do not refresh or exit page`.replace(/ {4} +/g, '')
                  }
                    
                </Typography>
              }
            </Box>

            {/* Advisory */}
            <Typography 
              component='pre'
              textAlign={'center'}
              sx={{
                mt: 2, mb: 2,
                width: '90%',
                fontStyle: 'italic',
                color: sheirBlue,
              }}
            >
              
              {`*Payments processed through Stripe`.replace(/ {4} +/g, '')}
                
            </Typography>
          </Box>

        </Box>

      </Box>
    </>
  )
}

export default Pay