import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import circlesSpinningGIF from '../../../assets/spinner-circles-orbiting-g.gif'

import PersonItem from './PersonItem.js'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

import AddIcon from '@mui/icons-material/Add'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { sheirBlue, navbarHeight, footerHeight } from '../../../helpers/variableDefaults'
import { standardSpinner } from '../../../helpers/spinners'

import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'

// Create Estate People Page
const People = (props) => {

  // Destructure Props
  const { options, setOptions, authenticatedUserObj, allPersonElementsAdded, includesAuthenticatedUserEmail, noDuplicateEmails, loading } = props

  useEffect(() => {
    
    // Collect Analytics
    customAnalyticsEvent(
      `onboarding_1_people`, 
      `onboarding_1_people`, 
      `onboarding_1_people`, 
      `onboarding_1_people`, 
      'Onboarding Step 1 — People'
    )

  }, [])

  // Handler for adding a new Person Item
  const handleAddPerson = async (e) => {
    e.preventDefault()

    // Destructure peopleArray array
    const newOptionsMetadata = { ...options.metadata } 

    // Push a new option onto the newOptionsMetadata.people
    newOptionsMetadata.people.push(
      {
        role: '',
        sharePercentage: '0',
        givenName: '',
        familyName: '',
        email: '',
      }
    )

    // setOptions with the newOptionsMetadata
    setOptions({ ...options, metadata: newOptionsMetadata })
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {seoPageTags(
        '404'
      )} */}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        : 
        <Box
          sx={{
            width: '100%',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {/* Title */}
          <Typography sx={{ mb: 1, mt: 3, fontSize: '20px' }}>
            <strong>People in Estate</strong>
          </Typography>

          {/* People */}
          {options.metadata.people[0] && options.metadata.people.map((item, itemIndex) => (  
            
            <Box key={itemIndex} 
              sx={{
                mt: itemIndex === 0 ? 1 : 0,
                width: '95%', maxWidth: '900px',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              }}
            >
              <PersonItem options={options} setOptions={setOptions} itemIndex={itemIndex} fromComponent={'onboarding'} />
            </Box>
          ))}

          {/* Add Person Text Button */}
          <Box key={5} 
            sx={{ 
              mb: 3, mt: 1,
              width: '100%', 
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            <Button 
              key={7} 
              disabled={!allPersonElementsAdded(options.metadata)}
              onClick={handleAddPerson}
              color='primary'
            >
              ADD ANOTHER PERSON
            </Button>
          </Box>

          {/* Advisory */}
          <Typography 
            textAlign={'center'}
            sx={{
              width: '90%',
              color: 
                // !noDuplicateEmails(options.metadata) ? 'red' : 
                includesAuthenticatedUserEmail(options.metadata, authenticatedUserObj) ? sheirBlue : 'red',
            }}
          >
            {
            // !noDuplicateEmails(options.metadata) ?
            //   <em>The same email is used for two people</em>
            //   : 
              includesAuthenticatedUserEmail(options.metadata, authenticatedUserObj) ? 
                <em>You can add more people now, or later if you prefer</em>
                : 
                <em>One person must have your email address</em> 
            }
          </Typography>

        </Box>
      }
    </>
  )
}

export default People