/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import htmlReactParser from 'html-react-parser'

import { handleChange } from './globalHelpers'
import { setTokenToLocalStorage, setProfPicToLocalStorage, setEmailToLocalStorage } from './storage'
import { loginTextField } from './textfields'
import { standardSubmitButton, handleSwitchLoginRegisterButton, standardButton } from './buttons'
import { loginRegisterErrors, standardErrorContainer } from './errors'
import { profPicDefaultURL, sheirBlue, sheirGreen } from './variableDefaults'
import { standardSpinner } from './spinners'
import { securityQuestionAndAnswerGridItems } from './securityQuestionsAndAnswers'
import { acceptTOSCheckbox } from './checkboxes.js'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'


// Proceed to next view, depending on the fromComponent
export const proceedToNextView = (fromComponent, navigate, setAllAuthViewIndex) => {

  if (fromComponent === '/login' || fromComponent === '/create-estate') {
    setAllAuthViewIndex(1)
  } 
}

// Reset all auth errors and Loadings
export const resetAllAuthErrors = ( setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister) => {
  // Reset error states
  setLoginErrors(false)
  setRegisterErrors({})

  // Reset Loading states
  setLoadingLogin(false)
  setLoadingRegister(false)
}

// Switches between login and register forms
const handleSwitchLoginRegister = async (e, fromComponent, loginViewIndex, setLoginViewIndex, setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister) => {
  e.preventDefault()

  // Toggle between login and register view indexes
  setLoginViewIndex(loginViewIndex === 0 ? 1 : 0)

  // reset all error states
  resetAllAuthErrors( setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister)
}

// Code inside 'try' part of try/catch when attempting a login
const loginTry = async (fromComponent, loginOrRegister, setLoading, setErrors, formToUpload, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture) => {
  
  // Set Loading
  setLoading(true)

  // Upload Login/Register Form to server
  // const { data } = await axios.post('/api/auth/', formToUpload)
  const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/${loginOrRegister}/`, formToUpload)

  // Set these to local storage after answering security question
  setToken(data.token)
  setEmail(data.email)
  setProfilePicture(data.profilePicture)

  // Depending on the component the login occurs in, go to the appropriate next view
  proceedToNextView(fromComponent, navigate, setAllAuthViewIndex)

  // Reset view
  setLoginViewIndex(0)

  // Set loading and login errors to false
  setLoading(false)
  setErrors(false)
}

// Code inside 'catch' part of try/catch when attempting to login
const loginCatch = async (err, setLoading, setErrors) => {
  // console.log('err: ', err.message)
  setLoading(false)
  setErrors(true)
}

// Code inside 'catch' part of try/catch when attempting to register
// Necessary because we want Register error messages to be more specific than login error messages
const registerCatch = async (err, setLoading, errors, setErrors) => {
  // console.log('err ->', err.response)
  const errorObj = err.response.data.errors
  const messageToReturn = errorObj[Object.keys(errorObj)[0]].message.includes('Value:') ? errorObj[Object.keys(errorObj)[0]].message.split(' Value:')[0] : errorObj[Object.keys(errorObj)[0]].message

  // console.log('error message ->', errorObj[Object.keys(errorObj)[0]].message)
  // console.log('message to return ->', messageToReturn)

  setLoading(false)
  setErrors({ ...errors, [Object.keys(errorObj)[0]]: messageToReturn })
}

// The try/catch when attempting to log in
const loginTryCatch = async (fromComponent, setLoading, setErrors, formToUpload, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture) => {
  try {
    await loginTry(fromComponent, 'login', setLoading, setErrors, formToUpload, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)

  } catch (err) {
    await loginCatch(err, setLoading, setErrors)
  }
}


// Email/Password login attempt submitted
const handleSubmitLogin = async (e, fromComponent, loginForm, setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture) => {
  e.preventDefault()

  // reset all error states
  resetAllAuthErrors(setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister)

  if (loginForm.email && loginForm.password) {

    // Upload login form to server to execute login
    await loginTryCatch(fromComponent, setLoadingLogin, setLoginErrors, loginForm, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)

  } else {
    // console.log('login form not complete')

    // Set errors to true if the email or password is missing
    setLoadingLogin(false)
    setLoginErrors(true)
  }
}

// Email/Password register attempt submitted
const handleSubmitRegister = async (e, fromComponent, registerForm, registerErrors, setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture) => {
  e.preventDefault()

  // reset all error states
  resetAllAuthErrors(setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister)

  // If none of the form fields are empty, POST the form data to the register endpoint and then navigate to /login
  if (registerForm.givenName && registerForm.familyName && registerForm.email && registerForm.password && registerForm.passwordConfirmation && (registerForm.password === registerForm.passwordConfirmation) && registerForm.securityQuestions[2].a) {
    try {

      // upload register form to server to execute login/register
      await loginTry(fromComponent, 'register', setLoadingRegister, setRegisterErrors, registerForm, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)

    } catch (err) {
      // console.log('register error ->', err)

      // Catch Register errors if there are any
      await registerCatch(err, setLoadingRegister, registerErrors, setRegisterErrors)

    }
  } else if (registerForm.password !== registerForm.passwordConfirmation) {
    // console.log('password does not match password confirmation')

    // If there's an error, pass this message
    setLoadingRegister(false)
    setRegisterErrors({ ...registerErrors, passwordConfirmation: 'password and password confirmation do not match' })
  } else {
    // console.log('register form not complete')

    // If there's an error, pass this message
    setLoadingRegister(false)
    setRegisterErrors({ ...registerErrors, passwordConfirmation: 'All fields are required' })
  }
}

// Email/Password Login Elements
export const inProcessLogin = (fromComponent, loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin, loginViewIndex, setLoginViewIndex, setRegisterErrors, setLoadingRegister, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture) => {

  return (
    <Box sx={{ 
      width: '85%',
      maxWidth: '400px',
      mt: 2, mb: 2,
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
    }}>
      {
        loadingLogin ?
          standardSpinner()
          :
          <>
            {/* Login Form */}
            <Box component="form" 
              onSubmit={(e) => handleSubmitLogin(e, fromComponent, loginForm, setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)} 
              noValidate 
              sx={{ 
                mt: 1, mb: 1,
              }}
            >
              {/* Grid Necessary for standardized spacing */}
              <Grid container spacing={2}>
              
                {/* Email */}
                <Grid item xs={12}>
                  {loginTextField('email', 'Email Address', loginForm.email, 'outlined', true, true, true, 'email', loginForm, setLoginForm, setLoginErrors, handleChange )}
                </Grid>
                
                {/* Password */}
                <Grid item xs={12}>
                  {loginTextField('password', 'Password', loginForm.password, 'outlined', true, true, false, 'current-password', loginForm, setLoginForm, setLoginErrors, handleChange )}
                </Grid>

              </Grid>
              
              {/* Errors if there are errors */}
              {loginRegisterErrors(loginErrors, 'Unauthorised.', 1, 0)}

              {/* Submit button */}
              <Grid container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12}>
                  {standardSubmitButton('Sign In', 'submit', false, 'primary', 2, 2, '150px', '50px')}
                </Grid>
              </Grid>

              {/* Link to Register page for people without accounts */}
              <Grid container>
                <Grid item xs={12} >
                  {handleSwitchLoginRegisterButton('No account yet? Sign Up', handleSwitchLoginRegister, fromComponent, loginViewIndex, setLoginViewIndex, setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister)}
                </Grid>

                {/* Link to Reset Password */}
                {fromComponent === '/login' && loginViewIndex === 0 &&
                  <Grid item xs={12} >
                    <Button 
                      onClick={(e) => navigate('/reset-password')} 
                      variant="text" 
                      color='secondary'
                      sx={{ 
                        mt: 0,
                        width: '225px', 
                        height: '40px', 
                        display: 'flex', flexDirection: 'row', justifyContent: 'left',
                      }}
                    >
                      Reset Password
                    </Button>
                  </Grid>
                }
                
                
              </Grid>
            </Box>
          </>
      }
    </Box>
  )
}

// Email/Password Register Elements
export const inProcessRegister = (fromComponent, registerForm, setRegisterForm, registerErrors, setRegisterErrors, loadingRegister, setLoadingRegister, acceptedTOS, setAcceptedTOS, loginViewIndex, setLoginViewIndex, setLoginErrors, setLoadingLogin, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture) => {
  return (
    <Box sx={{ 
      width: '85%',
      maxWidth: '400px',
      mt: 2, mb: 2,
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
    }}>
      {
        loadingRegister ?
          standardSpinner()
          :
          <>
            {/* Register Form */}
            <Box component="form" noValidate 
              onSubmit={(e) => handleSubmitRegister(e, fromComponent, registerForm, registerErrors, setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister, setLoginViewIndex, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)} 
              sx={{ 
                mt: 3, 
              }}
            >
              {/* Grid necessary for standardized spacing */}
              <Grid container spacing={2} sx={{ maxWidth: '400px' }}>
                
                {/* First Name */}
                <Grid item xs={12}>
                  {loginTextField('givenName', 'First Name', registerForm.givenName, 'outlined', true, true, true, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Grid>

                {/* Error message under givenName if it's a givenName error */}
                {loginRegisterErrors(registerErrors.givenName, registerErrors.givenName, 0, 1)}
                
                {/* Last Name */}
                <Grid item xs={12}>
                  {loginTextField('familyName', 'Last Name', registerForm.familyName, 'outlined', true, true, false, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Grid>

                {/* Error message under familyName if it's a familyName error */}
                {loginRegisterErrors(registerErrors.familyName, registerErrors.familyName, 0, 1)}

                {/* Email */}
                <Grid item xs={12}>
                  {loginTextField('email', 'Email Address', registerForm.email, 'outlined', true, true, false, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Grid>

                {/* Error message under email if it's an email error */}
                {loginRegisterErrors(registerErrors.email, registerErrors.email, 0, 1)}

                {/* Password */}
                <Grid item xs={12}>
                  {loginTextField('password', 'Password', registerForm.password, 'outlined', true, true, false, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Grid>

                {/* Error message under password if it's a password error */}
                {loginRegisterErrors(registerErrors.password, registerErrors.password, 0, 1)}

                {/* Password Confirmation */}
                <Grid item xs={12}>
                  {loginTextField('passwordConfirmation', 'Password Confirmation', registerForm.passwordConfirmation, 'outlined', true, true, false, 'new-password', registerForm, setRegisterForm, setRegisterErrors, handleChange )}
                </Grid>

                {/* Error message under password confirmation if it's a password confirmation error */}
                {loginRegisterErrors(registerErrors.passwordConfirmation, registerErrors.passwordConfirmation, 0, 1)}

                {/* Security Questions and Answers */}
                {securityQuestionAndAnswerGridItems(registerForm, setRegisterForm)}

              </Grid>
              
              {/* Terms of Service and Privacy Policy Checkbox */}
              <Grid item xs={12} 
                sx={{ 
                  fontSize: '13px',
                  pl: 1, pr: 1, pt: 1,
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap',
                  overflow: 'visible',
                }}
              >
                {acceptTOSCheckbox('acceptedTOS', acceptedTOS, setAcceptedTOS)}
              </Grid>

              {/* Register Button */}
              <Grid container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12}>
                  {standardSubmitButton(
                    'Register', 
                    'submit', 
                    !(registerForm.hasOwnProperty('givenName') && registerForm.givenName.length > 0 && registerForm.hasOwnProperty('familyName') && registerForm.familyName.length > 0 && registerForm.hasOwnProperty('email') && registerForm.email.length > 0 && registerForm.hasOwnProperty('password') && registerForm.password.length > 0 && registerForm.hasOwnProperty('passwordConfirmation') && registerForm.passwordConfirmation.length > 0 && acceptedTOS && registerForm.hasOwnProperty('securityQuestions') && registerForm.securityQuestions.length === 3 && registerForm.securityQuestions[2].q.length > 0 && registerForm.securityQuestions[2].a.length > 0), 
                    'primary', 
                    2, 
                    2, 
                    '150px', 
                    '50px')}
                </Grid>

                {/* Error message under givenName if it's a givenName error */}
                {loginRegisterErrors(registerErrors.givenName, registerErrors.givenName, 0, 1)}

                {/* Error message under familyName if it's a familyName error */}
                {loginRegisterErrors(registerErrors.familyName, registerErrors.familyName, 0, 1)}

                {/* Error message under email if it's an email error */}
                {loginRegisterErrors(registerErrors.email, registerErrors.email, 0, 1)}

                {/* Error message under password if it's a password error */}
                {loginRegisterErrors(registerErrors.password, registerErrors.password, 0, 1)}

                {/* Error message under password confirmation if it's a password confirmation error */}
                {loginRegisterErrors(registerErrors.passwordConfirmation, registerErrors.passwordConfirmation, 0, 1)}
              </Grid>

              {/* Switch to Login Button */}
              <Grid container>
                <Grid item xs={12}>
                  {handleSwitchLoginRegisterButton('Already have an account? Log in', handleSwitchLoginRegister, fromComponent, loginViewIndex, setLoginViewIndex, setLoginErrors, setRegisterErrors, setLoadingLogin, setLoadingRegister)}
                </Grid>
              </Grid>
            </Box>
          </>
      }
    </Box>
  )
}

// All Auth Elements: Email/Password Login and Register Options
export const inProcessAllAuth = (fromComponent, loginViewIndex, setLoginViewIndex, loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin, registerForm, setRegisterForm, registerErrors, setRegisterErrors, loadingRegister, setLoadingRegister, acceptedTOS, setAcceptedTOS, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture ) => {

  return (
    <>
      {/* Advisory */}
      {fromComponent === '/create-estate' &&
        <Typography 
          sx={{
            color: 'black',
          }}
        >
          <em>Log in or register to create estate</em>
        </Typography>
      }

      {/* Login Form */}
      {loginViewIndex === 0 && 
        inProcessLogin(fromComponent, loginForm, setLoginForm, loginErrors, setLoginErrors, loadingLogin, setLoadingLogin, loginViewIndex, setLoginViewIndex, setRegisterErrors, setLoadingRegister, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)
      }

      {/* Register Form */}
      {loginViewIndex === 1 &&
        inProcessRegister(fromComponent, registerForm, setRegisterForm, registerErrors, setRegisterErrors, loadingRegister, setLoadingRegister, acceptedTOS, setAcceptedTOS, loginViewIndex, setLoginViewIndex, setLoginErrors, setLoadingLogin, navigate, setToken, setAllAuthViewIndex, setEmail, setProfilePicture)
      }

    </>
  )
}