/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */

import React from 'react'

// mui
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import { Link } from '@mui/material'

import circlesSpinningGIF from '../assets/spinner-circles-orbiting-g.gif'
import { circularSpinner } from './spinners'

import PhotoCamera from '@mui/icons-material/PhotoCamera'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import InstagramIcon from '@mui/icons-material/Instagram'
import YouTubeIcon from '@mui/icons-material/YouTube'
import TwitterIcon from '@mui/icons-material/Twitter'
import { ReactComponent as TikTokIcon } from '../assets/tiktok-logo.svg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import CircularProgress from '@mui/material/CircularProgress'

import { sheirBlue } from './variableDefaults'

import { Input } from './globalHelpers'

// Standard Button
export const standardButton = (buttonText, type, variant, disabled = false, color, topMargin, bottomMargin, marginX, buttonWidth, buttonHeight, clickHandler) => {
  return (
    <Button 
      disabled={disabled}
      type={type}
      fullWidth
      variant={variant}
      color={color}
      onClick={clickHandler} 
      sx={{ 
        mt: topMargin, 
        mb: bottomMargin, 
        mx: marginX, 
        width: buttonWidth,
        height: buttonHeight,
      }}
    >
      {buttonText}
    </Button>
  )
}

// Standard Button
export const onboardingForwardButton = (buttonText, type, variant, disabled = false, color, topMargin, bottomMargin, marginX, buttonWidth, buttonHeight, clickHandler) => {
  return (
    <Button 
      disabled={disabled}
      type={type}
      fullWidth
      variant={variant}
      color={color}
      onClick={(e) => clickHandler(e)} 
      sx={{ 
        mt: topMargin, 
        mb: bottomMargin, 
        mx: marginX, 
        width: buttonWidth,
        height: buttonHeight,
      }}
    >
      {buttonText}
    </Button>
  )
}

// Standard Submit Button (No click handler)
export const standardSubmitButton = (buttonText, type, disabled = false, color, topMargin, bottomMargin, buttonWidth, buttonHeight) => {
  return (
    <Button
      disabled={disabled}
      type={type}
      fullWidth
      variant="contained"
      color={color}
      sx={{ 
        mt: topMargin, 
        mb: bottomMargin, 
        width: buttonWidth,
        height: buttonHeight,
      }}
    >
      {buttonText}
    </Button>
  )
}

// Choose Security Method Button (different click handler)
export const chooseSecurityMethodButton = (buttonText, type, variant, disabled = false, color, topMargin, bottomMargin, marginX, buttonWidth, buttonHeight, clickHandler, setAllAuthViewIndex, token, securityQuestionIndex, setSecurityQuestion) => {
  // console.log('chooseSecurityMethodButton setAllAuthViewIndex ->', setAllAuthViewIndex)
  // console.log('click handler ->', clickHandler)
  return (
    <Button 
      disabled={disabled}
      type={type}
      fullWidth
      variant={variant}
      color={color}
      onClick={() => clickHandler(setAllAuthViewIndex, token, securityQuestionIndex, setSecurityQuestion)}
      sx={{ 
        mt: topMargin, 
        mb: bottomMargin, 
        mx: marginX, 
        width: buttonWidth,
        height: buttonHeight,
      }}
    >
      {buttonText}
    </Button>
  )
}

// Answer Security Question Button (different click handler)
export const answerSecurityQuestionButton = (buttonText, codeOrQuestion, type, variant, value, attemptsRemaining, setAttemptsRemaining, disabled = false, color, topMargin, bottomMargin, marginX, buttonWidth, buttonHeight, clickHandler, token, setSecurityCodeErrors, email, profilePicture, navigate, securityQuestionIndex, urlPath, setAuthenticated) => {
  
  return (
    <Button 
      disabled={disabled}
      type={type}
      fullWidth
      variant={variant}
      color={color}
      onClick={() => clickHandler(codeOrQuestion, value, attemptsRemaining, setAttemptsRemaining, token, setSecurityCodeErrors, email, profilePicture, navigate, securityQuestionIndex, urlPath, setAuthenticated)}
      sx={{ 
        mt: topMargin, 
        mb: bottomMargin, 
        mx: marginX, 
        width: buttonWidth,
        height: buttonHeight,
      }}
    >
      {buttonText}
    </Button>
  )
}

// Handle Switch Between Login and Register
export const handleSwitchLoginRegisterButton = (buttonText, changeHandler, fromComponent, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister) => {
  return (
    <Button
      onClick={(e) => changeHandler(e, fromComponent, loginViewIndex, setLoginViewIndex, setGoogleLoginErrors, setFacebookLoginErrors, setLoginErrors, setRegisterErrors, setLoadingGoogle, setLoadingFacebook, setLoadingLogin, setLoadingRegister)} 
      color='secondary'
      sx={{ 
        textAlign: 'left',
      }}
    >
      {buttonText}
    </Button>
  )
}

// Replace an Existing image  — Image element with photoCamera IconButton 
export const replaceImageWithIconButton = (srcImage, previousImage, setPreviousImage, dimension, id, name, changeHandler, mb, loading = false, setLoadingProfPic, resetErrors, imageName, uploadModifiedUser, form, setForm, keyName, profPicErrors) => {
  return (
    <Box sx={{ mb: mb, textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
      {loading ?
        <Box sx={{ height: dimension, width: dimension, objectFit: 'cover', boxShadow: 2 }}> 
          {circularSpinner()}
        </Box>
        :
        <Box component='img' src={srcImage} alt='Image to upload' sx={{ height: dimension, width: dimension, objectFit: 'cover', boxShadow: 2 }} /> 
      }
      <label htmlFor={id}>
        <Input 
          id={id} 
          name={name} 
          className={name} 
          accept="image/*" 
          type="file" 
          onChange={(e) => changeHandler(e, previousImage, setPreviousImage, setLoadingProfPic, resetErrors, imageName, uploadModifiedUser, form, setForm, keyName, profPicErrors)}
        />
        <IconButton aria-label="upload picture" component="span" sx={{ bottom: 50, border: 2, borderColor: 'white', boxShadow: 3, backgroundColor: 'rgba(170,170,170,0.5)' }} >
          <PhotoCamera fontSize="small"/>
        </IconButton>
      </label>
    </Box>
  )
}

// Add new Image Button — button with text inside that's intertwined with an add image label/Input
export const addImageWithTextButton = (buttonText, id, name, changeHandler) => {
  return (
    <label htmlFor={id}>
      <Input 
        id={id} 
        name={name} 
        className={name} 
        accept="image/*" 
        type="file" 
        onChange={changeHandler}
      />
      <Button variant="contained" component="span" sx={{ mb: 1 }}>
        {buttonText}
      </Button>
    </label>
  )
}

// Delete a person Item; Occurs in onboarding process when adding people
export const deletePersonItemIconButton = (itemIndex, clickHandler, disabled) => {
  return (
    <Box 
      className={`${itemIndex}-item`} 
      onClick={clickHandler} 
      sx=
        {{ 
          borderRadius: '50%', display: 'flex', alignItems: 'center', 
        }} 
    >
      <IconButton 
        aria-label="delete-item" 
        name={`${itemIndex}-index`}
        className={`${itemIndex}-index`} 
        disabled={disabled}
        sx={{ 
          width: '30px', height: '30px', 
          mb: 1, mt: 1, mr: 2, ml: 2, bottom: 0, left: 0, 
          border: 2, borderColor: 'white', boxShadow: 3, 
        }} 
      >
        <CloseOutlinedIcon 
          key={`${itemIndex}`} 
          fontSize="small" 
          color= {disabled ? '' : 'error'}
          sx={{  }} 
        />
      </IconButton>
    </Box>
  )
}

// Social Media Buttons Elements
export const socialMediaButtons = (flexDirection = 'row', justifyContent = 'flex-end', alignItems = 'center', bottomMargin = 0, fromFooter = false) => {
  return (
    <Box sx={{ 
      mb: bottomMargin,
      display: 'flex', justifyContent: { justifyContent }, flexDirection: { flexDirection }, alignItems: { alignItems }, 
    }}>
            
      <Link href="https://www.instagram.com/sheir.estate/" className='instagram' target="_blank" >
        <IconButton id='instagram' aria-label="upload picture" component="span" sx={{ width: '40px', height: '40px', m: 1, border: 2, borderColor: 'black', boxShadow: 3, backgroundColor: 'white', '&:hover': { backgroundColor: sheirBlue } }} >
          <InstagramIcon sx={{ color: '#000000' }}/>
        </IconButton>
      </Link>

      {/* <Link href="https://www.tiktok.com/@3d.wrap" className='tiktok' target="_blank" >
        <IconButton id='tiktok' aria-label="upload picture" component="span" sx={{ width: '40px', height: '40px', m: 1, border: 2, borderColor: 'black', boxShadow: 3, backgroundColor: 'white' }} >
          <TikTokIcon color='primary' sx={{ color: '#000000' }}/>
        </IconButton>
      </Link>
            
      <Link href="https://www.youtube.com/channel/UCSxlfIDKJu2FT2BoqnHHUXw" className='youtube' target="_blank" >
        <IconButton id='youtube' aria-label="upload picture" component="span" sx={{ width: '40px', height: '40px', mr: fromFooter ? 0 : 1, ml: 1, my: 1, border: 2, borderColor: 'black', boxShadow: 3, backgroundColor: 'white' }} >
          <YouTubeIcon sx={{ color: '#000000' }}/>
        </IconButton>
      </Link> */}
            
      {/* <Link href="https://www.twitter.com/" className='twitter' target="_blank" >
        <IconButton id='youtube' aria-label="upload picture" component="span" sx={{ width: '40px', height: '40px', mr: fromFooter ? 0 : 1, ml: 1, my: 1, border: 2, borderColor: 'black', boxShadow: 3, backgroundColor: 'white' }} >
          <TwitterIcon sx={{ color: '#000000' }}/>
        </IconButton>
      </Link> */}
    </Box>
  )
}

// Up and Down arrows in Draft Planning
export const upDownArrowElement = (direction, disabled, clickHandler, setEstate, estate, itemIndex) => {

  return (
    <IconButton 
      disabled={disabled}
      color='primary' 
      size="small"
      onClick={(e) => clickHandler(e, setEstate, estate, itemIndex, direction)} 
      sx={{ 
        boxShadow: 3, 
        mb: direction === 'up' ? 2 : 0, 
        mt: direction === 'down' ? 2 : 0, 
        mx: 4, 
      }}
    >
      {direction === 'up' 
        ? 
        <ArrowUpwardIcon /> 
        : 
        <ArrowDownwardIcon /> 
      }
    </IconButton>
  )
}


// Drag/Drop IconButton (For draft planning)
export const dragAndDropIconElement = (disabled, ref) => {

  return (
    <IconButton 
      disableRipple
      // ref={ref}
      disabled={disabled}
      color='secondary' 
      size="small"
      // onClick={(e) => clickHandler(e, setEstate, estate, itemIndex, direction)} 
      sx={{ 
        // boxShadow: 3, 
        // mb: direction === 'up' ? 2 : 0, 
        // mt: direction === 'down' ? 2 : 0, 
        mx: 4, 
        cursor: 'move',
      }}
    >
      <DragIndicatorIcon /> 
    </IconButton>
  )
}