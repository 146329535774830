//mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs'

// Date selector, and a switch beside it
export const eventDateElement = (label, formData, setFormData, keyValue, disabled, fromComponent, modifyInDatabase, setEditSettingsError) => {
  // console.log('formData.event[0][keyValue]', dayjs(formData.event[0][keyValue]))
  // console.log('fromComponent ->', fromComponent)

  return (         
    <Box sx={{ width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', mb: fromComponent === 'addItem' ? 0 : 1, mt: 1 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          disabled={disabled}
          disablePast={keyValue === 'startDate' ? true : false}
          disableHighlightToday={keyValue === 'startDate' ? true : false}
          disableFuture={keyValue === 'startDate' ? false : true}
          label={label}
          openTo="year"
          views={keyValue === 'startDate' ? ['year', 'month', 'day', 'hours', 'minutes'] : ['year', 'month', 'day']}
          maxDate={keyValue === 'startDate' ? dayjs().add(15, 'year') : dayjs()}
          minDate={keyValue === 'startDate' && fromComponent === 'onboarding' ? dayjs().add(7, 'day') : keyValue === 'startDate' && fromComponent === 'ownerPersonSpace' ? dayjs().add(2, 'day') : dayjs().subtract(15, 'year') }
          value={
            fromComponent === 'ownerPersonSpace' ? 
              dayjs(formData.event[0][keyValue]) 
              : 
              formData[keyValue]
          }
          onChange={(newValue) => {
            // console.log('new value ->', newValue)
            // console.log('form data ->', formData)

            if (fromComponent === 'ownerPersonSpace') {
              const newEvent = [{ ...formData.event[0], [keyValue]: newValue }]

              // If modifying date of event in settings, modified date must be at least 48 hours away
              if (newValue > dayjs().add(2, 'day')) {
                modifyInDatabase('events', newEvent[0]._id, { [keyValue]: newValue }, setEditSettingsError)
              }
              
              setFormData({ ...formData, event: newEvent })
              
            } else {
              setFormData({ ...formData, [keyValue]: newValue })
            }

          }}
          // inputFormat="MMMM D, YYYY h:mm A (UTC Z)"
          inputFormat={ keyValue === 'startDate' ? 'MMMM D, YYYY h:mm A' : 'MMMM D, YYYY' }
          disableMaskedInput
          // inputFormat="DD-MM-YYYY HH:MM O"
          // format="E MMM dd yyyy HH:MM:SS O"
          renderInput={(params) => 
            <TextField 
              {...params} 
              focused 
              color='primary' 
              size='small'
              sx={{
                width: '100%',
              }}
            />
          }
        />
      </LocalizationProvider>
      
    </Box>
  )
}