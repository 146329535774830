/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import useWindowDimensions from '../../helpers/windowDimensions'
import { getUnassignedItems } from '../../helpers/getValuationTotals'
import { itemCategories, reservedForOptions, positionChangeWidthSm, positionChangeHeightSm, positionChangeWidthMd, navbarHeight, estateNavbarHeight, footerHeight } from '../../helpers/variableDefaults'
import { personItemTextField, descriptionTextField } from '../../helpers/textfields'
import { userIsAuthenticated, getPayload, getTokenFromLocalStorage } from '../../helpers/storage'
import { eventDateElement } from '../../helpers/dateAndTime'
import { selectElement, allotmentSelectElement } from '../../helpers/selects'
import { standardButton, addImageWithTextButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { replaceImageWithIconButton } from '../../helpers/buttons'
import { returnSquareImage, uploadAWSFileReactS3 } from '../../helpers/imageHandling'
import { standardErrorContainer, couldNotLoadPageError } from '../../helpers/errors'
import { getRemainingGreaterThanValuation } from '../../helpers/getValuationTotals'
import dayjs from 'dayjs'

import seedAnimalImage from '../../assets/seed-category-animal.jpg'
import seedArtImage from '../../assets/seed-category-art.jpg'
import seedClothingImage from '../../assets/seed-category-clothing.jpg'
import seedEquityImage from '../../assets/seed-category-equity.jpg'
import seedFurnitureImage from '../../assets/seed-category-furniture.jpg'
import seedJewelryImage from '../../assets/seed-category-jewelry.jpg'
import seedKeepsakeImage from '../../assets/seed-category-keepsake.jpg'
import seedRealEstateImage from '../../assets/seed-category-real-estate.jpg'
import seedStockImage from '../../assets/seed-category-stock.jpg'
import seedVehicleImage from '../../assets/seed-category-vehicle.jpg'
import seedLiquidityImage from '../../assets/seed-category-liquidity.jpg'
import seedMiscellaneousImage from '../../assets/seed-category-miscellaneous.jpg'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { modifyInDatabase } from '../../helpers/modifyInDatabase'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Add or Edit Item Page
const AddItem = (props) => {
  // console.log('AddItem component runs')

  // Destructure Props
  const { isTestEstate, testEstate, setTestEstate, testUserPerson, testItemSelected, setTestItemSelected, viewIndex, setViewIndex } = props
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId, itemId } = useParams()

  // Payload
  const payload = getPayload()

  // Item
  const [itemObj, setItemObj] = useState({
    estateId: estateId ? estateId : '',
    itemName: '',
    imageURL: '',
    valuation: '',
    valuationDate: dayjs(),
    category: '',
    description: '',
    allottedToName: '',
    giftFor: '',
  })
  const [previousImage, setPreviousImage] = useState('') // For deleting the old image if the item image is updated
  const categoryURLStarter = `https://sheir-bizops-images.s3.amazonaws.com/seed-category-`
  const categoryFolderStarter = `static/media/seed-category-`
  
  // An object with all categories and their associated default image
  const categoryImagesObj = {}
  for (let i = 0; i < itemCategories.length; i++) {
    categoryImagesObj[itemCategories[i].replace(/\s+/g, '-').toLowerCase()] = itemCategories[i] === 'Animal' ? seedAnimalImage : itemCategories[i] === 'Art' ? seedArtImage : itemCategories[i] === 'Clothing' ? seedClothingImage : itemCategories[i] === 'Equity' ? seedEquityImage : itemCategories[i] === 'Furniture' ? seedFurnitureImage : itemCategories[i] === 'Jewelry' ? seedJewelryImage : itemCategories[i] === 'Keepsake' ? seedKeepsakeImage : itemCategories[i] === 'Liquidity' ? seedLiquidityImage : itemCategories[i] === 'Real Estate' ? seedRealEstateImage : itemCategories[i] === 'Stock' ? seedStockImage : itemCategories[i] === 'Vehicle' ? seedVehicleImage : seedMiscellaneousImage
  }
  // console.log('categoryImagesObj ->', categoryImagesObj)

  // For setting max valuation date
  const today = new Date()
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
  
  // Estate
  const [estate, setEstate] = useState({})
  const [allotToOptions, setAllotToOptions] = useState([ ...reservedForOptions ]) // Array of options for who to allot the item to; The heirs will but added to the front of this when the Estate loads

  // Loading
  const [loading, setLoading] = useState(false)
  const [loadingImage, setLoadingImage] = useState(false)

  // Errors
  const [pageLoadErrors, setPageLoadErrors] = useState(false)
  const [uploadErrors, setUploadErrors] = useState(false)

  const getItemToEdit = async () => {
    // console.log('getItemToEdit runs')

    const { data: retrievedItem } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/items/${itemId}`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    })

    // console.log('retrievedItem ->', retrievedItem)

    setItemObj(retrievedItem)
    setPreviousImage(retrievedItem.imageURL)

  }

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {
    // console.log('useEffect runs')

    if (isTestEstate) {
      setEstate(testEstate)
      // console.log('testEstate ->', testEstate)

      // Adding Heirs and 'Unassigned' to the front of allToOptions
      const allotOptionsAdded = [ ...allotToOptions ]
      testEstate.people.filter(person => person.role === 'Heir').map( heir => (
        !allotOptionsAdded.includes(heir.userGivenAndFamilyName) 
        &&
        allotOptionsAdded.unshift(heir.userGivenAndFamilyName)
      ))
      !testEstate.event[0].hasBegunTimer && !allotOptionsAdded.includes('Unassigned') && allotOptionsAdded.unshift('Unassigned')
      // console.log('allotOptionsAdded', allotOptionsAdded)
      setAllotToOptions(allotOptionsAdded)

      // If the user is adding a new item, pre-populate "allottedToName" with "Unassigned"
      // console.log('testItemSelected ->', testItemSelected)
      // console.log('Object.keys(testItemSelected) ->', Object.keys(testItemSelected))
      if (Object.keys(testItemSelected).length === 0) {
        // console.log('no test item')
        setItemObj({ ...itemObj, estateId: testEstate._id, allottedToName: testEstate.event[0].hasBegunTimer ? 'Liquidate' : 'Unassigned' })
      } else {
        // console.log('test item exists')
        setItemObj(testItemSelected)
        setPreviousImage(testItemSelected.imageURL)
      }
    } else if (!userIsAuthenticated()) {
      navigate('/')
    } else {
      const getData = async () => {
        setLoading(true)
        try {
          const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved Estate ->', retrievedEstate)

          const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)
          
          if (userIsInEstate.length > 0 && retrievedEstate.subscriptionIsActive && (userIsInEstate[0].role !== 'Heir' || (retrievedEstate.hasOwnProperty('event') && retrievedEstate.event[0].hasOwnProperty('silentOrParticipatory') && retrievedEstate.event[0].silentOrParticipatory === 'Participatory'))) {
            // console.log('userIsInEstate people ->', retrievedEstate.people)
            // Set States
            if (userIsInEstate[0].readWriteAdmin === 'admin' || userIsInEstate[0].readWriteAdmin === 'write') {
              
              if (itemId) {
                // console.log('itemId in params')
                await getItemToEdit()
              }
              
              setEstate(retrievedEstate)

              // Adding Heirs and 'Unassigned' to the front of allToOptions
              const allotOptionsAdded = [ ...allotToOptions ]
              retrievedEstate.people.filter(person => person.role === 'Heir').map( heir => (
                !allotOptionsAdded.includes(heir.userGivenAndFamilyName) 
                &&
                allotOptionsAdded.unshift(heir.userGivenAndFamilyName)
              ))
              !retrievedEstate.event[0].hasBegunTimer && !allotOptionsAdded.includes('Unassigned') && allotOptionsAdded.unshift('Unassigned')
              // console.log('allotOptionsAdded', allotOptionsAdded)
              setAllotToOptions(allotOptionsAdded)

              // If the user is adding a new item, pre-populate "allottedToName" with "Unassigned"
              if (!itemId) {
                setItemObj({ ...itemObj, allottedToName: retrievedEstate.event[0].hasBegunTimer ? 'Liquidate' : 'Unassigned' })
              }

            } else {
              navigate(`/estate/${estateId}/family-hq`)
            }
            
            setPageLoadErrors(false)
            setLoading(false)

          } else {
            navigate(`/user/${payload.sub}`)

            setPageLoadErrors(false)
            setLoading(false)
          }

        } catch (error) {
          // console.log(error)

          navigate(`/user/${payload.sub}`)

          setPageLoadErrors(true)
          setLoading(false)
        }
      }

      getData()
    }

  }, [])

  const handleItemChange = async (e, setItemObj, itemObj) => {
    // console.log('handleItemChange runs')
    // console.log('itemObj ->', itemObj)

    // Create name and value variables
    let name
    let value

    // Assign values to name and value depending on the type of event target
    if (!e.target.name) {
      name = e.target.classList[1]
      value = e.target.classList[0].slice(5)
    } else {
      name = e.target.name
      value = e.target.value
    }
    // console.log('name ->', name)
    // console.log('value ->', value)

    if (name === 'allottedToName' && estate.people.filter(person => person.userGivenAndFamilyName === value && person.role === 'Heir').length > 0) {
      // If it's given to an heir (rather than a gift)
      const heirPerson = estate.people.filter(person => person.userGivenAndFamilyName === value)
      
      setItemObj({ ...itemObj, [name]: value, allottedToPersonId: heirPerson[0]._id })
    } else if (name === 'allottedToName' && itemObj.hasOwnProperty('allottedToPersonId')) {
      // If it was previously allotted to an Heir but is not anymore, delete the 'allottedToPersonId' key from the itemObj before setting 'allottedToName' to the new value
      const itemObjToEdit = { ...itemObj }
      delete itemObjToEdit.allottedToPersonId
      setItemObj({ ...itemObjToEdit, [name]: value })
    } else if (name === 'category' && (itemObj.imageURL.length === 0 || itemObj.imageURL.includes('/seed-category-'))) {
      // Autopopulate the image with a default category image if the image is blank or if the category changes to a different one
      setItemObj({ ...itemObj, [name]: value, imageURL: `${categoryImagesObj[value.replace(/\s+/g, '-').toLowerCase()]}` })
    } else {
      // Otherwise just set the name/value pairing to the itemObj
      setItemObj({ ...itemObj, [name]: value })
    }
    
  }

  // Adding a new image when the user selects the Upload Image or PhotoCamera button; this is only uploaded to server when User presses Save/Create
  const handleImageSelect = async (e) => {
    if (e.target.files.length > 0) {
      // Set errors to false and loading to true
      setUploadErrors(false)
      setLoadingImage(true)

      // Center image and crop it into a 350x350 jpg; save a dataURL onto the formdata as profilePicture
      const newSquareImage = await returnSquareImage(e.target.files[0])

      setItemObj({ ...itemObj, imageURL: newSquareImage })
    
      setLoadingImage(false)
    }
  }

  // Delete Previous Image
  const deletePreviousImage = async () => {
    // console.log('previousImage ->', previousImage)
    if (previousImage 
      && previousImage.includes(estateId) 
      && previousImage.includes(process.env.REACT_APP_S3_ITEM_BUCKET)
      && !previousImage.includes(categoryURLStarter)
      && !previousImage.includes(categoryFolderStarter)
    ) {
      const requestObject = {
        bucketType: 'item',
        urlString: previousImage,
      }
      axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/awsImageURL`, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
        data: requestObject,
      })
    }
  }

  const handleCancelPressed = async () => {
    // console.log('handleCancelPressed')
    if (isTestEstate) {
      setTestItemSelected({})
      setViewIndex(2)
    } else {
      navigate(`/estate/${estateId}/items`)
    }
  }

  // Delete the Item
  const handleDeletePressed = async () => {

    if (isTestEstate) {
      setLoading(true)
      const newEstateItems = [ ...estate.items ]
      const indexOfItemToDelete = newEstateItems.findIndex(item => item._id === itemObj._id)
      newEstateItems.splice(indexOfItemToDelete, 1)
      setTestEstate({ ...testEstate, items: newEstateItems })

      setTestItemSelected({})
      setViewIndex(2)
      setLoading(false)
    } else {

      setLoading(true)
      
      try {
        await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/items/${itemId}`, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })

        // Delete old prof pic from AWS if it is not the seed pic
        deletePreviousImage()

        navigate(`/estate/${estateId}/items`)

        setLoading(false)
      } catch (error) {
        // console.log('reject error ->', error)
        setUploadErrors(true)
        setLoading(false)
      }
    }
  }

  // Upload image to AWS after save is pressed if the image has changed to a non-seed image
  const uploadImageToAWS = async () => {
    // AWS Upload
    // console.log('item image url ->', itemObj.imageURL)
    // console.log('previous image ->', previousImage)
    if (itemObj.imageURL 
      && (itemObj.imageURL !== previousImage) 
      && itemObj.itemName && itemObj.estateId 
      && !itemObj.imageURL.includes(categoryURLStarter) 
      && !itemObj.imageURL.includes(categoryFolderStarter)
    ) {
      
      // Create AWS URL
      const awsImageLink = await uploadAWSFileReactS3(itemObj.imageURL, 'item', `${itemObj.estateId}-${itemObj.itemName.replace(/\s/g, '')}-${Date.now()}`)

      // Set AWS url to the Item imageURL and delete the previous image
      if (awsImageLink && awsImageLink.length > 0 && awsImageLink !== 'No URL') {
        
        // Set itemObj
        setItemObj({ ...itemObj, imageURL: awsImageLink })

        // Delete old prof pic from AWS if it is not the seed pic
        deletePreviousImage()
        
      }

      return awsImageLink
    } else {
      // console.log('image link failed or image not changed')
      return itemObj.imageURL.includes(categoryURLStarter) || itemObj.imageURL.includes(categoryFolderStarter) ? itemObj.imageURL : previousImage.length > 0 ? previousImage : 'No URL'
    }
  }

  const handleSavePressed = async () => {
    // console.log('handleSavePressed runs')
    if (isTestEstate) {
      setLoading(true)
      const newEstateItems = [ ...estate.items ]

      if (Object.keys(testItemSelected).length > 0 && itemObj.hasOwnProperty('_id') && itemObj._id) {
        const indexOfItemToDelete = newEstateItems.findIndex(item => item._id === itemObj._id)
        newEstateItems.splice(indexOfItemToDelete, 1)

        const itemToAdd = { ...itemObj }

        if (estate.people.filter(person => person.role === 'Heir' && person.userGivenAndFamilyName === itemToAdd.allottedToName).length > 0) {
          const allottedToNameArray = itemToAdd.allottedToName.split(' ')
          allottedToNameArray[0] = allottedToNameArray[0].toLowerCase()
          const allottedToPersonId = `${allottedToNameArray.join('')}PersonId`
          itemToAdd.allottedToPersonId = allottedToPersonId
        } else if (itemToAdd.hasOwnProperty('allottedToPersonId')) {
          delete itemToAdd.allottedToPersonId
        }

        newEstateItems.push(itemToAdd)
        setTestEstate({ ...testEstate, items: newEstateItems })

      } else {
        const itemToAdd = { ...itemObj, _id: itemObj.hasOwnProperty('_id') && itemObj._id ? itemObj._id : `newItemId${Math.floor(Math.random * 1000000)}` }
        if (estate.people.filter(person => person.role === 'Heir' && person.userGivenAndFamilyName === itemToAdd.allottedToName).length > 0) {
          const allottedToNameArray = itemToAdd.allottedToName.split(' ')
          allottedToNameArray[0] = allottedToNameArray[0].toLowerCase()
          const allottedToPersonId = `${allottedToNameArray.join('')}PersonId`
          itemToAdd.allottedToPersonId = allottedToPersonId
        } else if (itemToAdd.hasOwnProperty('allottedToPersonId')) {
          delete itemToAdd.allottedToPersonId
        }
        newEstateItems.push(itemToAdd)
        setTestEstate({ ...testEstate, items: newEstateItems })
      }

      setTestItemSelected({})
      setViewIndex(2)
      setLoading(false)
    } else {
      setLoading(true)

      const awsImageLink = await uploadImageToAWS()

      if (awsImageLink === 'No URL' || !awsImageLink) {
        setUploadErrors(true)
      } else {
        // console.log('awsImageLink ->', awsImageLink)

        // Set the current image link as the new 'previous image' in case the User changes the image again before navigating to a different page; In that case, this will be the image to delete
        setPreviousImage(awsImageLink)

        // Set the form to upload with the new AWS image link
        const formToUpload = { ...itemObj, imageURL: awsImageLink }
        // console.log('formToUpload ->', formToUpload)

        // Add in Bid History, Draft Rankings, and Auction Planning for each new Item
        if (!itemId) {
          const heirPersons = estate.people.filter(person => person.role === 'Heir')

          if (heirPersons.length > 0) {
            const bidHistory = {}
            const heirAuctionPlanning = {}
            const heirDraftPlanning = {}

            for (let i = 0; i < heirPersons.length; i++) {
              // Bid History
              bidHistory[heirPersons[i]._id] = 0

              // Auction Rankings
              heirAuctionPlanning[heirPersons[i]._id] = 0

              // Draft Planning
              heirDraftPlanning[heirPersons[i]._id] = formToUpload.allottedToName === 'Unassigned' ? getUnassignedItems(estate).length + 1 : 1000000000
            }
            
            // Add bidHistory, auction planning, and draft planning to the form to upload
            formToUpload.bidHistory = bidHistory
            formToUpload.heirAuctionPlanning = heirAuctionPlanning
            formToUpload.heirDraftPlanning = heirDraftPlanning
          }
          
        }
        // console.log('formToUpload ->', formToUpload)

        try {
          const { data } = await axios[itemId ? 'put' : 'post'](`${process.env.REACT_APP_SERVER_URL}/api/items/${itemId ? itemId : ''}`, formToUpload, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('data ->', data)
    
          // Navigate to Items Index
          navigate(`/estate/${estateId}/items`)
    
          // Update States
          setUploadErrors(false)
          setLoading(false)
    
        } catch (err) {
          // console.log('err: ', err)
          setUploadErrors(true)
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        `${isTestEstate ? Object.keys(testItemSelected).length > 0 ? 'Edit Test' : 'Add Test' : itemId ? 'Edit' : 'Add'} Item`
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        pageLoadErrors ? 
          <Box
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              height: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
            }}
          >
            {couldNotLoadPageError()}
          </Box>
          :
          <Box 
            sx={{ 
              pt: 4, pb: 4, 
              width: '100vw',
              minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            
            {/* Page Title */}
            <Typography
              sx={{
                mb: 2,
                fontSize: '30px',
                fontWeight: 'bold',
              }}
            >
              {(itemId || (isTestEstate && Object.keys(testItemSelected).length > 0)) ? 'Edit' : 'Add'} Item
            </Typography>

            {/* Item Name */}
            <Box sx={{ mt: 0, mb: 2, width: '100%', maxWidth: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {personItemTextField('itemName', 'Item Name*', 'text', itemObj.itemName, '', false, true, handleItemChange, setItemObj, itemObj, -1)}
            </Box>

            {/* Category */}
            <Box sx={{ mt: 1, mb: itemObj.imageURL ? 2 : 4, width: '100%', maxWidth: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {selectElement('category', 'Category', itemObj.category, false, true, itemCategories, handleItemChange, itemObj, setItemObj, -1)}
            </Box>

            {/* Image — If there is an image, make an icon button, if not, make an 'upload image' button */}
            {itemObj.imageURL ? 
              <>
                {
                  loadingImage ?
                    standardSpinner()
                    :
                    // Photocamera Icon over current image
                    replaceImageWithIconButton(
                      itemObj.imageURL, 
                      previousImage,
                      setPreviousImage,
                      200, 
                      `itemImageId`, 
                      `imageURL`, 
                      handleImageSelect,
                      -4,
                      loadingImage
                    )
                }
              </>
              :
              <Box sx={{ mt: 0, height: 133, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {
                  loading ?
                    standardSpinner()
                    :
                    // Green button with 'Image' in white text
                    addImageWithTextButton(
                      'Image', 
                      `itemImageId`, 
                      `imageURL`, 
                      handleImageSelect
                    )
                }
              </Box>
            }

            {/* Image Instructions */}
            <Typography
              textAlign={'center'}
              component="pre"
              sx={{
                mb: itemObj.imageURL ? 2 : 3,
                width: '95%',
                pl: '5px', pr: '5px',
                fontSize: '14px',
                fontStyle: 'italic',
                color: 'blue',
              }}
            >
              *Use 1:1 image for best results
            </Typography>


            {/* Valuation */}
            <Box 
              sx={{ 
                mt: 1, mb: 0,
                width: '100%', maxWidth: '400px', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
              }}
            >
              {personItemTextField('valuation', 'Valuation', 'text', itemObj.valuation, '', false, true, handleItemChange, setItemObj, itemObj, -1)}
            </Box>

            {/* Valuation Instructions */}
            <Typography
              textAlign={'center'}
              component="pre"
              sx={{
                mb: 1,
                width: '95%',
                pl: '5px', pr: '5px',
                fontSize: '14px',
                fontStyle: 'italic',
                color: 'blue',
              }}
            >
              *A best estimate is OK
            </Typography>

            {/* Valuation Date */}
            <Box 
              sx={{ 
                mt: 1, mb: 0, width: '100%', maxWidth: '400px', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
              }}
            >
              {eventDateElement('Date of Valuation*', itemObj, setItemObj, 'valuationDate', false, 'addItem', modifyInDatabase, setUploadErrors)}
            </Box>

            {/* Valuation Instructions */}
            <Typography
              textAlign={'center'}
              component="pre"
              sx={{
                mb: 1,
                width: '95%',
                pl: '5px', pr: '5px',
                fontSize: '14px',
                fontStyle: 'italic',
                color: 'blue',
              }}
            >
              *Use today&apos;s date for unofficial valuations
            </Typography>

            {/* Reserved For */}
            <Box sx={{ mt: 2, mb: 0, width: '100%', maxWidth: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {allotmentSelectElement('allottedToName', 'Reserved For', itemObj.allottedToName, false, true, allotToOptions, handleItemChange, itemObj, setItemObj, -1, estate)}
            </Box>

            {/* Valuation Instructions */}
            {/* {console.log('estate event length ->', estate.event.length)} */}
            <Typography
              textAlign={'left'}
              component="pre"
              sx={{
                // backgroundColor: 'yellow',
                mb: 2,
                width: '95%', maxWidth: '350px',
                pl: '5px', pr: '5px',
                fontSize: '14px',
                fontStyle: 'italic',
                color: 'blue',
              }}
            >
              {
                `*If it's intended for a specific heir, assign it to that person. 
                *All 'Unassigned' items will be distributed in the ${estate && estate.hasOwnProperty('event') && estate.event.length > 0 && estate.event[0].hasOwnProperty('distributionMethod') ? estate.event[0].distributionMethod : 'auction/draft'}`.replace(/ {5} +/g, '') //the regex is for deleting the indentation spaces in the string literal`
              }
            </Typography>

            {/* Gift For */}
            {itemObj.allottedToName === 'Gift' &&
              <Box sx={{ mt: 1, mb: 2, width: '90%', maxWidth: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {personItemTextField('giftFor', 'Gift For*', 'text', itemObj.giftFor, '', false, true, handleItemChange, setItemObj, itemObj, -1)}
              </Box>
            }
            
            {/* Description */}
            <Box sx={{ mt: 1, mb: 2, width: '100%', maxWidth: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {descriptionTextField(
                'description', 
                itemObj.description, 
                10, 
                'Describe the item and the story behind it...', 
                handleItemChange,
                setItemObj,
                itemObj
              )}
            </Box>

            {/* Error Text */}
            {uploadErrors &&
              standardErrorContainer(
                `*Upload error. Check Internet connection.`,
                0,
                0
              )
            }

            {/* Buttons */}
            <Box
              sx={{
                mb: 6,
                width: '100%',
                display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {/* Cancel/Delete Button */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {standardButton(
                  (itemId || (isTestEstate && Object.keys(testItemSelected).length > 0)) ? 'Delete' : 'Cancel', 
                  'button',
                  'contained',
                  false,
                  (itemId || (isTestEstate && Object.keys(testItemSelected).length > 0)) ? 'error' : 'secondary', 
                  1,
                  1,
                  2,
                  '150px', 
                  '45px', 
                  (itemId || (isTestEstate && Object.keys(testItemSelected).length > 0)) ? handleDeletePressed : handleCancelPressed
                )}
              </Box>


              {/* Save Button */}
              {/* {console.log('itemObj ->', itemObj)} */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {standardButton(
                  (itemId || (isTestEstate && Object.keys(testItemSelected).length > 0)) ? 'Save' : 'Create', 
                  'button',
                  'contained',
                  !(itemObj.estateId.length > 0 && itemObj.itemName.length > 0 && itemObj.imageURL.length > 0 && itemObj.valuation.length > 0 && itemObj.valuationDate && dayjs(itemObj.valuationDate) < tomorrow && itemObj.category.length > 0 && itemObj.description.length > 0 && itemObj.allottedToName.length > 0 && !(itemObj.allottedToName === 'Gift' && itemObj.giftFor.length === 0)),
                  'primary', 
                  1,
                  1,
                  2,
                  '150px', 
                  '45px', 
                  handleSavePressed
                )}
              </Box>

            </Box>

          </Box>
      }
    </>
  )
}

export default AddItem