/* eslint-disable no-prototype-builtins */
import * as React from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

import { positionChangeWidthSm } from './variableDefaults'
import { standardButton } from './buttons'
import { modifyInDatabase } from './modifyInDatabase'


export const standardModal = (name, value, advisoryText, estate, setEstate, open, setOpen, setSaveSuccessful, setError, width) => {

  const handleClose = () => {
    setOpen(false)
  }

  const handleContinue = () => {
    setOpen(false)

    const newEvent = [{ ...estate.event[0], [name]: value }]

    modifyInDatabase('events', newEvent[0]._id, { [name]: value }, setError)
    
    if (name === 'silentOrParticipatory' && (value === 'Silent' || value === 'Partial')) {
      newEvent[0].distributionMethod = 'Allocate All'
      newEvent[0].liveOrAuto = 'Auto'
    }
    
    setEstate({ ...estate, event: newEvent })
    setSaveSuccessful(true)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box 
        sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width < positionChangeWidthSm ? '80%' : 400,
          bgcolor: 'white',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {/* Advisory Text */}
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {advisoryText}
        </Typography>

        {/* Continue and Cancel Buttons */}
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center',
          }}
        >
          {standardButton(
            'Cancel', 
            'button',
            'contained',
            false,
            'secondary', 
            0,
            0,
            0,
            width < positionChangeWidthSm ? '45%' : '150px', 
            '45px', 
            handleClose
          )}
          {standardButton(
            'Continue', 
            'button',
            'contained',
            false,
            'warning', 
            0,
            0,
            0,
            width < positionChangeWidthSm ? '45%' : '150px', 
            '45px', 
            handleContinue
          )}
        </Box>
      </Box>
    </Modal>
  )
}