/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import useWindowDimensions from '../../helpers/windowDimensions'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { auctionTimerMinutes, auctionTimerSeconds, positionChangeWidthAuction, sheirBlue } from '../../helpers/variableDefaults'
import { scrollToBottomOfMessages, randomlyAssignTargetBidsToNonUserPersons } from '../../helpers/sharedDraftAuctionJSX'
import { isNumeric } from '../../helpers/globalHelpers'
import { getUnassignedItems, getHighestBid, getUserBid, getUserTargetBid, getHighestBidderId, getPersonInEstateFromId, getEventResults, getRemainingGreaterThanValuation, getPointsRemainingGreaterThanBid, getPointsRemainingGreaterThanBidCustom } from '../../helpers/getValuationTotals'
import { pauseCountDown, resetCountDown, startTimeout } from '../../helpers/timer'
import { computerAuctionJSX, mobileAuctionJSX } from '../../helpers/auctionEventJSX'
import { couldNotLoadPageError } from '../../helpers/errors'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Example Auction Page
const AuctionPractice = (props) => {
  // console.log('props.estate ->', props.estate)
  // console.log('props.userPerson ->', props.userPerson)
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId } = useParams()

  // Payload
  const payload = getPayload()

  // URL Path
  const urlPath = window.location.pathname

  // Estate
  const [estate, setEstate] = useState({}) //estate object
  const [userPerson, setUserPerson] = useState({}) //person object for the user
  const [onTheBlockItem, setOnTheBlockItem] = useState({}) //Item that is being bid upon

  const [autoSelect, setAutoSelect] = useState(false) //Boolean — auto select on is true; auto select off is false
  const [customBid, setCustomBid] = useState('') //Custom bid value
  const [resultsName, setResultsName] = useState('') //the name of the person appearing in the results tab on the right side of the computer view
  const [openResultsMenu, setOpenResultsMenu] = useState(false) // Boolean — results menu open is true; closed is false
  const [heirNames, setHeirNames] = useState([]) // Names of heirs; these are the menu options for the results select
  const [heirPersons, setHeirPersons] = useState([])
  const [heirPersonIds, setHeirPersonIds] = useState([]) // Heir person Ids array

  const [messages, setMessages] = useState([]) // Messages for the message board
  const [messageText, setMessageText] = useState('') // Text for a new message

  // Timer
  const [ eventHasBegun, setEventHasBegun ] = useState(false) // Boolean — event has begun is true; not yet begun is false
  const [ eventHasFinished, setEventHasFinished ] = useState(false) // Boolean — event has finished is true; not yet finished is false
  const [ minutes, setMinutes ] = useState(-1) // Number of minutes remaining to bid on an item
  const [ seconds, setSeconds ] =  useState(-1) // Number of seconds remaining to bid on an item
  const [ isPaused, setIsPaused ] = useState(false) // Boolean — the timer is paused is true; not paused is false

  // Loading
  const [loading, setLoading] = useState(false) // Boolean — loading the view is true; not loading is false

  // Errors
  const [errors, setErrors] = useState(false) // Boolean — true if there are errors; false if there are not any errors

  // For scrolling to bottom of message display automatically
  const messagesEndRef = useRef(null)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {

    if (!props.hasOwnProperty('estate')) {
      if (!userIsAuthenticated()) {

        // If there's no authenticated User, return to the homepage
        navigate('/')

      } else {

        // Get the necessary data
        const getData = async () => {
          setLoading(true)
          try {

            // Retrieve the Estate
            const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
              headers: {
                Authorization: `Bearer ${getTokenFromLocalStorage()}`,
              },
            })
            // console.log('retrieved Estate ->', retrievedEstate)

            // Find the user's Person in the Estate
            const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)

            // If the userPerson exists, continue to assigning states; if not, navigate to the User's profile
            if (userIsInEstate.length > 0 && retrievedEstate.subscriptionIsActive) {
              // console.log('userIsInEstate people ->', retrievedEstate.people)

              // Set Estate; Create random target bids for non-user heirs so that other heir preferences are not revealed during testing
              setEstate(randomlyAssignTargetBidsToNonUserPersons(retrievedEstate, userIsInEstate[0], urlPath === '/demo'))

              // Set States
              setUserPerson(userIsInEstate[0])
              setOnTheBlockItem(getUnassignedItems(retrievedEstate).sort((a, b) => b['valuation'] - a['valuation'])[0])
              setHeirNames(retrievedEstate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage).map(person => person.userGivenAndFamilyName))
              setHeirPersonIds(retrievedEstate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage).map(person => person._id))
              setResultsName(retrievedEstate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage)[0].userGivenAndFamilyName)
              setHeirPersons(retrievedEstate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage))
              
              setErrors(false)
              setLoading(false)
            } else {
              // if user is not in Estate, navigate to the User's profile
              navigate(`/user/${payload.sub}`)

              setErrors(false)
              setLoading(false)
            }

          } catch (error) {
            // console.log(error)

            // If there's an error retrieving the estate data, navigate to the User's profile
            navigate(`/user/${payload.sub}`)

            setErrors(true)
            setLoading(false)
          }
        }

        // Call getData()
        getData()
      }
    } else {

      const setStates = async () => {
        setEstate(randomlyAssignTargetBidsToNonUserPersons(props.estate, props.userPerson, urlPath === '/demo'))
        
        setUserPerson(props.userPerson)
        setHeirNames(props.estate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage).map(person => person.userGivenAndFamilyName))
        setHeirPersonIds(props.estate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage).map(person => person._id))
        setResultsName(props.estate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage)[0].userGivenAndFamilyName)
        setHeirPersons(props.estate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage))
      }

      setStates()
    }

  }, [])

  // Refresh view when state changes
  useEffect(() => {
    
  }, [onTheBlockItem, openResultsMenu])

  useEffect(() => {
    setOpenResultsMenu(false)
  }, [resultsName])

  // Timer
  useEffect(() => {
    // console.log('seconds ->', seconds)
    if ((minutes > 0 || seconds > -1) && (eventHasBegun && !eventHasFinished)) {
      startTimeout(decrementTimer)
    }
  }, [seconds])

  useEffect(() => {
    // console.log('estate useEffect items ->', estate.items)
    // console.log('unassigned items ->', estate.hasOwnProperty('items') && getUnassignedItems(estate))

    // Set the onTheBlockItem whenever estate changes
    if (estate.hasOwnProperty('items') && getUnassignedItems(estate).length > 0) {
      setOnTheBlockItem(getUnassignedItems(estate).sort((a, b) => b['valuation'] - a['valuation'])[0])
    } else if (estate.hasOwnProperty('event') && getEventResults(estate).length > 0) {
      setOnTheBlockItem(getEventResults(estate)[getEventResults(estate).length - 1])
    }
  }, [estate])

  useEffect(() => {
    // console.log('messages useEffect')
    // console.log('messages ->', messages)

    // Scroll to bottom of messages whenever a new message arrives
    if (width >= positionChangeWidthAuction) {
      scrollToBottomOfMessages(messagesEndRef)
    }

  }, [messages])

  // Decrement Timer Function
  const decrementTimer = () => {
    // console.log('decrementTimer runs')
    // console.log('isPaused ->', isPaused)

    // If seconds is greater than 0 and the timer is not paused, decrement the timer or execute an auto bid
    if (seconds > 0 && !isPaused) {
      // If after a specified number of seconds, execute the auto bid 
      if (seconds <= (auctionTimerSeconds - 3)) {
        // If the userPerson has autoSelect on, if the userPerson is able to bid higher, if the userPerson does not have the highest bid, and if the userPerson's target bid is higher than the highest bid — then execute an autobid
        if (autoSelect && getRemainingGreaterThanValuation(onTheBlockItem, estate, userPerson) && getPointsRemainingGreaterThanBidCustom(getHighestBid(onTheBlockItem) + 1, estate, userPerson, onTheBlockItem) && ((getUserBid(onTheBlockItem, userPerson._id) < getHighestBid(onTheBlockItem) && getHighestBid(onTheBlockItem) < getUserTargetBid(onTheBlockItem, userPerson._id)) || (getHighestBid(onTheBlockItem) === 0 && getUserTargetBid(onTheBlockItem, userPerson._id) > 0))) {
          if (getHighestBid(onTheBlockItem) + 50 <= getUserTargetBid(onTheBlockItem, userPerson._id) && getPointsRemainingGreaterThanBidCustom(getHighestBid(onTheBlockItem) + 50, estate, userPerson, onTheBlockItem)) {
            executeBid(getHighestBid(onTheBlockItem) + 50, userPerson)
          } else if (getHighestBid(onTheBlockItem) + 10 <= getUserTargetBid(onTheBlockItem, userPerson._id) && getPointsRemainingGreaterThanBidCustom(getHighestBid(onTheBlockItem) + 10, estate, userPerson, onTheBlockItem)) {
            executeBid(getHighestBid(onTheBlockItem) + 10, userPerson)
          } else if (getHighestBid(onTheBlockItem) + 5 <= getUserTargetBid(onTheBlockItem, userPerson._id) && getPointsRemainingGreaterThanBidCustom(getHighestBid(onTheBlockItem) + 5, estate, userPerson, onTheBlockItem)) {
            executeBid(getHighestBid(onTheBlockItem) + 5, userPerson)
          } else {
            executeBid(getHighestBid(onTheBlockItem) + 1, userPerson)
          }
        } else {
          // If the criteria are not met for the userPerson to autobid, make an autobid for non-userPerson heir if their target bids are higher than the highest bid and they are able to bid on the item

          // Retrieve the Id for the highest bidder
          const highestBidderId = getHighestBidderId(onTheBlockItem.bidHistory, getHighestBid(onTheBlockItem))
          // console.log('highestBidderId ->', highestBidderId)
          
          // Get the heir Ids for the heirs that are neither the userPerson nor the highest bidder and who can still bid on the item
          const otherHeirIds = [ ...heirPersonIds.filter(personId => (personId !== userPerson._id && (personId !== highestBidderId || getHighestBid(onTheBlockItem) === 0) && getRemainingGreaterThanValuation(onTheBlockItem, estate, getPersonInEstateFromId(estate, personId)) && getPointsRemainingGreaterThanBidCustom(getHighestBid(onTheBlockItem) + 1, estate, getPersonInEstateFromId(estate, personId), onTheBlockItem))) ]
          // console.log('otherHeirIds ->', otherHeirIds)
          
          // If otherHeirs is greater than zero, check to see if the heir has a target bid higher than the highest bid; if there is one that does, execute an auto bid
          if (otherHeirIds.length > 0) {

            // Check to see if the other heirs have a target bid higher than the highest bid; This must be done here, so don't change it
            const otherHeirsWithHigherTargetBidsIds = []
            for (let i = 0; i < otherHeirIds.length; i++) {
              if (getUserTargetBid(onTheBlockItem, otherHeirIds[i]) > getHighestBid(onTheBlockItem)) {
                otherHeirsWithHigherTargetBidsIds.push(otherHeirIds[i])
              }
            }
            // console.log('otherHeirsWithHigherTargetBidsIds ->', otherHeirsWithHigherTargetBidsIds)

            // If there is an heir who can bid and has a target bid higher than the highest bid, execute an autobid
            if (otherHeirsWithHigherTargetBidsIds.length > 0) {
              if (getHighestBid(onTheBlockItem) + 50 <= getUserTargetBid(onTheBlockItem, otherHeirsWithHigherTargetBidsIds[0]) && getPointsRemainingGreaterThanBidCustom(getHighestBid(onTheBlockItem) + 50, estate, getPersonInEstateFromId(estate, otherHeirsWithHigherTargetBidsIds[0]), onTheBlockItem)) {
                executeBid(getHighestBid(onTheBlockItem) + 50, estate.people.filter(person => person._id === otherHeirsWithHigherTargetBidsIds[0])[0])
              } else if (getHighestBid(onTheBlockItem) + 10 <= getUserTargetBid(onTheBlockItem, otherHeirsWithHigherTargetBidsIds[0]) && getPointsRemainingGreaterThanBidCustom(getHighestBid(onTheBlockItem) + 10, estate, getPersonInEstateFromId(estate, otherHeirsWithHigherTargetBidsIds[0]), onTheBlockItem)) {
                executeBid(getHighestBid(onTheBlockItem) + 10, estate.people.filter(person => person._id === otherHeirsWithHigherTargetBidsIds[0])[0])
              } else if (getHighestBid(onTheBlockItem) + 5 <= getUserTargetBid(onTheBlockItem, otherHeirsWithHigherTargetBidsIds[0]) && getPointsRemainingGreaterThanBidCustom(getHighestBid(onTheBlockItem) + 5, estate, getPersonInEstateFromId(estate, otherHeirsWithHigherTargetBidsIds[0]), onTheBlockItem)) {
                executeBid(getHighestBid(onTheBlockItem) + 5, estate.people.filter(person => person._id === otherHeirsWithHigherTargetBidsIds[0])[0])
              } else {
                executeBid(getHighestBid(onTheBlockItem) + 1, estate.people.filter(person => person._id === otherHeirsWithHigherTargetBidsIds[0])[0])
              }
            }
          } else if (seconds <= (auctionTimerSeconds - 3) && (!(getRemainingGreaterThanValuation(onTheBlockItem, estate, userPerson) && getPointsRemainingGreaterThanBidCustom(getHighestBid(onTheBlockItem) + 1, estate, userPerson, onTheBlockItem)) || (highestBidderId === userPerson._id && getHighestBid(onTheBlockItem) > 0))) {
            
            // Allot the item if there is only one person who can bid, and that person has a bid higher than zero
            executeAllotItem()
          }
        }
      } 
      
      // Decrement the timer
      setSeconds(seconds => seconds - 1)
      
    } else if (seconds === 0 && !isPaused) {
      if (minutes === 0) {

        // If timer reaches 0, allot the item
        executeAllotItem()
        
      } else {

        // Decrement the timer
        setMinutes(minutes - 1)
        setSeconds(59)

      }
    } 
  }

  // Execute Item Allotment
  const executeAllotItem = () => {

    // Pause the timer so it doesn't decrement further
    pauseCountDown(setIsPaused)

    // create the item to be allotted
    const assignItemOnTheBlock = { ...onTheBlockItem }

    // Assign the name and allotted to ID to the highest bidder
    if (getHighestBid(onTheBlockItem) > 0) {
      assignItemOnTheBlock.allottedToName = getPersonInEstateFromId(estate, getHighestBidderId(onTheBlockItem.bidHistory, getHighestBid(onTheBlockItem))).userGivenAndFamilyName
      assignItemOnTheBlock.allottedToPersonId = getHighestBidderId(onTheBlockItem.bidHistory, getHighestBid(onTheBlockItem))
    } else {
      assignItemOnTheBlock.allottedToName = 'Liquidate'
    }

    // Push the newly allotted item to the results array
    const newResults = [ ...getEventResults(estate) ]
    newResults.push(assignItemOnTheBlock)
    // console.log('new results ->', newResults)

    // Set the results on the event object equal to the new results
    const newEvent = [ ...estate.event ]
    newEvent[0].results = newResults

    // Update the item on estate.items
    const newItems = [ ...estate.items ]
    const auctionItemIndex = newItems.map(e => e._id).indexOf(assignItemOnTheBlock._id)
    if (auctionItemIndex !== -1) {
      newItems[auctionItemIndex] = assignItemOnTheBlock
    }

    // Set the estate with the item updated in estate.items and estate.event
    setEstate({ ...estate, ['items']: newItems, ['event']: newEvent })

    // Reset the timer
    setMinutes(auctionTimerMinutes)
    setSeconds(auctionTimerSeconds)

    // Create a new message indicating that an allotment has occurred
    const newMessage = {
      timestamp: new Date(),
      // fromName: userPerson.userGivenAndFamilyName,
      // fromName: userPerson.userGivenAndFamilyName.substring(0, userPerson.userGivenAndFamilyName.indexOf(' ') + 2),
      fromName: `Allotment ${newResults.length}`,
      message: 
        assignItemOnTheBlock.allottedToName === 'Liquidate' 
          ? 
          `<b>${newResults[newResults.length - 1].itemName}</b>, valuation: <b>$${Math.floor(newResults[newResults.length - 1].valuation).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</b> will be <b>LIQUIDATED</b>` 
          : 
          `<b>${newResults[newResults.length - 1].allottedToName}</b> receives <b>${newResults[newResults.length - 1].itemName}</b>, valuation: <b>$${Math.floor(newResults[newResults.length - 1].valuation).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</b> for a bid of <b>${getHighestBid(newResults[newResults.length - 1])}</b> points`,
    }
    setMessages([ ...messages, newMessage ])

    // If there are more items to bid upon, reset the countdown; if not, set eventHasFinished to true
    if (getUnassignedItems(estate).length > 1) {
      resetCountDown('auction', setIsPaused, setMinutes, setSeconds)
    } else {
      setEventHasFinished(true)
    }
  }

  // Execute bidding on an Item
  const executeBid = (newBid, biddingPerson) => {
    // console.log('executeBid runs')

    // Create a new bid history object
    const newBidHistory = { ...onTheBlockItem.bidHistory }
    // console.log('newBidHistory ->', newBidHistory)
    
    // Add the new bid to the biddingPerson._id key
    newBidHistory[biddingPerson._id] = newBid
    // console.log('newBidHistory ->', newBidHistory)

    // Add the newBidHistory to the corresponding index on estate.items
    const itemIndexOnEstate = estate.items.map(e => e._id).indexOf(onTheBlockItem._id)
    // console.log('itemIndexOnEstate ->', itemIndexOnEstate)
    const estateItems = [ ...estate.items ]
    estateItems[itemIndexOnEstate].bidHistory = newBidHistory
    // console.log('estate item bid history ->', estateItems[itemIndexOnEstate].bidHistory)

    // set the estate with the updated bidHistory items
    setEstate({ ...estate, items: estateItems })

    // Reset custom bid to blank if the bidding person is the userPerson
    if (biddingPerson._id === userPerson._id) {
      setCustomBid('')
    }

    // Reset the countdown
    resetCountDown('auction', setIsPaused, setMinutes, setSeconds)
  }

  // Returns a Boolean — if the user can bid, it's true; if not, then false
  const userCanBid = (newBid) => {
    // console.log('remaining greater than valuation ->', getRemainingGreaterThanValuation(onTheBlockItem, estate, getPersonInEstateFromId(estate, userPerson._id)))
    // console.log('remaining points greater than bid ->', getPointsRemainingGreaterThanBidCustom(newBid, estate, getPersonInEstateFromId(estate, userPerson._id), onTheBlockItem))
    return (!autoSelect && (getRemainingGreaterThanValuation(onTheBlockItem, estate, getPersonInEstateFromId(estate, userPerson._id)) && getPointsRemainingGreaterThanBidCustom(newBid, estate, getPersonInEstateFromId(estate, userPerson._id), onTheBlockItem)))
  }

  // Handle a submitted bid
  const handleSubmitBidPressed = async (e, type) => {
    e.preventDefault()
    // console.log('handleSubmitBidPressed')
    // console.log('e.target.text', Math.floor(e.target.innerText.replace('+','')))
    // console.log('onTheBlockItem ->', onTheBlockItem)
    
    // Create a new bid variable
    const newBid = type === 'custom' ? Math.floor(customBid) : Math.floor(getHighestBid(onTheBlockItem) + Math.floor(e.target.innerText.replace('+','')))

    // If the person is allowed to bid, execute the bid
    // console.log('newBid ->', newBid)
    // console.log('userCanBid ->', userCanBid(newBid))
    if ((userCanBid(newBid) && (type === 'plus' || (type === 'custom' && Math.floor(customBid) > getHighestBid(onTheBlockItem))))) {
      // console.log('execute bid runs')
      executeBid( 
        newBid,
        userPerson
      )
    }
  }

  // Start the timer when Start Timer button is pressed
  const handleStartTimer = () => {
    // console.log('handleStartTimer runs')

    // set event has begun to true
    setEventHasBegun(true)

    // Set the minutes and seconds to full time
    setMinutes(auctionTimerMinutes)
    setSeconds(auctionTimerSeconds)
  }

  // Exit the live event when the Exit button is pressed
  const handleExitDraft = () => {
    // console.log('handleExitDraft runs')

    console.log('urlPath ->', urlPath)
    if (urlPath.includes('demo/')) {
      // console.log('includes demo runs')
      navigate(`/demo`)
    } else {
      // console.log('does not include demo')
      navigate(`/estate/${estateId}/family-hq`)
    }
    // navigate(-1)
  }

  // Set the custom bid text
  const handleCustomBidText = async (e) => {
    e.preventDefault()
    // console.log('handleCustomBidText')
    // console.log('e.target.value ->', e.target.value)
    if (isNumeric(e.target.value)) {
      // console.log('is number !', e.target.value)

      setCustomBid(e.target.value)
      
    } else if (e.target.value === '') {
      setCustomBid('')
    } else {
      // console.log('is NOT number !', e.target.value)
    }

  }

  // Execute sending a message
  const handleSendMessage = (e) => {
    e.preventDefault()
    // console.log('handleSendMessage runs')
    // console.log('e.target.value ->', e.target.value)

    // Create the new message object
    const newMessage = {
      timestamp: new Date(),
      // fromName: userPerson.userGivenAndFamilyName,
      // fromName: userPerson.userGivenAndFamilyName.substring(0, userPerson.userGivenAndFamilyName.indexOf(' ') + 2),
      fromName: userPerson.userGivenAndFamilyName.substring(0, userPerson.userGivenAndFamilyName.indexOf(' ')),
      message: messageText,
    }

    // Add the new message to the messages array
    setMessages([ ...messages, newMessage ])

    // Reset the messageText to nothing
    setMessageText('')
  }

  // Update the message text as the user types
  const handleChangeMessageText = (e) => {
    e.preventDefault()
    // console.log('handleChangeMessageText runs')
    // console.log('e.target.value ->', e.target.value)
    setMessageText(e.target.value)
  }

  // Update the Person who's results are shown on the right side
  const handleResultsSelect = (e, type) => {
    e.preventDefault()
    // console.log('handleResultsSelect')
    // console.log('e.target.value ->', e.target.value)
    // console.log('e.target.innerText ->', e.target.innerText)
    // console.log('resultsName ->', resultsName)
    // console.log('type ->', type)
    // console.log('openResultsMenu before ->', openResultsMenu)

    // Update setResults if the name changes
    // setResultsName(e.target.value)

    if (type === 'change' || type === 'click' || (type === 'close') || (type === 'open')) {
      if (type === 'close') {
        // console.log('close runs')
        // setOpenResultsMenu(!openResultsMenu)
        if (e.target.innerText) {
          setResultsName(e.target.innerText)
        }
      } else if (type === 'change') {
        // console.log('change runs')
        setOpenResultsMenu(false)
        setResultsName(e.target.value)
      } else if (type === 'click') {
        // console.log('click runs')
        setOpenResultsMenu(true)
        setOpenResultsMenu(!openResultsMenu)
      } else if (type === 'open') {
        // console.log('open runs')
        setOpenResultsMenu(true)
      }
      // setResultsName(type === 'change' ? e.target.value : e.target.innerText)
      // setResultsName(type === 'change' ? e.target.value : e.target.innerText)
    }

  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {urlPath.includes('auction-practice') && seoPageTags(
        'Auction Practice'
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          height: '100vh',
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        errors ? 
          <Box
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              height: '100vh',
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
            }}
          >
            {couldNotLoadPageError()}
          </Box>
          :
          width >= positionChangeWidthAuction ?

            // Computer view for wider screens
            computerAuctionJSX(
              'practice',
              estate, 
              userPerson,
              autoSelect, setAutoSelect, 
              eventHasBegun, eventHasFinished, 
              handleStartTimer, handleExitDraft, minutes, seconds, 
              width, 
              onTheBlockItem,  
              handleSubmitBidPressed, customBid, handleCustomBidText, 
              messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef, 
              heirNames, resultsName, handleResultsSelect, openResultsMenu, setOpenResultsMenu,
              heirPersons, []
            )

            :

            // Mobile view for narrower screens
            mobileAuctionJSX(
              'practice',
              estate, 
              userPerson, 
              autoSelect, 
              eventHasBegun, eventHasFinished, 
              handleStartTimer, handleExitDraft, minutes, seconds, 
              width, 
              onTheBlockItem, 
              handleSubmitBidPressed, customBid, handleCustomBidText, 
              messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef,
              heirPersons, []
            )

      }
    </>
  )
}

export default AuctionPractice