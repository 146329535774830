import React from 'react'
import { Link } from 'react-router-dom'

import circlesSpinningGIF from '../../assets/spinner-circles-orbiting-g.gif'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { navbarHeight, footerHeight } from '../../helpers/variableDefaults'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// 404 Not Found Page
const NotFound = () => {
  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        '404'
      )}

      {/* Body */}
      <Box 
        sx={{ 
          height: `calc(100vh - ${navbarHeight} - ${footerHeight})`, 
          width: '100vw', 
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
        }}
      >
        {/* Circles Orbiting GIF */}
        <Box component='img' src={circlesSpinningGIF} alt='spinning circles gif' sx={{ width: 75 }} />

        {/* 404 Not Found text */}
        <Typography variant='h4'>Page Not Found!</Typography>

        {/* Link back to home.js */}
        <Typography as={Link} to="/" sx={{ textDecoration: 'underline' }}>Back to Home</Typography>
      </Box>
    </>
  )
}

export default NotFound