/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

// MUI
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import GroupIcon from '@mui/icons-material/Group'
import BarChartIcon from '@mui/icons-material/BarChart'
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline'
import PieChartIcon from '@mui/icons-material/PieChart'
import CategoryIcon from '@mui/icons-material/Category'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'

// Helper methods
import { userIsAuthenticated, getProfPicFromLocalStorage, getPayload, getTokenFromLocalStorage } from '../../helpers/storage'
import { navbarHeight, estateNavbarHeight, estateNavDefaultColor, navbarBgDefaultColor, navMenuOptionsLoggedIn, navMenuOptionsInsideEstate, navMenuOptionsInsideTestEstate, navMenuOptionsNotLoggedIn, profPicDefaultURL, positionChangeWidthSm, sheirGreen, sheirBlue } from '../../helpers/variableDefaults'

import useWindowDimensions from '../../helpers/windowDimensions'

// The navbar appears at the top of the website on all pages except for unwrap views
const NavEstate  = (props) => {

  const { isTestEstate, viewIndex, setViewIndex } = props

  // Navigate
  const navigate = useNavigate()

  // Payload
  const payload = isTestEstate ? 'NA' : getPayload()

  // URL Path
  const urlPath = window.location.pathname

  // Dynamic Window Dimensions
  const { height, width } = useWindowDimensions()

  // Params
  const { estateId, personId, estateOwnerName } = useParams()

  // States
  const [myPersonId, setMyPersonId] = useState({})
  const [onlyShowMyPlanning, setOnlyShowMyPlanning] = useState(false)


  // Get PersonId if there's an Estate Id for navigating to "My Planning"
  const getMyPersonId = async () => {
    try {
      const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })
      // console.log('retrieved Estate ->', retrievedEstate)

      const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)
      

      if (userIsInEstate.length > 0) {
        if (userIsInEstate.filter(user => user.role === 'Heir').length > 0 && retrievedEstate.hasOwnProperty('event') && retrievedEstate.event[0].hasOwnProperty('silentOrParticipatory') && retrievedEstate.event[0].silentOrParticipatory === 'Partial') {
          setOnlyShowMyPlanning(true)
        }

        return userIsInEstate[0]._id
      } else {
        return null
      }

    } catch (error) {
      // console.log(error)
      return null
    }
  }

  useEffect(() => {
    const getData = async () => {
      // const userPerson = await getMyPersonId()
      if (isTestEstate) {
        // console.log('is test estate')
      } else {
        setMyPersonId(await getMyPersonId())
      }
    }
    // if (personId) {
    getData()
    // }
  }, [])

  const handleNavFamilyHQ = async (event) => {
    if (isTestEstate) {
      setViewIndex(0)
    } else {
      navigate(`/estate/${estateId}/family-hq`)
    }
  }

  const handleNavThePot = async (event) => {
    if (isTestEstate) {
      setViewIndex(1)
    } else {
      navigate(`/estate/${estateId}/the-pot`)
    }
    
  }

  const handleNavItemsIndex = async (event) => {
    if (isTestEstate) {
      setViewIndex(2)
    } else {
      navigate(`/estate/${estateId}/items`)
    }
    
  }

  const handleNavMyPlanning = async (event) => {
    if (isTestEstate) {
      setViewIndex(3)
    } else {
      const userPerson = await getMyPersonId()

      navigate(`../estate/${estateId}/${userPerson}`, { replace: true })
      
      // Necessary for changing to your own Person Space if you click "My Planning" while in somebody else's Person Space
      if (personId) {
        navigate(0)
      }
    }
  }

  return (
    <>
      <AppBar position="static" component="nav"
        style={{ 
          background: estateNavDefaultColor,
          boxShadow: 'none', 
        }}
      >
        <Toolbar 
          sx={{ 
            width: '100%',
            height: estateNavbarHeight,
            fontFamily: 'Lato',
            display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', 
          }}
        >

          {(isTestEstate ? navMenuOptionsInsideTestEstate : onlyShowMyPlanning ? ['My Planning'] : navMenuOptionsInsideEstate).map((text, index) => (
            <Box key={text} sx={{ }}>
              
              {width < positionChangeWidthSm ?
                // Icon Button if smaller screen width
                <Box>
                  <IconButton
                    size="large"
                    aria-label="inside estate navigation"
                    aria-haspopup="true"
                    onClick={onlyShowMyPlanning ? handleNavMyPlanning : index === 0 ? handleNavFamilyHQ : index === 1 ? handleNavThePot : index === 2 ? handleNavItemsIndex : handleNavMyPlanning}
                    color="inherit"
                  >
                    {onlyShowMyPlanning ? <AssignmentIcon sx={{ color: sheirGreen }} /> : index === 0 ? <GroupIcon sx={{ color: (urlPath.includes('family-hq') || (isTestEstate && viewIndex === 0)) ? sheirGreen : 'white' }}/> : index === 1 ? <BarChartIcon sx={{ color: (urlPath.includes('the-pot') || (isTestEstate && viewIndex === 1)) ? sheirGreen : 'white' }}/> : index === 2 ? <CategoryIcon sx={{ color: (urlPath.includes('items') || (isTestEstate && viewIndex === 2)) ? sheirGreen : 'white' }}/> : <AssignmentIcon sx={{ color: ((urlPath.includes(personId) && personId === myPersonId) || (isTestEstate && viewIndex === 3)) ? sheirGreen : 'white' }}/>}
                  </IconButton>
                </Box>
                :
                // Words if larger screen width
                <Box
                  onClick={onlyShowMyPlanning ? handleNavMyPlanning : index === 0 ? handleNavFamilyHQ : index === 1 ? handleNavThePot : index === 2 ? handleNavItemsIndex : handleNavMyPlanning}
                  sx={{
                    cursor: 'pointer',
                    color: (onlyShowMyPlanning || (index === 0 && (urlPath.includes('family-hq') || (isTestEstate && viewIndex === 0))) || (index === 1 && (urlPath.includes('the-pot') || (isTestEstate && viewIndex === 1))) || (index === 2 && (urlPath.includes('items') || (isTestEstate && viewIndex === 2))) || (index === 3 && (((urlPath.includes(personId) && personId === myPersonId)) || (isTestEstate && viewIndex === 3)))) ? sheirGreen : 'white',
                    fontWeight: 'bold',
                  }}
                >
                  {text}
                </Box>
              }
              
            </Box>
          ))}
          
        </Toolbar>
      </AppBar>
    </>
  )
}

export default NavEstate