/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
// mui
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { NumericFormatCustom } from './formatting'


// Login/Register process TextFields (username, email, password, passwordConfirmation) in inProcessLoginRegister.js
export const loginTextField = (name, label, value, variant, required, fullWidth, autoFocus, autoComplete, form, setForm, setErrors, changeHandler ) => {
  return (
    <TextField
      required={required}
      fullWidth={fullWidth}
      label={label}
      variant={variant}
      name={name}
      type={name.includes('password') || name.includes('Password') || name.includes('security') || name.includes('Security') ? 'password' : ''}
      autoFocus={autoFocus}
      value={value} 
      onChange={(e) => changeHandler(e, setErrors, setForm, form)}
      inputProps={{ 
        style: { textTransform: name.includes('password') ? 'none' : 'none' }, 
        autoComplete: 'new-password',
        form: {
          autoComplete: 'off',
        },
      }}
    />
  )
}

// Textfield for inputting the recipient email address in emailTo.js during the wrap process
export const emailTextField = (name, value, placeholder, variant, clickHandler, state, setState, width) => {
  return (
    <TextField 
      variant={variant}
      name={name}
      maxLength={150}
      aria-label={name}
      placeholder={placeholder}
      autoComplete='off'
      value={value}
      onChange={(e) => clickHandler(e, state, setState)}
      color="primary"
      sx={{ 
        // backgroundColor: 'yellow',
        // width: width >= 440 ? 'calc(100% - 120px)' : '95%',
        width: '100%',
        // maxWidth: '325px', 
        // ml: '45px', 
        mt: 1,
      }}
    />
  )
}

// For answering Security Questions in inProcessLoginRegister.js
export const securityQuestionAnswerTextField = (name, label, value, disabled, variant, required, fullWidth, autoFocus, autoComplete, form, setForm, changeHandler, questionIndex ) => {
  return (
    <TextField
      required={required}
      fullWidth={fullWidth}
      disabled={disabled}
      label={label}
      variant={variant}
      name={name}
      // type={name.includes('password') || name.includes('Password') || name.includes('security') || name.includes('Security') ? 'password' : ''}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      value={value} 
      onChange={(e) => changeHandler(e, questionIndex, form, setForm)}
      inputProps={{ 
        style: { textTransform: name.includes('password') ? 'none' : 'none' }, 
        autoComplete: 'off',
        form: {
          autoComplete: 'off',
        },
      }}
    />
  )
}

// Textfield for inputting the security code in Security.js during the unwrap process
export const securityCodeTextField = (name, label, disabled, autoComplete, autoFocus, changeHandler) => {
  return (
    <TextField
      margin="normal"
      disabled={disabled}
      required
      fullWidth
      id={name}
      label={label}
      name={name}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      onChange={(e) => changeHandler(e.target.value)}
    />
  )
}

// PersonItem TextField (first name, last name, email); appears during the creating estate process; also for setting the rest of the onboarding Obj
export const personItemTextField = (name, label, type, value, placeholder, disabled, focused, changeHandler, setPeopleArray, peopleArray, itemIndex ) => {
  // console.log('disabled ->', disabled)
  // console.log('focused ->', focused)
  
  return (
    <TextField
      focused={focused}
      disabled={disabled}
      label={label}
      type= {type}
      autoComplete='off'
      variant='outlined'
      placeholder={placeholder}
      name={name}
      value={value}
      minRows={1}
      maxRows={1}
      inputProps={{ 
        maxLength: (name === 'sharePercentage' || name === 'heirAuctionPlanning') ? 5 : 75,
      }}
      InputProps={{
        startAdornment: (name === 'valuation' || name === 'fees' || name === 'totalTaxes') ? <InputAdornment position="start">$</InputAdornment> : '',
        inputComponent: (name === 'valuation' || name === 'fees' || name === 'totalTaxes') ? NumericFormatCustom : '',
        inputMode: (name === 'valuation' || name === 'heirAuctionPlanning' || name === 'fees' || name === 'totalTaxes') ? 'numeric' : '', 
        pattern: (name === 'valuation' || name === 'heirAuctionPlanning' || name === 'fees' || name === 'totalTaxes') ? '[0-9]*' : '',
      }}
      size={name === 'estateName' || name === 'itemName' ? 'large' : 'small'}
      onChange={(e) => changeHandler(e, setPeopleArray, peopleArray, itemIndex)}
      sx={{ 
        width: (name === 'sharePercentage' || name === 'heirAuctionPlanning') ? '75px' : name === 'fees' ? '125px' : '90%', 
        textOverflow: 'ellipsis', 
        '.MuiInputBase-input': { 
          fontSize: name === 'estateName' || name === 'itemName' ? '1.2rem' : '1rem', 
        }, 
      }}
    />
  )
}

export const onlyNumbersItemTextField = (name, label, value, placeholder, focused, changeHandler, setPeopleArray, peopleArray, itemIndex ) => {
  return (
    <TextField
      focused={focused}
      label={label}
      type={'number'}
      autoComplete='off'
      variant='outlined'
      placeholder={placeholder}
      name={name}
      value={value}
      minRows={1}
      maxRows={1}
      inputProps={{ maxLength: name === 'sharePercentage' ? 5 : 75 }}
      size={name === 'estateName' ? 'large' : 'small'}
      onChange={(e) => changeHandler(e, setPeopleArray, peopleArray, itemIndex)}
      sx={{ 
        width: name === 'sharePercentage' ? '75px' : '90%', 
        textOverflow: 'ellipsis', 
        '.MuiInputBase-input': { 
          fontSize: name === 'estateName' ? '1.2rem' : '1rem', 
        }, 
      }}
    />
  )
}

// Mission Textfield, appears in the Mission.js file, for writing a longer mission statement during the onboarding
export const missionTextField = (name, value, rows, placeholder, changeHandler) => {
  
  return (
    <TextField
      autoFocus={true}
      name={name}
      multiline
      autoComplete='off'
      rows={rows}
      maxLength={1000}
      aria-label={name}
      value= {value ? value : ''}
      placeholder={placeholder}
      onChange={changeHandler}
      color="primary"
      sx={{ 
        width: '90%', maxWidth: '600px',
        fontFamily: 'Lato, Arial, sans-serif', 
        fontSize: '16px', 
        padding: '10px', 
      }}
    />
  )
}

// Mission Textfield, appears in the Mission.js file, for writing a longer mission statement during the onboarding
export const descriptionTextField = (name, value, rows, placeholder, changeHandler, setForm, form) => {
  const upperCaseName = name.charAt(0).toUpperCase() + name.slice(1)

  return (
    <TextField
      focused={true}
      label={`${upperCaseName}*`}
      type= 'text'
      autoComplete='off'
      variant='outlined'
      placeholder={placeholder}
      name={name}
      value={value}
      multiline
      rows={rows}
      maxLength={1000}
      onChange={(e) => changeHandler(e, setForm, form)}
      sx={{ 
        width: '90%', 
        textOverflow: 'ellipsis', 
        '.MuiInputBase-input': { 
          fontSize: '1rem', 
        }, 
      }}
    />
  )
}