import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { missionTextTypography } from '../../../helpers/typographies'
import useWindowDimensions from '../../../helpers/windowDimensions'
import { positionChangeHeightSm, positionChangeWidthSm, positionChangeWidthMd } from '../../../helpers/variableDefaults'

import dateFormat from 'dateformat'

import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'

// Create Estate Review Page
const Review = (props) => {

  // Destructure Props
  const { options, setOptions } = props

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  useEffect(() => {
    
    // Collect Analytics
    customAnalyticsEvent(
      `onboarding_4_review`, 
      `onboarding_4_review`, 
      `onboarding_4_review`, 
      `onboarding_4_review`, 
      'Onboarding Step 4 — Review'
    )

  }, [])


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {seoPageTags(
        '404'
      )} */}

      {/* Body */}
      <Box sx={{ mt: width < positionChangeWidthSm ? 1 : 4, mb: width < positionChangeWidthSm ? 2 : 4, width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            
        {/* People */}
        <Box sx={{ mt: width < positionChangeWidthSm ? 2 : 4, mb: 0, width: '95%', maxWidth: '600px' }}>
          <Typography sx={{ mt: 1, fontSize: width < positionChangeWidthSm ? '16px' : '18px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <strong>People:</strong>
          </Typography>
          <Box 
            sx={{
              backgroundColor: 'lightyellow',
              mt: 1,
              pl: 3, pr: 3, pt: 1, pb: 1,
              borderRadius: '5px', boxShadow: 3,
              border: 2,
              borderColor: 'black',
            }}
          >
            {options.metadata.people.map((person, personIndex) => (
              <Box key={personIndex} sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
              }}>
                <Typography variant='p' sx={{ 
                  mb: 1, mt: 1, 
                  width: '95%', 
                  fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                }}>
                  * {person.role} — {person.givenName} {person.familyName} ({person.email})
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Mission */}
        <Box sx={{ mt: 4, mb: 0, width: '95%', maxWidth: '600px' }}>
          <Box>
            <Typography sx={{ mt: 0, fontSize: width < positionChangeWidthSm ? '16px' : '18px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <strong>Mission:</strong>
            </Typography>
          </Box>
          <Box 
            sx={{
              backgroundColor: 'lightBlue',
              mt: 1,
              pl: 3, pr: 3, pt: 2, pb: 2,
              borderRadius: '5px', boxShadow: 3,
              border: 2,
              borderColor: 'black',
            }}
          >
            {missionTextTypography(options.metadata.mission, width < positionChangeWidthSm ? '16px' : '18px', 'Lato')}
          </Box>
        </Box>

        {/* Settings */}
        <Box sx={{ mt: 4, mb: 3, width: '95%', maxWidth: '600px' }}>
          <Typography sx={{ mt: 0, fontSize: width < positionChangeWidthSm ? '16px' : '18px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <strong>Settings:</strong>
          </Typography>
          <Box 
            sx={{
              // backgroundColor: 'whitesmoke',
              backgroundColor: 'palegreen',
              mt: 1,
              pl: 3, pr: 3, pt: 1, pb: 1,
              borderRadius: '5px', boxShadow: 3,
              border: 2,
              borderColor: 'black',
              display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
            }}
          >
            {/* Estate Name */}
            <Typography variant='p' sx={{ 
              mb: 1, mt: 1, 
              width: '95%', 
              fontSize: width < positionChangeWidthSm ? '16px' : '18px',
            }}>
              <strong>Estate Name:</strong> {options.metadata.estateName} 
            </Typography>

            {/* Heir Allocations */}
            {options.metadata.people.filter(person => person.role === 'Heir').length > 0 &&
              <Typography
                sx={{
                  width: '81%', maxWidth: '540px',
                  mt: 2,
                  fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                  fontWeight: 'bold',
                  display: 'flex', flexDirection: 'row', justifyContent: 'left',
                }}
              >
                Heir Allocations:
              </Typography>
            }

            {options.metadata.people.map((person, personIndex) => 
              person.role === 'Heir' &&
              <Box key={personIndex} sx={{ mt: 1, mb: 1, width: '100%', maxWidth: '440px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                
                {/* Heir Name and Share Percentage*/}
                <Typography
                  sx={{
                    pl: width < positionChangeWidthSm ? 2 : 4,
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                  }}
                >
                  {person.givenName} {person.familyName} — {person.sharePercentage}%
                </Typography>
              </Box>
            )}

            {/* Process */}
            <Typography
              sx={{
                width: '81%', maxWidth: '540px',
                mt: 2,
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                fontWeight: 'bold',
                display: 'flex', flexDirection: 'row', justifyContent: 'left',
              }}
            >
              Allocation Process:
            </Typography>

            {/* Transparency Level */}
            <Typography variant='p' 
              sx={{
                mt: 1, mb: 1, 
                pl: width < positionChangeWidthSm ? 2 : 4,
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
              }}
            >
              <strong>Transparency Level:</strong> {options.metadata.silentOrParticipatory} 
            </Typography>

            {/* Distribution Method */}
            {options.metadata.silentOrParticipatory === 'Participatory' && 
              <Typography variant='p' 
                sx={{
                  mt: 1, mb: 1, 
                  pl: width < positionChangeWidthSm ? 2 : 4,
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                }}
              >
                <strong>Distribution Method:</strong> {options.metadata.distributionMethod} 
              </Typography>
            }

            {/* Live or Auto */}
            {options.metadata.silentOrParticipatory === 'Participatory' && options.metadata.distributionMethod !== 'Allocate All' &&
              <Typography variant='p' 
                sx={{
                  mt: 1, mb: 1, 
                  pl: width < positionChangeWidthSm ? 2 : 4,
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                }}
              >
                <strong>Live or Auto:</strong> {options.metadata.liveOrAuto} 
              </Typography>
            }

            {/* Advisory about heirs in Silent estates */}
            {options.metadata.silentOrParticipatory === 'Silent' && 
              <Typography variant='p' 
                sx={{
                  mt: 1, mb: 1, 
                  pl: width < positionChangeWidthSm ? 2 : 4,
                  width: '100%',
                  fontStyle: 'italic',
                  fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                }}
              >
                **Heirs will NOT be able to view the estate, and they will NOT receive an email about the estate being created. All other parties, such as lawyers, asset managers, and accountants, will receive an email about the estate upon creation, and they will be able to view assets and allotments. 
              </Typography>
            }

            {/* Advisory about heirs in Participatory estates */}
            {options.metadata.silentOrParticipatory === 'Participatory' && 
              <Typography variant='p' 
                sx={{
                  mt: 1, mb: 1, 
                  pl: width < positionChangeWidthSm ? 2 : 4,
                  width: '100%',
                  fontStyle: 'italic',
                  fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                }}
              >
                **All parties, such as heirs, lawyers, asset managers, and accountants, will receive an email about the estate upon creation, and they will be able to view assets and allotments. If you would prefer that heirs not witness the allocation process, click back to Settings and select Silent for Transparency Level
              </Typography>
            }

            {/* Start Date */}
            {/* <Typography variant='p' 
              sx={{
                mt: 1, mb: 1, 
                pl: width < positionChangeWidthSm ? 2 : 4,
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
              }}
            >
              <strong>Date of Event:</strong> {dateFormat(onboardingObj.startDate, 'dddd, mmmm d, yyyy, h:MM TT (Z)')} 
            </Typography> */}

          </Box>
        </Box>

      </Box>
    </>
  )
}

export default Review