/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { standardSpinner } from '../../../helpers/spinners'

import { Input, dummyBox, validEmail } from '../../../helpers/globalHelpers'
import { positionChangeHeightSm, positionChangeWidthSm, positionChangeWidthMd, personTypes } from '../../../helpers/variableDefaults'
import { deletePersonItemIconButton } from '../../../helpers/buttons'
import useWindowDimensions from '../../../helpers/windowDimensions'
import { personItemTextField } from '../../../helpers/textfields'
import { selectElement } from '../../../helpers/selects'


// Gift Item
const PersonItem = (props) => {
  
  // Destructure Props
  const { options, setOptions, itemIndex, fromComponent } = props
  // console.log('onboardingObj ->', onboardingObj)
  // console.log('fromComponent ->', fromComponent)

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Update view every time onboardingObj changes
  useEffect(() => {

  }, [options])

  // For handling changes to the person item
  const handlePersonItemChange = async (e, setOptions, options, itemIndex) => {
    // console.log('handlePersonItemChange runs')

    // Create name and value variables
    let name
    let value

    // Assign values to name and value depending on the type of event target
    if (!e.target.name) {
      name = e.target.classList[1]
      value = e.target.classList[0].slice(5)
    } else {
      name = e.target.name
      value = name === 'email' ? e.target.value.toLowerCase() : e.target.value
    }
    // console.log('name ->', name)
    // console.log('value ->', value)

    if (value.charAt(0) !== ' ') {
      const newOptionsMetadata = { ...options.metadata } 
    
      newOptionsMetadata.people[itemIndex][name] = value
    
      setOptions({ ...options, metadata: newOptionsMetadata })
    }
    
  } 

  // Handle deleting an item within an option
  const handleDeleteItem = async (e) => {
    e.preventDefault()
    
    // Get item index from the event currentTarget classList
    const selectedItemIndexString = e.currentTarget.classList[0].charAt(0)
    const selectedItemIndex = parseInt(selectedItemIndexString)

    // Destructure the onboardingObj.people array
    const newOptionsMetadata = { ...options.metadata } 
    
    // Splice the specified item from peopleArray
    if (selectedItemIndex > -1 ) { 
      newOptionsMetadata.people.splice(selectedItemIndex, 1)
    }

    // setOnboardingObj with the newArray
    setOptions({ ...options, metadata: newOptionsMetadata })
  }
  
  return (
    <>
      {/* This must be first input So that the file upload only fires when you press the button */}
      <Box sx={{ width: '100%' }}>
        <Input type="text" autoFocus="autoFocus" />
      </Box>
      
      {/* Person Item */}
      <Box 
        sx={{ 
          backgroundColor: 'whitesmoke', 
          mt: 1, mb: 2, boxShadow: 3, borderRadius: '5px', width: fromComponent === 'onboaording' ? '90%' : '100%', 
          display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: 'space-between', alignItems: width < positionChangeWidthSm ? 'center' : 'flex-start', 
        }}
      >

        {/* First Name */}
        <Box sx={{ mt: 1, mb: 1, width: width < positionChangeWidthSm ? '90%' : '20%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {personItemTextField('givenName', 'First Name*', 'text', options.metadata.people[itemIndex].givenName, '', false, true, handlePersonItemChange, setOptions, options, itemIndex)}
        </Box>
        
        {/* Last Name */}
        <Box sx={{ mt: 1, mb: 1, width: width < positionChangeWidthSm ? '90%' : '20%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {personItemTextField('familyName', 'Last Name*', 'text', options.metadata.people[itemIndex].familyName, '', false, true, handlePersonItemChange, setOptions, options, itemIndex)}
        </Box>

        {/* Email */}
        <Box sx={{ mt: 1, mb: 1, width: width < positionChangeWidthSm ? '90%' : '20%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {personItemTextField('email', 'Email*', 'text', options.metadata.people[itemIndex].email, '', false, true, handlePersonItemChange, setOptions, options, itemIndex)}

          {/* Advisory */}
          {!validEmail(options.metadata.people[itemIndex].email) && options.metadata.people[itemIndex].email.length > 0 &&
            <Typography 
              sx={{
                textAlign: 'left',
                color: 'blue',
                fontSize: '12px',
              }}
            >
              <em>*Email must be valid</em>
            </Typography>
          }
        </Box>

        {/* Role */}
        <Box sx={{ mt: 1, mb: 1, width: width < positionChangeWidthSm ? '90%' : '20%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {selectElement('role', 'Role', options.metadata.people[itemIndex]['role'], itemIndex === 0 && fromComponent === 'onboarding', !(itemIndex === 0 && fromComponent === 'onboarding'), itemIndex === 0 && fromComponent === 'onboarding' ? ['Owner'] : personTypes, handlePersonItemChange, options, setOptions, itemIndex)}
        </Box>

        {/* Delete item button if more than one item */}
        {deletePersonItemIconButton(itemIndex, handleDeleteItem, (fromComponent === 'onboarding' && itemIndex === 0))}
        
      </Box>
    </>
  )
}

export default PersonItem