/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import Slider from '@mui/material/Slider'

import { userIsAuthenticated, getPayload } from '../../helpers/storage.js'
import { standardErrorContainer } from '../../helpers/errors'

import { dummyBox, validEmail, reloadView } from '../../helpers/globalHelpers'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { handleChange } from '../../helpers/globalHelpers'
import { selectMultiple } from '../../helpers/selects.js'
import { itemCategories, positionChangeWidthSm } from '../../helpers/variableDefaults.js'

import useWindowDimensions from '../../helpers/windowDimensions'

const FilterItemsIndex = (props) => {

  // Dynamic Window Dimensions
  const { height, width } = useWindowDimensions()

  // Navigate
  const navigate = useNavigate()
  const urlPath = window.location.pathname

  // Payload
  const payload = getPayload()

  // Destructure Props
  const { fromTab, estate, filteredItems, setFilteredItems, filtersObj, setFiltersObj, showFilters, setShowFilters, allottedToNames } = props
  // console.log('filtered items length ->', filteredItems.length)
  // console.log('person allotments length ->', estate.items.filter(item => item.allottedToName === person.userGivenAndFamilyName).length)

  // non-login-related Loading and Errors
  const [loading, setLoading] = useState(false)
  const [filtersErrors, setFiltersErrors] = useState(false)

  // When 'X' button pressed, filters Modal is exited
  const handleExit = () => {
    setShowFilters(false)
  }

  // Saves filter criteria and sets a new array of filtered items
  const handleSavePressed = () => {

    const filteredItems = estate.items.filter(item => {
      return (fromTab === 'itemsIndex' ? item : item.allottedToName === allottedToNames[0])
        && Math.floor(item.valuation) >= filtersObj.minValuation 
        && Math.floor(item.valuation) <= filtersObj.maxValuation
        && (filtersObj.categories.length === 0 || filtersObj.categories.includes(item.category))
        && (filtersObj.allottedTo.length === 0 || filtersObj.allottedTo.includes(item.allottedToName) || filtersObj.allottedTo.includes(item.giftFor))
    })
    setFilteredItems(filteredItems)
    setShowFilters(false)
  }

  // Handles sliding on the min/max valuation sliders
  const handleValuationRangeChange = (e, setErrors, setFormData, formData) => {
    // console.log('handleValuationRangeChange runs')
    
    const minValuation = e.target.value[0]
    const maxValuation = e.target.value[1]
    
    setErrors(false)
    setFormData({ ...formData, minValuation: minValuation, maxValuation: maxValuation })
  }

  // Handle selecting item category filters
  const handleCategorySelection = (e) => {

    setFiltersErrors(false)

    // Add or Remove the selected category to or from the updatedCategories array
    const updatedCategories = filtersObj.categories
    if (updatedCategories.includes(e.target.textContent)) {
      const indexOfCategory = updatedCategories.indexOf(e.target.textContent)
      if (indexOfCategory > -1) {
        updatedCategories.splice(indexOfCategory, 1)
      }
    } else {
      updatedCategories.push(e.target.textContent)
    }

    // Set the form
    setFiltersObj({ ...filtersObj, categories: updatedCategories })

    // toggle the 'styled' class for the event target
    e.target.classList.toggle('styled')
  }

  return (
    <>

      {/* Show Filters Body */}
      <Box sx={{ 
        width: '100vw', height: '100vh',
        display: 'flex', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'stretch',
      }}>
        <Modal
          disableAutoFocus={true}
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={showFilters ? true : false}
          closeAfterTransition
          keepMounted
          sx={{ 
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
          }}
        >
          <Paper
            sx={{
              width: '85vw',
              height: '85vh',
              maxHeight: '85vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
            }}
          >
            {
              loading ?
                standardSpinner()
                :
                <>
                  {/* Main Elements */}
                  <Box 
                    id='scroller' 
                    sx={{
                      pb: 2,
                      height: '100%',
                      maxHeight: '100%',
                      // overflow: 'auto',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        WebkitAppearance: 'none',
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        borderRadius: '4px',
                        backgroundColor: 'rgba(0, 0, 0, .35)',
                        webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
                      },
                      width: '100%',
                      mb: 0, mt: 0, 
                      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                    }}
                  >
                    
                    {/*'X' button */}
                    <Box 
                      onClick={handleExit} 
                      sx={{
                        width: '100%',
                        display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',
                      }}
                    >
                      <IconButton 
                        sx={{ 
                          width: '30px', height: '30px', 
                          mt: 2, mr: 2, 
                          border: 2, borderColor: 'white', boxShadow: 3, 
                        }} 
                      >
                        <CloseOutlinedIcon 
                          fontSize="small" 
                          color='primary' 
                          sx={{  }} 
                        />
                      </IconButton>
                    </Box>

                    {/* Title */}
                    <Typography
                      sx={{
                        mb: width < positionChangeWidthSm ? 0 : 2,
                        fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                    >
                      Filters
                    </Typography>


                    {/* Valuation */}
                    <Box sx={{ mt: width < positionChangeWidthSm ? 2 : 3, mb: 0, width: '80%', maxWidth: '600px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <Slider
                        value={[filtersObj.minValuation, filtersObj.maxValuation]}
                        onChange={(e) => handleValuationRangeChange(e, setFiltersErrors, setFiltersObj, filtersObj)}
                        // valueLabelDisplay="auto"
                        name='valuation-range'
                        size="medium"
                        min={0}
                        max={fromTab === 'itemsIndex' ? Math.floor(estate.items.sort((a, b) => b.valuation - a.valuation)[0].valuation) : Math.floor(estate.items.filter(itemToFilter => itemToFilter.allottedToName === allottedToNames[0]).sort((a, b) => b.valuation - a.valuation)[0].valuation)}
                        // marks
                        step={1}
                        sx={{ width: '100%', align: 'center' }}
                      />
                    </Box>
                    <Typography id="height-slider" gutterBottom sx={{ pl: 0, mt: 0 }}>
                      ${filtersObj.minValuation.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} - ${filtersObj.maxValuation.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </Typography>


                    {/* Allotted To */}
                    {fromTab === 'itemsIndex' &&
                      <Box
                        sx={{
                          mt: width < positionChangeWidthSm ? 3 : 6,
                          width: '80%', maxWidth: '600px',
                          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                        }}
                      >
                        {selectMultiple('allottedTo', 'Allotted To', false, filtersObj.allottedTo, allottedToNames, handleChange, filtersObj, setFiltersObj, setFiltersErrors)}
                      </Box>
                    }
                    


                    {/* Categories */}
                    <Box textAlign='center' 
                      sx={{ 
                        mt: width < positionChangeWidthSm ? 4 : 6,
                        width: '80%',
                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', 
                      }} 
                    >
                      <Grid container spacing={1}>
                        {itemCategories.map((category, i) => {
                          return (
                            <Grid item xs={6} sm={4} key={i} sx={{  }}>
                              <Box
                                onClick={(e) => handleCategorySelection(e)}
                                className= {filtersObj.categories.includes(category) ? `${category} styled` : `${category}` }
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: 'lightgray',
                                  borderRadius: 10,
                                  p: 1,
                                  fontSize: 14,
                                  fontFamily: 'Lato',
                                  '&:hover': {
                                    cursor: 'pointer',
                                    backgroundColor: '#e0e0e0',
                                  },
                                }}>
                                {category}
                              </Box>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>


                    {/* Save Button */}
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {standardButton(
                        'Save', 
                        'button',
                        'contained',
                        filtersObj.minValuation === 0 && (fromTab === 'itemsIndex' && filtersObj.maxValuation === Math.floor(estate.items.sort((a, b) => b.valuation - a.valuation)[0].valuation) || (fromTab === 'heirSpace' && filtersObj.maxValuation === Math.floor(estate.items.filter(itemToFilter => itemToFilter.allottedToName === allottedToNames[0]).sort((a, b) => b.valuation - a.valuation)[0].valuation))) && filtersObj.allottedTo.length === 0 && filtersObj.categories.length === 0 && ((fromTab === 'itemsIndex' && filteredItems.length === estate.items.length) || (fromTab === 'heirSpace' && filteredItems.length === estate.items.filter(item => item.allottedToName === allottedToNames[0]).length) ),
                        'primary', 
                        6,
                        2,
                        2,
                        '150px', 
                        '45px', 
                        handleSavePressed
                      )}
                    </Box>

                  </Box>
                </>
            }
          </Paper>
        </Modal>
      </Box>
    </>
  )
}

export default FilterItemsIndex