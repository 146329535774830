

// Sources:
// https://www.decorsed.com/the-great-gatsby/

// Constants
const startBidHistoryAndAuctionPlanningObj = {
  nickCarrawayPersonId: 0, 
  daisyBuchananPersonId: 0, 
  owlEyesPersonId: 0,
}

const startDraftPlanningObj = {
  nickCarrawayPersonId: 1000000000, 
  daisyBuchananPersonId: 1000000000, 
  owlEyesPersonId: 1000000000,
}


// Items

// The Gatsby Mansion
// https://www.architecturaldigest.com/story/the-great-gatsby-mansion-is-on-the-market
export const gatsbyMansion = {
  _id: 'gatsbyMansionItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Gatsby Mansion',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-mansion-1.jpg',
  valuation: '85000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Mansion in Long Island — a vast estate in the, well, less-fashionable West Egg.',
  allottedToName: 'Daisy Buchanan',
  allottedToPersonId: 'daisyBuchananPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Rolls Royce Phantom — 1928
// https://www.gobankingrates.com/net-worth/celebrities/modern-great-gatsby-net-worth-23-million-debt/
export const gatsbyRollsRoycePhantom = {
  _id: 'rollsRoycePhantomItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Rolls Royce Phantom',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-rolls-royce-1.jpg',
  valuation: '3000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'My yellow Rolls Royce Phantom that I drive with me on business excursions in the city.',
  allottedToName: 'Nick Carraway',
  allottedToPersonId: 'nickCarrawayPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Leather Books
export const gatsbyLeatherBooks = {
  _id: 'leatherBooksItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Leather Books',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-leather-books-1.jpg',
  valuation: '500000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'The leather books that decorate my library.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Yacht
export const gatsbyYacht = {
  _id: 'gatsbyYachtItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Tuolomee — Yacht',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-yacht-1.jpg',
  valuation: '90000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'The yacht that I inherited from my mentor, the copper tycoon, Dan Cody. ',
  allottedToName: 'Nick Carraway',
  allottedToPersonId: 'nickCarrawayPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Speed Boat — Hurrica V
export const gatsbySpeedBoat = {
  _id: 'gatsbySpeedBoatItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Speed Boat',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-speedboat-1.jpg',
  valuation: '30000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'My speedboat, for buzzing around the sound.',
  allottedToName: 'Owl Eyes',
  allottedToPersonId: 'owlEyesPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Chandelier
export const gatsbyChandelier = {
  _id: 'gatsbyChandelierItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Crystal Chandelier Collection',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-chandelier-1.jpg',
  valuation: '20000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: '30 Crystal Chandeliers that adorn my mansion, imported from France.',
  allottedToName: 'Daisy Buchanan',
  allottedToPersonId: 'daisyBuchananPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Suit — White
export const gatsbySuitWhite = {
  _id: 'suitWhiteitemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Suit — White',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-suit-white-1.jpg',
  valuation: '1000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My white suit, custom made for me by an esteemed European designer',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'Meyer Wolfsheim',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Suit — Brown
export const gatsbySuitBrown = {
  _id: 'suitBrownItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Suit — Brown',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-suit-brown-1.jpg',
  valuation: '1000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My brown suit, custom made for me by an esteemed European designer',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'George Wilson',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Suit — Pink
export const gatsbySuitPink = {
  _id: 'suitPinkItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Suit — Pink',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-suit-pink-1.jpg',
  valuation: '1000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My pink suit, custom made for me by an esteemed European designer',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'Ewing Klipspringer',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Champagne Collection
export const gatsbyChampagneCollection = {
  _id: 'gatsbyChampagneCollectionItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Champagne Collection',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-champagne-1.jpg',
  valuation: '10000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Liquidity',
  description: '10000 bottles of the finest French Champagne, that I obtained from a guy who knows a guy, through Meyer Wofsheim.',
  allottedToName: 'Owl Eyes',
  allottedToPersonId: 'owlEyesPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Liquor Collection
export const gatsbyLiquorCollection = {
  _id: 'gatsbyLiquorCollectionItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Liquor Collection',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-liquor-collection-1.jpg',
  valuation: '25000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Liquidity',
  description: '50000 bottles of the finest rums, gins, and whiskeys, imported from a guy who knows a guy, through Meyer Wolfsheim',
  allottedToName: 'Owl Eyes',
  allottedToPersonId: 'owlEyesPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Saloon Decor
export const gatsbySaloonDecor = {
  _id: 'saloonDecorItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Saloon Decor',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-saloon-1.jpg',
  valuation: '10000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'The decor for my upscale saloon in New York City.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Silk Shirts
export const gatsbySilkShirts = {
  _id: 'silkShirtsItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Silk Shirts',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-silk-shirts-1.jpg',
  valuation: '200000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: '500 silk shirts that I never wore after it turned out that Daisy did not like them.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Crystal Glasses
export const gatsbyCrystalDrinkingGlasses = {
  _id: 'gatsbyCrystalDrinkingGlassesItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Crystal Drinking Glasses',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-crystal-glasses-1.jpg',
  valuation: '3000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: '5000 crystal drinking glasses for all types of liquor. Ideal for throwing enormous parties in the summer.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Salon Chair - 1
export const gatsbySalonChairsSilk = {
  _id: 'gatsbySalonChairsSilkItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Salon Chairs — Silk',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-salon-chair-silk-1.jpg',
  valuation: '2500000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: '10 salon chairs, custom made with the finest silk tapestry',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Salon Rug
export const gatsbySalonRug = {
  _id: 'gatsbySalonRugItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Salon Rug',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-salon-rug-1.jpg',
  valuation: '5000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'A 1000ft x 1000ft handmade rug imported from Persia that took 100 years to make.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Salon Couches
export const gatsbySalonCouches = {
  _id: 'gatsbySalonCouchesItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Salon Couches',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-salon-couches-1.jpg',
  valuation: '3000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: '5 couches in my salon.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Salon Chair — 2
export const gatsbySalonChairsVelvet = {
  _id: 'gatsbySalonChairsVelvetItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Salon Chairs — Velvet',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-salon-chair-velvet-1.jpg',
  valuation: '2500000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: '10 salon chairs, custom made with the finest velvet tapestry',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Gold Candelabra
export const gatsbyGoldCandelabra = {
  _id: 'gatsbyGoldCandelabraItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Golden Candelabra',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-golden-candelabra-1.jpg',
  valuation: '500000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'A golden candelabra, an accent item in my salon',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Table Clock
export const gatsbyTableClock = {
  _id: 'gatsbyTableClockItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'Table Clock',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-table-clock-1.jpg',
  valuation: '300000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'A table clock, another accent item in my salon',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Art — The Resurrection, by Francesco Buoneri
export const gatsbyTheResurrection = {
  _id: 'gatsbyTheResurrectionItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'The Resurrection — Painting',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-the-resurrection-1.jpg',
  valuation: '80000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Art',
  description: 'An original Fresco Buoneri painting from 1589, titled The Resurrection.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// The Green Light
export const gatsbyGreenLight = {
  _id: 'gatsbyGreenLightItemId',
  estateId: 'jayGatsbyEstateId',
  itemName: 'The Green Light',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-gatsby-green-light-1.jpg',
  valuation: '0',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'The least valuable and most important asset of all — hope.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// export const item23 = {
//   _id: '',
//   estateId: 'jayGatsbyEstateId',
//   itemName: '',
//   imageURL: 'https://sheir-item-images.s3.amazonaws.com/',
//   valuation: '',
//   valuationDate: '2021-08-27T13:49:49.000Z',
//   category: '',
//   description: '',
//   allottedToName: 'Unassigned',
//   allottedToPersonId: 'NA',
//   giftFor: '',
//   bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
//   heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
//   heirDraftPlanning: { ...startDraftPlanningObj },
// }

// export const item24 = {
//   _id: '',
//   estateId: 'jayGatsbyEstateId',
//   itemName: '',
//   imageURL: 'https://sheir-item-images.s3.amazonaws.com/',
//   valuation: '',
//   valuationDate: '2021-08-27T13:49:49.000Z',
//   category: '',
//   description: '',
//   allottedToName: 'Unassigned',
//   allottedToPersonId: 'NA',
//   giftFor: '',
//   bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
//   heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
//   heirDraftPlanning: { ...startDraftPlanningObj },
// }