/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import useWindowDimensions from '../../helpers/windowDimensions'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { familyHQTabs, navbarHeight, estateNavbarHeight, footerHeight, positionChangeWidthSm, superSmallScoreboardWidth } from '../../helpers/variableDefaults'
import { peopleTab, settingsTab, missionTab } from '../../helpers/familyHQTabs'
import { makeTabIndex } from '../../helpers/formatting'

//mui
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Family HQ Page
const FamilyHQ = (props) => {

  // Destructure Props
  const { isTestEstate, testEstate, testUserPerson } = props
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId } = useParams()

  // Payload
  const payload = getPayload()

  // Estate
  const [estate, setEstate] = useState({})
  const [userPerson, setUserPerson] = useState({})

  //Keeps track of which tab we are in, default is Settings at index 0
  const [value, setValue] = useState(0)

  // Loading
  const [loading, setLoading] = useState(false)

  // Errors
  const [errors, setErrors] = useState(false)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {

    if (isTestEstate) {
      setEstate(testEstate)
      setUserPerson(testUserPerson)
    } else if (!userIsAuthenticated()) {
      navigate('/')
    } else {
      const getData = async () => {
        setLoading(true)
        try {
          const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved Estate ->', retrievedEstate)

          const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)

          if (userIsInEstate.length > 0 && retrievedEstate.subscriptionIsActive && (userIsInEstate[0].role !== 'Heir' || (retrievedEstate.hasOwnProperty('event') && retrievedEstate.event[0].hasOwnProperty('silentOrParticipatory') && retrievedEstate.event[0].silentOrParticipatory === 'Participatory'))) {

            // console.log('userIsInEstate people ->', retrievedEstate.people)
            // Set States
            setEstate(retrievedEstate)
            setUserPerson(userIsInEstate[0])

            setErrors(false)
            setLoading(false)
          } else {
            navigate(`/user/${payload.sub}`)
            setErrors(false)
            setLoading(false)
          }

        } catch (error) {
          // console.log(error)

          navigate(`/user/${payload.sub}`)

          setErrors(true)
          setLoading(false)
        }
      }

      getData()
    }

  }, [])

  // Refresh view when estate state changes
  useEffect(() => {
    // console.log('estate people', estate.people)
  }, [estate])

  // Handles tab changes
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Family HQ'
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        <Box
          sx={{
            // backgroundColor: 'yellow',
            pt: 4, 
            pb: value === 0 ? 2 : 6,
            width: '100%',
            minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {/* Estate Title */}
          <Typography
            textAlign={'center'}
            sx={{
              fontSize: '30px', px: 1,
              fontWeight: 'bold',
            }}
          >
            {estate.estateName}
          </Typography>

          {/* Tabs */}
          <Box 
            sx={{ 
              mt: 2,
              // maxWidth: '275px',
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="primary tabs example"
              variant={width < superSmallScoreboardWidth ? 'scrollable' : 'standard'}
              sx={{ 
                // backgroundColor: 'yellow',
                // width: '90vw', maxWidth: '375px',
                width: width < superSmallScoreboardWidth ? '100%' : '275px',
                borderTop: 1, borderColor: 'divider',
                // display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {familyHQTabs.map((tabName, tabIndex) => (
                <Tab key={tabIndex} label={tabName} {...makeTabIndex(tabIndex)} />
              ))}
            </Tabs>
          </Box>


          {/* Tab Panels */}

          {/* Settings */}
          {estate.hasOwnProperty('people') && estate.people.length > 0 && estate.hasOwnProperty('event') && estate.event.length > 0
            && settingsTab(estate.people, estate.event[0], estate, userPerson, 'settings', value, 0, width, loading, errors, setLoading, setErrors, navigate)
          }

          {/* People */}
          {estate.hasOwnProperty('people') && estate.people.length > 0 
            && peopleTab(estate.people, 'people', value, 1, width, loading, errors, isTestEstate)
          }

          {/* Mission */}
          {estate.hasOwnProperty('mission') && estate.mission.length > 0 
            && missionTab(estate.mission, 'mission', value, 2, width, loading, errors)
          }

        </Box>
      }
    </>
  )
}

export default FamilyHQ