/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */

// Images and GIFs
import circlesSpinningGIF from '../assets/spinner-circles-orbiting-g.gif'

import { sheirBlue } from './variableDefaults'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// Standard Error Container
export const standardErrorContainer = (errorText, marginBottom, marginTop) => {
  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
      mb: marginBottom, mt: marginTop,
    }}>
      <Typography variant='p' sx={{ color: 'red' }}>
        {errorText}
      </Typography>
    </Box>
  )
}

// Email/Password login/registration errors
export const loginRegisterErrors = (isError, errorText, marginTop, marginBottom) => {
  return (
    <>
      {isError && 
        <Grid item xs={12} sx={{ mt: marginTop, mb: marginBottom }}>
          {standardErrorContainer(errorText)}
        </Grid>  
      }
    </>
  )
}

// Request Error
export const requestError = () => {
  return (
    <Box textAlign={'center'} sx=
      {{ 
        height: '100%', width: '90vw', 
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
      }}
    >
      {/* Neat wrapping present GIF */}
      <Box component='img' src={circlesSpinningGIF} alt='neat open' sx={{ width: 150 }} />
      
      {/* Request Error text */}
      <Typography variant='h4'>Error! Could not fetch data!</Typography>
      <Typography>Check your Internet connection.</Typography>
      
    </Box>
  )
}

export const couldNotLoadPageError = () => {
  return (
    <Typography
      // textAlign={'center'}
      sx={{
        width: '80%',
        fontWeight: 'bold',
        color: sheirBlue,
        display: 'flex', flexDirection: 'row', justifyContent: 'center',
      }}
    >
      Could not load page. Check your Internet connection and try again.
    </Typography>
  )
}