/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import dayjs from 'dayjs'

import useWindowDimensions from '../../helpers/windowDimensions'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { draftTimerMinutes, draftTimerSeconds, positionChangeWidthDraft } from '../../helpers/variableDefaults'
import { scrollToBottomOfMessages, createPassItem, randomlyAssignTargetDraftOrderToUserPerson } from '../../helpers/sharedDraftAuctionJSX.js'
import { getUnassignedItems, getEventResults, getPersonDrafting, getUnassignedItemsWithValuationUnderRemaining, getRemainingGreaterThanValuation, getAtLeastOnePersonCanStillDraft, getIndexOfItemInEstate } from '../../helpers/getValuationTotals'
import { pauseCountDown, resetCountDown, clearAllTimeouts, startTimeout } from '../../helpers/timer'
import { computerDraftJSX, mobileDraftJSX } from '../../helpers/draftEventJSX.js'
import { couldNotLoadPageError } from '../../helpers/errors'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { shuffleArray } from '../../helpers/globalHelpers'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Example Draft Page
const DraftPractice = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId } = useParams()

  // Payload
  const payload = getPayload()

  // URL Path
  const urlPath = window.location.pathname

  // Estate
  const [estate, setEstate] = useState({}) // the estate
  const [userPerson, setUserPerson] = useState({}) // the Person in the estate associated with the User
  const [draftOrderPersons, setDraftOrderPersons] = useState([]) // the Persons of the people drafting in their draft order

  const [itemHighlighted, setItemHighlighted] = useState({}) // the item that the user has highlighted to draft
  const [passItem, setPassItem] = useState({}) // a "Pass" Item
  const [autoSelect, setAutoSelect] = useState(false) // Boolean — if true, then auto-select is on; if false, then auto-select is off

  const [messages, setMessages] = useState([]) // the messages array for the message board
  const [messageText, setMessageText] = useState('') // the text for a newly drafted message

  // Timer
  const [ eventHasBegun, setEventHasBegun ] = useState(false) // Boolean — if true, the event has begun; if false, the event has not yet begun
  const [ eventHasFinished, setEventHasFinished ] = useState(false) // Boolean — if true, the event has finished; if false, the event has not yet finished
  const [ minutes, setMinutes ] = useState(-1) // minutes remaining on the timer
  const [ seconds, setSeconds ] =  useState(-1) // seconds remaining on the timer
  const [ isPaused, setIsPaused ] = useState(false) // Boolean — if true, the timer has paused; if false, the timer is not paused

  // Loading
  const [loading, setLoading] = useState(false) // Boolean — if true, the view is loading; if false, the view is not loading

  // Errors
  const [errors, setErrors] = useState(false) // Boolean — if true, there are errors; if false, there are no errors

  // For scrolling to bottom of message display automatically
  const messagesEndRef = useRef(null)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {

    if (!props.hasOwnProperty('estate')) {
      if (!userIsAuthenticated()) {

        // Navigate to the homepage if the user is not authenticated
        navigate('/')

      } else {

        // Retrieve the estate data
        const getData = async () => {
          setLoading(true)
          try {
            // Retrieve the estate
            const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
              headers: {
                Authorization: `Bearer ${getTokenFromLocalStorage()}`,
              },
            })
            // console.log('retrieved Estate ->', retrievedEstate)

            // Find the Person in the estate associated with the user
            const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)

            if (userIsInEstate.length > 0 && retrievedEstate.subscriptionIsActive) {
              
              // Set States
              setUserPerson(userIsInEstate[0])
              setEstate(retrievedEstate)
              setMessages(retrievedEstate.event[0].messageBoard)

              // Draft Order Persons
              const heirPersonsOrderedArray = []
              for (let i = 0; i < retrievedEstate.event[0].draftOrder.length; i++) {
                heirPersonsOrderedArray.push(retrievedEstate.people.filter(person => person._id === retrievedEstate.event[0].draftOrder[i])[0])
              }
              setDraftOrderPersons(heirPersonsOrderedArray)

              // Pass Item
              const passItemObj = createPassItem(retrievedEstate._id, heirPersonsOrderedArray)
              setPassItem(passItemObj)

              // Item Highlighted
              setItemHighlighted(getUnassignedItemsWithValuationUnderRemaining(retrievedEstate, userIsInEstate[0]).length > 0 ? getUnassignedItemsWithValuationUnderRemaining(retrievedEstate, userIsInEstate[0]).sort((a, b) => b['heirDraftPlanning'][userIsInEstate[0]._id] === a['heirDraftPlanning'][userIsInEstate[0]._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][userIsInEstate[0]._id] - b['heirDraftPlanning'][userIsInEstate[0]._id])[0] : { ...passItemObj })

              setErrors(false)
              setLoading(false)
            } else {

              // Navigate to the user page if the user is not associated with any Person in the estate
              navigate(`/user/${payload.sub}`)

              setErrors(false)
              setLoading(false)
            }

          } catch (error) {
            // console.log(error)

            // Navigate to the user profile if the estate cannot be retrieved
            navigate(`/user/${payload.sub}`)

            setErrors(true)
            setLoading(false)
          }
        }

        getData()
      }
    } else {

      const setStates = async () => {
        const estateWithRandomizedUserPersonDraftOrder = randomlyAssignTargetDraftOrderToUserPerson(props.estate, props.userPerson._id, urlPath === '/demo')
        setEstate(estateWithRandomizedUserPersonDraftOrder)
        // setEstate(props.estate)

        setUserPerson(props.userPerson)
        setMessages(props.estate.event[0].messageBoard)

        // Draft Order Persons
        const heirPersonsOrderedArray = []
        for (let i = 0; i < props.estate.event[0].draftOrder.length; i++) {
          heirPersonsOrderedArray.push(props.estate.people.filter(person => person._id === props.estate.event[0].draftOrder[i])[0])
        }
        setDraftOrderPersons(shuffleArray(heirPersonsOrderedArray))

        // Pass Item
        const passItemObj = createPassItem(props.estate._id, heirPersonsOrderedArray)
        setPassItem(passItemObj)

        // Item Highlighted
        setItemHighlighted(getUnassignedItemsWithValuationUnderRemaining(estateWithRandomizedUserPersonDraftOrder, props.userPerson).length > 0 ? getUnassignedItemsWithValuationUnderRemaining(estateWithRandomizedUserPersonDraftOrder, props.userPerson).sort((a, b) => b['heirDraftPlanning'][props.userPerson._id] === a['heirDraftPlanning'][props.userPerson._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][props.userPerson._id] - b['heirDraftPlanning'][props.userPerson._id])[0] : { ...passItemObj })
        // setItemHighlighted(getUnassignedItemsWithValuationUnderRemaining(props.estate, props.userPerson).length > 0 ? getUnassignedItemsWithValuationUnderRemaining(props.estate, props.userPerson).sort((a, b) => b['heirDraftPlanning'][props.userPerson._id] === a['heirDraftPlanning'][props.userPerson._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][props.userPerson._id] - b['heirDraftPlanning'][props.userPerson._id])[0] : { ...passItemObj })
      }

      setStates()
    }

  }, [])

  // Refresh view when itemHighlighted state changes
  useEffect(() => {
    // console.log('estate items ->', estate.items)
    // console.log('userPerson ->', userPerson)
    // console.log('itemHighlighted ->', itemHighlighted)
    // console.log('draftOrderPersons ->', draftOrderPersons)
    // console.log('eventHasFinished ->', eventHasFinished)
  }, [itemHighlighted, eventHasFinished, isPaused])

  // Timer — refresh view when seconds changes; start a new timeout or assign all remaining items to be liquidated
  useEffect(() => {
    // console.log('seconds ->', seconds)
    
    if ((minutes > 0 || seconds > -1) && !(estate.hasOwnProperty('items') && draftOrderPersons.length > 0 && (!getAtLeastOnePersonCanStillDraft(estate, draftOrderPersons) || getUnassignedItems(estate).length === 0))) {
      // console.log('timer should decrement')
      // Decrement the timer if the draft is still going
      startTimeout(decrementTimer)
      
    } else if (eventHasBegun && !eventHasFinished && estate.hasOwnProperty('items') && getUnassignedItems(estate).length > 0) {
      // Liquidate everything if nobody can draft anymore
      // console.log('estate ->', estate)
    
      pauseCountDown(setIsPaused)

      // Item arrays
      const allItems = [ ...estate.items ]
      const newResults = [ ...getEventResults(estate) ]
      // console.log('newResults before ->', newResults)

      // Assign each item for liquidation on both the newResults array and estate.items
      getUnassignedItems(estate).forEach(item => {
        // console.log('item name ->', item.itemName)
        item.allottedToName = 'Liquidate'
        newResults.push(item)
        // console.log('getIndexOfItemInEstate(newEstate, item)', getIndexOfItemInEstate(estate, item))
        // console.log('estate.items[getIndexOfItemInEstate(estate, item)].itemName ->', estate.items[getIndexOfItemInEstate(estate, item)].itemName)
        // console.log('all items map ->', allItems[allItems.map(e => e._id).indexOf(item._id)].itemName)
        allItems[allItems.map(e => e._id).indexOf(item._id)].allottedToName = 'Liquidate'
      })

      // Create a newEvent variable and assign newResults to the .results key
      const newEvent = [ ...estate.event ]
      newEvent[0].results = newResults
      // console.log('newResults after ->', newResults)
      // console.log('allItems after ->', allItems)
      // console.log('newEvent ->', newEvent)
  
      // Create a new estate variable with the new items and new event
      const newEstate = { ...estate, ['items']: allItems, ['event']: newEvent }
      // console.log('newEstate ->', newEstate)

      // Set the estate
      setEstate(newEstate)

      // Set eventHasFinished to true
      setEventHasFinished(true)
    } else if (estate.hasOwnProperty('items') && getUnassignedItems(estate).length === 0) {
      // Set eventHasFinished to true
      pauseCountDown(setIsPaused)

      setEventHasFinished(true)
    }
  }, [seconds])

  // Scroll to bottom of messages when a new message appears
  useEffect(() => {
    if (width >= positionChangeWidthDraft) {
      scrollToBottomOfMessages(messagesEndRef)
    }
  }, [messages])

  // Start the timer when the user Press the Start button
  const handleStartTimer = () => {
    // console.log('handleStartTimer runs')

    // Set the eventHasBegun to true
    setEventHasBegun(true)

    // Set the clock to their start values 
    setMinutes(draftTimerMinutes)
    setSeconds(draftTimerSeconds)
  }

  // Execute exit draft when the user clicks the Exit button
  const handleExitDraft = () => {
    // console.log('handleExitDraft runs')

    if (urlPath.includes('demo/')) {
      navigate(`/demo`)
    } else {
      navigate(`/estate/${estateId}/family-hq`)
    }
    // navigate(-1)
  }

  // Decrement the timer and execute autodrafting when appropriate
  const decrementTimer = () => {
    // console.log('decrementTimer runs')
    // console.log('isPaused ->', isPaused)
    // console.log('eventHasFinished ->', eventHasFinished)

    if (eventHasBegun && !eventHasFinished) {
      if (seconds > 0 && !isPaused) {
        if ((autoSelect || (draftOrderPersons.length > 0 && estate.hasOwnProperty('event') && (getPersonDrafting(estate, draftOrderPersons)._id !== userPerson._id || (getPersonDrafting(estate, draftOrderPersons)._id === userPerson._id && getUnassignedItemsWithValuationUnderRemaining(estate, userPerson).length === 0)))) && seconds <= (draftTimerSeconds - 3)) {
          // Execute auto-draft if the criteria are met 
          if (getUnassignedItems(estate).length > 0) {
            // console.log('auto draft executes')
            // console.log('unassigned items at end ->', getUnassignedItems(estate))
            executeItemDrafted()
          }
        } else {
          // Decrement the timer
          setSeconds(seconds => seconds - 1)
        }
      } else if (seconds === 0 && !isPaused) {
        if (minutes === 0) {
          // If time runs out, execute draft item
          if (getUnassignedItems(estate).length > 0) {
            // console.log('unassigned greater than zero and timer ran out')
            executeItemDrafted()
          }
        } else {
          // Decrement the timer
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      } 
    }
  }

  // Execute an item being drafted
  const executeItemDrafted = () => {

    // Pause the countdown
    if (seconds !== draftTimerSeconds && minutes !== draftTimerMinutes) {
      pauseCountDown(setIsPaused)
    }

    // Person drafting
    const personDrafting = getPersonDrafting(estate, draftOrderPersons)

    // Item to be drafted; in this view a random item is assigned if someone other than the UserPerson is drafting
    const assignItemHighlighted = personDrafting._id === userPerson._id ? { ...itemHighlighted } : getUnassignedItemsWithValuationUnderRemaining(estate, personDrafting).length > 0 ? getUnassignedItemsWithValuationUnderRemaining(estate, personDrafting).sort((a, b) => b['heirDraftPlanning'][personDrafting._id] === a['heirDraftPlanning'][personDrafting._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][personDrafting._id] - b['heirDraftPlanning'][personDrafting._id])[Math.floor(Math.random() * getUnassignedItemsWithValuationUnderRemaining(estate, personDrafting).length)] : { ...passItem }

    // Assign the allotted to name and allotted to ID of the person drafting to the item
    assignItemHighlighted.allottedToName = getPersonDrafting(estate, draftOrderPersons).userGivenAndFamilyName
    assignItemHighlighted.allottedToPersonId = getPersonDrafting(estate, draftOrderPersons)._id

    // Add item to results
    const newResults = [ ...getEventResults(estate) ]
    newResults.push(assignItemHighlighted)
    // console.log('new results ->', newResults)

    // Add results to event
    const newEvent = [ ...estate.event ]
    newEvent[0].results = newResults

    // Replace the 'Unassigned' item with the assigned item on estate.items
    const newItems = [ ...estate.items ]
    const draftedItemIndex = newItems.map(e => e._id).indexOf(assignItemHighlighted._id)
    if (draftedItemIndex !== -1) {
      newItems[draftedItemIndex] = assignItemHighlighted
    }

    // Add updated items and event to estate
    const newEstate = { ...estate, ['items']: newItems, ['event']: newEvent }
    setEstate(newEstate)


    // Highlight a new item
    const newItemsForHighlighting = [ ...newItems ]
    newItemsForHighlighting.splice(draftedItemIndex, 1)
    if (getUnassignedItemsWithValuationUnderRemaining(newEstate, userPerson).filter(item => item._id === itemHighlighted._id).length === 0) {
      setItemHighlighted(newItemsForHighlighting.filter(item => item.allottedToName === 'Unassigned' && getRemainingGreaterThanValuation(item, newEstate, userPerson)).length > 0 ? newItemsForHighlighting.filter(item => item.allottedToName === 'Unassigned' && getRemainingGreaterThanValuation(item, newEstate, userPerson)).sort((a, b) => b['heirDraftPlanning'][userPerson._id] === a['heirDraftPlanning'][userPerson._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][userPerson._id] - b['heirDraftPlanning'][userPerson._id])[0] : { ...passItem })
    }

    // Post a message indicating item was drafted
    const newMessage = {
      timestamp: new Date(),
      fromName: `Round: ${Math.ceil(newResults.length / draftOrderPersons.length)} , Pick: ${newResults.length % draftOrderPersons.length === 0 ? draftOrderPersons.length : newResults.length % draftOrderPersons.length}, (${newResults.length})`,
      message: `<b>${newResults[newResults.length - 1].allottedToName}</b> selects <b>${newResults[newResults.length - 1].itemName}</b>, valuation: <b>$${Math.floor(newResults[newResults.length - 1].valuation).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</b>`,
    }
    setMessages([ ...messages, newMessage ])

    // Reset countdown and timer
    if (getUnassignedItems(newEstate).length > 0 && getAtLeastOnePersonCanStillDraft(estate, draftOrderPersons) && !(seconds === draftTimerSeconds && minutes === draftTimerMinutes)) {
      // console.log('reset runs')
      resetCountDown('draft', setIsPaused, setMinutes, setSeconds)
    } else {
      // console.log('set minutes and seconds runs')
      setMinutes(draftTimerMinutes)
      setSeconds(seconds !== draftTimerSeconds ? draftTimerSeconds : draftTimerSeconds + 1)
    }
  }

  // Draft Item click handler
  const handleDraftItemPressed = async (e) => {
    e.preventDefault()
    // console.log('handleDraftItemPressed runs')

    executeItemDrafted()
  }

  // Highlight Item click handler
  const highlightNewItem = (e, item, index) => {
    e.preventDefault()
    // console.log('highlightNewItem')
    // console.log('item ->', item)

    if (item._id !== itemHighlighted._id && getRemainingGreaterThanValuation(item, estate, userPerson)) {
      // setItemHighlighted(getUnassignedItems(estate).sort((a, b) => b['heirDraftPlanning'][userPerson._id] === a['heirDraftPlanning'][userPerson._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][userPerson._id] - b['heirDraftPlanning'][userPerson._id])[index])
      setItemHighlighted(getUnassignedItemsWithValuationUnderRemaining(estate, userPerson).length > 0 ? getUnassignedItems(estate).sort((a, b) => b['heirDraftPlanning'][userPerson._id] === a['heirDraftPlanning'][userPerson._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][userPerson._id] - b['heirDraftPlanning'][userPerson._id])[index] : { ...passItem })
    }
    
  }

  // Send Message click handler
  const handleSendMessage = (e) => {
    e.preventDefault()
    // console.log('handleSendMessage runs')
    // console.log('e.target.value ->', e.target.value)


    // Create the newMessage object
    const newMessage = {
      timestamp: new Date(),
      // fromName: userPerson.userGivenAndFamilyName,
      // fromName: userPerson.userGivenAndFamilyName.substring(0, userPerson.userGivenAndFamilyName.indexOf(' ') + 2),
      fromName: userPerson.userGivenAndFamilyName.includes(' ') ? userPerson.userGivenAndFamilyName.substring(0, userPerson.userGivenAndFamilyName.indexOf(' ')) : userPerson.userGivenAndFamilyName,
      message: messageText,
    }

    // Add the newMessage to the messages array
    setMessages([ ...messages, newMessage ])

    // Reset the messageText to empty
    setMessageText('')
  }

  // New Message textfield change handler
  const handleChangeMessageText = (e) => {
    e.preventDefault()
    // console.log('handleChangeMessageText runs')
    // console.log('e.target.value ->', e.target.value)
    setMessageText(e.target.value)
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {urlPath.includes('draft-practice') && seoPageTags(
        '404'
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          height: '100vh',
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        errors ? 
          <Box
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              height: '100vh',
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
            }}
          >
            {couldNotLoadPageError()}
          </Box>
          :
          width >= positionChangeWidthDraft ?

            // More detailed draft view for wider screens
            computerDraftJSX(
              'practice', 
              estate, 
              userPerson, 
              autoSelect, setAutoSelect, 
              itemHighlighted, highlightNewItem, setItemHighlighted, 
              width, 
              draftOrderPersons, 
              handleDraftItemPressed, 
              passItem, 
              minutes, seconds, 
              eventHasBegun, handleStartTimer, handleExitDraft, 
              messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef
            )

            :

            // Simpler draft view for narrower screens
            mobileDraftJSX(
              'practice', 
              estate, 
              userPerson, 
              autoSelect, 
              itemHighlighted, highlightNewItem, setItemHighlighted, 
              width, 
              draftOrderPersons, 
              handleDraftItemPressed, 
              passItem, 
              minutes, seconds, 
              eventHasBegun, handleStartTimer, handleExitDraft, 
              messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef
            )
          
      }
    </>
  )
}

export default DraftPractice