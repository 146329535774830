/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { Input } from '../../../helpers/globalHelpers.js'
import { replaceImageWithIconButton } from '../../../helpers/buttons'
import { standardErrorContainer } from '../../../helpers/errors.js'
import { handleImageSelect } from '../../../helpers/profilePictureElements.js'
import { getTokenFromLocalStorage, setProfPicToLocalStorage } from '../../../helpers/storage.js'
import { personSpaceProfilePicture } from '../../../helpers/profilePictureElements.js'
import { familyHQTabs, superSmallScoreboardWidth } from '../../../helpers/variableDefaults.js'
import { peopleTabOwnerPersonSpace, settingsTabOwnerPersonSpace, missionTabOwnerPersonSpace } from '../../../helpers/ownerPersonSpaceTabs.js'
import { makeTabIndex } from '../../../helpers/formatting.js'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'

// Owner Person Space Page
const OwnerPersonSpace = (props) => {

  // Destructure Props
  const { userIsPerson, person, estate, personId, estateId, setPerson, setEstate, loading, setLoading, loadingProfPic, setLoadingProfPic, resetLoading, errors, setErrors, uploadProfilePictureErrors, setUploadProfilePictureErrors, resetErrors, uploadModifiedUser, payload, navigate, width, setSaveSwitchSuccessful } = props

  //Keeps track of which tab we are in, default is Wishlist at index 0
  const [value, setValue] = useState(0)

  // States specific to the Owner's person space
  const [peopleToDelete, setPeopleToDelete] = useState([])
  const [peopleToAdd, setPeopleToAdd] = useState({ metadata: { people: [] } })
  const [peopleToModify, setPeopleToModify] = useState({ people: [] })

  // Keeps track of whether heir shares add up to more than 100
  const [sharesOver100, setSharesOver100] = useState(false)

  // Keep track of settings changes
  const [newEstateName, setNewEstateName] = useState(estate.estateName)

  const estateHeirAllotmentsPeople = [ ...estate.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)) ]
  const [newHeirAllotments, setNewHeirAllotments] = useState({ people: estateHeirAllotmentsPeople })

  const estateFeesPeople = [ ...estate.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)) ]
  const [newFees, setNewFees] = useState({ people: estateFeesPeople })

  const [newTotalTaxes, setNewTotalTaxes] = useState(estate.totalTaxes)
  const [newMission, setNewMission] = useState(estate.mission)

  const [silentOrParticipatoryValue, setSilentOrParticipatoryValue] = useState(estate.hasOwnProperty('event') && estate.event[0].hasOwnProperty('silentOrParticipatory') ? estate.event[0].silentOrParticipatory : 'Silent')
  const [openModal, setOpenModal] = useState(false)

  // Edit Error
  const [saveEstateNameSuccessful, setSaveEstateNameSuccessful] = useState(false)
  const [saveEventSuccessful, setSaveEventSuccessful] = useState(false)
  const [saveTransparencySuccessful, setSaveTransparencySuccessful] = useState(false)
  const [saveHeirAllotmentsSuccessful, setSaveHeirAllotmentsSuccessful] = useState(false)
  const [saveFeesSuccessful, setSaveFeesSuccessful] = useState(false)
  const [saveTotalTaxesSuccessful, setSaveTotalTaxesSuccessful] = useState(false)
  const [saveMissionSuccessful, setSaveMissionSuccessful] = useState(false)
  const [editSettingsError, setEditSettingsError] = useState(false)
  const [errorEstateName, setErrorEstateName] = useState(false)
  const [errorSaveEvent, setErrorSaveEvent] = useState(false)
  const [errorSaveTransparency, setErrorSaveTransparency] = useState(false)
  const [errorHeirAllotments, setErrorHeirAllotments] = useState(false)
  const [errorFees, setErrorFees] = useState(false)
  const [errorTotalTaxes, setErrorTotalTaxes] = useState(false)
  const [editPeopleError, setEditPeopleError] = useState(false)
  const [editMissionError, setEditMissionError] = useState(false)

  useEffect(() => {
    
    // Collect Analytics
    customAnalyticsEvent(
      `person_space_owner`, 
      `person_space_owner`, 
      'person_space_owner', 
      `person_space_owner`, 
      'Person Space — Owner'
    )

  }, [])

  // Update view every time estate changes
  useEffect(() => {
    // console.log('newHeirAllotments.people ->', newHeirAllotments.people)
    // console.log('estate.people ->', estate.people)

    // console.log('estate.people === newHeirAllotments.people ->', estate.people === newHeirAllotments.people)

  }, [estate, peopleToDelete, newEstateName, newHeirAllotments, newFees, newTotalTaxes, newMission, openModal])

  // Handles tab changes
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
    setSaveSwitchSuccessful(false)

    // Collect Analytics
    customAnalyticsEvent(
      `owner_space_${newValue === 0 ? 'settings' : newValue === 1 ? 'people' : 'mission'}_tab`, 
      `owner_space_${newValue === 0 ? 'settings' : newValue === 1 ? 'people' : 'mission'}_tab`, 
      `owner_space_${newValue === 0 ? 'settings' : newValue === 1 ? 'people' : 'mission'}_tab`, 
      `owner_space_${newValue === 0 ? 'settings' : newValue === 1 ? 'people' : 'mission'}_tab`, 
      `Owner Space — ${newValue === 0 ? 'Settings' : newValue === 1 ? 'People' : 'Mission'} Tab`
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {seoPageTags(
        '404'
      )} */}

      {/* Body */}
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

        {/* Tabs */}
        <Box sx={{ mt: 2 }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
            variant={width < superSmallScoreboardWidth ? 'scrollable' : 'standard'}
            sx={{ 
              // backgroundColor: 'yellow',
              // width: '90vw', maxWidth: '375px',
              width: width < superSmallScoreboardWidth ? '100%' : '275px',
              borderTop: 1, borderColor: 'divider',
              // display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {familyHQTabs.map((tabName, tabIndex) => (
              <Tab key={tabIndex} label={tabName} {...makeTabIndex(tabIndex)} />
            ))}
          </Tabs>
        </Box>


        {/* Tab Panels */}

        {/* Settings */}
        {estate.hasOwnProperty('event') && estate.event.length > 0 
          && settingsTabOwnerPersonSpace(estate, setEstate, 'settings', value, 0, width, loading, errors, sharesOver100, setSharesOver100, editSettingsError, setEditSettingsError, newEstateName, setNewEstateName, saveEstateNameSuccessful, setSaveEstateNameSuccessful, errorEstateName, setErrorEstateName, newTotalTaxes, setNewTotalTaxes, saveTotalTaxesSuccessful, setSaveTotalTaxesSuccessful, errorTotalTaxes, setErrorTotalTaxes, saveEventSuccessful, setSaveEventSuccessful, errorSaveEvent, setErrorSaveEvent, saveTransparencySuccessful, setSaveTransparencySuccessful, errorSaveTransparency, setErrorSaveTransparency, newHeirAllotments, setNewHeirAllotments, saveHeirAllotmentsSuccessful, setSaveHeirAllotmentsSuccessful, errorHeirAllotments, setErrorHeirAllotments, newFees, setNewFees, saveFeesSuccessful, setSaveFeesSuccessful, errorFees, setErrorFees, setSaveSwitchSuccessful, openModal, setOpenModal, silentOrParticipatoryValue, setSilentOrParticipatoryValue)
        }

        {/* People */}
        {estate.hasOwnProperty('people') && estate.people.length > 0 
          && peopleTabOwnerPersonSpace(estate.people, person, estate, setEstate, 'people', value, 1, width, loading, errors, peopleToDelete, setPeopleToDelete, peopleToAdd, setPeopleToAdd, setLoading, setErrors, peopleToModify, setPeopleToModify, navigate, editPeopleError, setEditPeopleError, setSaveSwitchSuccessful)
        }

        {/* Mission */}
        {estate.hasOwnProperty('mission')
          && missionTabOwnerPersonSpace(estate, setEstate, 'mission', value, 2, width, loading, errors, newMission, setNewMission, editMissionError, setEditMissionError, saveMissionSuccessful, setSaveMissionSuccessful, setSaveSwitchSuccessful)
        }
        
      </Box>
    </>
  )
}

export default OwnerPersonSpace