/* eslint-disable no-prototype-builtins */
import React from 'react'
import axios from 'axios'

import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import StarIcon from '@mui/icons-material/Star'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1' 
import SendIcon from '@mui/icons-material/Send'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import PropTypes from 'prop-types'
import Avatar from '@mui/material/Avatar'

import { Link } from 'react-router-dom'

import HeirAllotmentsItemCard from '../components/person/types/HeirAllotmentsItemCard.js'
import HeirAuctionPlanningItemCard from '../components/person/types/HeirAuctionPlanningItemCard.js'
import HeirDraftRankingsItemCard from '../components/person/types/HeirDraftRankingsItemCard.js'

import { TabPanel } from './formatting.js'
import { standardSpinner } from './spinners.js'
import { standardButton } from './buttons.js'
import { standardErrorContainer } from './errors.js'
import { missionTextTypography, saveSuccessfulTypography } from './typographies.js'
import { positionChangeWidthSm, sheirBlue, widthInsideTabPanel, positionChangeWidthMd, positionChangeHeightSm, heirAllotmentsSortByArray, sheirGreen } from './variableDefaults.js'
import { validEmail, reloadView } from './globalHelpers.js'
import { getTokenFromLocalStorage } from './storage.js'
import { personItemTextField, descriptionTextField } from './textfields.js'
import { selectElement, selectSortCategory } from './selects.js'
import { eventDateElement } from './dateAndTime.js'
import { getEstateTotalValue, getHeirAllotmentsTotal, getHeirShareTotal, categoryPercentOfTotal, getHeirAuctionPlanningPointsAllottedTotal, getHeirAllotmentsTotalByEstate } from './getValuationTotals.js'
import { pieChartOfCategoryPercents } from './pieCharts.js'
import { modifyInDatabase } from './modifyInDatabase.js'
import { NumericFormatCustom } from './formatting.js'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'


// Allotment title and dollar value JSX; used in Heir Person Space Allotments Tab, and also in The Pot, and also in Heir Person Space Auction Planning
export const allotmentNumberElements = (title, value, fromTab) => {
  // console.log('title ->', title)
  // console.log('value ->', value)
  // console.log('fromTab ->', fromTab)
  return (
    <Box 
      sx={{
        width: '100%',
        px: (fromTab === 'liquidatePot' || fromTab === 'giftPot' || fromTab === 'pot') ? 1 : 0,
        maxWidth: '300px',
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
      }}
    >
      {/* Title */}
      <Typography
        sx={{
          ml: 1,
          mt: 1,
          // width: '100%',
          minWidth: '100px',
          fontSize: '18px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: title === 'Total For Heirs' ? 'bold' : 'normal',
          color: (fromTab === 'liquidatePot' || fromTab === 'giftPot') ? 'white' : 'black',
        }}
      >
        {title}
      </Typography>

      {/* Allotment Amount */}
      <Typography 
        sx={{ 
          mr: 1, 
          mt: 1,
          fontSize: '18px',
          color: (fromTab === 'liquidatePot' || fromTab === 'giftPot') ? 'white' : (title === 'Taxes' || title === 'Fees' || title === 'Gifts' || ((fromTab === 'allotments' || fromTab === 'pot' || fromTab === 'personSpace') && typeof value === 'number' && value < 0)) ? 'red' : fromTab === 'planningDisabled' ? 'darkgray' : sheirBlue,
          fontWeight: title === 'Total For Heirs' ? 'bold' : 'normal',
          // display: 'flex', flexDirection: 'row', alignItems: 'flex-end',
        }}
      >
        {(fromTab === 'liquidatePot' || fromTab === 'giftPot' || fromTab === 'allotments' || fromTab === 'pot' || fromTab === 'personSpace') && '$'}{value.toLocaleString('en-US', { minimumFractionDigits: 0 })}
      </Typography>
    </Box>
  )
}

// Planning Tab in Heir Person Space
export const heirPlanningTab = (userIsPerson, estate, setEstate, person, setPerson, pointsOver1000, setPointsOver1000, hasModifiedAuctionPlanning, setHasModifiedAuctionPlanning, itemsAtLoad, setItemsAtLoad, value, index, width, loading, errors, draftCards, setDraftCards, savedDraftRankings, setSavedDraftRankings, saveRankingsError, setSaveRankingsError, setSaveSwitchSuccessful) => {

  // Checks for duplicate draft rankings
  // Boolean — returns true if there are no duplicate rankings; returns false if there are duplicates
  const noDuplicateRankings = (currentRankings) => {
    if (currentRankings.length > 0) {
      // const emailsArray = onboardingObj.people.map(person => person.hasOwnProperty('email') && person.email)
      // console.log('are there duplicate emails ?', new Set(emailsArray).size === emailsArray.length)

      const rankingsArray = currentRankings.map(item => item.hasOwnProperty('heirDraftPlanning') && item.heirDraftPlanning[person._id])
      // console.log('rankingsArray ->', rankingsArray)

      return new Set(rankingsArray).size === rankingsArray.length
    } else {
      return true
    }
  }

  const draftRankingsHaveChanged = (currentRankingsArray, lastSavedRankingsArray) => {

    if (currentRankingsArray.length === lastSavedRankingsArray.length 
    // && noDuplicateRankings(currentRankingsArray)
    ) {
      // console.log('this runs')
      for (let i = 0; i < lastSavedRankingsArray.length; i++) {
        if (currentRankingsArray[i]._id !== lastSavedRankingsArray[i]._id) {
          return true
        }
      }
      return false
    } else {
      return false
    }
  }

  const handleCancelModifiedDraftRankingsPressed = () => {
    // console.log('handleCancelModifiedDraftRankingsPressed runs')
    setSaveRankingsError(false)
    setDraftCards(savedDraftRankings)
  }

  const handleSaveModifiedDraftRankingsPressed = async () => {
    // console.log('handleSaveModifiedDraftRankingsPressed runs')
    setSaveRankingsError(false)

    const objectToUpload = {
      editedItemsArray: draftCards, 
      personId: person._id,
    }
    try {
      // PUT request with the new draft rankings
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/items-array/draft-rankings`, objectToUpload, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })

      setSavedDraftRankings(draftCards)

    } catch (error) {
      console.log('error ->', error)
      setSaveRankingsError(true)
    }

  }

  // Save and Cancel Buttons when changing Draft Rankings
  const saveAndCancelButtonsHeirDraftRankings = () => {
    // console.log('saveAndCancelButtonsHeirDraftRankings')

    return (
      <>
        {/* Buttons */}
        <Box
          sx={{
            mb: 4, mt: 4,
            width: '100%',
            display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Cancel/Delete Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Cancel', 
              'button',
              'contained',
              false,
              'secondary', 
              1,
              1,
              2,
              '150px', 
              '45px', 
              handleCancelModifiedDraftRankingsPressed
            )}
          </Box>


          {/* Save Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Save', 
              'button',
              'contained',
              false,
              'primary', 
              1,
              1,
              2,
              '150px', 
              '45px', 
              handleSaveModifiedDraftRankingsPressed
            )}
          </Box>

        </Box>

        {/* Error Text */}
        {saveRankingsError &&
          standardErrorContainer(
            `*Upload error. Check Internet connection.`,
            0,
            0
          )
        }
      </>
    )
  }

  const planninTabJSX = () => {
    return (
      <Box 
        sx={{ 
          // backgroundColor: 'yellow',
          // width: '100%', 
        }}
      >
        {loading ?
          standardSpinner()
          : errors ?
            <Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center', my: '10%' }} >
              <Typography variant='p' sx={{ color: 'red' }}>
                Error! Could not fetch data!
              </Typography>
            </Container>
            : 
            <>
              {/* Advisory — Auction */}
              {estate.event[0].distributionMethod === 'Auction' && estate.event[0].silentOrParticipatory === 'Participatory' &&
                <>
                  {/* Total Points to Allot */}
                  <Box sx={{ mt: 1, mb: 2, width: widthInsideTabPanel, maxWidth: '600px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {allotmentNumberElements('Points to Allot:', 1000, (estate.event[0].hasBegunTimer || estate.event[0].hasFinished) ? 'planningDisabled' : 'planning')}
                  </Box>

                  {/* Total Points Allotted */}
                  <Box sx={{ mt: 1, mb: 2, width: widthInsideTabPanel, maxWidth: '600px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {allotmentNumberElements('Allotted', getHeirAuctionPlanningPointsAllottedTotal(estate, person._id), (estate.event[0].hasBegunTimer || estate.event[0].hasFinished) ? 'planningDisabled' : 'planning')}
                  </Box>

                  {/* Total Remaining Points to Allot */}
                  <Box sx={{ mt: 1, mb: 2, width: widthInsideTabPanel, maxWidth: '600px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {allotmentNumberElements('Remaining', 1000 - getHeirAuctionPlanningPointsAllottedTotal(estate, person._id), (estate.event[0].hasBegunTimer || estate.event[0].hasFinished) ? 'planningDisabled' : 'planning')}
                  </Box>
                </>
              }

              {/* Advisory — Draft */}
              {(estate.event[0].distributionMethod === 'Draft' || estate.event[0].silentOrParticipatory === 'Partial') && !(estate.event[0].hasBegunTimer || estate.event[0].hasFinished) &&
                <Box sx={{ mt: estate.event[0].silentOrParticipatory === 'Partial' ? 2 : 0, mb: 0, width: widthInsideTabPanel, maxWidth: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Typography 
                    // textAlign={'left'}
                    sx={{
                      // backgroundColor: 'yellow',
                      mt: 0,
                      px: 1,
                      width: '95%',
                      textAlign: 'left',
                      color: 'black',
                      fontSize: '16px',
                      fontStyle: 'italic',
                      display: 'flex', flexDirection: 'row', justifyContent: userIsPerson ? 'flex-start' : 'center',
                    }}
                  >
                    {userIsPerson ?
                      `*Rank items in the order that you would like to draft them by using up and down arrows${width < positionChangeWidthSm ? '.' : ' or drag-and-drop.'} Then press 'Save'.`
                      :
                      `The are ${person.userGivenAndFamilyName.split(' ')[0]}'s rankings for the unassigned items.`
                    }
                  </Typography>
                </Box>
              }

              {/* Save and Cancel Buttons for Draft */}
              {/* {console.log('rankings changed ->', draftRankingsHaveChanged(draftCards, savedDraftRankings))} */}
              {(estate.event[0].distributionMethod === 'Draft' || estate.event[0].silentOrParticipatory === 'Partial') && draftRankingsHaveChanged(draftCards, savedDraftRankings) &&
                saveAndCancelButtonsHeirDraftRankings()
              }

              {(estate.event[0].hasBegunTimer || estate.event[0].hasFinished) &&
                <Box sx={{ mt: 0, mb: 0, width: widthInsideTabPanel, maxWidth: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Typography 
                    // textAlign={'left'}
                    sx={{
                      // backgroundColor: 'yellow',
                      mt: 0,
                      px: 1,
                      width: '95%',
                      textAlign: 'center',
                      color: 'black',
                      fontSize: '16px',
                      fontStyle: 'italic',
                      display: 'flex', flexDirection: 'row', justifyContent: 'center',
                    }}
                  >
                    *Cannot change event planning after event has started.
                  </Typography>
                </Box>
              }

              {/* Unassigned Items to Rank or Allot Points To */}
              <Box sx={{ mt: 4, mb: 2, width: widthInsideTabPanel, maxWidth: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                
                {/* If Auction */}
                {estate.event[0].distributionMethod === 'Auction' && estate.event[0].silentOrParticipatory === 'Participatory' && itemsAtLoad.length > 0 && itemsAtLoad.map((item, itemIndex) => (
                  <Box key={itemIndex}
                    sx={{
                      width: '100%',
                      mb: 2,
                      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                    }}
                  >
                    <HeirAuctionPlanningItemCard item={item} person={person} estate={estate} setEstate={setEstate} pointsOver1000={pointsOver1000} setPointsOver1000={setPointsOver1000} />
                  </Box>
                ))}

                {/* If Draft — DndProvider enables Drag and Drop*/}
                {(estate.event[0].distributionMethod === 'Draft' || estate.event[0].silentOrParticipatory === 'Partial') && 
                  <DndProvider backend={HTML5Backend}>
                    {draftCards.length > 0 && draftCards.map((item, itemIndex) => (
                      <Box key={itemIndex}
                        sx={{
                          width: '100%',
                          mb: 2,
                          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                        }}
                      >
                        <HeirDraftRankingsItemCard item={item} person={person} userIsPerson={userIsPerson} estate={estate} setEstate={setEstate} ranking={itemIndex + 1} indexOfRanking={itemIndex} indexOnEstateItems={estate.items.map(e => e._id).indexOf(item._id)} draftCards={draftCards} setDraftCards={setDraftCards} savedDraftRankings={savedDraftRankings} setSavedDraftRankings={setSavedDraftRankings} />
                      </Box>
                    ))}
                  </DndProvider>
                }
                

              </Box>

              {/* Save and Cancel Buttons for Draft */}
              {/* {console.log('rankings changed ->', draftRankingsHaveChanged(draftCards, savedDraftRankings))} */}
              {(estate.event[0].distributionMethod === 'Draft' || estate.event[0].silentOrParticipatory === 'Partial') && draftRankingsHaveChanged(draftCards, savedDraftRankings) &&
                saveAndCancelButtonsHeirDraftRankings()
              }
            </>
        }
      </Box>
    )
  }

  return (
      
    <>
      {estate.hasOwnProperty('event') && estate.event[0].hasOwnProperty('silentOrParticipatory') && estate.event[0].silentOrParticipatory === 'Participatory' ?
        <TabPanel value={value} index={index}>
          {planninTabJSX()}
        </TabPanel>
        :
        <Box 
          sx={{ 
            // backgroundColor: 'orange',
            width: '100%', 
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
          }}>
          {planninTabJSX()}
        </Box>
      }
      
    </>
    
  )
}

// Used in Heir Allotments Tab and also in The Pot
export const heirAllotmentsTab = (userIsPerson, fromTab, estate, person, value, index, hover, setHover, width, loading, errors, heirAllotmentsSortBy, setHeirAllotmentsSortBy, filteredItems, setFilteredItems, filtersObj, setFiltersObj, showFilters, setShowFilters, filtersInUse, handleFilterIconPressed, handleResetPressed) => {

  // console.log('person ->', person)

  const allotmentsTabJSX = () => {
    return (
      <Box 
        sx={{ 
          // backgroundColor: 'yellow',
          width: userIsPerson ? widthInsideTabPanel : '100%', 
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {loading ?
          standardSpinner()
          : errors ?
            <Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center', my: '10%' }} >
              <Typography variant='p' sx={{ color: 'red' }}>
                Error! Could not fetch data!
              </Typography>
            </Container>
            : 
            <>
              {/* Total Heir Share */}
              <Box sx={{ mt: fromTab === 'pot' ? 0 : 1, mb: fromTab === 'pot' ? 0 : 2, width: fromTab === 'pot' ? '100%' : '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {allotmentNumberElements('Total', Math.floor(person.sharePercentage) === 0 ? 0 : getHeirShareTotal(estate, person.sharePercentage), fromTab)}
              </Box>

              {/* Total Allotted to Heir */}
              <Box sx={{ mt: fromTab === 'pot' ? 0 : 1, mb: fromTab === 'pot' ? 0 : 2, width: fromTab === 'pot' ? '100%' : '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {allotmentNumberElements('Allotted', getHeirAllotmentsTotalByEstate(estate, person), fromTab)}
              </Box>

              {/* Total Remaining for Heir */}
              <Box sx={{ mt: fromTab === 'pot' ? 0 : 1, mb: fromTab === 'pot' ? 0 : 2, width: fromTab === 'pot' ? '100%' : '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {allotmentNumberElements('Remaining', getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person), fromTab)}
              </Box>


              {/* Pie Chart of Category Percents */}
              <Box sx={{ mt: fromTab === 'pot' ? 0 : 1, mb: fromTab === 'pot' ? 0 : 2, width: fromTab === 'pot' ? '100%' : '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {pieChartOfCategoryPercents(person, estate, hover, setHover, width, 'categories', fromTab)}
              </Box>

              {/* Allotments */}
              {(fromTab === 'heirSpace' || fromTab === 'personSpace') && estate.hasOwnProperty('items') && estate.items.filter(item => item.allottedToPersonId === person._id).length > 0 &&
                <Box sx={{ mt: 0, mb: 2, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Box
                    sx={{
                      mb: width < positionChangeWidthSm ? 1 : 1,
                      width: width < positionChangeWidthSm ? '80%' : '95%',
                      // maxWidth: '300px',
                      display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'space-between' : 'space-between', alignItems: 'center',
                    }}
                  >
                    {/* Sort Allotted Items */}
                    {selectSortCategory('sortItemsIndex', 'Sort by:', false, heirAllotmentsSortBy, heirAllotmentsSortByArray, setHeirAllotmentsSortBy)}

                    <Box
                      sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'center' : 'flex-end', alignItems: 'center',
                      }}
                    >
                      {/* Filter Button */}
                      <IconButton
                        onClick={handleFilterIconPressed} 
                        sx={{ 
                          width: '30px', height: '30px', 
                          mb: 1, mt: 1, mr: width < positionChangeWidthSm ? 1 : 1, ml: 1,bottom: 0, left: 0, 
                          border: 2, borderColor: 'white', boxShadow: 3, 
                        }} 
                      >
                        <FilterAltIcon 
                          fontSize="small" 
                          color="primary"
                          sx={{ 

                          }} 
                        />
                      </IconButton>

                      {/* Reset Button */}
                      {filtersInUse() &&
                        standardButton(
                          'Reset', 
                          'button',
                          'text',
                          false,
                          'primary', 
                          0,
                          0,
                          width < positionChangeWidthSm ? -1 : 0,
                          '55px', 
                          '30px', 
                          handleResetPressed
                        )
                      }

                    </Box>
                    
                  </Box>

                  {/* Number of items that match criteria */}
                  <Box 
                    sx={{
                      // backgroundColor: 'yellow',
                      width: width < positionChangeWidthSm ? '80%' : '95%', maxWidth: '600px',
                      mb: 1.2, mr: width < positionChangeWidthSm ? 2 : 4, ml: 1, mt: -1, pr: 0,
                      fontSize: '12px',
                      fontStyle: 'italic',
                      display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
                    }}
                  >
                    {filteredItems.length} items
                  </Box>
                  
                  {/* Listing the Allotted Items */}
                  {estate.hasOwnProperty('items') && filteredItems.length > 0 && filteredItems.sort((a, b) => heirAllotmentsSortBy === 'Valuation ⬇' ? b.valuation - a.valuation : heirAllotmentsSortBy === 'Valuation ⬆' ? a.valuation - b.valuation : heirAllotmentsSortBy === 'Category' ? a[heirAllotmentsSortBy.toLowerCase()].localeCompare(b[heirAllotmentsSortBy.toLowerCase()]) : heirAllotmentsSortBy === 'A ➡ Z' ? a.itemName.localeCompare(b.itemName) : b.itemName.localeCompare(a.itemName)).map((item, itemIndex) => (
                    <Box key={itemIndex}
                      sx={{
                        width: '100%',
                        mb: 2,
                        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                      }}
                    >
                      <HeirAllotmentsItemCard item={item} person={person} estate={estate}  />
                    </Box>
                  ))}

                  {/* Message if no items meet search criteria */}
                  {filteredItems.length === 0 &&
                    <Typography 
                      sx={{ 
                        color: sheirBlue, mt: 4, fontWeight: 'bold', 
                      }}
                    >
                      No items fit your search criteria
                    </Typography>
                  }

                </Box>
              }
              
            </>
        }
      </Box>
    )
  }

  return (
    <>
      {userIsPerson && fromTab === 'heirSpace' ?
        <TabPanel value={value} index={index}>
          {allotmentsTabJSX()}
        </TabPanel>
        :
        <Box 
          sx={{ 
            // backgroundColor: 'orange',
            width: '100%', 
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
          }}>
          {allotmentsTabJSX()}
        </Box>
      }
      
    </>
    
  )
}

export const heirNotesTab = (person, setPerson, value, index, width, loading, errors, newNotes, setNewNotes, editNotesError, setEditNotesError, saveNotesSuccessful, setSaveNotesSuccessful, setSaveSwitchSuccessful) => {
  

  const handleNotesChange = async (e) => {
    // console.log('handleNotesChange')

    setSaveSwitchSuccessful(false)
    setEditNotesError(false)
    setSaveNotesSuccessful(false)

    setNewNotes(e.target.value)
  }

  const handleCancelNotesPressed = async (e) => {
    // console.log('handleCancelNotesPressed')

    setNewNotes(person.notes)
  }

  const handleSaveNotesPressed = async (e) => {
    // console.log('handleSaveMissionPressed')

    modifyInDatabase('persons', person._id, { 'notes': newNotes ? newNotes : '' }, setEditNotesError)

    setPerson({ ...person, 'notes': newNotes })

    setSaveNotesSuccessful(true)

  }

  const saveAndCancelButtonsOwnerNotesTab = (name, disabled, handleCancel, handleSave) => {
    return (
      <>
        <Box
          sx={{
            mt: 1,
            mb: 1,
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Cancel/Delete Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Cancel', 
              'button',
              'contained',
              disabled,
              'secondary', 
              1,
              1,
              2,
              '80px', 
              '35px', 
              handleCancel
            )}
          </Box>


          {/* Save Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Save', 
              'button',
              'contained',
              disabled,
              'primary', 
              1,
              1,
              2,
              '80px', 
              '35px', 
              handleSave
            )}
          </Box>

        </Box>
      </>
    )
  }

  return (
    <TabPanel value={value} index={index}>
      {loading ?
        standardSpinner()
        : errors ?
          <Box maxWidth='md' sx={{ width: '95%', display: 'flex', justifyContent: 'center', my: '10%' }} >
            <Typography variant='p' sx={{ color: 'red' }}>
              Error! Could not fetch data!
            </Typography>
          </Box>
          : 
          
          <Box 
            sx={{ 
              mt: 1, mb: 2, 
              width: widthInsideTabPanel,
              // backgroundColor: 'yellow',
              maxWidth: '600px', 
              // minWidth: '250px', 
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            {/* Description */}
            {descriptionTextField(
              'notes', 
              newNotes, 
              10, 
              'Write notes here...', 
              handleNotesChange,
              setPerson,
              person
            )}

            {/* Save and Cancel Buttons */}
            {person.notes !== newNotes && newNotes.length > 0 &&
              saveAndCancelButtonsOwnerNotesTab('notes', person.notes === newNotes, handleCancelNotesPressed, handleSaveNotesPressed)
            }

            {/* Save Notes Successful */}
            {saveNotesSuccessful && !editNotesError &&
              saveSuccessfulTypography()
            }

            {/* Error Text */}
            {editNotesError &&
              standardErrorContainer(
                `*Upload error. Check Internet connection.`,
                0,
                0
              )
            }

          </Box>

      }
    </TabPanel>
  )
}