/* eslint-disable no-prototype-builtins */
import React from 'react'
import axios from 'axios'

import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import StarIcon from '@mui/icons-material/Star'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import SendIcon from '@mui/icons-material/Send'
import PropTypes from 'prop-types'
import Avatar from '@mui/material/Avatar'

import { Link } from 'react-router-dom'

import PersonItem from '../components/onboarding/steps/PersonItem.js'
import PersonItemOwnerSpace from '../components/person/types/PersonItemOwnerSpace.js'

import { TabPanel } from './formatting.js'
import { standardSpinner } from './spinners.js'
import { standardButton } from './buttons.js'
import { standardErrorContainer } from './errors.js'
import { missionTextTypography, saveSuccessfulTypography } from './typographies.js'
import { personTypes, silentDistributionMethods, distributionMethod, liveOrAuto, positionChangeWidthSm, sheirBlue, positionChangeWidthMd, positionChangeHeightSm, widthInsideTabPanel, tooltipInfoDimension, sheirGreen, silentOrParticipatory } from './variableDefaults.js'
import { validEmail, reloadView } from './globalHelpers.js'
import { getTokenFromLocalStorage } from './storage.js'
import { personItemTextField, descriptionTextField } from './textfields.js'
import { selectElement } from './selects.js'
import { eventDateElement } from './dateAndTime.js'
import { modifyInDatabase } from './modifyInDatabase'
import { standardInfoTooltip } from './tooltips.js'
import { standardModal } from './modals.js'

import profPicDefault from '../assets/seed-profpic-default-4.jpg'


// Makes sure all Person items have a role, given name, family name, and email filled in 
export const allPersonElementsAdded = (onboardingObj) => {
  // console.log('allPersonElementsAdded runs')

  let totalItems = 0 // Count of total items
  let totalCompletedItems = 0 // Count of items with role, given name, family name, and email filled in

  for (let i = 0; i < onboardingObj.people.length; i++) {

    totalItems = totalItems + 1 // Increment totalItems

    if (
      (onboardingObj.people[i].hasOwnProperty('role') && onboardingObj.people[i].role) 
      && (onboardingObj.people[i].hasOwnProperty('givenName') && onboardingObj.people[i].givenName) 
      && (onboardingObj.people[i].hasOwnProperty('familyName') && onboardingObj.people[i].familyName)
      && (onboardingObj.people[i].hasOwnProperty('email') && onboardingObj.people[i].email && validEmail(onboardingObj.people[i].email))
    ) {
      totalCompletedItems = totalCompletedItems + 1 // Increment totalCompletedItems
    }

  }

  return totalItems === totalCompletedItems //Must be true for Next Button and Add Person Button to be enabled
}

export const includesAuthenticatedUserEmail = (onboardingObj, authenticatedUserObj) => {
  // console.log('authenticatedUserObj ->', authenticatedUserObj)
  if (onboardingObj.hasOwnProperty('people') && onboardingObj.people.length > 0 && authenticatedUserObj && authenticatedUserObj.hasOwnProperty('email')) {
    // console.log('true runs')
    return onboardingObj.people.filter(person => person.hasOwnProperty('email') && person.email === authenticatedUserObj.email).length > 0
  } else {
    // console.log('false runs')
    // console.log('onboardingObj.hasOwnProperty(people) ->', onboardingObj.hasOwnProperty('people'))
    // console.log('onboardingObj.people.length > 0 ->', onboardingObj.people.length > 0)
    // console.log('authenticatedUserObj && authenticatedUserObj.hasOwnProperty(email) ->', authenticatedUserObj && authenticatedUserObj.hasOwnProperty('email'))

    return false
  }
}

// Checks for duplicate email in onboarding object
// Boolean — returns true if there are no duplicate emails; returns false if there are duplicates
export const noDuplicateEmails = (onboardingObj) => {
  if (onboardingObj.hasOwnProperty('people') && onboardingObj.people.length > 0) {
    const emailsArray = onboardingObj.people.map(person => person.hasOwnProperty('email') && person.email)
    // console.log('are there duplicate emails ?', new Set(emailsArray).size === emailsArray.length)
    return new Set(emailsArray).size === emailsArray.length
  } else {
    return true
  }
}

export const noDuplicateEmailsOwnerPersonTab = (peopleToAdd, people) => {
  // console.log('peopleToAdd ->', peopleToAdd)
  // console.log('people ->', people)
  if (peopleToAdd.length > 0 && people.length > 0) {
    for (let i = 0; i < peopleToAdd.length; i++) {
      for (let x = 0; x < people.length; x++) {
        if (peopleToAdd[i].email === people[x].email) {
          return false
        }
      }
    }
    return true
  } else {
    return true
  }
}

// Save and Cancel Buttons when adding or removing a person to or from the Estate
const saveAndCancelButtonsOwnerPeopleTab = (fromTab, width, estate, setEstate, peopleToAdd, setPeopleToAdd, peopleToDelete, setPeopleToDelete, setLoading, errors, setErrors, navigate, editPeopleError, setEditPeopleError) => {

  // Resets peopleToAdd and peopleToDelete when cancel is pressed
  const handleCancelPressed = () => {
    setPeopleToAdd({ metadata: { people: [] } })
    setPeopleToDelete([])
  }

  // Delete people if 'Save' is pressed and there are people in the peopleToDelete array
  const deletePeople = async (deletePeopleArray) => {
    try {
      axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/persons`, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
        data: deletePeopleArray,
      })

    } catch (err) {
      // console.log('err: ', err)
      setEditPeopleError(true)
      setLoading(false)
    }
  }

  // Upload new people to database if 'Save' is pressed and there are people in the peopleToAdd array
  const uploadNewPeople = async (newPersonsArray) => {
    try {
      const { data: addedPeople } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/persons/`, newPersonsArray, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })

      return addedPeople

    } catch (err) {
      // console.log('err: ', err)
      setEditPeopleError(true)
      setLoading(false)
    }
  }

  // When save is pressed, determine whether to add, delete, or add and delete people
  const handleSavePressed = async () => {
    // console.log('handleSavePressed')

    if ((peopleToAdd.people.length > 0) || (peopleToDelete.length > 0)) {
      setLoading(true)

      const newEstatePeople = [ ...estate.people ]

      // Remove deleted people; later push() added people if there are people to add
      const estatePeopleDeletedAndAdded = await newEstatePeople.filter(person => !peopleToDelete.includes(person._id))

      // Remove people to delete in from database
      if (peopleToDelete.length > 0) {
        const deletePersons = [ ...peopleToDelete ]
        await deletePeople(deletePersons)
      }

      // Add people to add to database
      if (peopleToAdd.people.length > 0) {
        const newPersonsArray = [ ...peopleToAdd.people ]
        const addedPeople = await uploadNewPeople(newPersonsArray)

        // Adding people for setting estate.people
        for (let i = 0; i < addedPeople.length; i++) {
          // Avoid duplicates
          if (estatePeopleDeletedAndAdded.filter(person => person._id === addedPeople[i]._id).length === 0) {
            estatePeopleDeletedAndAdded.push(addedPeople[i])
          }
        }
      }

      // Update States
      setEstate({ ...estate, people: estatePeopleDeletedAndAdded })
      setPeopleToAdd({ metadata: { people: [] } })
      setPeopleToDelete([])
      
      setEditPeopleError(false)
      setLoading(false)
    }
    
  }

  return (
    <>
      {/* Buttons */}
      <Box
        sx={{
          mb: 4,
          width: '100%',
          display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {/* Cancel/Delete Button */}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {standardButton(
            'Cancel', 
            'button',
            'contained',
            false,
            'secondary', 
            1,
            1,
            2,
            '150px', 
            '45px', 
            handleCancelPressed
          )}
        </Box>


        {/* Save Button */}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {standardButton(
            'Save', 
            'button',
            'contained',
            !((fromTab === 'people' && allPersonElementsAdded(peopleToAdd) && noDuplicateEmailsOwnerPersonTab(peopleToAdd.people, estate.people))),
            'primary', 
            1,
            1,
            2,
            '150px', 
            '45px', 
            handleSavePressed
          )}
        </Box>

      </Box>

      {/* Error Text */}
      {errors &&
        standardErrorContainer(
          `*Upload error. Check Internet connection.`,
          0,
          0
        )
      }
    </>
  )
}

export const peopleTabOwnerPersonSpace = (people, userPerson, estate, setEstate, panelName, value, index, width, loading, errors, peopleToDelete, setPeopleToDelete, peopleToAdd, setPeopleToAdd, setLoading, setErrors, peopleToModify, setPeopleToModify, navigate, editPeopleError, setEditPeopleError, setSaveSwitchSuccessful ) => {
  
  // When 'Add Person' is pressed
  const handleAddPerson = async (e) => {
    e.preventDefault()
    setSaveSwitchSuccessful(false)

    // Destructure peopleArray array
    const newArray = [ ...peopleToAdd.metadata.people ]

    // Push a new option onto the newArray
    newArray.push(
      {
        role: '',
        sharePercentage: '0',
        givenName: '',
        familyName: '',
        email: '',
        estateId: estate._id,
      }
    )

    // setPeopleArray with the newArray
    setPeopleToAdd({ metadata: { people: newArray } })
  }
  
  // console.log('peopleTab')
  return (
    <TabPanel value={value} index={index}>
      {loading ?
        standardSpinner()
        : errors ?
          <Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center', my: '10%' }} >
            <Typography variant='p' sx={{ color: 'red' }}>
              Error! Could not fetch data!
            </Typography>
          </Container>
          : 
          <>
            {/* Save and Cancel Buttons */}
            {((peopleToAdd.metadata.people.length > 0) || (peopleToDelete.length > 0) ) && saveAndCancelButtonsOwnerPeopleTab('people', width, estate, setEstate, peopleToAdd.metadata, setPeopleToAdd, peopleToDelete, setPeopleToDelete, setLoading, errors, setErrors, navigate, editPeopleError, setEditPeopleError)}

            {/* Errors */}
            {editPeopleError &&
              standardErrorContainer('Error uploading changes. Check your Internet connection', 1, 0)
            }

            {/* People */}
            {/* Owner */}
            {people.filter(personInRole => personInRole.role === 'Owner').map((person, personIndex) => (
              <Box key={'Owner'}
                sx={{
                  // backgroundColor: 'blue',
                  width: '90vw', 
                  maxWidth: positionChangeWidthMd,
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
                }}
              >
                <PersonItemOwnerSpace userPerson={userPerson} estate={estate} setEstate={setEstate} itemIndex={estate.people.map(x => x._id).indexOf(person._id)} fromComponent={'ownerSpace'} peopleToDelete={peopleToDelete} setPeopleToDelete={setPeopleToDelete} peopleToModify={peopleToModify} setPeopleToModify={setPeopleToModify} />
              </Box>
            ))}

            {/* Other People */}
            {personTypes.map((personType) => (
              <Box key={personType}
                sx={{
                  // backgroundColor: 'blue',
                  width: '90vw', 
                  maxWidth: positionChangeWidthMd,
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
                }}
              >
                {/* People in Estate */}
                {people.filter(personInRole => (personInRole.role === personType && !peopleToDelete.includes(personInRole._id))).map((person, personIndex) => (
                  <Box key={person._id} sx={{ width: '100%' }}>
                    <PersonItemOwnerSpace userPerson={userPerson} estate={estate} setEstate={setEstate} itemIndex={estate.people.map(x => x._id).indexOf(person._id)} fromComponent={'ownerSpace'} peopleToDelete={peopleToDelete} setPeopleToDelete={setPeopleToDelete} peopleToModify={peopleToModify} setPeopleToModify={setPeopleToModify} />                                                                                       
                  </Box>
                ))}
              </Box>
            ))}

            {/* People to Add */}
            {peopleToAdd.metadata.people.length > 0 && peopleToAdd.metadata.people.map((personToAdd, personToAddIndex) => (  
          
              <Box key={personToAddIndex} 
                sx={{
                  mt: personToAddIndex === 0 ? 1 : 0,
                  width: '100%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                <PersonItem options={peopleToAdd} setOptions={setPeopleToAdd} itemIndex={personToAddIndex} fromComponent={'ownerSpace'} />
              </Box>
            ))}
            {/* Advisory */}
            {!noDuplicateEmailsOwnerPersonTab(peopleToAdd.metadata.people, people) &&
              <Typography 
                sx={{
                  color: 'red',
                  width: '100%',
                  display: 'flex', flexDirection: 'row', justifyContent: 'center',
                }}
              >
                <em>The same email cannot be used for two people</em>
              </Typography>
            }

            {/* Errors */}
            {editPeopleError &&
              standardErrorContainer('Error uploading changes. Check your Internet connection', 1, 1)
            }

            {/* Add Person Text Button */}
            {/* {!(estate.event[0].hasStarted) && */}
            <Box key={5} 
              sx={{ 
                mb: 0, mt: 1,
                width: '100%', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
              }}
            >
              <Button 
                key={7} 
                disabled={(!allPersonElementsAdded(peopleToAdd.metadata)) || (estate.event[0].hasStarted) || !noDuplicateEmailsOwnerPersonTab(peopleToAdd.metadata.people, people)}
                onClick={handleAddPerson}
                color='primary'
              >
                ADD ANOTHER PERSON
              </Button>
            </Box>
            {/* } */}

            {/* Save and Cancel Buttons */}
            {((peopleToAdd.metadata.people.length > 0) || (peopleToDelete.length > 0) ) && saveAndCancelButtonsOwnerPeopleTab('people', width, estate, setEstate, peopleToAdd.metadata, setPeopleToAdd, peopleToDelete, setPeopleToDelete, setLoading, errors, setErrors, navigate, editPeopleError, setEditPeopleError)}
          </>
      }
    </TabPanel>
  )
}

export const transparencyLevelJSX = (fromTab, silentOrParticipatoryValue, estateOrOnboardingObj, setEstateOrOnboardingObj, changeHandler, width) => {
  // console.log('estateOrOnboardingObj ->', estateOrOnboardingObj)
  const disabledIsTrue = estateOrOnboardingObj.hasOwnProperty('event') && estateOrOnboardingObj.event[0].hasOwnProperty('hasStarted') && estateOrOnboardingObj.event[0].hasStarted

  return (
    <>
      <Box
        sx={{
          width: '81%', maxWidth: '540px',
          mt: 0,
          display: 'flex', flexDirection: 'row', justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            // width: `calc(100% + ${tooltipInfoDimension})`,
            fontSize: '18px',
            fontWeight: 'bold',
            display: 'flex', flexDirection: 'row', justifyContent: 'center',
          }}
        >
          Transparency Level:
        </Typography>

        {/* Tooltip */}
        {standardInfoTooltip(
          `
            <span style="font-weight:bold; color:${sheirGreen};">Silent</span>
            <br/>
            The estate owner allocates items <b>WITHOUT heir involvement</b>. Lawyers, Accountants, Executors, and Witnesses will be able to view the estate and the allocation, but heirs will not, and no estate-related emails or updates will be sent to the heirs.   
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">Partial</span>
            <br/>
            Heirs will be able to rank their preferences for unassigned items, but they will not be able to see the full estate or the valuations of the items. 
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">Participatory</span>
            <br/>
            Heirs will be able to view the estate and participate in the allocation process by ranking their preferences for items to receive, and by taking part in an auction or draft for all unassigned items. 
          `,
          1
        )}
      </Box>

      {/* Distribution Method */}
      <Box sx={{ mt: 2, mb: 1, width: '90%', maxWidth: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {selectElement('silentOrParticipatory', 'Silent or Participatory', silentOrParticipatoryValue, disabledIsTrue, !disabledIsTrue, silentOrParticipatory, changeHandler, estateOrOnboardingObj, setEstateOrOnboardingObj, -1)}
      </Box>

      {/* Advisory — Onboarding */}
      {fromTab === 'onboarding' && estateOrOnboardingObj.silentOrParticipatory === 'Silent' &&
        <Typography 
          sx={{
            width: '90%',
            color: sheirBlue,
            display: 'flex', flexDirection: 'row', justifyContent: 'center',
          }}
        >
          <em>All selections can be changed later</em>
        </Typography>
      }

      {/* Advisory — Owner Person Space, Silent */}
      {fromTab === 'ownerPersonSpace' && estateOrOnboardingObj.hasOwnProperty('event') && estateOrOnboardingObj.event[0].silentOrParticipatory === 'Silent' &&
        <Typography 
          sx={{
            width: '90%',
            color: sheirBlue,
            display: 'flex', flexDirection: 'row', justifyContent: 'center',
          }}
        >
          <em>**Heirs will NOT be able to view the estate, and they will NOT receive an email about changes to the estate. All other parties, such as lawyers, asset managers, and accountants, will be able to view assets and allotments. If you would like heirs to access the estate and view the allotments, select Participatory for the Transparency Level</em>
        </Typography>
      }

      {/* Participatory — Owner Person Space, Participatory */}
      {fromTab === 'ownerPersonSpace' && estateOrOnboardingObj.hasOwnProperty('event') && estateOrOnboardingObj.event[0].silentOrParticipatory === 'Participatory' &&
        <Typography 
          sx={{
            width: '90%',
            color: sheirBlue,
            display: 'flex', flexDirection: 'row', justifyContent: 'center',
          }}
        >
          <em>**All parties, such as heirs, lawyers, asset managers, and accountants, will receive emails about changes to the estate, and they will be able to view assets and allotments. If you would prefer that heirs not witness the allocation process, select Silent for Transparency Level</em>
        </Typography>
      }
    </>
  )
}

export const allocationProcessJSX = (fromTab, distributionMethodValue, liveOrAutoValue, estateOrOnboardingObj, setEstateOrOnboardingObj, changeHandler, width, editSettingsError, setEditSettingsError) => {
  
  const disabledIsTrue = estateOrOnboardingObj.hasOwnProperty('event') && estateOrOnboardingObj.event[0].hasOwnProperty('hasStarted') && estateOrOnboardingObj.event[0].hasStarted
  
  return (
    <>
      <Box
        sx={{
          width: '81%', maxWidth: '540px',
          mt: 0,
          display: 'flex', flexDirection: 'row', justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            // width: `calc(100% + ${tooltipInfoDimension})`,
            fontSize: '18px',
            fontWeight: 'bold',
            display: 'flex', flexDirection: 'row', justifyContent: 'center',
          }}
        >
          Allocation Process:
        </Typography>

        {/* Tooltip */}
        {standardInfoTooltip(
          `
            <span style="font-weight:bold; color:${sheirGreen};">Distribution Method</span>
            <br/>
            The estate owner can choose to 'Allocate All' items. Or, if 'Auction' or 'Draft', is selected, unassigned items will be claimed in either an auction or a draft. You are able to pre-allot any items that are intended for a specific heir.   
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">Live or Auto</span>
            <br/>
            If you have chosen 'Allocate All', there will be no event. If you have chosen 'Auction' or 'Draft' for the distribution method, there must be an event. Live events take place in real time and require everyone's attendance. For auto events, everyone will mark that they are ready, and then the estate owner will press an 'Execute' button, which will automatically pair items with heirs based on their pre-draft or pre-auction preferences. 
          `,
          1
        )}
      </Box>

      {/* Distribution Method */}
      <Box sx={{ mt: 2, mb: 1, width: '90%', maxWidth: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {selectElement('distributionMethod', 'Distribution Method', distributionMethodValue, disabledIsTrue, !disabledIsTrue, distributionMethod, changeHandler, estateOrOnboardingObj, setEstateOrOnboardingObj, -1)}
      </Box>

      {/* Live or Auto */}
      {distributionMethodValue !== 'Allocate All' && 
        <Box sx={{ mt: 1, mb: 1, width: '90%', maxWidth: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {selectElement('liveOrAuto', 'Live or Auto', liveOrAutoValue, disabledIsTrue, !disabledIsTrue, liveOrAuto, changeHandler, estateOrOnboardingObj, setEstateOrOnboardingObj, -1)}
        </Box>
      }

      {/* Date */}
      {/* <Box sx={{ mt: 1, mb: 1, width: '90%', maxWidth: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {eventDateElement('Date and Time of Event', estateOrOnboardingObj, setEstateOrOnboardingObj, 'startDate', disabledIsTrue, fromTab, modifyInDatabase, setEditSettingsError)}
      </Box> */}

      {/* Advisory */}
      {fromTab === 'onboarding' &&
        <Typography 
          sx={{
            width: '90%',
            color: sheirBlue,
            display: 'flex', flexDirection: 'row', justifyContent: 'center',
          }}
        >
          <em>All selections can be changed later</em>
        </Typography>
      }
    </>
  )
}

export const heirAllotmentsJSX = (estateOrOnboardingObj, setEstateOrOnboardingObj, sharesOver100, changeHandler, width, disabledIsTrue = false ) => {
  
  // const disabledIsTrue = estateOrOnboardingObj.hasOwnProperty('event') && estateOrOnboardingObj.event[0].hasOwnProperty('hasStarted') && estateOrOnboardingObj.event[0].hasStarted
  // console.log('disabled is true ->', disabledIsTrue)
  const peopleArray = [ ...estateOrOnboardingObj.people ]

  // Fixing indescrepencies between different arrays that might be passed in
  if (peopleArray.length > 0 && !peopleArray[0].hasOwnProperty('userGivenAndFamilyName')) {
    for (let i = 0; i < estateOrOnboardingObj.people.length; i++) {
      peopleArray[i].userGivenAndFamilyName = `${peopleArray[i].givenName} ${peopleArray[i].familyName}`
    }
  }
  
  return (
    <>
      {/*  Heir Allocations */}
      <Typography
        sx={{
          width: '81%', maxWidth: '540px',
          mt: 2,
          fontSize: '18px',
          fontWeight: 'bold',
          display: 'flex', flexDirection: 'row', justifyContent: 'center',
        }}
      >
        Heir Allocations:
      </Typography>

      {/* Advisory */}
      {sharesOver100 &&
        <Typography 
          sx={{
            width: '81%', maxWidth: '540px',
            textAlign: 'center',
            color: sharesOver100 ? 'red' : 'black',
            fontSize: '12px',
          }}
        >
          <em>*Cannot add up to more than 100</em>
        </Typography>
      }

      {peopleArray.filter(personInRole => personInRole.role === 'Heir').length > 0 ? 
        peopleArray.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)).map((person, personIndex) => 
          person.role === 'Heir' &&
          <Box key={personIndex} sx={{ mt: width < positionChangeWidthSm ? 1 : 0, mb: 1, width: width < positionChangeWidthSm ? '80%' : '90%', maxWidth: width < positionChangeWidthSm ? '360px' : '440px', display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'space-between' : 'center', alignItems: 'center' }}>
            
            {/* Heir Name */}
            <Typography
              sx={{
                // pl: width < positionChangeWidthSm ? 2 : 4,
                width: 'calc(95% - 150px)',
                minWidth: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {person.userGivenAndFamilyName}
            </Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'space-between' : 'center', alignItems: 'center' }}>
              {personItemTextField('sharePercentage', '', 'text', peopleArray[personIndex].sharePercentage === '0.00' ? '' : peopleArray[personIndex].sharePercentage, '', disabledIsTrue, !disabledIsTrue, changeHandler, setEstateOrOnboardingObj, estateOrOnboardingObj, personIndex)}
              <Typography sx={{ ml: 1 }}><strong>%</strong></Typography>
            </Box>
          </Box>
        )
        :
        <>
          <Typography 
            sx={{
              width: '81%', maxWidth: '540px',
              textAlign: 'center',
              color: sheirBlue,
              fontSize: '16px',
            }}
          >
            <em>*Add heirs in the &apos;People&apos; tab.</em>
          </Typography>
        </>
      }
    </>
  )
}

export const settingsTabOwnerPersonSpace = (estate, setEstate, panelName, value, index, width, loading, errors, sharesOver100, setSharesOver100, editSettingsError, setEditSettingsError, newEstateName, setNewEstateName, saveEstateNameSuccessful, setSaveEstateNameSuccessful, errorEstateName, setErrorEstateName, newTotalTaxes, setNewTotalTaxes, saveTotalTaxesSuccessful, setSaveTotalTaxesSuccessful, errorTotalTaxes, setErrorTotalTaxes, saveEventSuccessful, setSaveEventSuccessful, errorSaveEvent, setErrorSaveEvent, saveTransparencySuccessful, setSaveTransparencySuccessful, errorSaveTransparency, setErrorSaveTransparency, newHeirAllotments, setNewHeirAllotments, saveHeirAllotmentsSuccessful, setSaveHeirAllotmentsSuccessful, errorHeirAllotments, setErrorHeirAllotments, newFees, setNewFees, saveFeesSuccessful, setSaveFeesSuccessful, errorFees, setErrorFees, setSaveSwitchSuccessful, openModal, setOpenModal, silentOrParticipatoryValue, setSilentOrParticipatoryValue) => {
  
  // console.log('estate.event ->', estate.event[0])

  const resetErrors = () => {
    setSaveSwitchSuccessful(false)
    setSharesOver100(false)
    setSaveEstateNameSuccessful(false)
    setErrorEstateName(false)
    setSaveHeirAllotmentsSuccessful(false)
    setErrorHeirAllotments(false)
    setSaveFeesSuccessful(false)
    setErrorFees(false)
    setSaveTotalTaxesSuccessful(false)
    setErrorTotalTaxes(false)
    setSaveEventSuccessful(false)
    setErrorSaveEvent(false)
    setSaveTransparencySuccessful(false)
    setErrorSaveTransparency(false)

    setOpenModal(false)
  }

  // Upload new people to database if 'Save' is pressed and there are people in the peopleToAdd array
  const uploadModifiedPeople = async (modifiedPersonsArray) => {
    try {
      const { data: message } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/persons/`, modifiedPersonsArray, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })

      // console.log('message ->', message)

    } catch (err) {
      // console.log('err: ', err)
      setErrorHeirAllotments(true)
      setSaveHeirAllotmentsSuccessful(false)
    }
  }

  const handleEstateNameChange = async (e) => {
    // console.log('handleEstateNameChange')

    resetErrors()

    setNewEstateName(e.target.value)
  }

  const handleCancelEstateNamePressed = async (e) => {
    // console.log('handleCancelEstateNamePressed')

    setNewEstateName(estate.estateName)
  }

  const handleSaveEstateNamePressed = async (e) => {
    // console.log('handleSaveEstateNamePressed')

    if (newEstateName.length > 0) {

      modifyInDatabase('estates', estate._id, { 'estateName': newEstateName ? newEstateName : estate.estateName }, setErrorEstateName)

      setEstate({ ...estate, 'estateName': newEstateName ? newEstateName : estate.estateName })

      setSaveEstateNameSuccessful(true)

    } else {

      setNewEstateName(estate.estateName)

    }
  }

  const heirAllotmentsHaveChanged = () => {
    // console.log('heirAllotmentsHaveChanged runs')

    // console.log('newHeirAllotments.people.length ->', newHeirAllotments.people.length)
    // console.log('estate.people.length ->', estate.people.length)

    if (newHeirAllotments.people.length === estate.people.length) {
      // console.log('equal lengths')

      const sortedNewHeirAllotments = [ ...newHeirAllotments.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)) ]
      const sortedEstatePeople = [ ...estate.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)) ]

      for (let i = 0; i < newHeirAllotments.people.length; i++) {

        // console.log('sortedNewHeirAllotments[i].sharePercentage ->', sortedNewHeirAllotments[i].sharePercentage)
        // console.log('sortedEstatePeople[i].sharePercentage ->', sortedEstatePeople[i].sharePercentage)
        // console.log('shares equal ->', sortedNewHeirAllotments[i].sharePercentage === sortedEstatePeople[i].sharePercentage)
        
        if (sortedNewHeirAllotments[i].sharePercentage !== sortedEstatePeople[i].sharePercentage) {
          return true
        }
      }

      return false
    } else {
      return false
    }
    
  }

  const handleHeirAllotmentsChange = async (e, setPeopleArray, peopleArray, personIndex) => {
    // console.log('handleCancelHeirAllotmentsPressed')

    resetErrors()

    const name = e.target.name
    const value = e.target.value

    if (personIndex > -1 && name === 'sharePercentage') {
      // For whatever reason, spreading doesn't separate the new obj from the old one, so this has to be done in a roundabout way until I learn of something better
      const newNewHeirAllotments = [ ...newHeirAllotments.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)) ]

      let sharePercentageTotal = 0
      await newNewHeirAllotments.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)).forEach((person, index) => {
        if (index !== personIndex) {
          // console.log('share ->', person.sharePercentage)
          sharePercentageTotal = sharePercentageTotal + parseFloat(person.sharePercentage)
        } else if (value) {
          // console.log('share ->', value)
          sharePercentageTotal = sharePercentageTotal + parseFloat(value)
        }
        // console.log('person name ->', person.userGivenAndFamilyName)
        // console.log('sharePercentageTotal', sharePercentageTotal)
      })
      // console.log('sharePercentageTotal ->', sharePercentageTotal)

      if (sharePercentageTotal <= 100) {
        // console.log('newNewHeirAllotments === estate.people ->', newNewHeirAllotments[personIndex] === estate.people[personIndex])
        
        newNewHeirAllotments[personIndex] = { ...newNewHeirAllotments[personIndex], [name]: value ? value : '0.00' }

        // modifyInDatabase('persons', newEstate.people[personIndex]._id, { [name]: value ? value : '0.00' }, setEditSettingsError)

        // console.log('newNewHeirAllotments ->', newNewHeirAllotments)
        // console.log('estate.people ->', estate.people)
        // console.log('newNewHeirAllotments === estate.people ->', newNewHeirAllotments[personIndex] === estate.people[personIndex])


        setSharesOver100(false)
        setNewHeirAllotments({ people: [ ...newNewHeirAllotments ] })
      } else {
        setSharesOver100(true)
      }
    
    }

  }

  const handleCancelHeirAllotmentsPressed = async (e) => {
    // console.log('handleCancelHeirAllotmentsPressed')
    
    const estatePeople = [ ...estate.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)) ]
    setNewHeirAllotments({ people: estatePeople })
  }

  const handleSaveHeirAllotmentsPressed = async (e) => {
    console.log('handleSaveHeirAllotmentsPressed')

    await uploadModifiedPeople(newHeirAllotments.people)

    setSaveHeirAllotmentsSuccessful(true)
    setNewFees({ people: newHeirAllotments.people })
    setEstate({ ...estate, people: newHeirAllotments.people })
  }



  const feesHaveChanged = () => {
    // console.log('feesHaveChanged runs')

    if (newFees.people.length === estate.people.length) {
      // console.log('equal lengths')

      const sortedNewFees = [ ...newFees.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)) ]
      const sortedEstatePeople = [ ...estate.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)) ]

      for (let i = 0; i < newFees.people.length; i++) {
        
        if (sortedNewFees[i].fees !== sortedEstatePeople[i].fees) {
          return true
        }
      }

      return false
    } else {

      return false

    }
    
  }

  const handleFeesChange = async (e, setPeopleArray, peopleArray, personIndex) => {
    // console.log('handleFeesChange')

    resetErrors()

    const name = e.target.name
    const value = e.target.value

    if (personIndex > -1 && name === 'fees') { 

      const newNewFees = [ ...newFees.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)) ]

      // newNewFees[personIndex][name] = value ? value : '0'
      newNewFees[personIndex] = { ...newNewFees[personIndex], [name]: value ? value : '0.00' }

      // modifyInDatabase('persons', newEstate.people[personIndex]._id, { [name]: value ? value : '0' }, setEditSettingsError)

      setNewFees({ people: [ ...newNewFees ] })
      
    }
  }

  const handleCancelFeesPressed = async (e) => {
    // console.log('handleCancelFeesPressed')
    
    const estatePeople = [ ...estate.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)) ]
    setNewFees({ people: estatePeople })
  }

  const handleSaveFeesPressed = async (e) => {
    // console.log('handleSaveFeesPressed')

    await uploadModifiedPeople(newFees.people)

    setSaveFeesSuccessful(true)
    setNewHeirAllotments({ people: newFees.people })
    setEstate({ ...estate, people: newFees.people })
  }

  const handleTotalTaxesChange = async (e) => {
    // console.log('handleTotalTaxesChange')

    resetErrors()

    setNewTotalTaxes(e.target.value)
  }

  const handleCancelTotalTaxesPressed = async (e) => {
    // console.log('handleCancelTotalTaxesPressed')
    
    setNewTotalTaxes(estate.totalTaxes)
  }

  const handleSaveTotalTaxesPressed = async (e) => {
    // console.log('handleSaveTotalTaxesPressed')

    if (newTotalTaxes.toString().length > 0) {

      modifyInDatabase('estates', estate._id, { 'totalTaxes': newTotalTaxes ? newTotalTaxes : estate.totalTaxes }, setErrorTotalTaxes)

      setEstate({ ...estate, 'totalTaxes': newTotalTaxes ? newTotalTaxes : estate.totalTaxes })

      setSaveTotalTaxesSuccessful(true)

    } else {

      setNewTotalTaxes(estate.totalTaxes)

    }
  }

  const handleSettingsChange = async (e, setEstate, estate, personIndex) => {
    console.log('handleSettingsChange runs')
    resetErrors()

    // Create name and value variables
    let name
    let value

    // Assign values to name and value depending on the type of event target
    if (!e.target.name) {
      name = e.target.classList[1]
      value = e.target.classList[0].slice(5)
    } else {
      name = e.target.name
      value = e.target.value
    }
    // console.log('name ->', name)
    // console.log('value ->', value)
    // console.log('personIndex ->', personIndex)

    if (personIndex > -1 && name === 'sharePercentage') {
      // For whatever reason, spreading doesn't separate the new obj from the old one, so this has to be done in a roundabout way until I learn of something better
      const newEstate = { ...estate }

      let sharePercentageTotal = 0
      await newEstate.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)).forEach((person, index) => {
        if (index !== personIndex) {
          // console.log('share ->', person.sharePercentage)
          sharePercentageTotal = sharePercentageTotal + parseFloat(person.sharePercentage)
        } else if (value) {
          // console.log('share ->', value)
          sharePercentageTotal = sharePercentageTotal + parseFloat(value)
        }
        // console.log('person name ->', person.userGivenAndFamilyName)
        // console.log('sharePercentageTotal', sharePercentageTotal)
      })
      // console.log('sharePercentageTotal ->', sharePercentageTotal)

      if (sharePercentageTotal <= 100) {
        newEstate.people[personIndex][name] = value ? value : '0.00'

        modifyInDatabase('persons', newEstate.people[personIndex]._id, { [name]: value ? value : '0.00' }, setErrorHeirAllotments)

        setSharesOver100(false)
        setEstate({ ...estate, people: newEstate.people })
      } else {
        setSharesOver100(true)
      }
    
    } else if (personIndex > -1 && name === 'fees') { 

      const newEstate = { ...estate }

      newEstate.people[personIndex][name] = value ? value : '0'

      modifyInDatabase('persons', newEstate.people[personIndex]._id, { [name]: value ? value : '0' }, setErrorFees)

      setEstate({ ...estate, people: newEstate.people })
      
    }  else if (name === 'estateName' || name === 'totalTaxes') {

      modifyInDatabase('estates', estate._id, { [name]: value ? value : 0 }, name === 'estateName' ? setErrorEstateName : setErrorTotalTaxes)

      setEstate({ ...estate, [name]: value })

    } else if (name === 'silentOrParticipatory') {
      
      setSilentOrParticipatoryValue(value)
      setOpenModal(true)

    } else {
      const newEvent = [{ ...estate.event[0], [name]: value }]

      modifyInDatabase('events', newEvent[0]._id, { [name]: value }, setErrorSaveEvent)

      setEstate({ ...estate, event: newEvent })
      setSaveEventSuccessful(true)
    }
  }

  const saveAndCancelButtonsOwnerSettingsTab = (name, disabled, handleCancel, handleSave) => {
    return (
      <>
        <Box
          sx={{
            mt: 1,
            mb: 1,
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Cancel/Delete Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Cancel', 
              'button',
              'contained',
              disabled,
              'secondary', 
              1,
              1,
              2,
              '80px', 
              '35px', 
              handleCancel
            )}
          </Box>


          {/* Save Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Save', 
              'button',
              'contained',
              disabled,
              'primary', 
              1,
              1,
              2,
              '80px', 
              '35px', 
              handleSave
            )}
          </Box>

        </Box>
      </>
    )
  }
  
  return (
    <TabPanel value={value} index={index}>
      {loading ?
        standardSpinner()
        : errors ?
          <Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center', my: '10%' }} >
            <Typography variant='p' sx={{ color: 'red' }}>
              Error! Could not fetch data!
            </Typography>
          </Container>
          : 
          <Box sx={{ mt: 0, mb: 0, width: widthInsideTabPanel, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {/* Estate Name */}
            <Box
              sx={{
                mt: 0, pt: 2, pb: 2,
                backgroundColor: 'whitesmoke',
                boxShadow: 3, borderRadius: 3,
                width: '100%', maxWidth: '600px',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
              }}
            >
              <Box 
                sx={{ 
                  mt: 0, mb: 0, 
                  width: '90%', maxWidth: '400px', 
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
                }}
              >
                
                {personItemTextField('estateName', 'Estate Name*', 'text', newEstateName, '', false, true, handleEstateNameChange, setNewEstateName, newEstateName, -1)}


                {/* Save and Cancel Buttons */}
                {estate.estateName !== newEstateName && newEstateName.length > 0 &&
                  saveAndCancelButtonsOwnerSettingsTab('estateName', estate.estateName === newEstateName, handleCancelEstateNamePressed, handleSaveEstateNamePressed)
                }

                {/* Save Estate Name Successful */}
                {saveEstateNameSuccessful && !errorEstateName &&
                  saveSuccessfulTypography()
                }

                {/* Save Estate Name Error */}
                {errorEstateName &&
                  standardErrorContainer('Error Uploading Changes. Check Internet connection', 0, 1)
                }
              
              </Box>
            </Box>

            {/* Transparency Level */}
            <Box
              sx={{
                mt: 4, pt: 2, pb: 2,
                backgroundColor: 'whitesmoke',
                boxShadow: 3, borderRadius: 3,
                width: '100%', maxWidth: '600px',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
              }}
            >
              {transparencyLevelJSX('ownerPersonSpace', estate.event[0].silentOrParticipatory, estate, setEstate, handleSettingsChange, width)}

              {/* Save Transparency Successful */}
              {saveTransparencySuccessful && !errorSaveTransparency &&
                saveSuccessfulTypography()
              }

              {/* Save Transparency Error */}
              {errorSaveTransparency &&
                standardErrorContainer('Error Uploading Changes. Check Internet connection', 0, 1)
              }
            </Box>

            {/* Allocation Process */}
            {estate.hasOwnProperty('event') && estate.event[0].silentOrParticipatory === 'Participatory' &&
              <Box
                sx={{
                  mt: 4, pt: 2, pb: 2,
                  backgroundColor: 'whitesmoke',
                  boxShadow: 3, borderRadius: 3,
                  width: '100%', maxWidth: '600px',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
                }}
              >

                {allocationProcessJSX('ownerPersonSpace', estate.event[0].distributionMethod, estate.event[0].liveOrAuto, estate, setEstate, handleSettingsChange, width, errorSaveEvent, setErrorSaveEvent)}
                
                {/* Save Event Successful */}
                {saveEventSuccessful && !errorSaveEvent &&
                  saveSuccessfulTypography()
                }

                {/* Save Event Error */}
                {errorSaveEvent &&
                  standardErrorContainer('Error Uploading Changes. Check Internet connection', 0, 1)
                }
              </Box>
            }

            {/* Heir Allocations */}
            <Box
              sx={{
                mt: 4, pt: 2, pb: 2,
                backgroundColor: 'whitesmoke',
                boxShadow: 3, borderRadius: 3,
                width: '100%', maxWidth: '600px',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
              }}
            >

              {heirAllotmentsJSX(newHeirAllotments, setNewHeirAllotments, sharesOver100, handleHeirAllotmentsChange, width, estate.event[0].hasStarted)}

              {/* Save and Cancel Buttons */}
              {heirAllotmentsHaveChanged() && 
                saveAndCancelButtonsOwnerSettingsTab('sharePercentage', !heirAllotmentsHaveChanged(), handleCancelHeirAllotmentsPressed, handleSaveHeirAllotmentsPressed)
              }

              {/* Save Estate Name Successful */}
              {saveHeirAllotmentsSuccessful && !errorHeirAllotments &&
                saveSuccessfulTypography()
              }

              {/* Save Estate Name Error */}
              {errorHeirAllotments &&
                standardErrorContainer('Error Uploading Changes. Check Internet connection', 0, 1)
              }
              
            </Box>

            {/* Fees */}
            <Box
              sx={{
                mt: 4, pt: 2, pb: 2,
                backgroundColor: 'whitesmoke',
                boxShadow: 3, borderRadius: 3,
                width: '100%', maxWidth: '600px',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
              }}
            >
              <Typography
                sx={{
                  width: '81%', maxWidth: '540px',
                  mt: 0, pb: 1,
                  fontSize: '18px',
                  fontWeight: 'bold',
                  display: 'flex', flexDirection: 'row', justifyContent: 'center',
                }}
              >
                Fees:
              </Typography>

              {estate.people.filter(person => person.role !== 'Owner' && person.role !== 'Heir').length > 0 ?
                estate.people.sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName)).map((person, personIndex) => 
                  person.role !== 'Heir' && person.role !== 'Owner' &&
                  <Box key={personIndex} sx={{ mt: width < positionChangeWidthSm ? 1 : 0, mb: 1, width: width < positionChangeWidthSm ? '80%' : '90%', maxWidth: width < positionChangeWidthSm ? '360px' : '400px', display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'space-between' : 'center', alignItems: 'center' }}>
                    
                    {/* Person Name */}
                    <Typography
                      sx={{
                        // pl: width < positionChangeWidthSm ? 2 : 4,
                        width: 'calc(95% - 150px)',
                        minWidth: '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {person.userGivenAndFamilyName}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'space-between' : 'center', alignItems: 'center' }}>
                      {/* <Typography sx={{ ml: 1 }}><strong>%</strong></Typography> */}
                      {personItemTextField('fees', '', 'text', newFees.people[personIndex].fees === '0' ? '' : newFees.people[personIndex].fees, '', estate.event[0].hasStarted, !estate.event[0].hasStarted, handleFeesChange, setNewFees, newFees, personIndex)}

                    </Box>
                  </Box>
                )
                :
                <>
                  <Typography 
                    sx={{
                      width: '81%', maxWidth: '540px',
                      textAlign: 'center',
                      color: sheirBlue,
                      fontSize: '16px',
                    }}
                  >
                    <em>*No fees accounted for yet.</em>
                  </Typography>
                </>
              }

              {/* Save and Cancel Buttons */}
              {/* {console.log('feesHaveChanged ->', feesHaveChanged())} */}
              {feesHaveChanged() && 
                saveAndCancelButtonsOwnerSettingsTab('fees', !feesHaveChanged(), handleCancelFeesPressed, handleSaveFeesPressed)
              }

              {/* Save Estate Name Successful */}
              {saveFeesSuccessful && !errorFees &&
                saveSuccessfulTypography()
              }

              {/* Save Estate Name Error */}
              {errorFees &&
                standardErrorContainer('Error Uploading Changes. Check Internet connection', 0, 1)
              }
            </Box>

            {/* Taxes */}
            <Box
              sx={{
                mt: 4, pt: 2, pb: 2,
                backgroundColor: 'whitesmoke',
                boxShadow: 3, borderRadius: 3,
                width: '100%', maxWidth: '600px',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
              }}
            >
              <Box
                sx={{
                  width: '81%', maxWidth: '540px',
                  mt: 0, pb: 1,
                  display: 'flex', flexDirection: 'row', justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                >
                  Taxes:
                </Typography>

                {/* Tooltip */}
                {standardInfoTooltip(
                  `
                    A best estimate is OK. Exact numbers are hard to know.   
                  `,
                  1
                )}
              </Box>

              <Box 
                sx={{ 
                  mt: 1, mb: 1,
                  width: '90%', maxWidth: '400px', 
                  display: 'flex', flexDirection: 'column', justifyContent: width < positionChangeWidthSm ? 'space-between' : 'center', alignItems: 'center', 
                }}
              >
                {personItemTextField('totalTaxes', 'Estimated Total Taxes', 'text', newTotalTaxes, '', estate.event[0].hasStarted, !estate.event[0].hasStarted, handleTotalTaxesChange, setEstate, estate, -1)}

                {/* Save and Cancel Buttons */}
                {estate.totalTaxes !== newTotalTaxes && newTotalTaxes.length > 0 &&
                  saveAndCancelButtonsOwnerSettingsTab('totalTaxes', estate.totalTaxes === newTotalTaxes, handleCancelTotalTaxesPressed, handleSaveTotalTaxesPressed)
                }

                {/* Save Total Taxes Successful */}
                {saveTotalTaxesSuccessful && !errorTotalTaxes &&
                  saveSuccessfulTypography()
                }

                {/* Save Total Taxes Error */}
                {errorTotalTaxes &&
                  standardErrorContainer('Error Uploading Changes. Check Internet connection', 0, 1)
                }
              </Box>
            </Box>

            {/* Modal for Transparency level change */}
            {standardModal(
              'silentOrParticipatory', 
              silentOrParticipatoryValue, 
              `Are you sure you would like to change to a ${silentOrParticipatoryValue} estate? Doing so means Heirs ${silentOrParticipatoryValue === 'Participatory' ? 'will be able to view the entire estate.' : silentOrParticipatoryValue === 'Partial' ? 'will be able to rank preferences for Unassigned items but not see the entire estate or valuations' : 'will no longer be able to view the estate.'}`,
              estate,
              setEstate,
              openModal,
              setOpenModal,
              setSaveTransparencySuccessful,
              setErrorSaveTransparency,
              width
            )}
          </Box>
      }
    </TabPanel>
  )
}



export const missionTabOwnerPersonSpace = (estate, setEstate, panelName, value, index, width, loading, errors,  newMission, setNewMission, editMissionError, setEditMissionError, saveMissionSuccessful, setSaveMissionSuccessful, setSaveSwitchSuccessful) => {
  

  const handleMissionChange = async (e) => {
    // console.log('handleMissionChange')

    setSaveSwitchSuccessful(false)
    setEditMissionError(false)
    setSaveMissionSuccessful(false)

    setNewMission(e.target.value)
  }

  const handleCancelMissionPressed = async (e) => {
    // console.log('handleCancelMissionPressed')

    setNewMission(estate.mission)
  }

  const handleSaveMissionPressed = async (e) => {
    // console.log('handleSaveMissionPressed')

    modifyInDatabase('estates', estate._id, { 'mission': newMission ? newMission : '' }, setEditMissionError)

    setEstate({ ...estate, 'mission': newMission ? newMission : '' })

    setSaveMissionSuccessful(true)

  }

  const saveAndCancelButtonsOwnerMissionTab = (name, disabled, handleCancel, handleSave) => {
    return (
      <>
        <Box
          sx={{
            mt: 1,
            mb: 1,
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Cancel/Delete Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Cancel', 
              'button',
              'contained',
              disabled,
              'secondary', 
              1,
              1,
              2,
              '80px', 
              '35px', 
              handleCancel
            )}
          </Box>


          {/* Save Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Save', 
              'button',
              'contained',
              disabled,
              'primary', 
              1,
              1,
              2,
              '80px', 
              '35px', 
              handleSave
            )}
          </Box>

        </Box>
      </>
    )
  }
  
  return (
    <TabPanel value={value} index={index}>
      {loading ?
        standardSpinner()
        : errors ?
          <Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center', my: '10%' }} >
            <Typography variant='p' sx={{ color: 'red' }}>
              Error! Could not fetch data!
            </Typography>
          </Container>
          : 
          
          <Box sx={{ mt: 1, mb: 2, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {/* Description */}
            {descriptionTextField(
              'mission', 
              newMission, 
              10, 
              'What is the goal for the estate? (Example: "To minimize sibling drama by distributing assets fairly and transparently among heirs.")', 
              handleMissionChange,
              setEstate,
              estate
            )}

            {/* Save and Cancel Buttons */}
            {estate.mission !== newMission && newMission.length > 0 &&
              saveAndCancelButtonsOwnerMissionTab('mission', estate.mission === newMission, handleCancelMissionPressed, handleSaveMissionPressed)
            }

            {/* Save Mission Successful */}
            {saveMissionSuccessful && !editMissionError &&
              saveSuccessfulTypography()
            }

            {/* Error Text */}
            {editMissionError &&
              standardErrorContainer(
                `*Upload error. Check Internet connection.`,
                0,
                0
              )
            }

          </Box>

      }
    </TabPanel>
  )
}