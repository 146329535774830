

// Sources:

// Constants
const startBidHistoryAndAuctionPlanningObj = {
  clarkKentPersonId: 0, 
  peterParkerPersonId: 0, 
  jamesBondPersonId: 0,
}

const startDraftPlanningObj = {
  clarkKentPersonId: 1000000000, 
  peterParkerPersonId: 1000000000, 
  jamesBondPersonId: 1000000000,
}


// Items

// Batsuit
export const bruceWayneBatsuit = {
  _id: 'bruceWayneBatsuitItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Batsuit',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-batsuit-1.jpg',
  valuation: '500000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My batsuit, for when I transform into batman',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Batmobile
export const bruceWayneBatmobile = {
  _id: 'bruceWayneBatmobileItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Batmobile',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-batmobile-1.jpg',
  valuation: '1000000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'The batmobile, for getting around town. Extra fast, extra nimble, extra durable.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Wayne Manor
export const bruceWayneManor = {
  _id: 'bruceWayneManorItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Wayne Manor',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-manor-1.jpg',
  valuation: '500000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My estate overlooking Gotham',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Bat Cape
export const bruceWayneBatCape = {
  _id: 'bruceWayneBatCapeItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Bat Cape',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-cape-1.jpg',
  valuation: '50000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My batcape, for swooshing through the air and disappearing without a trace.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Microwave Emitter
export const bruceWayneMicrowaveEmitter = {
  _id: 'bruceWayneMicrowaveEmitterItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Microwave Emitter',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-microwave-emitter-1.jpg',
  valuation: '3000000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'A weapon developed in the batcave to instantaneously vaporize large quantities of water.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Batarangs
export const bruceWayneBatarangs = {
  _id: 'bruceWayneBatarangsItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Batarangs (100,000)',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-batarang-1.jpg',
  valuation: '100000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My lifetime supply of 100,000 batarangs.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Batpod Motorcycle
export const bruceWayneBatpod = {
  _id: 'bruceWayneBatpodItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Batpod',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-batpod-1.jpg',
  valuation: '500000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'The Batpod — my motorcycle, for when I need something more nimble than the Batmobile.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// The Batcave
export const bruceWayneBatcave = {
  _id: 'bruceWayneBatcaveItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Batcave',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-batcave-1.jpg',
  valuation: '4000000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'The batcave, where I do all of my batman preparatory work.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Utility Belt
export const bruceWayneUtilityBelt = {
  _id: 'bruceWayneUtilityBeltItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Utility Belt',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-utility-belt-1.jpg',
  valuation: '20000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'My utility belt, where I store my batarangs',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Alfred's Loyalty
export const bruceWayneAlfred = {
  _id: 'bruceWayneAlfredItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Alfred\'s Services',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-alfred-1.jpg',
  valuation: '10000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'The best and most loyal butler around.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// The Wayne Enterprises
export const bruceWayneWayneEnterprises = {
  _id: 'bruceWayneWayneEnterprisesItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Wayne Enterprises',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-enterprises-1.jpg',
  valuation: '10000000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 100% stake in Wayne Enterprises.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Private Jet
export const bruceWaynePrivateJet = {
  _id: 'bruceWaynePrivateJetItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Private Jet',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-private-jet-1.jpg',
  valuation: '3000000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'My supersonic private jet, for zipping to and from my training facility in the Himalayas',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Himalayas Training Studio
export const bruceWayneHimalayasTrainingStudio = {
  _id: 'bruceWayneHimalayasTrainingStudioItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Himalayas Training Studio',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-training-studio-1.jpg',
  valuation: '2000000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My training studio in the Himalayas.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Batcomputer
export const bruceWayneBatcomputer = {
  _id: 'bruceWayneBatcomputerItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Batcomputer',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-batcomputer-1.jpg',
  valuation: '8000000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'The strongest, most powerful computer in the world.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Batwing (Flying Vehicle)
export const bruceWayneBatwing = {
  _id: 'bruceWayneBatwingItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Batwing',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-batwing-1.jpg',
  valuation: '6000000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'The batwing, my flying vehicle.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Cash Reserves
export const bruceWayneCashReserves = {
  _id: 'bruceWayneCashReservesItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Cash Reserves',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-cash-reserves-1.jpg',
  valuation: '12000000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Liquidity',
  description: 'My cash reserves',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Recovery Spa
export const bruceWayneRecoverySpa = {
  _id: 'bruceWayneRecoveryItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Recovery Spa',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-spa-1.jpg',
  valuation: '500000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'The spa where I recover',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Robin's Loyalty
export const bruceWayneRobinsLoyalty = {
  _id: 'bruceWayneRobinsLoyaltyItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Robin\'s Loyalty',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-robin-1.jpg',
  valuation: '0',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'The loyalty of my most trusted friend',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Catwoman's Loyalty
export const bruceWayneCatwomansLoyalty = {
  _id: 'bruceWayneCatwomansLoyaltyItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Catwoman\'s Loyalty',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-catwoman-1.jpg',
  valuation: '0',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'The loyalty of a trusted friend',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Flock of Bats (1 million bats)
export const bruceWayneFlockOfBats = {
  _id: 'bruceWayneFlockOfBatsItemId',
  estateId: 'bruceWayneEstateId',
  itemName: 'Flock of Bats',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-bruce-wayne-flock-of-bats-1.jpg',
  valuation: '100000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: '',
  description: 'One million bats that make a flock to create chaos and form my symbol',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}