/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import { itemCategories, pieChartPaletteScheme, positionChangeWidthSm, liquidationPieChartPaletteScheme, giftPieChartPaletteScheme, sheirBlue } from './variableDefaults'
import { getEstateTotalValue, getHeirAllotmentsTotal, getHeirShareTotal, categoryPercentOfTotal, categoryPercentOfTotalEstateExceptGifts, categoryPercentsTaxesFeesGiftsHeirs, categoryPercentOfLiquidation, categoryPercentOfGifts } from './getValuationTotals'
import { standardInfoTooltip } from './tooltips'

import { Chart, PieSeries, Title, Legend, Tooltip } from '@devexpress/dx-react-chart-material-ui'
import { EventTracker, HoverState, Palette, Animation } from '@devexpress/dx-react-chart'

//mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Pie Chart Showing Breakdows by inputted categories
export const pieChartOfCategoryPercents = (person, estate, hover, setHover, width, type, fromTab) => {
  // console.log('type ->', type)
  // console.log('fromTab ->', fromTab)

  let categoryPercentsArray = [] 
  
  if (type === 'categories') {
    // Shows Category percents of total Heir Allotment for an Heir
    categoryPercentsArray = categoryPercentOfTotal(person, estate)
  } else if (type === 'taxesFeesGiftsHeirs') {
    // Shows the Percents of the total estate allotted to Taxes, Fees, Gifts, and Heirs
    categoryPercentsArray = categoryPercentsTaxesFeesGiftsHeirs(estate)
  } else if (type === 'categoriesTotalEstate') {
    // Shows Category percents of total estate, not including the items that are gifted
    categoryPercentsArray = categoryPercentOfTotalEstateExceptGifts(estate)
  } else if (type === 'liquidation') {
    // Shows Category percents of Liquidation share for each entity with a stake
    categoryPercentsArray = categoryPercentOfLiquidation(estate) 
  } else if (type === 'gifts') {
    // Shows Category percents of Liquidation share for each entity with a stake
    categoryPercentsArray = categoryPercentOfGifts(estate) 
  }
  // console.log('categoryPercentsArray ->', categoryPercentsArray)
  
  // Hover Effect
  const handleHoverTrue = (event) => {
    if (event) {
      setHover(event.currentTarget)
    } else if (hover !== null) {
      setHover(null)
    }
  }

  // Tooltip on Hover
  const PieChartToolTipContent = (props) => {
    const { text, targetItem } = props
    // console.log('text ->', text)
    // console.log('targetItem ->', targetItem)
    // console.log('categoryPercents Index ->', categoryPercentsArray[targetItem.point])
    const targetArgumentValueObj = categoryPercentsArray[targetItem.point]
    
    return (
      <Box>
        {targetArgumentValueObj.hasOwnProperty('value') && targetArgumentValueObj.value === text ?
          `${targetArgumentValueObj.argument}: ${targetArgumentValueObj.value}%`
          :
          `${text}%`
        }
      </Box>
    )
  }

  // Title of Pie Chart
  const TitleTextComponent = (props) => {
    // console.log('props ->', props)
    const { text } = props

    return (
      <Box 
        sx={{
          mb: 2,
          // backgroundColor: 'blue',
          // px: type === 'categories' && fromTab === 'pot' ? 6 : 0,
          width: '100%', 
          fontSize: '18px',
          display: 'flex', flexDirection: 'row', justifyContent: 'center',
        }}
      >
        <Typography 
          sx={{
            // backgroundColor: 'yellow',
            color: (type === 'liquidation' || type === 'gifts') ? 'white' : 'black',
            mb: 0,
            px: (fromTab === 'pot' && (text !== 'Assets Before Debits' && type !== 'liquidation')) ? 1 : 0,
            width: '100%', maxWidth: '285px',
            fontSize: '18px',
            fontWeight: (text === 'Assets Before Debits' || type === 'liquidation' || type === 'gifts') ? 'bold' : 'normal',
            display: 'flex', flexDirection: 'row', justifyContent: (text === 'Assets Before Debits' || type === 'liquidation' || type === 'gifts') ? 'center' : 'flex-start',
          }}
        >
          {text}
        </Typography>
      </Box>
    )
  }

  // Styling the 'Legend' of categories; Currently the height is 0px, so there is no Legend, but this was hard to figure out, so I'm leaving in the code in case it's helpful in the future
  const LegendRoot = (props) => {
    const { children } = props

    return (
      <Legend.Root 
        // {...props}
        sx={{
          // mt: -8,
          // mt: -12,
          ml: width < positionChangeWidthSm ? 3 : 6,
          width: '90%', maxWidth: '600px',
          display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: width < positionChangeWidthSm ? 'flex-start' : 'center', alignItems: 'flex-start',
        }}
      >
        {children.map((child, childIndex) => (
          <Typography key={childIndex}
            variant="body1"
            noWrap
            sx={{
              maxHeight: '0px',
              // backgroundColor: 'yellow',
              width: width < positionChangeWidthSm ? '50%' : '30%',
              // display: 'flex', flexWrap: 'nowrap',
            }}
          >
            {child}
          </Typography>
        ))}
      </Legend.Root>
    )
  }

  return (
    <Box
      sx={{
        // backgroundColor: 'blue',
        mt: 0, mb: 0,
        width: '100%',
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
      }}
    >
    
      <Box
        sx={{
          // backgroundColor: 'orange',
          // mt: -12,
          // mt: -6,
          width: '100%',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* The Chart */}
        <Chart
          height={300}
          data={categoryPercentsArray}
        >
          {/* The color scheme for the chart */}
          <Palette scheme={type === 'liquidation' ? liquidationPieChartPaletteScheme : type === 'gifts' ? giftPieChartPaletteScheme : pieChartPaletteScheme} />
          
          {/* Define it as a Pie Chart */}
          <PieSeries 
            valueField="value" 
            argumentField="argument" 
            innerRadius={.5}
            outerRadius={1}
          />
          
          {/* The Pie Chart needs either a "Legend" or a "Title", so I'm leaving the Legend in here even though the height is 0px because sometimes I don't want a Title*/}
          <Legend position='bottom' 
            rootComponent={LegendRoot} 
          />

          {/* Tooltip */}
          {type === 'liquidation' &&
            standardInfoTooltip(
              `Stake of items that are currently 'Unassigned' or marked for Liquidation`,
              0,
              'white'
            )
          }

          {/* Title */}
          <Title text={type === 'liquidation' ? 'Liquidation Stakes' : type === 'gifts' ? 'Gifts' : type === 'categories' ? 'Allotments by Category' : type === 'taxesFeesGiftsHeirs' ? 'Assets Before Debits' : ' '} textComponent={TitleTextComponent} />
            

          {/* Necessary for Hover and Tooltip */}
          <EventTracker />

          {/* Hover */}
          <HoverState 
            hover={hover} 
            onHoverChange={handleHoverTrue} 
          />

          {/* Tooltipe */}
          <Tooltip 
            // overlayComponent={PieChartToolTipOverlay} 
            contentComponent={PieChartToolTipContent} 
          />

          {/* Animation */}
          <Animation />

        </Chart>
      </Box>
    </Box>
  )
}