/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import dayjs from 'dayjs'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import SendIcon from '@mui/icons-material/Send'
import CheckIcon from '@mui/icons-material/Check'

import logoImage from '../assets/circles-orbiting-g-sq-white.jpg'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import PeopleIcon from '@mui/icons-material/People'

import { TooltipPeopleChatJSX } from './tooltips'
import { standardButton } from './buttons'
import { passDraftPickImageBlackURL, positionChangeWidthSm, sheirBlue, draftNextImageDimension, draftResultCardWidth, draftResultCardHeight, draftNextTitleWidth, draftResultHeirColumnHeight, draftResultHeirColumnWidth, draftResultRoundColumnWidth, draftResultImageDimension, onTheClockHeight, sheirGreen, chatHeight, auctionResultHeirColumnWidth, auctionResultHeirColumnHeight, auctionResultCardWidth, auctionResultCardHeight, auctionResultImageDimension, positionChangeWidthDraft, positionChangeWidthAuction, chatHeightMobile, superSmallScoreboardWidth, totalAllottedRemainingHeight, draftQueueMinWidth, autoSelectHeight, draftNextHeight, tooltipMinWidth } from './variableDefaults'
import { getBeforeAtDraftPosition, getAfterAtDraftPosition, getRemainingAtDraftPosition, getPersonDrafting, getPersonAtDraftPosition, getHeirShareTotal, getHeirAllotmentsTotalByEstate, getUnassignedItems, getHighestBid, getPersonInEstateFromId, getPointsRemainingGreaterThanBid, getPointsRemainingGreaterThanBidCustom, getRemainingGreaterThanValuation, getUnassignedItemsWithValuationUnderRemaining, getRemainingGreaterThanValuationAll, getBeforeAtAuctionPosition, getAfterAtAuctionPosition, getRemainingAtAuctionPosition, getPersonInEstateFromName, getRemainingPointsAtAutoAuctionPosition, getTotalAllottedPointsByEstate, getHeirAllotmentsTotal } from './getValuationTotals'
import { shuffleArray } from './globalHelpers'
import { standardInfoTooltip } from './tooltips'

// Scroll to Bottom of Messages
export const scrollToBottomOfMessages = (messagesEndRef) => {
  messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
}

// Create the "Pass" Item
export const createPassItem = (estateId, draftOrderPersons) => {
  
  const bidHistory = {}
  const heirAuctionPlanning = {}
  const heirDraftPlanning = {}

  for (let i = 0; i < draftOrderPersons.length; i++) {
    bidHistory[draftOrderPersons[i]._id] = 0
    heirAuctionPlanning[draftOrderPersons[i]._id] = 0
    heirDraftPlanning[draftOrderPersons[i]._id] = 1000000000
  }

  return {
    estateId: estateId,
    itemName: 'Skip Draft Pick',
    imageURL: passDraftPickImageBlackURL,
    valuation: '0',
    valuationDate: dayjs(),
    category: 'Pass',
    description: 'All remaining items have a valuation greater than my remaining allotment.',
    allottedToName: 'Pass',
    giftFor: '',
    bidHistory: bidHistory,
    heirAuctionPlanning: heirAuctionPlanning,
    heirDraftPlanning: heirDraftPlanning,
  }
}

export const randomlyAssignTargetDraftOrderToUserPerson = (estate, userPersonId, isTestProduct) => {

  const unassignedItems = shuffleArray(getUnassignedItems(estate))
  
  for (let i = 0; i < unassignedItems.length; i++) {
    unassignedItems[i]['heirDraftPlanning'][userPersonId] = i + 1
  }

  return estate
}

// Randomly assign target bids to non-user heirs; Do this so that every practice auction is different
export const randomlyAssignTargetBidsToNonUserPersons = (estate, personTesting, isTestProduct) => {
  // console.log('randomlyAssignTargetBidsToNonUserPersons runs')

  // console.log('isTestProduct ->', isTestProduct)

  // Create an array of all heirs who are not the userPerson
  const nonUserHeirs = isTestProduct ? estate.people.filter(person => person.role === 'Heir') : estate.people.filter(person => person._id !== personTesting._id && person.role === 'Heir')

  // If nonUserHeirs is greater than 0, randomly assign them target bids
  if (nonUserHeirs.length > 0) {
    for (let x = 0; x < nonUserHeirs.length; x++) {

      let totalPointsToAllot = 1000
      const unassignedItems = getUnassignedItems(estate)
      const maxPoints = unassignedItems.length < 20 ? 125 : unassignedItems.length < 30 ? 90 : unassignedItems.length < 40 ? 75 : 50

      // For each heir, for each unassigned item, allot a target bid between 0 and 125 such that the total points allotted is exactly 1000 
      for (let i = 0; i < unassignedItems.length; i++) {
        const maxPointsToAllot = totalPointsToAllot >= maxPoints ? maxPoints : totalPointsToAllot
        const pointsToAllot = (i === unassignedItems.length - 1) ? totalPointsToAllot : Math.floor(Math.random() * maxPointsToAllot)
        unassignedItems[i]['heirAuctionPlanning'][nonUserHeirs[x]._id] = pointsToAllot
        totalPointsToAllot = totalPointsToAllot - pointsToAllot
        // console.log('item ->', unassignedItems[i].itemName)
        // console.log('item ->', unassignedItems[i].heirAuctionPlanning)
      }
      // console.log('totalPointsToAllot ->', totalPointsToAllot)
    }
  }
  
  // console.log(estate.items)

  return estate
}

// Label/Number space-between formatting
export const totalAllottedRemainingJSX = (title, value, type, width, highestBid = false, disabledFromBidding = false, isUserPerson = false, connectedPersons = [], person) => {
  
  // console.log('disabledFromBidding ->', disabledFromBidding)
  // if (type === 'bids') {
  //   console.log('isUserPerson ->', isUserPerson)
  // }

  return (
    <Box
      sx={{
        // backgroundColor: 'orange',
        px: (type === 'auctionResultsMoney' || type === 'auctionResultsPoints') ? 2 : ((width < superSmallScoreboardWidth && type !== 'bids' && type !== 'liquidateBids' && type !== 'moneyRemainingAuctionResults') || (title === 'Remaining Share' || title === 'Remaining Points')) ? 0 : 4,
        width: '100%', 
        display: 'flex', flexDirection: 'row', justifyContent: type === 'bids' ? 'space-between' : 'space-between', alignItems: 'center',
      }}
    >
      {/* Title */}
      <Box
        sx={{
          width: '100%',
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {type === 'bids' && connectedPersons.length > 0 && attendanceDotJSX('10px', connectedPersons, person)}
        <Typography
          sx={{
            // backgroundColor: 'green',
            pr: type === 'bids' ? 2 : 0,
            fontWeight: 'bold',
            fontStyle: isUserPerson ? 'italic' : 'normal',
            // color: type === 'bids' && highestBid ? sheirBlue : type === 'liquidateBids' || title === 'Remaining Share' || title === 'Remaining Points' ? 'white' : disabledFromBidding ? 'lightgray' : 'black',
            color: type === 'bids' && highestBid ? sheirBlue : title === 'Remaining Share' || title === 'Remaining Points' ? 'white' : disabledFromBidding ? 'lightgray' : type === 'liquidateBids' ? 'white' : 'black',
          }}
        >
          {title}:
        </Typography>

        {/* Tooltips */}
        {title === 'Share' && standardInfoTooltip(
          `
            <span style="font-weight:bold; color:${sheirGreen};">Share</span>
            <br/>
            The total asset value of items to which you are entitled, and over which you will not be permitted to claim.   
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">Allotted</span>
            <br/>
            The total asset value of the items that have already been allotted to you. 
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">Remaining</span>
            <br/>
            The part of your share that has not yet been filled. You are only allowed to bid on or draft items with valuations equal to or less than this number. 
          `,
          0.25
        )}
        {title === 'Points' && standardInfoTooltip(
          `
            <span style="font-weight:bold; color:${sheirGreen};">Points</span>
            <br/>
            For auctions all parties start with 1000 points to 'spend'. Points spent on an item someone else wins will NOT be returned to you, so it's in your interest to only bid on things you actually want.
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">Allotted</span>
            <br/>
            The total points that you have spent throughout the auction. 
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">Remaining</span>
            <br/>
            The number of points that remain for you to bid on current and future assets. 
          `,
          0.25
        )}
        {title === 'Remaining Share' && standardInfoTooltip(
          `
            The part of your share that has not yet been filled. You are only allowed to bid on or draft items with valuations equal to or less than this number. 
          `,
          0.25
        )}
        {title === 'Remaining Points' && standardInfoTooltip(
          `
            The number of points that remain for you to bid on current and future assets. 
          `,
          0.25
        )}
      </Box>

      {/* Value */}
      <Typography
        sx={{
          fontWeight: 'bold',
          fontStyle: isUserPerson ? 'italic' : 'normal',
          color: type === 'bids' && highestBid ? sheirBlue : disabledFromBidding ? 'lightgray' : type === 'liquidateBids' ? 'white' : type === 'bids' ? 'black' : sheirBlue,
        }}
      >
        {(type === 'money' || type === 'auctionResultsMoney' || type === 'moneyRemainingAuctionResults') && '$'}{value.toLocaleString('en-US', { minimumFractionDigits: 0 })}
      </Typography>
    </Box>
  )
}

// Queue Item without the expand arrow at the bottom
export const draftQueueItemJSX = (estate, item, itemHighlighted, autoSelect, fromTab, userPerson, width, index) => {
  return (
    <Box
      // key={index}
      sx={{
        my: 1,
        py: 1,
        backgroundColor: 'whitesmoke',
        boxShadow: 3,
        borderRadius: 5,
        border: (estate.hasOwnProperty('items') && !getRemainingGreaterThanValuation(item, estate, userPerson) && fromTab !== 'auctionResultsList') ? '2px solid darkgray' : ((item._id === itemHighlighted._id && !(autoSelect) && item.allottedToName === 'Unassigned' && getRemainingGreaterThanValuation(item, estate, userPerson) && fromTab === 'draftQueue') || (fromTab === 'auctionQueue' && index === 0)) ? '2px solid #2979ff' : '0px solid #2979ff',
        width: fromTab === 'auctionResultsList' ? '90%' : '95%', 
        maxWidth: '375px',
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
      }}
    >
      {/* Image */}
      <Box component='img' src={item.imageURL} alt='Draft Next Image' 
        sx= {{ 
          ml: 2, mr: 0.5,
          height: draftNextImageDimension, width: draftNextImageDimension, objectFit: 'cover', boxShadow: 2, 
        }} 
      /> 

      {/* Title, Category, Valuation */}
      <Box
        sx={{
          // backgroundColor: 'yellow',
          width: fromTab === 'auctionResultsList' ? '130px' : draftNextTitleWidth,
          minWidth: fromTab === 'auctionResultsList' ? '130px' : draftNextTitleWidth,
          maxWidth: fromTab === 'auctionResultsList' ? '130px' : draftNextTitleWidth,
          height: draftNextImageDimension,
          ml: 0.5,
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
        }}
      >
        {/* Item Name */}
        <Box
          textAlign={'left'}
          sx={{
            // backgroundColor: 'yellow',
            width: '95%',
            fontSize: '14px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {item.itemName}
        </Box>

        {/* Category */}
        <Box
          textAlign={'left'}
          sx={{
            width: '95%',
            fontSize: '14px',
          }}
        >
          {item.category}
        </Box>

        {/* Valuation */}
        <Box
          textAlign={'left'}
          sx={{
            width: '95%',
            fontSize: '14px',
            color: (estate.hasOwnProperty('items') && item.allottedToName === 'Unassigned' && !getRemainingGreaterThanValuation(item, estate, userPerson)) ? 'darkgray' : sheirBlue,
          }}
        >
          ${Math.floor(item.valuation).toLocaleString('en-US', { minimumFractionDigits: 0 })}
        </Box>

        {/* Target Bid */}
        {userPerson.role === 'Heir' && (fromTab === 'auctionQueue' || fromTab === 'auctionResultsList') &&
          <Box
            textAlign={'left'}
            sx={{
              width: '95%',
              fontSize: '14px',
            }}
          >
            {ReactHtmlParser(`${fromTab === 'auctionResultsList' ? '' : 'Target '}Bid: <span style="font-weight:bold; color:#2979ff;">${fromTab === 'auctionResultsList' ? item.bidHistory[userPerson._id] : item.heirAuctionPlanning[userPerson._id]}</span>`)}
          </Box>
        }
        
      </Box>
    </Box>
  )
}

// Time Remaining; Customized for draft/auction and computer/mobile
export const timeRemainingJSX = (fromTab, minutes, seconds, width, estate, userPerson) => {
  return (
    <Box
      sx={{
        // backgroundColor: 'pink',
        width: '100%',
        display: 'flex', flexDirection: 'column', justifyContent: fromTab === 'draft' ? 'flex-start' : 'center', alignItems: fromTab === 'draft' && width >= positionChangeWidthDraft ? 'flex-start' : 'center',
      }}
    >
      {/* Remaining ($ and Points); for mobile */}
      {width < positionChangeWidthAuction && fromTab === 'auction' && userPerson.role === 'Heir' &&
        <Box
          sx={{
            // backgroundColor: 'pink',
            pt: 0, 
            width: '95%', 
            maxWidth: '350px',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {totalAllottedRemainingJSX('Remaining Share', (!(estate.hasOwnProperty('items')) || (Math.floor(userPerson.sharePercentage) === 0)) ? 0 : getHeirShareTotal(estate, userPerson.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, userPerson), 'money', width)}
          {totalAllottedRemainingJSX('Remaining Points', (!(estate.hasOwnProperty('items'))) ? 1000 : 1000 - getTotalAllottedPointsByEstate(estate, userPerson), 'points', width)}
        </Box>
      }

      {/* Time Remaining */}
      <Box
        sx={{
          // backgroundColor: 'orange',
          pl: fromTab === 'draftCurrent' ? 3 : 0,
          color: fromTab === 'draftCurrent' ? 'black' : 'white',
          width: '95%',
          maxWidth: fromTab === 'auction' ? '350px' : '310px',
          // maxWidth: width < superSmallScoreboardWidth ? '95%' : fromTab === 'auction' ? '285px' : '310px',
          display: 'flex', flexDirection: 'row', justifyContent: ((fromTab === 'auction' && width < positionChangeWidthAuction) || (fromTab === 'draft' && width < positionChangeWidthDraft)) ? 'space-between' : (fromTab === 'auction') ? 'center' : 'flex-start', alignItems: 'center',
        }}
      >
        {/* Title */}
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: fromTab === 'draftCurrent' || (width < positionChangeWidthDraft && fromTab === 'draft') ? '16px' : '20px',
          }}
        >
          Time Remaining: 
        </Typography>

        {/* Timer */}
        <Typography
          color= {fromTab === 'draftCurrent' ? sheirBlue : 'error'}
          sx={{
            pl: fromTab === 'draftCurrent' ? 1 : 2,
            fontWeight: 'bold',
            fontSize: fromTab === 'draftCurrent' || (width < positionChangeWidthDraft && fromTab === 'draft') ? '16px' : '20px',
          }}
        >
          {`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
        </Typography>

      </Box>
    </Box>
  )
}

export const allHeirsPresent = (heirPersons, connectedPersonIds) => {
  for (let i = 0; i < heirPersons.length; i++) {
    if (connectedPersonIds.filter(connectedPersonId => connectedPersonId === heirPersons[i]._id).length === 0) {
      return false
    }
  }
  return true
}

// Draft event — timer, round, and person drafting
export const onTheClockJSX = (fromTab, minutes, seconds, estate, draftOrderPersons, userPerson, eventHasBegun, handleStartTimer, handleExitDraft, width, connectedPersons = []) => {
  
  const draftRound = Math.ceil((estate.event[0].results.length + 1) / draftOrderPersons.length)
  
  const pickNumber = (estate.event[0].results.length + 1) % draftOrderPersons.length === 0 ? draftOrderPersons.length : (estate.event[0].results.length + 1) % draftOrderPersons.length

  const overallPickNumber = (estate.event[0].results.length + 1) % draftOrderPersons.length === 0 ? Math.floor(((draftRound - 1) * draftOrderPersons.length) + draftOrderPersons.length) : Math.floor(((draftRound - 1) * draftOrderPersons.length) + ((estate.event[0].results.length + 1) % draftOrderPersons.length))

  const personDrafting = getPersonDrafting(estate, draftOrderPersons)

  

  return (
    <Box
      sx={{
        backgroundColor: 'black',
        height: width >= positionChangeWidthDraft ? onTheClockHeight : `calc(${onTheClockHeight} + 30px)`,
        minHeight: width >= positionChangeWidthDraft ? onTheClockHeight : `calc(${onTheClockHeight} + 30px)`,
        width: '100%', 
        // width: `calc(100% - 10px)`, 
        borderLeft: width >= positionChangeWidthDraft ? '1px solid lightgray' : '0px solid lightgray',
        boxShadow: 3,
        display: 'flex', flexDirection: width >= positionChangeWidthDraft ? 'row' : 'column', justifyContent: width >= positionChangeWidthDraft ? 'flex-start' : 'flex-start', alignItems: width >= positionChangeWidthDraft ? 'center' : 'center',
      }}
    >
      {fromTab === 'liveDraft' && eventHasBegun === false ?
        // Start Draft Button
        <Box
          sx={{
            backgroundColor: width >= positionChangeWidthDraft ? 'lightgray' : 'black',
            // pl: 2,
            height: '100%',
            width: '100%', 
            fontWeight: 'bold',
            fontSize: '20px',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            color: 'white',
          }}
        >
          {standardButton(
            'Start Draft', 
            'button',
            'contained',
            // fromTab === 'liveDraft' && (userPerson.readWriteAdmin !== 'admin' || !allHeirsPresent(draftOrderPersons, connectedPersons)),
            false,
            'primary', 
            0,
            0,
            0,
            '130px', 
            '40px', 
            handleStartTimer
          )}
        </Box>
        :
        overallPickNumber <= estate.event[0].results.length + getUnassignedItems(estate).length ?
          // Live Draft Timer
          <>
            {/* Round and Pick */}
            <Box
              sx={{
                // backgroundColor: 'bisque',
                // pl: 2,
                height: width >= positionChangeWidthDraft ? '100%' : '40px',
                width: width >= positionChangeWidthDraft ? '50%' : width < superSmallScoreboardWidth ? '95%' : '310px', 
                fontWeight: 'bold',
                fontSize: width >= positionChangeWidthDraft ? '20px' : '16px',
                display: 'flex', flexDirection: width >= positionChangeWidthDraft ? 'column' : 'row', justifyContent: width >= positionChangeWidthDraft ? 'center' : 'center', alignItems: 'center',
                color: 'white',
              }}
            >
              <Box
                sx={{
                  pr: width >= positionChangeWidthDraft ? 0 : 1,
                  // width: width >= positionChangeWidthDraft ? '100%' : '335px',
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                }}
              >
                Round {draftRound}, Pick {pickNumber}
              </Box>
              <Box
                sx={{
                  // width: width >= positionChangeWidthDraft ? '100%' : '40px' ,
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                }}
              >
                ({overallPickNumber})
              </Box>
              
            </Box>

            {/* On the Clock and Time Remaining */}
            <Box
              sx={{
                // backgroundColor: 'bisque',
                // height: '100%',
                width: width >= positionChangeWidthDraft ? '50%' : width < superSmallScoreboardWidth ? '95%' : '375px', 
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: width >= positionChangeWidthDraft ? 'flex-start' : 'center',
              }}
            >
              {/* On the Clock */}
              <Box
                sx={{
                  // backgroundColor: 'pink',
                  width: '95%', 
                  maxWidth: '310px',
                  display: 'flex', flexDirection: 'row', justifyContent: width >= positionChangeWidthDraft ? 'flex-start' : 'space-between', alignItems: 'center',
                }}
              >
                {/* Title */}
                <Typography
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: width >= positionChangeWidthDraft ? '20px' : '16px',
                  }}
                >
                  On the Clock: 
                </Typography>

                {/* Name */}
                <Typography
                  sx={{
                    pl: 2,
                    fontWeight: 'bold',
                    fontSize: width >= positionChangeWidthDraft ? '20px' : '16px',
                    color: sheirGreen,
                  }}
                >
                  {personDrafting.userGivenAndFamilyName}
                </Typography>

              </Box>

              {/* Remaining ($ and Points); for mobile */}
              {width < positionChangeWidthDraft && fromTab === 'liveDraft' &&
                <Box
                  sx={{
                    // backgroundColor: 'orange',
                    color: 'white',
                    pt: 0, 
                    width: '95%', 
                    maxWidth: '310px',
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'space-between',
                  }}
                >
                  {totalAllottedRemainingJSX('Remaining Share', (!(estate.hasOwnProperty('items')) || (Math.floor(personDrafting.sharePercentage) === 0)) ? 0 : getHeirShareTotal(estate, personDrafting.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, personDrafting), 'money', width)}
                </Box>
              }

              {/* Time Remaining */}
              {timeRemainingJSX('draft', minutes, seconds, width, estate, userPerson)}
              
            </Box>
          </>
          :
          // Draft completed and exit button
          <Box
            sx={{
              height: '100%',
              width: '100%', 
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {/* Draft Completed */}
            <Box
              sx={{
                // backgroundColor: 'bisque',
                pb: 1,
                // height: '100%',
                width: '100%', 
                fontWeight: 'bold',
                fontSize: '18px',
                display: 'flex', flexDirection: 'row', justifyContent: 'center',
                color: 'white',
              }}
            >
              Draft Completed
            </Box>

            {/* Exit Button */}
            {standardButton(
              'Exit', 
              'button',
              'contained',
              false,
              'primary', 
              0,
              0,
              0,
              '130px', 
              '40px', 
              handleExitDraft
            )}
          </Box>
      }
    </Box>
  )
}

// Item for Auction Results page
export const auctionResultItemJSX = (fromComponent, blockNumber, item, estate, width) => {
  // console.log('blockNumber ->', blockNumber)

  return (
    <Box
      key={blockNumber}
      sx={{
        // backgroundColor: 'pink',
        minHeight: '100%', height: '100%', maxHeight: '100%',
        width: auctionResultHeirColumnWidth,
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
      }}
    >
      {/* Block Item */}
      <Box
        sx={{
          py: 1,
          backgroundColor: item.allottedToName === 'Liquidate' ? 'darkolivegreen' : 'whitesmoke',
          width: auctionResultCardWidth,
          height: item.allottedToName === 'Liquidate' ? `calc(${auctionResultCardHeight} - 100px)` : estate.hasOwnProperty('event') && estate.event[0].draftOrder.length > 1 ? `calc(${auctionResultCardHeight} + ${(estate.event[0].draftOrder.length - 3) * 50}px)` : auctionResultCardHeight,
          boxShadow: 3,
          borderRadius: 5,
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Block Number */}
        <Typography 
          sx={{ 
            // backgroundColor: 'yellow',
            width: '90%',
            color: item.allottedToName === 'Liquidate' ? 'white' : 'black',
            // height: draftResultCardHeight,
            // minHeight: draftResultHeirColumnHeight, height: draftResultHeirColumnHeight, maxHeight: draftResultHeirColumnHeight,
            fontWeight: 'bold', fontSize: '18',
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
          }}
        >
          ({blockNumber})
        </Typography>

        {/* Image */}
        <Box component='img' src={item.imageURL} alt='Auction Image' 
          sx= {{ 
            ml: 2, mr: 1,
            height: auctionResultImageDimension, width: auctionResultImageDimension, objectFit: 'cover', boxShadow: 2, 
          }} 
        /> 

        {/* Item Name */}
        <Box
          textAlign={'center'}
          sx={{
            pt: 1.5,
            // backgroundColor: 'yellow',
            color: item.allottedToName === 'Liquidate' ? 'white' : 'black',
            width: '95%',
            fontSize: '14px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {item.itemName}
        </Box>

        {/* Category */}
        <Box
          textAlign={'center'}
          sx={{
            width: '95%',
            fontSize: '14px',
            color: item.allottedToName === 'Liquidate' ? 'white' : 'black',
          }}
        >
          {item.category}
        </Box>

        {/* Valuation */}
        <Box
          textAlign={'center'}
          sx={{
            width: '95%',
            fontSize: '14px',
            color: item.allottedToName === 'Liquidate' ? 'white' : sheirBlue,
          }}
        >
          ${Math.floor(item.valuation).toLocaleString('en-US', { minimumFractionDigits: 0 })}
        </Box>
        
        <Divider sx={{ width: '90%', borderTopWidth: '1px', borderColor: 'lightgray', mt: 2, mb: 2 }} />
        
        {/* Bids */}
        {estate.hasOwnProperty('people') && estate.people.filter(person => person.role === 'Heir').map((heirPerson, heirPersonIndex) =>
          <Box
            key={heirPersonIndex}
            sx={{
              width: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
            }}
          >
            {totalAllottedRemainingJSX(heirPerson.userGivenAndFamilyName, Math.floor(item.bidHistory[heirPerson._id]), item.allottedToName === 'Liquidate' ? 'liquidateBids' : 'bids', width, heirPerson.userGivenAndFamilyName === item.allottedToName, getRemainingPointsAtAutoAuctionPosition(estate, heirPerson, blockNumber > 1 ? blockNumber - 1 : blockNumber) === 0 || (parseFloat(item.valuation) > (!estate || !(estate.hasOwnProperty('items')) || (getPersonInEstateFromName(estate, heirPerson.userGivenAndFamilyName).hasOwnProperty('allotments') && getPersonInEstateFromName(estate, heirPerson.userGivenAndFamilyName).allotments.length === 0 && Math.floor(getPersonInEstateFromName(estate, heirPerson.userGivenAndFamilyName).sharePercentage) === 0) ? 0 : parseFloat(getRemainingAtAuctionPosition(estate, getPersonInEstateFromName(estate, heirPerson.userGivenAndFamilyName), blockNumber > 1 ? blockNumber - 1 : blockNumber)))))}
          </Box>
        )}

        <Divider sx={{ width: '90%', borderTopWidth: '1px', borderColor: 'lightgray', mt: 2, mb: 2 }} />
        
        {/* Total Allotted Remaining from Highest Bidder */}
        {item.allottedToName !== 'Liquidate' && item.category !== 'Pass' && totalAllottedRemainingJSX(
          'Before', 
          estate.hasOwnProperty('items') ? getBeforeAtAuctionPosition(estate, getPersonInEstateFromName(estate, item.allottedToName), blockNumber) : 0, 
          'moneyRemainingAuctionResults', 
          width
        )}
        {item.allottedToName !== 'Liquidate' && item.category !== 'Pass' && totalAllottedRemainingJSX(
          'After', 
          estate.hasOwnProperty('items') ? getAfterAtAuctionPosition(estate, getPersonInEstateFromName(estate, item.allottedToName), blockNumber) : 0, 
          'moneyRemainingAuctionResults', 
          width
        )}
        {item.allottedToName !== 'Liquidate' && item.category !== 'Pass' && totalAllottedRemainingJSX(
          'Remaining', 
          (!(estate.hasOwnProperty('items')) || (getPersonInEstateFromName(estate, item.allottedToName).hasOwnProperty('allotments') && getPersonInEstateFromName(estate, item.allottedToName).allotments.length === 0 && Math.floor(getPersonInEstateFromName(estate, item.allottedToName).sharePercentage) === 0)) ? 0 : getRemainingAtAuctionPosition(estate, getPersonInEstateFromName(estate, item.allottedToName), blockNumber), 
          'moneyRemainingAuctionResults', 
          width
        )}


        {item.allottedToName !== 'Liquidate' && <Divider sx={{ width: '90%', borderTopWidth: '1px', borderColor: 'lightgray', mt: 2, mb: 2 }} />}
        
        {/* Remaining Points if not liquidated */}
        {item.allottedToName !== 'Liquidate' && estate.hasOwnProperty('people') && estate.people.filter(person => person.role === 'Heir').map((heirPerson, heirPersonIndex) =>
          <Box
            key={heirPersonIndex}
            sx={{
              width: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
            }}
          >
            {totalAllottedRemainingJSX(heirPerson.userGivenAndFamilyName, getRemainingPointsAtAutoAuctionPosition(estate, heirPerson, blockNumber), 'bids', width, false)}
          </Box>
        )}

        {/* Explanation text if liquidated */}
        {item.allottedToName === 'Liquidate' && 
          <Box
            textAlign={'left'}
            sx={{
              // pt: 1,
              width: '80%',
              fontSize: '14px',
              color: 'white',
            }}
          >
            Item will be liquidated because there were no bids above zero for it.
          </Box>
        }
      </Box>
    </Box>
  )
}

// Item for Draft Result Page
export const draftResultItemJSX = (fromComponent, pickNumber, draftRound, personIndexPlusOne, item, estate, draftOrderPersons, width) => {
  // console.log('pick number ->', pickNumber)
  // console.log('draft round ->', draftRound)
  // console.log('person index plus one ->', personIndexPlusOne)

  const personDrafting = getPersonAtDraftPosition(draftRound, personIndexPlusOne, draftOrderPersons)
  // console.log('person drafting ->', personDrafting)

  return (
    <Box
      key={pickNumber}
      sx={{
        // backgroundColor: 'pink',
        minHeight: '100%', height: '100%', maxHeight: '100%',
        width: draftResultHeirColumnWidth,
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
      }}
    >
      {/* Drafted Item */}
      <Box
        sx={{
          py: 1,
          backgroundColor: item.allottedToName === 'Liquidate' ? 'darkolivegreen' : 'whitesmoke',
          width: draftResultCardWidth,
          height: draftResultCardHeight,
          boxShadow: 3,
          borderRadius: 5,
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Pick Number */}
        <Typography 
          sx={{ 
            // backgroundColor: 'yellow',
            width: '90%',
            color: item.allottedToName === 'Liquidate' ? 'white' : 'black',
            // height: draftResultCardHeight,
            // minHeight: draftResultHeirColumnHeight, height: draftResultHeirColumnHeight, maxHeight: draftResultHeirColumnHeight,
            fontWeight: 'bold', fontSize: '18',
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
          }}
        >
          ({pickNumber})
        </Typography>

        {/* Image */}
        <Box component='img' src={item.imageURL} alt='Draft Next Image' 
          sx= {{ 
            ml: 2, mr: 1,
            height: draftResultImageDimension, width: draftResultImageDimension, objectFit: 'cover', boxShadow: 2, 
          }} 
        /> 

        {/* Item Name */}
        <Box
          textAlign={'center'}
          sx={{
            pt: 1.5,
            // backgroundColor: 'yellow',
            color: item.allottedToName === 'Liquidate' ? 'white' : 'black',
            width: '95%',
            fontSize: '14px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {item.itemName}
        </Box>

        {/* Category */}
        {(fromComponent !== 'draftResults' || fromComponent === 'draftResults' && (width > positionChangeWidthSm || item.allottedToName === 'Liquidate')) &&
          <Box
            textAlign={'center'}
            sx={{
              width: '95%',
              fontSize: '14px',
              color: item.allottedToName === 'Liquidate' ? 'white' : 'black',
            }}
          >
            {item.category}
          </Box>
        }

        {/* Valuation */}
        <Box
          textAlign={'center'}
          sx={{
            width: '95%',
            fontSize: '14px',
            color: item.allottedToName === 'Liquidate' ? 'white' : sheirBlue,
          }}
        >
          ${Math.floor(item.valuation).toLocaleString('en-US', { minimumFractionDigits: 0 })}
        </Box>

        {/* Person Name for Mobile */}
        {fromComponent === 'draftResults' && width <= positionChangeWidthSm && item.allottedToName !== 'Liquidate' &&
          <Box
            textAlign={'center'}
            sx={{
              width: '95%',
              fontSize: '16px',
              fontWeight: 'bold',
              // color: 'darkolivegreen',
              color: 'black',
            }}
          >
            {item.allottedToName}
          </Box>
        }

        <Divider sx={{ width: '90%', borderTopWidth: '1px', borderColor: 'lightgray', mt: 2, mb: 2 }} />
        
        {/* $ Before, After, Remaining */}
        {item.allottedToName !== 'Liquidate' && item.category !== 'Pass' && totalAllottedRemainingJSX(
          'Before', 
          estate.hasOwnProperty('items') ? getBeforeAtDraftPosition(estate, personDrafting, draftRound) : 0, 
          'money', 
          width
        )}
        {item.allottedToName !== 'Liquidate' && item.category !== 'Pass' && totalAllottedRemainingJSX(
          'After', 
          estate.hasOwnProperty('items') ? getAfterAtDraftPosition(estate, personDrafting, draftRound) : 0, 
          'money', 
          width
        )}
        {item.allottedToName !== 'Liquidate' && item.category !== 'Pass' && totalAllottedRemainingJSX(
          'Remaining', 
          (!(estate.hasOwnProperty('items')) || Math.floor(personDrafting.sharePercentage) === 0) ? 0 : getRemainingAtDraftPosition(estate, personDrafting, draftRound), 
          'money', 
          width
        )}

        {/* Explanation text if item will be liquidated */}
        {item.allottedToName === 'Liquidate' && 
          <Box
            textAlign={'left'}
            sx={{
              // pt: 1,
              width: '80%',
              fontSize: '14px',
              color: 'white',
            }}
          >
            Item will be liquidated because its valuation is higher than the remaining share for all heirs.
          </Box>
        }

        {/* Explanation text if 'Pass' */}
        {item.category === 'Pass' && 
          <Box
            textAlign={'left'}
            sx={{
              // pt: 1,
              width: '80%',
              fontSize: '14px',
              // color: 'white',
            }}
          >
            Pick was forfeited because valuation for all remaining items is greater than remaining share.
          </Box>
        }
      </Box>
    </Box>
  )
}

// Item view for the current on-the-clock pick in live drafts
const currentlyPickingResultItemJSX = (pickNumber, draftRound, personIndexPlusOne, estate, draftOrderPersons, minutes, seconds, width) => {
  // console.log('estate ->', estate)
  // console.log('draftOrderPersons ->', draftOrderPersons)
  return (
    <Box
      key={pickNumber}
      sx={{
        // backgroundColor: 'pink',
        minHeight: '100%', height: '100%', maxHeight: '100%',
        width: draftResultHeirColumnWidth,
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
      }}
    >
      {/* On the Clock Item Space */}
      <Box
        sx={{
          py: 1,
          backgroundColor: 'white',
          width: draftResultCardWidth,
          height: draftResultCardHeight,
          boxShadow: 3,
          border: 2, borderColor: sheirBlue,
          borderRadius: 5,
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Pick Number */}
        <Typography 
          sx={{ 
            // backgroundColor: 'yellow',
            width: '90%',
            height: '30px',
            // height: draftResultCardHeight,
            // minHeight: draftResultHeirColumnHeight, height: draftResultHeirColumnHeight, maxHeight: draftResultHeirColumnHeight,
            fontWeight: 'bold', fontSize: '18',
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
          }}
        >
          ({pickNumber})
        </Typography>
        
        {/* Main Content, centered vertically */}
        <Box
          sx={{
            // backgroundColor: 'yellow',
            width: '100%',
            // height: '100%',
            height: `calc(100% - 60px)`,
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Round #, Pick # */}
          <Box
            textAlign={'left'}
            sx={{
              pt: 0, pl: 3,
              // backgroundColor: 'yellow',
              color: 'black',
              width: '95%',
              fontSize: '16px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {ReactHtmlParser(`<span>Round: <span style="color:#2979ff;">${draftRound}</span>, Pick: <span style="color:#2979ff;">${personIndexPlusOne}</span></span>`)}
          </Box>

          {/* Drafting: XXX */}
          <Box
            textAlign={'left'}
            sx={{
              pt: 1.5, pl: 3,
              // backgroundColor: 'yellow',
              color: 'black',
              width: '95%',
              fontSize: '16px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {/* {ReactHtmlParser(`<span>Drafting: <span style="color:#2979ff;">${draftOrderPersons[draftRound % 2 > 0 ? personIndexPlusOne - 1 : draftOrderPersons.length - personIndexPlusOne].userGivenAndFamilyName.substring(0, draftOrderPersons[draftRound % 2 > 0 ? personIndexPlusOne - 1 : draftOrderPersons.length - personIndexPlusOne].userGivenAndFamilyName.indexOf(' ') + 2)}.</span></span>`)} */}
            {ReactHtmlParser(`<span>Drafting: <span style="color:#2979ff;">${getPersonAtDraftPosition(draftRound, personIndexPlusOne, draftOrderPersons).userGivenAndFamilyName.substring(0, getPersonAtDraftPosition(draftRound, personIndexPlusOne, draftOrderPersons).userGivenAndFamilyName.indexOf(' ') + 2)}.</span></span>`)}
          </Box>
          
          {/* Time Remaining */}
          <Box
            textAlign={'left'}
            sx={{
              pt: 1.5,
              // backgroundColor: 'yellow',
              color: sheirBlue,
              width: '100%',
              // fontSize: '18px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {/* Time Remaining */}
            {timeRemainingJSX('draftCurrent', minutes, seconds, width, estate, getPersonAtDraftPosition(draftRound, personIndexPlusOne, draftOrderPersons))}
          </Box>

        </Box>
        
      </Box>
    </Box>
  )
}

// Item view for future picks in a live draft
const futurePickResultItemJSX = (pickNumber, draftRound, personIndexPlusOne, estate, draftOrderPersons) => {
  // console.log('pickNumber ->', pickNumber)
  // console.log('personIndexPlusOne ->', personIndexPlusOne)
  return (
    <Box
      key={pickNumber}
      sx={{
        // backgroundColor: 'pink',
        minHeight: '100%', height: '100%', maxHeight: '100%',
        width: draftResultHeirColumnWidth,
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
      }}
    >
      {/* Future Pick Item Space */}
      <Box
        sx={{
          py: 1,
          backgroundColor: 'slategray',
          width: draftResultCardWidth,
          height: draftResultCardHeight,
          boxShadow: 3,
          borderRadius: 5,
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Pick Number */}
        <Typography 
          sx={{ 
            // backgroundColor: 'yellow',
            color: 'white',
            width: '90%',
            height: '30px',
            // height: draftResultCardHeight,
            // minHeight: draftResultHeirColumnHeight, height: draftResultHeirColumnHeight, maxHeight: draftResultHeirColumnHeight,
            fontWeight: 'bold', fontSize: '18',
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
          }}
        >
          ({pickNumber})
        </Typography>
        
        {/* Main Content, centered vertically */}
        <Box
          sx={{
            // backgroundColor: 'yellow',
            width: '100%',
            // height: '100%',
            height: `calc(100% - 60px)`,
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Round: #, Pick # */}
          <Box
            textAlign={'center'}
            sx={{
              pt: 0, pl: 0,
              // backgroundColor: 'yellow',
              color: 'white',
              width: '95%',
              fontSize: '16px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          > 
            {ReactHtmlParser(`<span>Round: <span style="color:#ffffff;">${draftRound}</span>, Pick: <span style="color:#ffffff;">${personIndexPlusOne}</span></span>`)}
          </Box>

          {/* Person Drafting at Position */}
          <Box
            textAlign={'center'}
            sx={{
              pt: 1.5, pl: 0,
              // backgroundColor: 'yellow',
              color: 'white',
              width: '95%',
              fontSize: '16px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {/* {ReactHtmlParser(`<span><span style="color:#000000;">${draftOrderPersons[draftRound % 2 > 0 ? personIndexPlusOne - 1 : draftOrderPersons.length - personIndexPlusOne].userGivenAndFamilyName}</span></span>`)} */}
            
            {ReactHtmlParser(`<span><span style="color:#ffffff;">${getPersonAtDraftPosition(draftRound, personIndexPlusOne, draftOrderPersons).userGivenAndFamilyName}</span></span>`)}
          </Box>

        </Box>
      </Box>
    </Box>
  )
}

// Item view for a pick that may not happen in a live draft
const noMoreItemsResultItemJSX = (pickNumber) => {
  return (
    <Box
      key={pickNumber}
      sx={{
        // backgroundColor: 'pink',
        minHeight: '100%', height: '100%', maxHeight: '100%',
        width: draftResultHeirColumnWidth,
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
      }}
    >
      {/* No More Items Space */}
      <Box
        sx={{
          py: 1,
          backgroundColor: 'black',
          width: draftResultCardWidth,
          height: draftResultCardHeight,
          boxShadow: 3,
          borderRadius: 5,
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      />
    </Box>
  )
}

// Items in a single round for a live draft
export const draftResultsSingleRoundJSX = (fromComponent, index, resultsArray, unassignedItems, estate, draftOrderPersons, minutes, seconds, width) => {
  // console.log('results array ->', resultsArray)
  // console.log('estate ->', estate)
  // console.log('draftOrderPersons ->', draftOrderPersons)

  // console.log('draftOrderPersons.length ->', draftOrderPersons.length)
  // console.log('draftResultHeirColumnWidth ->', Math.floor(draftResultHeirColumnWidth.substring(0, draftResultHeirColumnWidth.indexOf('p'))))
  // console.log('(draftOrderPersons.length * draftResultHeirColumnWidth) + draftResultRoundColumnWidth ->', ((draftOrderPersons.length) * Math.floor(draftResultHeirColumnWidth.substring(0, draftResultHeirColumnWidth.indexOf('p')))) + Math.floor(draftResultRoundColumnWidth.substring(0, draftResultRoundColumnWidth.indexOf('p'))))
  // console.log('width ->', width)
  // console.log('((draftOrderPersons.length) * draftResultHeirColumnWidth) + draftResultRoundColumnWidth < width ->', (draftOrderPersons.length) * Math.floor(draftResultHeirColumnWidth.substring(0, draftResultHeirColumnWidth.indexOf('p'))) + Math.floor(draftResultRoundColumnWidth.substring(0, draftResultRoundColumnWidth.indexOf('p'))) < width)

  const shouldCenterResults = ((draftOrderPersons.length) * Math.floor(draftResultHeirColumnWidth.substring(0, draftResultHeirColumnWidth.indexOf('p')))) + Math.floor(draftResultRoundColumnWidth.substring(0, draftResultRoundColumnWidth.indexOf('p'))) < width

  if ((index + 1) > (Math.ceil((unassignedItems.length + resultsArray.length) / draftOrderPersons.length))) {
    return
  } else {
    return (
      <Box
        key={index}
        sx={{
          fontWeight: 'bold',
          width: '100%',
          minHeight: draftResultHeirColumnHeight, height: draftResultHeirColumnHeight, maxHeight: draftResultHeirColumnHeight,
          display: 'flex', flexDirection: 'row', justifyContent: (fromComponent === 'draftResults' && shouldCenterResults) ? 'center' : 'flex-start', alignItems: 'center',
        }}
      >
        {/* Draft Round Number */}
        <Typography 
          sx={{ 
            // backgroundColor: 'plum',
            minWidth: draftResultRoundColumnWidth,
            minHeight: '100%', maxHeight: '100%',
            fontWeight: 'bold', fontSize: '18',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {index + 1}
        </Typography>
        
        {/* Box is for snake order */}
        <Box
          sx={{
            display: 'flex', flexDirection: index % 2 === 1 ? 'row-reverse' : 'row', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {draftOrderPersons.map((person, personIndex) => (
            resultsArray.length >= (index * draftOrderPersons.length) + (personIndex + 1) 
              ?
              draftResultItemJSX(fromComponent, ((index * draftOrderPersons.length) + (personIndex + 1)), (index + 1), (personIndex + 1), resultsArray[((index * draftOrderPersons.length) + (personIndex))], estate, draftOrderPersons, width)
              :
              (resultsArray.length + 1 === (index * draftOrderPersons.length) + (personIndex + 1) && resultsArray.length + unassignedItems.length >= (index * draftOrderPersons.length) + (personIndex + 1))
                ?
                currentlyPickingResultItemJSX(((index * draftOrderPersons.length) + (personIndex + 1)), (index + 1), (personIndex + 1), estate, draftOrderPersons, minutes, seconds, width)
                :
                resultsArray.length + unassignedItems.length >= (index * draftOrderPersons.length) + (personIndex + 1) 
                  ?
                  futurePickResultItemJSX(((index * draftOrderPersons.length) + (personIndex + 1)), (index + 1), (personIndex + 1), estate, draftOrderPersons)
                  :
                  noMoreItemsResultItemJSX(((index * draftOrderPersons.length) + (personIndex + 1)))
            
          ))}
        </Box>
        
      </Box>
    )
  }
}

export const attendanceDotJSX = (dimension, connectedPersons, person) => {
  return (
    <Box
      sx={{
        mx: 1,
        backgroundColor: connectedPersons.filter(connectedPersonId => connectedPersonId === person._id).length > 0 ? 'darkolivegreen' : 'red',
        borderRadius: '50%',
        width: dimension,
        height: dimension,
      }}
    />
  )
}

export const attendanceSidePanelJSX = (type, estate, connectedPersons, waitingForStart = false) => {
  return (
    <Box
      sx={{
        // backgroundColor: 'lightblue',
        pb: 2,
        height: waitingForStart ? '100%' : type === 'Auction' ? `calc(${totalAllottedRemainingHeight} + ${totalAllottedRemainingHeight} + ${autoSelectHeight})` : `calc(${totalAllottedRemainingHeight} + ${autoSelectHeight} + ${draftNextHeight})`,
        width: waitingForStart ? '100%' : `calc(100% - 10px)`, 
        borderBottom: waitingForStart ? '0px solid gray' : '1px solid gray',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          WebkitAppearance: 'none',
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          backgroundColor: 'rgba(0, 0, 0, .35)',
          webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
        },
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
      }}
    >
      {/* Advisory waiting for start */}
      {waitingForStart &&
        <Typography
          sx={{
            py: 1,
            width: '90%',
            maxWidth: '350px',
            fontStyle: 'italic',
            fontWeight: 'bold',
            color: sheirBlue,
          }}
        >
          When all heirs have arrived in the room, the &apos;admin&apos; will click &apos;Start {type}&apos; to begin the event
        </Typography>
      }

      {/* Title */}
      {!waitingForStart &&
        <Typography
          sx={{
            // backgroundColor: 'orange',
            pt: 1,
            // fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Attendance
        </Typography>
      }

      {/* People */}
      {estate.hasOwnProperty('people') && estate.people.sort((a, b) => a['userGivenAndFamilyName'].localeCompare(b['userGivenAndFamilyName'])).map((person, personIndex) => (
        <Box
          key={personIndex}
          // onClick={(e) => highlightNewItem(e, item, index)}
          sx={{
            // backgroundColor: 'yellow',
            py: 0.25,
            width: '100%', 
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Colored Circle indicating attendance */}
          {attendanceDotJSX('10px', connectedPersons, person)}

          {/* Person Name */}
          <Typography
            textAlign={'left'}
            sx={{
              // backgroundColor: 'yellow',
              // width: `calc(${draftQueueMinWidth} - 100px)`,
              width: '125px',
              fontSize: '14px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {person.userGivenAndFamilyName}
          </Typography>

        </Box>
      ))}
    </Box>
  )
}

// Chat view for live draft and auction
export const chatJSX = (chatData, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef, width, distributionMethod, connectedPersons, estate) => {
  // console.log('chatData ->', chatData)
  return (
    <Box
      sx={{
        // backgroundColor: 'mediumaquamarine',
        height: ((width >= positionChangeWidthAuction && distributionMethod === 'Auction') || (width >= positionChangeWidthDraft && distributionMethod === 'Draft')) ? chatHeight : chatHeightMobile,
        minHeight: ((width >= positionChangeWidthAuction && distributionMethod === 'Auction') || (width >= positionChangeWidthDraft && distributionMethod === 'Draft')) ? chatHeight : chatHeightMobile,
        width: '100%', 
        // width: `calc(100% - 10px)`, 
        borderTop: '1px solid lightgray',
        borderLeft: '1px solid lightgray',
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
      }}
    >
      {/* Messages Display */}
      <Box
        sx={{
          backgroundColor: 'lightgray',
          p: 1,
          height: ((width >= positionChangeWidthAuction && distributionMethod === 'Auction') || (width >= positionChangeWidthDraft && distributionMethod === 'Draft')) ? `calc(100% - 40px)` : '100%',
          width: '100%',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, .35)',
            webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
          },
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
        }}
      >
        {/* Map out individual messages */}
        {chatData.sort((a, b) => dayjs(a.timestamp) - dayjs(b.timestamp)).map((message, messageIndex) => (
          <Box
            key={messageIndex}
            sx={{
              pb: 1,
              width: '100%',
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', 
              flexWrap: 'wrap',
            }} 
          >
            {ReactHtmlParser(
              `<span><span style="padding-right:5px; font-weight:bold">${message.fromName}:</span> ${message.message}</span>`
            )}
          </Box>
        ))}

        {/* For automatically scrolling to the bottom of the message screen */}
        <Box ref={messagesEndRef}></Box>
      </Box>

      {/* Input Text and Send Button */}
      {((width >= positionChangeWidthAuction && distributionMethod === 'Auction') || (width >= positionChangeWidthDraft && distributionMethod === 'Draft')) &&
        <Box
          component="form"
          onSubmit={(e) => handleSendMessage(e)}
          sx={{
            backgroundColor: 'white',
            p: '2px 4px',
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {/* Input text */}
          <InputBase
            placeholder="Type message..."
            value={messageText}
            onChange={handleChangeMessageText}
            inputProps={{ 'aria-label': 'type message' }}
            sx={{ 
              ml: 1, 
              width: connectedPersons.length > 0 ? `calc(100% - 60px)` : `calc(100% - 30px)`,
              // flex: 1,
            }}
          />

          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          
          {/* Send Button */}
          <IconButton 
            size="small" 
            color="primary" 
            aria-label="send-message" 
            type='submit'
            disabled={!messageText}
            // onClick={(e) => handleSendMessage(e)}
            sx={{ 
              // p: '10px',
              width: '30px', height: '30px', 
            }}
          >
            <SendIcon />
          </IconButton>

          {connectedPersons.length > 0 && <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />}

          {/* Attendance Tooltip */}
          {connectedPersons.length > 0 &&
            <Box 
              sx={{ 
                color: sheirBlue,
                width: '30px', height: '30px', 
              }}
            >
              <TooltipPeopleChatJSX disableFocusListener 
                title={
                  estate.hasOwnProperty('people') && estate.people.sort((a, b) => a['userGivenAndFamilyName'].localeCompare(b['userGivenAndFamilyName'])).map((person, personIndex) => (
                    <Box
                      key={personIndex}
                      sx={{
                        py: 0.25,
                        width: '100%',
                        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                      }}
                    >
                      {attendanceDotJSX('10px', connectedPersons, person)}
                      {/* Person Name */}
                      <Typography
                        textAlign={'left'}
                        sx={{
                          // backgroundColor: 'yellow',
                          width: tooltipMinWidth,
                          fontSize: '14px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '1',
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {person.userGivenAndFamilyName}
                      </Typography>
                    </Box>
                  ))
                  
                }
              >
                <PeopleIcon />
              </TooltipPeopleChatJSX>
            </Box>
          }
        </Box>
      }

    </Box>
  
  )
}

// Bidding Space for live auction
export const biddingSpaceJSX = (estate, userPerson, itemOnBlock, autoSelect, handleSubmitBidPressed, customBid, handleCustomBidText, minutes, seconds, eventHasBegun, width, type, connectedPersons = []) => {
  
  return (
    <Box
      sx={{
        // backgroundColor: 'yellow',
        mt: width >= positionChangeWidthAuction ? 8 : 0,
        width: width >= positionChangeWidthAuction ? '50%' : '95%', 
        // height: '100%',
        minHeight: width >= positionChangeWidthAuction ? '300px' : '180px', height: width >= positionChangeWidthAuction ? '300px' : '170px',
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: width >= positionChangeWidthAuction ? 'flex-start' : 'center',
      }}
    >

      {/* Title (if not mobile) */}
      {width >= positionChangeWidthAuction &&
        <Box
          sx={{
            // pb: 3, pt: 2,
            width: '100%',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
          }}
        >
          {/* Title */}
          <Typography
            textAlign={'center'}
            sx={{
              py: 1,
              fontSize: '18px',
              width: '100%',
              fontWeight: 'bold',
            }}
          >
            Bids
          </Typography>

        </Box>
      }
      
      {/* Top Bids Submitted for each Heir */}
      <Box
        sx={{
          pb: 2, 
          width: '100%',
          maxWidth: '350px',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: width >= positionChangeWidthAuction ? 'flex-start' : 'center',
        }}
      >
        {/* Bids */}
        {estate.hasOwnProperty('people') && estate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage).map((heirPerson, heirPersonIndex) =>
          
          <Box
            key={heirPersonIndex}
            sx={{
              // backgroundColor: 'red',
              width: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: width >= positionChangeWidthAuction ? 'flex-start' : 'center',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex', flexDirection: 'row', alignItems: 'center',
              }}
            >
              {/* {console.log('person name ->', heirPerson.userGivenAndFamilyName)}
              {console.log('share percentage ->', heirPerson.sharePercentage)} */}
              {/* {type === 'live' && attendanceDotJSX('10px', connectedPersons, heirPerson)} */}
              {totalAllottedRemainingJSX(heirPerson.userGivenAndFamilyName, Math.floor(itemOnBlock.bidHistory[heirPerson._id]), 'bids', width, (Math.floor(itemOnBlock.bidHistory[heirPerson._id]) !== 0 && getHighestBid(itemOnBlock) === Math.floor(itemOnBlock.bidHistory[heirPerson._id])), !(getRemainingGreaterThanValuation(itemOnBlock, estate, heirPerson) && getPointsRemainingGreaterThanBidCustom(Math.floor(getHighestBid(itemOnBlock)), estate, heirPerson, itemOnBlock) && (1000 - getTotalAllottedPointsByEstate(estate, heirPerson) > 0)), heirPerson._id === userPerson._id, connectedPersons, heirPerson)}
            </Box>
            
          </Box>
        )}
      </Box>

      {/* Buttons (+1, +5, +10, +50, Custom) */}
      {userPerson.role === 'Heir' &&
        <Box
          sx={{
            width: '100%',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
          }}
        >
          {/* Standard buttons */}
          <Box
            sx={{
              width: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {['+1', '+5', '+10', '+50'].map((value, index) => (
                <Box
                  key={index}
                  onClick={(e) => handleSubmitBidPressed(e, 'plus')}
                  sx={{ 
                    mx: 0.5,
                    cursor: (autoSelect || !eventHasBegun || !(getRemainingGreaterThanValuation(itemOnBlock, estate, userPerson) && getPointsRemainingGreaterThanBidCustom(getHighestBid(itemOnBlock) + Math.floor(value.replace('+','')), estate, getPersonInEstateFromId(estate, userPerson._id), itemOnBlock)) || (seconds === 0 && minutes === 0)) ? 'normal' : 'pointer',
                    borderRadius: '50%', boxShadow: 3, border: 2, borderColor: (autoSelect || !eventHasBegun || !(getRemainingGreaterThanValuation(itemOnBlock, estate, userPerson) && getPointsRemainingGreaterThanBidCustom(getHighestBid(itemOnBlock) + Math.floor(value.replace('+','')), estate, getPersonInEstateFromId(estate, userPerson._id), itemOnBlock)) || (seconds === 0 && minutes === 0)) ? 'lightgray' : sheirGreen,
                    width: '40px', height: '40px',
                    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  }}
                >
                  {value}
                </Box>
              ))}
            </Box>
            
            {/* Custom Amount */}
            {width >= positionChangeWidthAuction &&
              <Box
                component="form"
                onSubmit={(e) => handleSubmitBidPressed(e, 'custom')}
                sx={{
                  backgroundColor: 'white',
                  mt: 2, mb: 2,
                  p: '0px 0px',
                  width: '200px',
                  height: '40px',
                  boxShadow: 3,
                  borderRadius: '5px',
                  display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {/* Text input */}
                <InputBase
                  disabled={autoSelect || !eventHasBegun || !(getRemainingGreaterThanValuation(itemOnBlock, estate, userPerson)) || (seconds === 0 && minutes === 0)}
                  placeholder="Custom Bid"
                  value={customBid}
                  inputProps={{ 
                    'aria-label': 'custom bid',
                    maxLength: 3,
                  }}
                  onChange={(e) => handleCustomBidText(e)}
                  sx={{ 
                    ml: 1, 
                    width: `calc(200px - 40px)`,
                  }}
                />

                <Divider sx={{ height: 24, m: 0.5 }} orientation="vertical" />
                
                {/* Submit bid button */}
                {/* {console.log('highest bid > custom bid', getHighestBid(itemOnBlock) >= customBid)}
                {console.log('getPointsRemainingGreaterThanBidCustom ->', getPointsRemainingGreaterThanBidCustom(Math.floor(customBid), estate, getPersonInEstateFromId(estate, userPerson._id), itemOnBlock))}
                {console.log('valuation and points ok ->', (getRemainingGreaterThanValuation(itemOnBlock, estate, userPerson) && getPointsRemainingGreaterThanBidCustom(Math.floor(customBid), estate, getPersonInEstateFromId(estate, userPerson._id), itemOnBlock)))} */}
                {/* {console.log('autoSelect ->', autoSelect)}
                {console.log('eventHasBegun  ->', eventHasBegun)}
                {console.log('time out ->', (seconds === 0 && minutes === 0))} */}
                {/* {console.log('icon disabled ->', autoSelect || !eventHasBegun || getHighestBid(itemOnBlock) >= customBid || !(getRemainingGreaterThanValuation(itemOnBlock, estate, userPerson) && getPointsRemainingGreaterThanBidCustom(Math.floor(customBid), estate, getPersonInEstateFromId(estate, userPerson._id), itemOnBlock)) || (seconds === 0 && minutes === 0))} */}
                <IconButton 
                  disabled={autoSelect || !eventHasBegun || getHighestBid(itemOnBlock) >= customBid || !(getRemainingGreaterThanValuation(itemOnBlock, estate, userPerson) && getPointsRemainingGreaterThanBidCustom(Math.floor(customBid), estate, getPersonInEstateFromId(estate, userPerson._id), itemOnBlock)) || (seconds === 0 && minutes === 0)}
                  size="small" 
                  color="primary" 
                  aria-label="send-message" 
                  type='submit'
                  // onClick={(e) => handleSendMessage(e)}
                  sx={{ 
                    // p: '10px',
                    width: '30px', height: '30px', 
                  }}
                >
                  <CheckIcon />
                </IconButton>
              </Box>
            }

            {/* Advisory — Not enough remaining $ */}
            {!(getRemainingGreaterThanValuation(itemOnBlock, estate, userPerson)) &&
              <Typography 
                sx={{
                  pt: width >= positionChangeWidthAuction ? 0 : 1,
                  textAlign: 'center',
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                <em>*Valuation higher than remaining allotment</em>
              </Typography>
            }

            {/* Advisory — Not enough remaining points */}
            {(getRemainingGreaterThanValuation(itemOnBlock, estate, userPerson)) && !getPointsRemainingGreaterThanBidCustom(Math.floor(getHighestBid(itemOnBlock)) + 1, estate, getPersonInEstateFromId(estate, userPerson._id), itemOnBlock) &&
              <Typography 
                sx={{
                  pt: width >= positionChangeWidthAuction ? 0 : 1,
                  textAlign: 'center',
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                <em>*Not enough points remaining to bid higher</em>
              </Typography>
            }
          </Box>

        </Box>
      }

    </Box>
  )
}