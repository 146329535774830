import { alexSpiliyachtesEstate, jayGatsbyEstate, blancheDevereauxEstate, hollyGolightlyEstate, johnLennonEstate, albusDumbledoreEstate, cosmoKramerEstate, mrMonopolyEstate, bruceWayneEstate } from '../components/test/data/estatesTestData'
import { alexSpiliyachtesUser, albusDumbledoreUser, blancheDevereauxUser, bruceWayneUser, cosmoKramerUser, hollyGolightlyUser, jayGatsbyUser, johnLennonUser, mrMonopolyUser } from '../components/test/data/users/ownerUsers'

// Variable Defaults


// Widths and Heights
export const positionChangeHeightSm = 700
export const positionChangeWidthSm = 600
export const positionChangeWidthMd = 900
export const positionChangeWidthLg = 1200
export const positionChangeWidthAuction = 1000
export const positionChangeWidthDraft = 900
export const widthInsideTabPanel = '90vw'
export const priceCardWidth = 250
export const tooltipMinWidth = '225px'
export const tooltipInfoDimension = '25px'

// Navbar and Footer
export const navbarHeight = '70px' //Height for the navigation bar
export const estateNavbarHeight = '65px' //Height of the navbar inside the estate
export const footerHeight = '150px' //Height for the footer bar

// Live Draft/Auction
export const draftQueueMinWidth = '275px'
export const auctionResultsMinWidth = '250px'
export const totalAllottedRemainingHeight = '100px'
export const autoSelectHeight = '50px'
export const draftNextHeight = '200px'
export const onTheClockHeight = '100px'
export const chatHeight = '150px'
export const chatHeightMobile = '75px'
export const draftNextImageDimension = '75px'
export const draftNextTitleWidth = '155px'
export const draftResultCardWidth = '250px'
export const draftResultRoundColumnWidth = '50px'
export const draftResultPickNumberColumnWidth = '25px'
export const draftResultHeirColumnWidth = '300px'
export const draftResultHeirColumnHeight = '400px'
export const auctionResultHeirColumnWidth = '300px'
export const auctionResultHeirColumnHeight = '400px'
export const draftResultCardHeight = '350px'
export const draftResultImageDimension = '125px'
export const auctionResultCardWidth = '250px'
export const auctionResultCardHeight = '550px'
export const auctionResultImageDimension = '125px'
export const superSmallScoreboardWidth = 375
export const superSmallAuctionBlockWidth = 440
export const superSmallAuctionBlockCardDimension = '90px'
export const auctionBlockCardDimension = '150px'
export const iconImageDimension = '115px'
export const queueItemImageDimension = '75px'
export const privacyPolicyMaxWidth = '600px'
export const testProductImageDimension = '125px'

// Colors
export const sheirGreen = '#59e19c' // sHeir green
export const sheirBlue = '#2979ff' //sHeir blue
export const sheirYellow = '#FFEB3B' //sHeir yellow
export const navbarBgDefaultColor = '#59e19c' // sHeir green
export const estateNavDefaultColor = 'black' // black

// Images
export const profPicDefaultURL = 'https://sheir-bizops-images.s3.amazonaws.com/seed-profpic-default-4.jpg'
export const passDraftPickImageBlackURL = 'https://sheir-bizops-images.s3.amazonaws.com/seed-category-pass-bg-black.jpg'
export const passDraftPickImageWhiteURL = 'https://sheir-bizops-images.s3.amazonaws.com/seed-category-pass-bg-white.jpg'
export const uploadImageDimension = 350

// Timers
export const auctionTimerMinutes = 0
export const auctionTimerSeconds = 30
export const draftTimerMinutes = 1
export const draftTimerSeconds = 30

// Arrays

// Test Data to Export
export const estatesTestDataArray = [
  // alexSpiliyachtesEstate,
  jayGatsbyEstate,
  // blancheDevereauxEstate,
  // hollyGolightlyEstate,
  johnLennonEstate,
  albusDumbledoreEstate,
  cosmoKramerEstate
  // mrMonopolyEstate,
  // bruceWayneEstate
]

export const estateOwnerUsersTestDataArray = [
  // alexSpiliyachtesUser,
  jayGatsbyUser,
  // blancheDevereauxUser,
  // hollyGolightlyUser,
  johnLennonUser,
  albusDumbledoreUser,
  cosmoKramerUser
  // mrMonopolyUser,
  // bruceWayneUser
]

// Nav menu when logged in
export const navMenuOptionsLoggedIn = [
  'Profile', 
  'Create Estate',
  // 'About',
  // 'Pricing',
  // 'Demo',
  'Logout'
]

// Nav menu when inside estate
export const navMenuOptionsInsideEstate = [
  'Family HQ', 
  'The Pot',
  'Items',
  'My Planning'
  // '',
  // 'Profile',
  // 'Create Estate',
  // 'Logout'
]
export const navMenuOptionsInsideTestEstate = [
  'Family HQ', 
  'The Pot',
  'Items'
  // 'My Planning'
  // '',
  // 'Profile',
  // 'Create Estate',
  // 'Logout'
]

// Nav menu when not logged in
export const navMenuOptionsNotLoggedIn = [
  'Create Estate',
  // 'About',
  // 'Pricing',
  'Demo',
  'Login'
]

// Security Questions
export const securityQuestion1 = [
  'What were the last 5 digits of your childhood phone number?',
  'What was the name of your first pet?',
  'What was the name of your childhood best friend?'
]

export const securityQuestion2 = [
  'What is your mother\'s maiden name?',
  'What is the first name of your father\'s mother?',
  'What is the first name of your mother\'s mother?',
  'What is the first name of your father\'s father?',
  'What is the first name of your mother\'s father?'
]

export const securityQuestion3 = [
  'What was your high school\'s mascot?',
  'What was your university\'s mascot?',
  'What is your favorite color?',
  'Who was the first presidential candidate that you voted for?'
]

// Bottom steps for creating an estate
export const bottomSteps = [
  'People',
  'Mission',
  'Settings',
  'Review',
  'Pay'
]

// Rates
export const monthlyRate = 12
export const yearlyRate = 130
export const oneTimeRate = 350

// Subscription Types
export const subscriptionTypes = [
  // {
  //   interval: 'month',
  //   rate: monthlyRate,
  //   freeTrial: true,
  // },
  // {
  //   interval: 'year',
  //   rate: yearlyRate,
  //   freeTrial: true,
  // },
  {
    interval: 'one-time',
    rate: oneTimeRate,
    freeTrial: false,
  }
]

// Person Types for adding a new person to the estate
export const personTypes = [
  // 'Owner', 
  'Heir',
  'Lawyer',
  'Asset Manager',
  'Executor',
  'Accountant',
  'Witness'
]

// Onboarding Starting People Array
export const onboardingStartingPeopleArray = [
  {
    role: 'Owner', // Keep default text when deploying
    sharePercentage: '0', // Keep default when deploying
    givenName: '', // ! set to '' when deploying
    familyName: '', // ! set to '' when deploying
    email: '', // ! set to '' when deploying
  }
  // ,
  // {
  //   role: 'Asset Manager', // Keep default text when deploying
  //   sharePercentage: '0', // Keep default when deploying
  //   givenName: '', // ! set to '' when deploying
  //   familyName: '', // ! set to '' when deploying
  //   email: '', // ! set to '' when deploying
  // },
  // {
  //   role: 'Executor', // Keep default text when deploying
  //   sharePercentage: '0', // Keep default when deploying
  //   givenName: '', // ! set to '' when deploying
  //   familyName: '', // ! set to '' when deploying
  //   email: '', // ! set to '' when deploying
  // },
  // {
  //   role: 'Heir', // Keep default text when deploying
  //   sharePercentage: '0', // Keep default when deploying
  //   givenName: '', // ! set to '' when deploying
  //   familyName: '', // ! set to '' when deploying
  //   email: '', // ! set to '' when deploying
  // },
  // {
  //   role: 'Heir', // Keep default text when deploying
  //   sharePercentage: '0', // Keep default when deploying
  //   givenName: '', // ! set to '' when deploying
  //   familyName: '', // ! set to '' when deploying
  //   email: '', // ! set to '' when deploying
  // }
]

// Distribution Method
export const distributionMethod = [
  'Allocate All',
  'Auction',
  'Draft'
]

export const auctionOrDraft = [
  'Auction',
  'Draft'
]

export const silentDistributionMethods = [
  'Allocate All'
]

// Live or Auto
export const liveOrAuto = [
  'Live',
  'Auto'
]

// Silent or Participatory
export const silentOrParticipatory = [
  'Silent',
  'Partial',
  'Participatory'
]

// Family HQ Tabs
export const familyHQTabs = [
  'Settings',
  'People',
  'Mission'
]

// Item Categories
export const itemCategories = [
  'Animal',
  'Art',
  'Clothing',
  'Equity',
  'Furniture',
  'Jewelry',
  'Keepsake',
  'Liquidity',
  'Real Estate',
  // 'Stock',
  'Vehicle',
  'Miscellaneous'
]

// Reserved for (default options)
export const reservedForOptions = [
  // 'Unassigned',
  'Gift',
  'Liquidate',
  'Fees'
]

// Read, write, admin privilege options
export const privilegesOptions = [
  'admin',
  'write',
  'read-only'
]

// Heir Person Space Tabs
export const heirPersonSpaceTabs = [
  'Allotments',
  'Planning',
  'Notes'
]

// Pie Chart Color Palette
export const pieChartPaletteScheme = [
  '#7FFFD4', // Aquamarine
  '#8A2BE2', // Blue Violet
  '#FFEBCD', // Blanched Almond
  '#6495ED', // Cornflower Blue
  '#FF7F50', // Coral
  '#DC143C', // Crimson
  '#00008B', // Dark Blue
  '#FFD700', // Gold
  '#8FBC8F', // Dark Sea Green
  '#FFE4E1', // Misty Rose
  '#D8BFD8'  // Thistle
]

// Liquidation Pie Chart Color Palette
export const liquidationPieChartPaletteScheme = [
  '#7FFFD4', // Aquamarine
  '#8A2BE2', // Blue Violet
  '#FFEBCD', // Blanched Almond
  '#6495ED', // Cornflower Blue
  '#FF7F50', // Coral
  '#DC143C', // Crimson
  '#00008B', // Dark Blue
  '#FFD700', // Gold
  '#E1E1E1', // Light Gray
  '#FFE4E1', // Misty Rose
  '#D8BFD8'  // Thistle
]

export const giftPieChartPaletteScheme = [
  '#7FFFD4', // Aquamarine
  '#8A2BE2', // Blue Violet
  '#FFEBCD', // Blanched Almond
  '#8FBC8F', // Dark Sea Green
  '#FF7F50', // Coral
  '#DC143C', // Crimson
  '#FF00BF', // Lyft Pink
  '#FFD700', // Gold
  '#E1E1E1', // Light Gray
  '#FFE4E1', // Misty Rose
  '#D8BFD8'  // Thistle
]

// Heir Allotments Sort By Array
export const heirAllotmentsSortByArray = [
  'Valuation ⬇',
  'Valuation ⬆',
  'Category',
  'A ➡ Z',
  'Z ➡ A'
]

// Items Index Sort By Array
export const itemsIndexSortByArray = [
  'Valuation ⬇',
  'Valuation ⬆',
  'Allotment ⬇',
  'Allotment ⬆',
  'Category',
  'A ➡ Z',
  'Z ➡ A'
]


// Filler Chat Data
export const sheirChecklist = [
  {
    toDo: 'Add Items',
    description: `The estate owner, the asset manager, and anyone with 'write' privileges adds items to the estate. When all items are added, flip the 'All Items Added' switch in the Items Index tab.`,
    // description: ``,
    videoURL: 'https://www.youtube.com/embed/T-K1MJn07Ts',
    writtenInstructions: 
    `1) Navigate to the 'Items' tab in your estate.
      2) Click 'Add Item' to add an item, or click the pencil icon next to an item to edit an existing item.
      3) Input the item's details.
      4) Scroll down and press 'Save'.`,
  },
  {
    toDo: 'Heirs Prepare and Practice',
    description: `Heirs prepare for the event by arranging their target bids for auctions or draft rankings for drafts. To practice live events and to get a sense for the event rules, click the 'Practice' button in Family HQ.`,
    // description: ``,
    videoURL: 'https://www.youtube.com/embed/tg80xBxgeUw',
    writtenInstructions: 
    `1) Navigate to your 'My Planning' space and select the planning tab.
      2a) If it's an auction, assign all of your available 1000 points to various items. These will be your bids for auto-auctions and your target bids for live auctions.
      2b) If it's a draft, pre-rank the items in order from most-to-least desired.
      3) In Family HQ, click the 'Practice' button to get the hang of how the draft/auction works. Here, in the 'Practice' space, you will be bidding/drafting against robot versions of other heirs, not real people.
      4) Adjust your event planning as necessary.
      5) Mark yourself as 'Ready' when you feel prepared.
      6) When all heirs have marked themselves as ready, the event can be executed.`,
  },
  {
    toDo: 'Execute Event',
    description: `The estate owner, executor, or anyone with 'admin' credentials executes an auto event or starts a live event.`,
    // description: ``,
    videoURL: 'https://www.youtube.com/embed/9VV8CZUBMNU',
    writtenInstructions: 
    `1) The estate 'Owner' adds all items to the estate and marks 'All Items Added'
      2) Heirs prepare for the auction or draft and then mark themselves as ready for the event.
      3a) For auto-events the owner will press the 'Execute Auction' or 'Execute Draft' button, and items will be allotted instantaneously based on the heirs' pre-event planning.
      3b) For live events, the owner clicks the 'Start' button when everyone has arrived in the event space, and the events will continue until completion.
      4) When the event has finished, the results will exist as a ledger, giving everyone peace of mind.`,
  },
  {
    toDo: 'Finalize Estate',
    // description: `Make sure everyone is happy, and do whatever last minute reshuffling is needed. Then show the results to your estate lawyer and have them create the necessary legal documents to make everything final.`,
    description: ``,
    videoURL: '',
    writtenInstructions: 
    `1) Make sure everyone is happy, and do whatever last minute reshuffling is needed.
      2) Show the results to your estate lawyer and have them create the necessary legal documents to make everything final.`,
  }
]

export const sheirChecklistAllocateAll = [
  {
    toDo: 'Add First Item',
    description: `The estate owner, the asset manager, and anyone with 'write' privileges adds items to the estate by pressing the 'Add Item' Button in the Items Index`,
    // description: ``,
    videoURL: 'https://www.youtube.com/embed/T-K1MJn07Ts',
    writtenInstructions: 
    `1) Navigate to the 'Items' tab in your estate.
      2) Click 'Add Item' to add an item, or click the pencil icon next to an item to edit an existing item.
      3) Input the item's details.
      4) Scroll down and press 'Save'.`,
  },
  {
    toDo: 'Assign All Items',
    description: `Added items remain unassigned. Make sure to assign all items by navigating to the Items Index tab, clicking the pencil icon in the upper right corner of all unassigned items, editing the 'Allotted To' value, and clicking save.`,
    // description: ``,
    videoURL: 'https://www.youtube.com/embed/tg80xBxgeUw',
    writtenInstructions: 
    `1) Navigate to the 'Items Index' tab.
      2) Click the pencil icon in the upper right corner of all unassigned items.
      3) Edit the 'Allotted To' value.
      4) Click 'Save'.`,
  },
  {
    toDo: 'Even Out Allotments',
    description: `Make sure no heirs exceed their allotments by navigating to 'The Pot' tab and scrolling through each heir's total/allotted/remaining details. Edit allotments if any evening out needs to be done.`,
    // description: ``,
    videoURL: '',
    writtenInstructions: 
    `1) Navigating to 'The Pot' tab and scrolling through each heir's total/allotted/remaining details'
      2) Edit allotments if any evening out needs to be done.`,
  },
  {
    toDo: 'Finalize Estate',
    // description: `Make sure everyone is happy, and do whatever last minute reshuffling is needed. Then show the results to your estate lawyer and have them create the necessary legal documents to make everything final.`,
    description: ``,
    videoURL: '',
    writtenInstructions: 
    `1) Make sure everyone is happy, and do whatever last minute reshuffling is needed.
      2) Show the results to your estate lawyer and have them create the necessary legal documents to make everything final.`,
  }
]

// Permitted Country Codes — https://www.iban.com/country-codes
export const permittedCountryCodes = [
  'US', // United States
  'FR', // France
  'AU', // Australia
  'AT', // Austria
  'BE', // Belgium
  'CA', // Canada
  'DK', // Denmark
  'FI', // Finland
  'DE', // Germany
  'GR', // Greece
  'HK', // Hong Kong
  'IE', // Ireland
  'IL', // Israel
  'IT', // Italy
  'JP', // Japan
  'LI', // Liechtenstein
  'LU', // Luxembourg
  'MX', // Mexico
  'NL', // The Netherlands
  'NZ', // New Zealand
  'NO', // Norwary
  'PL', // Poland
  'PT', // Portugal
  'PR', // Puerto Rico
  'SG', // Singapore
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'GB', // Great Britain

  'AF', // Afghanistan
  'AL', // Albania
  'DZ', // Algeria
  'AM', // American Samoa
  'AD', // Andorra
  'AO', // Angola
  'AI', // Anguilla
  'AQ', // Antarctica
  'AG', // Antigua and Barbuda
  'AR', // Argentina
  'AM', // Armenia
  'AW', // Aruba
  'AZ', // Azerbaijan
  'BS', // The Bahamas
  'BH', // Bahrain
  'BD', // Bangladesh
  'BB', // Barbados
  'BY', // Belarus
  'BZ', // Belize
  'BJ', // Benin
  'BM', // Bermuda
  'BT', // Bhutan
  'BO', // Bolivia
  'BQ', // Bonaire
  'BA', // Bosnia and Herzegovina
  'BW', // Botswana
  'BV', // Bouvet Island
  'IO', // British Indian Ocean Territory
  'BN', // Brunei
  'BG', // Bulgaria
  'BF', // Burkina Faso
  'BI', // Burundi
  'CV', // Cabo Verde
  'KH', // Cambodia
  'CM', // Camaroon
  'KY', // the Cayman Islands
  'CF', // Central African Republic
  'TD', // Chad
  'CL', // Chile
  'CN', // China
  'CX', // Christmas Island
  'CC', // The Cocos Islands
  'CO', // Colombia
  'KM', // The Comoros
  'CD', // Democratic Republic of Congo
  'CG', // Congo
  'CK', // The Cook Islands
  'CR', // Costa Rica
  'HR', // Croatia
  'CU', // Cuba
  'CW', // Curacao
  'CY', // Cyprus
  'CZ', // Czech Republic
  'CI', // Ivory Coast
  'DJ', // Djibouti
  'DM', // Dominica
  'DO', // The Dominican Republic
  'EC', // Ecuador
  'EG', // Egypt
  'SV', // El Salvador
  'GQ', // Equatorial Guinea
  'ER', // Eritrea
  'EE', // Estonia
  'SZ', // Eswatini
  'ET', // Ethiopia
  'FK', // Falkland Islands
  'FO', // Faroe Islands
  'FJ', // Fiji
  'GF', // French Guiana
  'PF', // French Polynesia
  'TF', // French Southern Territories
  'GA', // Gabon
  'GM', // Gambia
  'GE', // Georgia
  'GH', // Ghana
  'GI', // Gibraltar
  'GL', // Greenland
  'GD', // Grenada
  'GP', // Guadeloupe
  'GU', // Guam
  'GT', // Guatemala
  'GG', // Guernsey
  'GN', // Guinea
  'GW', // Guinea-Bissau
  'GY', // Guyana
  'HT', // Haiti
  'HM', // Heard Island and McDonald Islands
  'VA', // The Holy See
  'HN', // Honduras
  'HU', // Hungary
  'IS', // Iceland
  'IN', // India
  'ID', // Indonesia
  'IR', // Iran
  'IQ', // Iraq
  'IM', // Isle of Man
  'JM', // Jamaica
  'JE', // Jersey
  'JO', // Jordan
  'KZ', // Kazakhstan
  'KE', // Kenya
  'KI', // Kiribati
  // 'KP', // North Korea
  'KR', // South Korea
  'KW', // Kuwait
  'KG', // Kyrgyzstan
  'LA', // Laos
  'LV', // Latvia
  'LB', // Lebanon
  'LS', // Lesotho
  'LR', // Liberia
  'LY', // Libya
  'LT', // Lithuania
  'MO', // Macao
  'MG', // Madagascar
  'MW', // Malawi
  'MY', // Malaysia
  'MV', // The Maldives
  'ML', // Mali
  'MT', // Malta
  'MH', // The Marshall Islands
  'MQ', // Martinique
  'MR', // Mauritania
  'MU', // Mauritius
  'YT', // Mayotte
  'FM', // Micronesia
  'MD', // Maldova
  'MC', // Monaco
  'MN', // Mongolia
  'ME', // Montenegro
  'MS', // Montserrat
  'MA', // Morocco
  'MZ', // Mozambique
  'MM', // Myanmar
  'NA', // Namibia
  'NR', // Nauru
  'NP', // Nepal
  'NC', // New Caledonia
  'NI', // Nicaragua
  'NE', // Niger
  'NG', // Nigeria
  'NU', // Niue
  'NF', // Norfolk Island
  'MP', // Northern Mariana Islands
  'OM', // Oman
  'PK', // Pakistan
  'PW', // Palau
  'PS', // Palestine
  'PA', // Panama
  'PG', // Papua New Guinea
  'PY', // Paraguay
  'PE', // Peru
  'PH', // The Philippines
  'PN', // Pitcairn
  'QA', // Qatar
  'MK', // Macedonia
  'RO', // Romania
  // 'RU', // Russia
  'RW', // Rwanda
  'RE', // Reunion
  'BL', // Saint Barthelemy
  'SH', // Saint Helena
  'KN', // Saint Kitts and Nevis
  'LC', // Saint Lucia
  'MF', // Saint Martin
  'PM', // Saint Pierre and Miquelon
  'VC', // Saint Vincent and the Grenadines
  'WS', // Samoa
  'SM', // San Marino
  'ST', // Sao Tome and Principe
  'SA', // Saudi Arabia
  'SN', // Senegal
  'RS', // Serbia
  'SC', // Seychelles
  'SL', // Sierra Leone
  'SX', // Sint Maarten
  'SK', // Slovakia
  'SI', // Slovenia
  'SB', // Solomon Islands
  'SO', // Somalia
  'ZA', // South Africa
  'GS', // South Georgia and the Sandwich Islands
  'SS', // South Sudan
  'LK', // Sri Lanka
  'SD', // Sudan
  'SR', // Suriname
  'SJ', // Svalbard
  'SY', // Syria
  'TW', // Taiwan
  'TJ', // Tajikistan
  'TZ', // Tanzania
  'TH', // Thailand
  'TL', // Timor-Leste
  'TG', // Togo
  'TK', // Tokelau
  'TO', // Tonga
  'TT', // Trinidad and Tobago
  'TN', // Tunisia
  'TR', // Turkey
  'TM', // Turkmenistan
  'TC', // Turks and Caicos
  'TV', // Tuvalu
  'UG', // Uganda
  'UA', // Ukraine
  'AE', // United Arab Emirates
  'UM', // United States Minor Outlying Islands
  'UY', // Uruguay
  'UZ', // Uzbekistan
  'VU', // Vanuatu
  'VE', // Venezuela
  'VN', // Vietnam
  'VG', // British Virgin Islands
  'VI', // US Virgin Islands
  'WF', // Wallis and Futuna
  'EH', // Western Sahara
  'YE', // Yemen
  'ZM', // Zambia
  'ZW', // Zimbabwe
  'AX', // Aland Islands
  ''
]