/* eslint-disable no-prototype-builtins */
import { getKeyByValue, removeDuplicatesFromArray } from './globalHelpers'
import { itemCategories } from './variableDefaults'


// ! General Estate

// Retrieve all Unassigned Items in an estate
export const getUnassignedItems = (estate) => {
  return estate.items.filter(item => item.allottedToName === 'Unassigned')
}

// Retrieve the event results from the estate object
export const getEventResults = (estate) => {
  // console.log('gdr estate ->', estate)
  return estate.event[0].results
}

// Estate Total Value
// Sums up the value of the inputted Items array, so it could be used with a subset of the estate items, not always the entire estate
export const getEstateTotalValue = (estateItemsArray) => {
  if (estateItemsArray) {

    return estateItemsArray.reduce((sum, estateItem) => {
      return sum + Math.floor(estateItem.valuation)
    }, 0)

  } else {
    return 0
  }
  
}

// Estate Total Value Minus Fees, Taxes, and Gifts
export const getEstateTotalMinusFeesTaxesGifts = (estate) => {
  
  return getEstateTotalValue(estate.items) - Math.floor(getTotalFees(estate.people)) - Math.floor(getTotalGifts(estate.items)) - Math.floor(estate.totalTaxes)
}

// Estate Total Value Minus Gifts
export const getEstateTotalMinusGifts = (estate) => {
  
  return getEstateTotalValue(estate.items) - Math.floor(getTotalGifts(estate.items))
}

// Total Value of Fees in Estate
export const getTotalFees = (personsArray) => {
  if (personsArray) {

    return personsArray.reduce((sum, person) => {
      return sum + Math.floor(person.fees)
    }, 0)

  } else {
    return 0
  }
}

// Total Value of gifts in Estate
export const getTotalGifts = (itemsArray) => {
  // console.log('getTotalGifts runs')
  if (itemsArray && itemsArray.filter(item => item.allottedToName === 'Gift').length > 0) {

    return itemsArray.filter(item => item.allottedToName === 'Gift').reduce((sum, item) => {
      return sum + Math.floor(item.valuation)
    }, 0)

  } else {
    return 0
  }

}

// Total Heir Share
export const getHeirShareTotal = (estate, sharePercentage) => {
  return Math.floor(getEstateTotalMinusFeesTaxesGifts(estate) * (parseFloat(sharePercentage) / 100))
}

// Total Sum of Valuations Currently allotted to an Heir (by person)
export const getHeirAllotmentsTotal = (person) => {
  // console.log('person ->', person.allotments.length)

  return person.allotments.reduce((sum, estateItem) => {
    return sum + Math.floor(estateItem.valuation)
  }, 0)
}

// Total Sum of Valuations Currently allotted to an Heir (by estate)
export const getHeirAllotmentsTotalByEstate = (estate, person) => {
  // console.log('person ->', person.allotments.length)

  return estate.items.filter(item => item.hasOwnProperty('allottedToPersonId') && item.allottedToPersonId === person._id).reduce((sum, estateItem) => {
    return sum + Math.floor(estateItem.valuation)
  }, 0)
}

// Retrieve a Person in an estate from an Id
export const getPersonInEstateFromId = (estate, personId) => {
  return estate.people.filter(person => person._id === personId)[0]
}

// Retrieve a Person in an estate from a name
export const getPersonInEstateFromName = (estate, personName) => {
  // console.log('personName ->', personName)
  // console.log('person from name ->', estate.people.filter(person => person.userGivenAndFamilyName === personName)[0])

  // console.log('person name filtered ->', personName && estate.people.filter(person => person.userGivenAndFamilyName === personName))
  // console.log('length of array ->', personName && estate.people.filter(person => person.userGivenAndFamilyName === personName).length > 0)
  // console.log('index 0 value ->', personName && estate.people.filter(person => person.userGivenAndFamilyName === personName)[0])

  return personName && estate.hasOwnProperty('people') && estate.people.filter(person => person.userGivenAndFamilyName === personName).length > 0 ? estate.people.filter(person => person.userGivenAndFamilyName === personName)[0] : ''
}

// Retrieve all unassigned items with a valuation under a Person's remaining allotment
export const getUnassignedItemsWithValuationUnderRemaining = (estate, person) => {
  // console.log('person ->', person.userGivenAndFamilyName)
  // console.log('estate ->', estate.estateName)
  return estate.items.filter(item => item.allottedToName === 'Unassigned' && getRemainingGreaterThanValuation(item, estate, person))
}

// Boolean — returns true if remaining share is greater than an Item's valuation
export const getRemainingGreaterThanValuation = (item, estate, person) => {
  // console.log('estate ->', estate)
  // console.log('person ->', person.userGivenAndFamilyName)
  // console.log('item ->', item.itemName)
  // console.log('valuation ->', Math.floor(item.valuation))
  // console.log('remaining $ ->', getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person))
  // console.log('heir share > remaining ->', (getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person)) >= Math.floor(item.valuation))
  return (getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person)) >= Math.floor(item.valuation)
}

// Retrieve the index of an Item in an estate
export const getIndexOfItemInEstate = (estate, item) => {
  return estate.items.map(e => e._id).indexOf(item._id)
}

// Boolean — true if all heirs are ready for the event; false if not
export const getAllHeirsAreReady = (persons) => {
  for (let i = 0; i < persons.length; i++) {
    if (persons[i].role === 'Heir' && !persons[i].isReadyForEvent) {
      return false
    }
  }
  return true
}

export const getAllHeirsWithinAllotment = (estate) => {
  
  if (estate.hasOwnProperty('people') && estate.people.length > 0 && estate.people.filter(person => person.role === 'Heir').length > 0) {
    const heirPersons = estate.people.filter(person => person.role === 'Heir')

    for (let i = 0; i < heirPersons.length; i++) {

      const remainingShare = Math.floor(getHeirShareTotal(estate, heirPersons[i].sharePercentage) - getHeirAllotmentsTotalByEstate(estate, heirPersons[i]))

      if (remainingShare < 0) {
        return false
      }
    }
  }
  
  return true
}


// ! Auction-Specific

// Retrieve the highest bid on an item in an auction
export const getHighestBid = (item) => {
  // console.log('item.bidHistory ->', item.bidHistory)
  return Object.values(item.bidHistory).sort((a, b) => b - a)[0]
}

// Retrieve a specific Person's bid on an item
export const getUserBid = (item, userPersonId) => {
  // console.log('item ->', item)
  // console.log('item.bidHistory ->', item.bidHistory)
  // console.log('item.bidHistory[userPersonId] ->', item.bidHistory[userPersonId])
  return item.bidHistory[userPersonId]
}

// Retrieve the target bid for a Person
export const getUserTargetBid = (item, userPersonId) => {
  return item.heirAuctionPlanning[userPersonId]
}

// Retrieve the Id of the highest bidder
export const getHighestBidderId = (bidHistoryObj, highestBid) => {
  // console.log('bidHistoryArray ->', bidHistoryObj)
  // console.log('highestBid ->', highestBid)
  let highestBidder
  const keysArray = Object.keys(bidHistoryObj)
  for (let i = 0; i < keysArray.length; i++) {
    // console.log('bidHistoryObj[keysArray[i]] ->', bidHistoryObj[keysArray[i]])
    if (bidHistoryObj[keysArray[i]] === highestBid) {
      highestBidder = keysArray[i]
    }
  }
  // console.log('highest bidder ->', highestBidder)
  return highestBidder
}

// Total Auction Planning Points allotted
export const getHeirAuctionPlanningPointsAllottedTotal = (estate, personId) => {

  return estate.items.reduce((sum, estateItem) => {
    return sum + Math.floor(estateItem['heirAuctionPlanning'][personId])
  }, 0)
}

// Total allotted points by estate items 
// For items allotted to the specific Person
export const getAllottedPointsByEstate = (estate, person) => {
  return estate.items.filter(item => item.allottedToPersonId === person._id).reduce((sum, estateItem) => {
    return sum + Math.floor(estateItem.bidHistory[person._id])
  }, 0)
}

// Total allotted points by estate items 
// For ALL items in the estate
export const getTotalAllottedPointsByEstate = (estate, person) => {
  return estate.items.reduce((sum, estateItem) => {
    return sum + Math.floor(estateItem.bidHistory[person._id])
  }, 0)
}

// Points remaining in an auto-auction by Items
export const getAutoAuctionPointsRemainingByItems = (itemsArray, person) => {
  return itemsArray.reduce((sum, estateItem) => {
    return sum + Math.floor(estateItem.bidHistory[person._id])
  }, 0)
}

// Valuation of allotments before current auction position
export const getBeforeAtAuctionPosition = (estate, person, blockNumber) => {

  const myAuctionResults = getEventResults(estate).filter(item => item.allottedToPersonId === person._id)

  const itemsInCurrentAndFutureBlocks = myAuctionResults.filter((item, itemIndex) => {
    // console.log('draft round ->', draftRound)
    // console.log('item index ->', itemIndex)
    const indexOnResultsArray = getEventResults(estate).map(e => e._id).indexOf(item._id)

    return indexOnResultsArray + 1 >= blockNumber
  })

  // console.log('itemsInCurrentAndFutureBlocks ->', itemsInCurrentAndFutureBlocks)
  const currentAndFutureAuctionBlockValuations = itemsInCurrentAndFutureBlocks.length === 0 ? 0 : getEstateTotalValue(itemsInCurrentAndFutureBlocks)

  return getHeirAllotmentsTotalByEstate(estate, person) - currentAndFutureAuctionBlockValuations
}

// Valuation of allotments after current auction position
export const getAfterAtAuctionPosition = (estate, person, blockNumber) => {
  
  const myAuctionResults = getEventResults(estate).filter(item => item.allottedToPersonId === person._id)

  const itemsInFutureBlocks = myAuctionResults.filter((item, itemIndex) => {
    // console.log('draft round ->', draftRound)
    // console.log('item index ->', itemIndex)
    const indexOnResultsArray = getEventResults(estate).map(e => e._id).indexOf(item._id)

    return indexOnResultsArray + 1 > blockNumber
  })
  // console.log('itemsInCurrentAndFutureBlocks ->', itemsInCurrentAndFutureBlocks)

  const totalValuationOfItemsInFutureBlocks = itemsInFutureBlocks.length === 0 ? 0 : getEstateTotalValue(itemsInFutureBlocks)

  return getHeirAllotmentsTotalByEstate(estate, person) - totalValuationOfItemsInFutureBlocks
}

// Remaining heir share after current auction position
export const getRemainingAtAuctionPosition = (estate, person, blockNumber) => {

  const myAuctionResults = getEventResults(estate).filter(item => item.allottedToPersonId === person._id)
  // console.log('draftRound ->', draftRound)
  // console.log('myDraftResults ->', myDraftResults)

  const itemsInFutureBlocks = myAuctionResults.filter((item, itemIndex) => {
    // console.log('draft round ->', draftRound)
    // console.log('item index ->', itemIndex)
    const indexOnResultsArray = getEventResults(estate).map(e => e._id).indexOf(item._id)

    return indexOnResultsArray + 1 > blockNumber
  })
  // console.log('itemsInFutureBlocks ->', itemsInFutureBlocks)

  const totalValuationOfItemsInFutureBlocks = itemsInFutureBlocks.length === 0 ? 0 : getEstateTotalValue(itemsInFutureBlocks)
  // console.log('ValuationOfItemsInFutureRounds ->', totalValuationOfItemsInFutureRounds)
  
  return getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person) + totalValuationOfItemsInFutureBlocks
}

// Remaining points at auto-auction position
export const getRemainingPointsAtAutoAuctionPosition = (estate, person, blockNumber) => {

  // const myAuctionResults = getEventResults(estate).filter(item => item.allottedToPersonId === person._id)
  // console.log('draftRound ->', draftRound)
  // console.log('myDraftResults ->', myDraftResults)

  const itemsInFutureBlocks = getEventResults(estate).filter((item, itemIndex) => {
    // console.log('draft round ->', draftRound)
    // console.log('item index ->', itemIndex)
    // const indexOnResultsArray = getEventResults(estate).map(e => e._id).indexOf(item._id)

    return itemIndex + 1 > blockNumber
  })
  // console.log('itemsInFutureBlocks ->', itemsInFutureBlocks)

  const totalPointsOfItemsInFutureBlocks = itemsInFutureBlocks.length === 0 ? 0 : getAutoAuctionPointsRemainingByItems(itemsInFutureBlocks, person)
  // console.log('ValuationOfItemsInFutureRounds ->', totalValuationOfItemsInFutureRounds)
  
  return 1000 - getTotalAllottedPointsByEstate(estate, person) + totalPointsOfItemsInFutureBlocks
}

// Boolean — true if points remaining is greater than the bid; false if not
export const getPointsRemainingGreaterThanBid = (bid, estate, person, item) => {
  // console.log('person', person.userGivenAndFamilyName)
  // console.log('points remaining ->', 1000 - getTotalAllottedPointsByEstate(estate, person))
  // console.log('points remaining - bid ->', 1000 - getTotalAllottedPointsByEstate(estate, person) - (bid - item.bidHistory[person._id]))
  // console.log('bid ->', bid)
  // console.log('points remaining >= bid ->', (1000 - getTotalAllottedPointsByEstate(estate, person)) >= bid)
  // console.log('points remaining - bid >= 0 ->', (1000 - getTotalAllottedPointsByEstate(estate, person)) - (bid - item.bidHistory[person._id]) >= 0)
  
  return (1000 - getTotalAllottedPointsByEstate(estate, person)) - (bid) >= 0
  // return (1000 - getTotalAllottedPointsByEstate(estate, person)) + item.bidHistory[person._id] >= bid
}

// Boolean — true if points remaining is greater than the bid; false if not
export const getPointsRemainingGreaterThanBidCustom = (bid, estate, person, item) => {
  // console.log('person', person.userGivenAndFamilyName)
  // console.log('points remaining ->', 1000 - getTotalAllottedPointsByEstate(estate, person))
  // console.log('points remaining - bid ->', 1000 - getTotalAllottedPointsByEstate(estate, person) - (bid))
  // console.log('bid ->', bid)
  // console.log('points remaining >= bid ->', (1000 - getTotalAllottedPointsByEstate(estate, person)) >= bid)
  // console.log('points remaining - bid >= 0 ->', (1000 - getTotalAllottedPointsByEstate(estate, person)) - (bid) >= 0)
  // console.log('return value ->', (1000 - getTotalAllottedPointsByEstate(estate, person)) - (bid) >= 0)
  
  return (1000 - getTotalAllottedPointsByEstate(estate, person)) - (bid - item.bidHistory[person._id]) >= 0
  // return (1000 - getTotalAllottedPointsByEstate(estate, person)) - (bid) >= 0
  // return (1000 - getTotalAllottedPointsByEstate(estate, person)) + item.bidHistory[person._id] >= bid
}


// ! Draft-Specific

// Retrieve the Person drafting
export const getPersonDrafting = (estate, draftOrderPersons) => {
  // console.log('is odd round ->', Math.floor( getEventResults().length / draftOrderPersons.length) % 2 === 0)
  // console.log('odd round draft order person index ->', getEventResults().length % draftOrderPersons.length)
  // console.log('even round draft order person index ->', (draftOrderPersons.length - 1) - (getEventResults().length % draftOrderPersons.length))

  // console.log('gpd estate ->', estate)
  // console.log('gpd draftOrderPersons ->', draftOrderPersons)
  // console.log('getEventResults(estate) ->', getEventResults(estate).length === 0)
  return Math.floor(getEventResults(estate).length / draftOrderPersons.length) % 2 === 0 ? draftOrderPersons[getEventResults(estate).length % draftOrderPersons.length] : draftOrderPersons[(draftOrderPersons.length - 1) - (getEventResults(estate).length % draftOrderPersons.length)]
}

// Retrieve the index of the person drafting on the draftOrderPersons array
export const getPersonDraftingIndex = (resultsIndex, draftOrderPersons) => {
  return Math.floor((resultsIndex + 1) % draftOrderPersons.length === 0 ? draftOrderPersons.length : (resultsIndex + 1) % draftOrderPersons.length)
}

// Retrieve the Person drafting at a specific position in the draft
export const getPersonAtDraftPosition = (draftRound, personIndexPlusOne, draftOrderPersons) => {

  // console.log('gpd estate ->', estate)
  // console.log('gpd draftOrderPersons ->', draftOrderPersons)
  return draftOrderPersons[draftRound % 2 > 0 ? personIndexPlusOne - 1 : draftOrderPersons.length - personIndexPlusOne]
}

// Get allotments value before draft pick
export const getBeforeAtDraftPosition = (estate, personDrafting, draftRound) => {

  const myDraftResults = getEventResults(estate).filter(item => item.allottedToPersonId === personDrafting._id)

  const itemsInCurrentAndFutureRounds = myDraftResults.filter((item, itemIndex) => {
    // console.log('draft round ->', draftRound)
    // console.log('item index ->', itemIndex)
    return itemIndex + 1 >= draftRound
  })

  // console.log('itemsInCurrentAndFutureRounds ->', itemsInCurrentAndFutureRounds)
  const currentAndFutureDraftRoundValuations = itemsInCurrentAndFutureRounds.length === 0 ? 0 : getEstateTotalValue(itemsInCurrentAndFutureRounds)

  return getHeirAllotmentsTotalByEstate(estate, personDrafting) - currentAndFutureDraftRoundValuations
}

// Get allotments value after draft pick
export const getAfterAtDraftPosition = (estate, personDrafting, draftRound) => {
  
  const myDraftResults = getEventResults(estate).filter(item => item.allottedToPersonId === personDrafting._id)

  const itemsInFutureRounds = myDraftResults.filter((item, itemIndex) => {
    // console.log('draft round ->', draftRound)
    // console.log('item index ->', itemIndex)
    return itemIndex + 1 > draftRound
  })
  // console.log('itemsInFutureRounds ->', itemsInFutureRounds)

  const totalValuationOfItemsInFutureRounds = itemsInFutureRounds.length === 0 ? 0 : getEstateTotalValue(itemsInFutureRounds)

  return getHeirAllotmentsTotalByEstate(estate, personDrafting) - totalValuationOfItemsInFutureRounds
}

// Get remaining share after draft pick
export const getRemainingAtDraftPosition = (estate, personDrafting, draftRound) => {

  const myDraftResults = getEventResults(estate).filter(item => item.allottedToPersonId === personDrafting._id)
  // console.log('draftRound ->', draftRound)
  // console.log('myDraftResults ->', myDraftResults)

  const itemsInFutureRounds = myDraftResults.filter((item, itemIndex) => {
    // console.log('draft round ->', draftRound)
    // console.log('item index ->', itemIndex)
    return itemIndex + 1 > draftRound
  })
  // console.log('itemsInFutureRounds ->', itemsInFutureRounds)

  const totalValuationOfItemsInFutureRounds = itemsInFutureRounds.length === 0 ? 0 : getEstateTotalValue(itemsInFutureRounds)
  // console.log('ValuationOfItemsInFutureRounds ->', totalValuationOfItemsInFutureRounds)
  
  return getHeirShareTotal(estate, personDrafting.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, personDrafting) + totalValuationOfItemsInFutureRounds
}

// Boolean — true if one person can still draft; false if not
export const getAtLeastOnePersonCanStillDraft = (estate, draftOrderPersons) => {
  // console.log('person ->', person.userGivenAndFamilyName)
  // console.log('item ->', item.itemName)
  // console.log('valuation ->', Math.floor(item.valuation))
  // console.log('remaining $ ->', getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person))
  // console.log('heir share > remaining ->', (getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person)) >= Math.floor(item.valuation))
  for (let i = 0; i < getUnassignedItems(estate).length; i++) {
    for (let x = 0; x < draftOrderPersons.length; x++) {
      if ((getHeirShareTotal(estate, draftOrderPersons[x].sharePercentage) - getHeirAllotmentsTotalByEstate(estate, draftOrderPersons[x])) >= Math.floor(getUnassignedItems(estate)[i].valuation)) {
        return true
      }
    }
  }
  return false
}



// ! Pie Charts

// Get { argument: 'Title', Value: 'XX.XX' } for category and percent makeup of category in pie charts
export const categoryPercentOfTotal = (person, estate) => {
  const categoryPercentsArray = []
  let unassignedPercent = 100

  // If person has at least one Item allotted to them, filter through all Items and assign categories and percents to them
  if (estate.items.filter(item => item.hasOwnProperty('allottedToPersonId') && item.allottedToPersonId === person._id).length > 0) {
    for (let i = 0; i < itemCategories.length; i++){
      categoryPercentsArray.push({ 
        argument: itemCategories[i], 
        value: ((getEstateTotalValue(estate.items.filter(item => item.hasOwnProperty('allottedToPersonId') && item.allottedToPersonId === person._id && item.category === itemCategories[i])) / getHeirShareTotal(estate, person.sharePercentage)) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
      })
      
      unassignedPercent = unassignedPercent - parseFloat(categoryPercentsArray[i].value)
    }
  }
  
  // The value of 'Unassigned' is 100 minus all percents allotted above
  categoryPercentsArray.push({ 
    argument: 'Unassigned', 
    value: unassignedPercent.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
  })

  return categoryPercentsArray
}

// Get category percent of total except for gifts — for showing all items available to heirs
export const categoryPercentOfTotalEstateExceptGifts = (estate) => {
  const categoryPercentsArray = []
  let unassignedPercent = 100

  for (let i = 0; i < itemCategories.length; i++){
    categoryPercentsArray.push({ argument: itemCategories[i], value: ((getEstateTotalValue(estate.items.filter(item => item.category === itemCategories[i] && item.allottedToName !== 'Gift')) / getEstateTotalMinusGifts(estate)) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) })
    
    unassignedPercent = unassignedPercent - parseFloat(categoryPercentsArray[i].value)
  }

  categoryPercentsArray.push({ 
    argument: 'Unassigned', 
    value: unassignedPercent.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
  })

  return categoryPercentsArray
}

// Get the percents of the total estate allotted to Taxes, Fees, Gifts, and Heirs
export const categoryPercentsTaxesFeesGiftsHeirs = (estate) => {

  const taxes = {
    argument: 'Taxes',
    value: ((Math.floor(estate.totalTaxes) / getEstateTotalValue(estate.items)) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
  }

  const fees = {
    argument: 'Fees',
    value: ((Math.floor(getTotalFees(estate.people)) / getEstateTotalValue(estate.items)) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
  }
  // console.log('total fees ->', Math.floor(getTotalFees(estate.people)))
  // console.log('fees ->', fees)

  const gifts = {
    argument: 'Gifts',
    value: ((Math.floor(getTotalGifts(estate.items)) / getEstateTotalValue(estate.items)) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
  }
  // console.log('total gifts ->', Math.floor(getTotalGifts(estate.items)))
  // console.log('gifts ->', gifts)

  const heirs = {
    argument: 'Heirs',
    value: (100 - taxes.value - fees.value - gifts.value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
  }

  return [taxes, fees, gifts, heirs]
}

export const getTotalRemainingHeirSharesAllHeirs = (estate) => {
  
  let sumOfRemainingHeirShares = 0
  
  if (estate.hasOwnProperty('people') && estate.people.length > 0 && estate.people.filter(person => person.role === 'Heir').length > 0) {
    const heirPersons = estate.people.filter(person => person.role === 'Heir')

    for (let i = 0; i < heirPersons.length; i++) {

      sumOfRemainingHeirShares = sumOfRemainingHeirShares + Math.floor(getHeirShareTotal(estate, heirPersons[i].sharePercentage) - getHeirAllotmentsTotalByEstate(estate, heirPersons[i]))
    }
  }
  
  return sumOfRemainingHeirShares
}

export const categoryPercentOfLiquidation = (estate) => {
  
  const arrayToReturn = []

  const liquidationValue = getEstateTotalValue(estate.items.filter(item => item.allottedToName === 'Liquidate' || item.allottedToName === 'Unassigned'))

  const taxes = {
    argument: 'Taxes',
    value: ((Math.floor(estate.totalTaxes) / liquidationValue) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
  }
  arrayToReturn.push(taxes)

  const fees = {
    argument: 'Fees',
    value: ((Math.floor(getTotalFees(estate.people)) / liquidationValue) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
  }
  arrayToReturn.push(fees)

  if (estate.hasOwnProperty('people') && estate.people.length > 0 && estate.people.filter(person => person.role === 'Heir').length > 0) {
    const heirPersons = estate.people.filter(person => person.role === 'Heir')

    for (let i = 0; i < heirPersons.length; i++) {
      const heirObj = {
        argument: heirPersons[i].userGivenAndFamilyName,
        value: (((getHeirShareTotal(estate, heirPersons[i].sharePercentage) - getHeirAllotmentsTotalByEstate(estate, heirPersons[i])) / liquidationValue) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      }

      arrayToReturn.push(heirObj)
    }
  }
  
  return arrayToReturn
}

export const categoryPercentOfGifts = (estate) => {
  
  const arrayToReturn = []

  const giftsArray = estate.items.filter(item => item.allottedToName === 'Gift')

  const giftsTotalValue = getEstateTotalValue(giftsArray)

  if (giftsArray.length > 0 && giftsTotalValue > 0) {

    const giftRecipientsNoDuplicatesArray = removeDuplicatesFromArray(giftsArray.map(item => item.giftFor.length > 0 ? item.giftFor : 'NA'))

    for (let i = 0; i < giftRecipientsNoDuplicatesArray.length; i++) {
      const giftRecipientObj = {
        argument: giftRecipientsNoDuplicatesArray[i],
        value: ((getEstateTotalValue(giftsArray.filter(item => item.giftFor === giftRecipientsNoDuplicatesArray[i])) / giftsTotalValue) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      }

      arrayToReturn.push(giftRecipientObj)
    }
  }
  
  return arrayToReturn
}