import { shuffleArray } from '../../../helpers/globalHelpers.js'

const oneYearFromNow = () => {
  const oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  return oneYearFromNow
} 

const now = new Date()


export const alexSpiliyachtesEvent = {
  _id: 'alexSpiliyachtesEventId',
  estateId: 'alexSpiliyachtesEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['brinkerSpiliyachtesPersonId', 'eatonSpiliyachtesPersonId', 'mariaSpiliyachtesPersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const jayGatsbyEvent = {
  _id: 'jayGatsbyEventId',
  estateId: 'jayGatsbyEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['nickCarrawayPersonId', 'daisyBuchananPersonId', 'owlEyesPersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const blancheDevereauxEvent = {
  _id: 'blancheDevereauxEventId',
  estateId: 'blancheDevereauxEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['dorothyZbornakPersonId', 'sophiaPetrilloPersonId', 'roseNylundPersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const cosmoKramerEvent = {
  _id: 'cosmoKramerEventId',
  estateId: 'cosmoKramerEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['georgeCostanzaPersonId', 'elaineBenesPersonId', 'jerrySeinfeldPersonId', 'newmanPersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const hollyGolightlyEvent = {
  _id: 'hollyGolightlyEventId',
  estateId: 'hollyGolightlyEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['catPersonId', 'doePersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const johnLennonEvent = {
  _id: 'johnLennonEventId',
  estateId: 'johnLennonEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['ringoStarrPersonId', 'georgeHarrisonPersonId', 'paulMcCartneyPersonId', 'yokoOnoPersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const albusDumbledoreEvent = {
  _id: 'albusDumbledoreEventId',
  estateId: 'albusDumbledoreEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['harryPotterPersonId', 'ronWeasleyPersonId', 'hermioneGrangerPersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const lucyRicardoEvent = {
  _id: 'lucyRicardoEventId',
  estateId: 'lucyRicardoEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['littleRickyPersonId', 'littleLucyPersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const marilynMonroeEvent = {
  _id: 'marilynMonroeEventId',
  estateId: 'marilynMonroeEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['mrPresidentPersonId', 'joeDiMaggioPersonId', 'frankSinatraPersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const jacquelineKennedyOnassisEvent = {
  _id: 'jacquelineKennedyOnassisEventId',
  estateId: 'jacquelineKennedyOnassisEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['johnFKennedyJrPersonId', 'carolineKennedyPersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const bruceWayneEvent = {
  _id: 'bruceWayneEventId',
  estateId: 'bruceWayneEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['clarkKentPersonId', 'peterParkerPersonId', 'jamesBondPersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const mrMonopolyEvent = {
  _id: 'mrMonopolyEventId',
  estateId: 'mrMonopolyEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['shoePersonId', 'hatPersonId', 'ironPersonId', 'carPersonId', 'thimblePersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}

export const homerSimpsonEvent = {
  _id: 'homerSimpsonEventId',
  estateId: 'homerSimpsonEstateId',
  startDate: now,
  hasStarted: true,
  hasBegunTimer: false,
  hasFinished: false,
  allItemsAdded: true,
  liveOrAuto: 'Auto',
  distributionMethod: 'Allocate All',
  silentOrParticipatory: 'Silent',
  draftOrder: ['bartSimpsonPersonId', 'lisaSimpsonPersonId', 'maggieSimpsonPersonId'],
  results: [],
  messageBoard: [],
  connectedPersons: [],
  timer: { 
    seconds: -1, 
    minutes: -1, 
  },
}