/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import axios from 'axios'

import useWindowDimensions from '../../helpers/windowDimensions'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { navbarHeight, estateNavbarHeight, footerHeight, familyHQTabs, positionChangeWidthSm, positionChangeWidthMd, sheirBlue } from '../../helpers/variableDefaults'
import { allotmentNumberElements } from '../../helpers/heirPersonSpaceTabs'
import { getEstateTotalValue, getTotalFees, getTotalGifts, categoryPercentsTaxesFeesGiftsHeirs, getHeirShareTotal, getHeirAllotmentsTotalByEstate, getTotalRemainingHeirSharesAllHeirs } from '../../helpers/getValuationTotals'
import { pieChartOfCategoryPercents } from '../../helpers/pieCharts'
import { heirAllotmentsTab } from '../../helpers/heirPersonSpaceTabs'
import { couldNotLoadPageError } from '../../helpers/errors'
import { removeDuplicatesFromArray } from '../../helpers/globalHelpers'
import { standardInfoTooltip } from '../../helpers/tooltips'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// The Pot Page
const ThePot = (props) => {

  // Destructure Props
  const { isTestEstate, testEstate } = props
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId } = useParams()

  // Payload
  const payload = getPayload()

  // Estate
  const [estate, setEstate] = useState({})

  // Pie Chart
  const [hover, setHover] = useState(null)

  // Loading
  const [loading, setLoading] = useState(false)

  // Errors
  const [errors, setErrors] = useState(false)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {

    if (isTestEstate) {
      setEstate(testEstate)
    } else if (!userIsAuthenticated()) {
      navigate('/')
    }  else {
      const getData = async () => {
        setLoading(true)
        try {
          const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved Estate ->', retrievedEstate)

          const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)

          if (userIsInEstate.length > 0 && retrievedEstate.subscriptionIsActive && (userIsInEstate[0].role !== 'Heir' || (retrievedEstate.hasOwnProperty('event') && retrievedEstate.event[0].hasOwnProperty('silentOrParticipatory') && retrievedEstate.event[0].silentOrParticipatory === 'Participatory'))) {
            // console.log('userIsInEstate people ->', retrievedEstate.people)
            // Set States
            setEstate(retrievedEstate)

            setErrors(false)
            setLoading(false)
          } else {
            navigate(`/user/${payload.sub}`)
            setErrors(false)
            setLoading(false)
          }

        } catch (error) {
          // console.log(error)

          navigate(`/user/${payload.sub}`)

          setErrors(true)
          setLoading(false)
        }
      }

      getData()
    }

  }, [])

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'The Pot'
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          // backgroundColor: 'yellow',
          width: '100%',
          minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        errors ? 
          <Box
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              height: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
            }}
          >
            {couldNotLoadPageError()}
          </Box>
          :
          <Box
            sx={{
              // backgroundColor: 'yellow',
              pt: 4, pb: 3,
              minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
              width: '100vw', maxWidth: '100vw',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}
          >

            {/* Assets Before Debits */}
            <Box
              sx={{
                backgroundColor: 'whitesmoke',
                borderRadius: 2, boxShadow: 3,
                width: width >= 1000 ? '30%' : '95%', maxWidth: '300px',
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >
              {/* Pie Chart of Taxes, Fees, Gifts, Heirs Percents */}
              {estate.hasOwnProperty('people') && estate.people.length > 0 &&
                <Box sx={{ mt: 1, mb: -4, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  {pieChartOfCategoryPercents(estate.people[0], estate, hover, setHover, width, 'taxesFeesGiftsHeirs', 'pot')}
                </Box>
              }
              
              {/* Total Asset Value */}
              <Box sx={{ mt: 0, mb: 0, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {allotmentNumberElements('Total Asset Value', getEstateTotalValue(estate.items), 'pot')}
              </Box>
              
              {/* Taxes */}
              <Box sx={{ mt: 0, mb: 0, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {allotmentNumberElements('Taxes', Math.floor(estate.totalTaxes), 'pot')}
              </Box>
              
              {/* Fees */}
              <Box sx={{ mt: 0, mb: 0, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {allotmentNumberElements('Fees', Math.floor(getTotalFees(estate.people)), 'pot')}
              </Box>
              
              {/* Gifts */}
              <Box sx={{ mt: 0, mb: 1, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {allotmentNumberElements('Gifts', Math.floor(getTotalGifts(estate.items)), 'pot')}
              </Box>
            </Box>

            {/* Gifts */}
            {estate.hasOwnProperty('items') && 
            estate.items.filter(item => item.allottedToName === 'Gift').length > 0 && 
            getEstateTotalValue(estate.items.filter(item => item.allottedToName === 'Gift')) > 0 &&
              <Box
                sx={{
                  // backgroundColor: 'whitesmoke',
                  backgroundColor: '#00008B',
                  mt: 3,
                  pb: 3,
                  borderRadius: 2, boxShadow: 3,
                  width: width >= 1000 ? '30%' : '95%', maxWidth: '300px',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                <Box sx={{ mt: 1, mb: -4, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  {pieChartOfCategoryPercents(estate.people[0], estate, hover, setHover, width, 'gifts', 'pot')}
                </Box>

                {/* Total Gifts Value */}
                <Box sx={{ mt: 0, mb: 0, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  {allotmentNumberElements('Total', getEstateTotalValue(estate.items.filter(item => item.allottedToName === 'Gift')), 'giftPot')}
                </Box>

                {/* Gift Recipients */}
                {estate.hasOwnProperty('items') && estate.items.length > 0 && estate.items.filter(item => item.allottedToName === 'Gift').length > 0 &&
                  removeDuplicatesFromArray(estate.items.filter(item => item.allottedToName === 'Gift').map(item => item.giftFor.length > 0 ? item.giftFor : 'NA')).sort((a, b) => getEstateTotalValue(estate.items.filter(item => item.allottedToName === 'Gift' && item.giftFor === b)) - getEstateTotalValue(estate.items.filter(item => item.allottedToName === 'Gift' && item.giftFor === a))).map((giftRecipientName, giftRecipientNameIndex) => (
                    <Box key={giftRecipientNameIndex} sx={{ mt: 0, mb: 0, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      {/* {console.log('giftRecipientName ->', giftRecipientName)} */}
                      {allotmentNumberElements(giftRecipientName, getEstateTotalValue(estate.items.filter(item => item.allottedToName === 'Gift' && item.giftFor === giftRecipientName)), 'giftPot')}
                    </Box>
                  ))
                }

              </Box>
            }
            
            {/* Total Available for Heirs */}
            {estate.hasOwnProperty('people') && estate.people.filter(person => person.role === 'Heir').length > 0 && estate.hasOwnProperty('items') && getEstateTotalValue(estate.items) > 0 &&
              <Box
                sx={{
                  backgroundColor: 'whitesmoke',
                  my: 3,
                  borderRadius: 2, boxShadow: 3,
                  width: width >= 1000 ? '30%' : '95%', maxWidth: '300px',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {/* Total Available To Heirs */}
                <Box sx={{ mt: 2, mb: -2, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  {allotmentNumberElements('Total For Heirs', getEstateTotalValue(estate.items) - Math.floor(estate.totalTaxes) - Math.floor(getTotalFees(estate.people)) - Math.floor(getTotalGifts(estate.items)), 'pot')}
                </Box>

                {/* Pie Chart of Categories for all items available to Heirs */}
                {estate.hasOwnProperty('people') && estate.people.length > 0 &&
                  <Box sx={{ mt: 0, mb: -2, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {pieChartOfCategoryPercents(estate.people[0], estate, hover, setHover, width, 'categoriesTotalEstate', 'pot')}
                  </Box>
                }
              </Box>
            }

            {/* Individual Heir Allotments */}
            {estate.hasOwnProperty('people') && estate.people.filter(person => person.role === 'Heir').length > 0 && estate.hasOwnProperty('items') && getEstateTotalValue(estate.items) > 0 &&
              <Box 
                sx={{ 
                  // backgroundColor: 'green',
                  mt: 0, mb: estate.hasOwnProperty('event') && estate.event[0].hasFinished ? 3 : estate.hasOwnProperty('items') && estate.items.filter(item => item.allottedToName === 'Unassigned' || item.allottedToName === 'Liquidate').length > 0 ? 2 : 8,
                  width: '100vw', maxWidth: '1000px', minWidth: '250px', 
                  display: 'flex', flexDirection: width >= 1000 ? 'row' : 'column', justifyContent: width >= 1000 && (estate.hasOwnProperty('event') && estate.event[0].draftOrder.length <= 3) ? 'space-around' : width >= 1000 && (estate.hasOwnProperty('event') && estate.event[0].draftOrder.length > 3 && estate.event[0].draftOrder.length % 3 !== 1) ? 'center' : width >= 1000 && (estate.hasOwnProperty('event') && estate.event[0].draftOrder.length > 3 && estate.event[0].draftOrder.length % 3 === 1) ? 'space-between' : 'flex-start', alignItems: width >= 1000 ? 'flex-start' : 'center', 
                  flexWrap: width >= 1000 ? 'wrap' : 'nowrap',
                }}
              >
                {estate.hasOwnProperty('people') && estate.people.length > 0 &&
                  estate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage).map((heirPerson, heirIndex) => (
                    <Box key={heirIndex}
                      sx={{
                        backgroundColor: 'whitesmoke',
                        mt: (width < 1000 && heirIndex > 0) ? 3 : (width >= 1000 && heirIndex > 2) ? 3 : 0,
                        mx: width >= 1000 && (estate.hasOwnProperty('event') && estate.event[0].draftOrder.length > 3 && estate.event[0].draftOrder.length % 3 !== 1) ? 0 : 0,
                        borderRadius: 2, boxShadow: 3,
                        width: width >= 1000 ? '30%' : '95%', maxWidth: '300px',
                        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                      }}
                    >
                      <Box 
                        sx={{ 
                          color: sheirBlue,
                          mt: 1, mb: 0, pt: 1,
                          fontWeight: 'bold', fontSize: '18px',
                          width: '100%', maxWidth: '300px', minWidth: '250px', 
                          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
                        }}
                      >
                        {!isTestEstate ?
                          <Link to={`/estate/${heirPerson.estateId}/${heirPerson._id}`}>
                            {heirPerson.userGivenAndFamilyName}
                          </Link>
                          :
                          heirPerson.userGivenAndFamilyName
                        }
                        <Typography
                          sx={{
                            color: 'black',
                            ml: 1,
                            fontWeight: 'bold', fontSize: '18px',
                          }}
                        >
                          {`(${heirPerson.sharePercentage}%)`}
                        </Typography>
                      </Box>

                      {heirAllotmentsTab(false, 'pot', estate, heirPerson, -1, 1, hover, setHover, width, loading, errors)}
                      
                    </Box>
                  ))
                }
              </Box>
            }

            {/* Liquidation */}
            {estate.hasOwnProperty('event') && 
            (estate.event[0].hasFinished || (estate.event[0].distributionMethod === 'Allocate All' || estate.event[0].silentOrParticipatory === 'Silent')) &&
              <Box
                sx={{
                  // backgroundColor: 'whitesmoke',
                  backgroundColor: 'darkolivegreen',
                  my: 2,
                  pb: 3,
                  borderRadius: 2, boxShadow: 3,
                  width: width >= 1000 ? '30%' : '95%', maxWidth: '300px',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                <Box sx={{ mt: 1, mb: -4, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  {estate.items.filter(item => item.allottedToName === 'Unassigned' || item.allottedToName === 'Liquidate').length > 0 ? 
                    pieChartOfCategoryPercents(estate.people[0], estate, hover, setHover, width, 'liquidation', 'pot')
                    :
                    <Box
                      sx={{
                        pt: 1,
                        width: '100%',
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                      }}
                    >
                      {standardInfoTooltip(
                        `Stake of items that are currently 'Unassigned' or marked for Liquidation`,
                        0,
                        'white'
                      )}
                      <Typography
                        sx={{
                          mt: 1,
                          mb: 4,
                          width: '100%',
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '18px',
                          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                        }}
                      >
                        Liquidation Stake
                      </Typography>
                    </Box>
                  }
                </Box>

                {/* Total Asset Value */}
                <Box sx={{ mt: 0, mb: 0, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  {allotmentNumberElements('Total', getEstateTotalValue(estate.items.filter(item => (item.allottedToName === 'Liquidate' || item.allottedToName === 'Unassigned'))), 'liquidatePot')}
                </Box>

                {/* Taxes */}
                <Box sx={{ mt: 0, mb: 0, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  {allotmentNumberElements('Taxes', Math.floor(estate.totalTaxes), 'liquidatePot')}
                </Box>
            
                {/* Fees */}
                <Box sx={{ mt: 0, mb: 0, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  {allotmentNumberElements('Fees', Math.floor(getTotalFees(estate.people)), 'liquidatePot')}
                </Box>

                {/* Heirs */}
                {estate.hasOwnProperty('people') && estate.people.length > 0 && estate.people.filter(person => person.role === 'Heir').length > 0 &&
                  estate.people.filter(person => person.role === 'Heir').map((heirPerson, heirPersonIndex) => (
                    <Box key={heirPersonIndex} sx={{ mt: 0, mb: 0, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      {allotmentNumberElements(heirPerson.userGivenAndFamilyName, (getHeirShareTotal(estate, heirPerson.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, heirPerson)), 'liquidatePot')}
                    </Box>
                  ))
                }
            
                {/* Leftover */}
                <Box sx={{ mt: 0, mb: 0, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  {allotmentNumberElements('Leftovers', Math.floor(getEstateTotalValue(estate.items.filter(item => (item.allottedToName === 'Liquidate' || item.allottedToName === 'Unassigned')))) - Math.floor(estate.totalTaxes) - Math.floor(getTotalFees(estate.people)) - Math.floor(getTotalRemainingHeirSharesAllHeirs(estate)), 'liquidatePot')}
                </Box>
              </Box>
            }

          </Box>
      }
    </>
  )
}

export default ThePot