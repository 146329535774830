/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Routes, Route } from 'react-router-dom' //Router components

// Import Components
import ScrollToTop from './components/common/ScrollToTop'
import NotFound from './components/common/NotFound'
import Layout from './components/common/Layout'
import LayoutEstate from './components/common/LayoutEstate'
import FAQ from './components/faq/FAQ'
import Home from './components/Home'
import AllAuth from './components/auth/AllAuth'
import TermsOfService from './components/auth/TermsOfService'
import PrivacyPolicy from './components/auth/PrivacyPolicy'
import ResetPassword from './components/auth/ResetPassword'
import CreateEstate from './components/onboarding/CreateEstate'
import UserProfile from './components/user/UserProfile'
import PersonSpace from './components/person/PersonSpace'
import FamilyHQ from './components/estate/FamilyHQ'
import ThePot from './components/estate/ThePot'
import AddItem from './components/item/AddItem'
import ItemsIndex from './components/item/ItemsIndex'
import DraftResults from './components/draft/DraftResults'
import DraftPractice from './components/draft/DraftPractice'
import LiveDraft from './components/draft/LiveDraft'
import AuctionResults from './components/auction/AuctionResults'
import LiveAuction from './components/auction/LiveAuction'
import AuctionPractice from './components/auction/AuctionPractice'
import Demo from './components/test/Demo.js'
import TestEstate from './components/test/TestEstate.js'
import TestEvent from './components/test/TestEvent.js'

import { permittedCountryCodes } from './helpers/variableDefaults'

//MUI
import getDesignTokens from './helpers/theme.js' //MUI theming presets
import { createTheme, ThemeProvider } from '@mui/material/styles' //MUI theme creator functions/components
import Box from '@mui/material/Box'

// Stripe
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY)


const App = () => {
  // Helmet Context
  const helmetContext = {}

  // Geolocation
  const [countryCode, setCountryCode] = useState('')
  const getData = async () => {
    axios.get('https://ipapi.co/json/').then((response) => {
      // const data = response.data
      // console.log('countryCode ->', response.data.country_code)
      setCountryCode(
        response.hasOwnProperty('data') && 
        response.data.hasOwnProperty('country_code') && 
        response.data.country_code.length === 2 
          ? 
          response.data.country_code 
          : 
          ''
      )
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  // Stripe Options
  const [options, setOptions] = useState({
    mode: 'subscription',
    currency: 'usd',
    amount: 0,
    // metadata: {
    //   estateName: '',
    //   mission: '',
    //   startDate: '',
    //   liveOrAuto: 'Auto',
    //   distributionMethod: 'Draft',
    //   silentOrParticipatory: 'Silent',
    //   subscriptionType: 'month',
    //   cardholderName: '',
    //   promoCode: '',
    //   paymentMethodIsValid: false,
    //   people: [
    //     {
    //       role: 'Owner',
    //       sharePercentage: '0', 
    //       givenName: '', 
    //       familyName: '', 
    //       email: '', 
    //     }
    //   ],
    // },
    appearance: {
      theme: 'stripe',
    },
  })

  return (
    // HelmetProvider must go around everything in order to use dynamic helmets
    <HelmetProvider context={helmetContext}>
      
      {/* Wrap everything in the theme so that these presets cascade down */}
      <ThemeProvider theme={createTheme(getDesignTokens())}>

        {/* The 'wrapper-box' id is referenced in main.scss for global variables that cascade down */}
        <Box id='wrapper-box' bgcolor='background.default' color='text.primary'>
          
          {/* All the routes must be set inside the Browser Router */}
          <BrowserRouter>

            {/* Scroll to top automatically every time route changes */}
            <ScrollToTop />

            {/* Stripe Elements */}
            <Elements options={options} stripe={stripePromise}>
                
              {/* The Website */}
              <Routes>

                {/* No Navbar Routes */}

                {/* Draft */}
                <Route path="/estate/:estateId/live-draft" element={<LiveDraft />} />
                <Route path="/estate/:estateId/draft-practice" element={<DraftPractice />} />

                {/* Auction */}
                <Route path="/estate/:estateId/live-auction" element={<LiveAuction />} />
                <Route path="/estate/:estateId/auction-practice" element={<AuctionPractice />} />

                {/* Demo */}
                <Route path="/demo" element={<Demo />} />
                <Route path="/demo/:estateOwnerName/test-estate" element={<TestEstate />} />
                <Route path="/demo/:estateOwnerName/:eventType/:heirName" element={<TestEvent />} />


                {/* Navbar In "Layout"; Navbar only appears for routes that are children of this Route */}
                <Route element={<Layout />}> 

                  {/* Homepage */}
                  <Route path="/" element={<Home />} />

                  {/* FAQ route */}
                  <Route path="/faq" element={<FAQ fromComponent='faq' role='NA' transparencyLevel='NA' distributionMethod='NA' />} />

                  {/* Test Product */}
                  {/* <Route path="/demo" element={<Demo />} /> */}

                  {/* Auth routes */}
                  <Route path="/login" element={<AllAuth />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/reset-password/:userId/:token" element={<ResetPassword />} />
                  <Route path="/tos" element={<TermsOfService />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />

                  {/* Onboarding */}
                  <Route path="/create-estate" element={<CreateEstate options={options} setOptions={setOptions} countryCode={countryCode} />} />
                  
                  {/* User */}
                  <Route path="/user/:userId" element={<UserProfile />} />
                  
                  {/* Routes that require NavbarEstate */}
                  <Route element={<LayoutEstate />}>
                    {/* Person */}
                    <Route path="/estate/:estateId/:personId" element={<PersonSpace isTestEstate={false} />} />

                    {/* Estate */}
                    <Route path="/estate/:estateId/family-hq" element={<FamilyHQ isTestEstate={false} />} />
                    <Route path="/estate/:estateId/the-pot" element={<ThePot isTestEstate={false} />} />

                    {/* Items */}
                    <Route path="/estate/:estateId/add" element={<AddItem isTestEstate={false} />} />
                    <Route path="/estate/:estateId/:itemId/edit" element={<AddItem isTestEstate={false} />} />
                    <Route path="/estate/:estateId/items" element={<ItemsIndex isTestEstate={false} />} />

                    {/* Results */}
                    <Route path="/estate/:estateId/draft-results" element={<DraftResults isTestEstate={false} />} />
                    <Route path="/estate/:estateId/auction-results" element={<AuctionResults isTestEstate={false} />} />

                  </Route>

                  {/* Not Found; this path matches any path specified, so it needs to come last */}
                  <Route path="*" element={<NotFound />} />

                </Route>

              </Routes>

            </Elements>
                
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default App
