
export const johnLennon = {
  _id: 'johnLennonPersonId',
  userId: 'johnLennonUserId',
  estateId: 'johnLennonEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-john-lennon-1.jpg',
  userGivenAndFamilyName: 'John Lennon',
  email: 'johnLennon@email.com',
  role: 'Owner',
  readWriteAdmin: 'admin',
  sharePercentage: '0',
  fees: '0',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const ringoStarr = {
  _id: 'ringoStarrPersonId',
  userId: 'ringoStarrUserId',
  estateId: 'johnLennonEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-ringo-starr-1.jpg',
  userGivenAndFamilyName: 'Ringo Starr',
  email: 'ringoStarr@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '25',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const georgeHarrison = {
  _id: 'georgeHarrisonPersonId',
  userId: 'georgeHarrisonUserId',
  estateId: 'johnLennonEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-george-harrison-1.jpg',
  userGivenAndFamilyName: 'George Harrison',
  email: 'georgeHarrison@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '25',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const paulMcCartney = {
  _id: 'paulMcCartneyPersonId',
  userId: 'paulMcCartneyUserId',
  estateId: 'johnLennonEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-paul-mccartney-1.jpg',
  userGivenAndFamilyName: 'Paul McCartney',
  email: 'paulMcCartney@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '25',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const yokoOno = {
  _id: 'yokoOnoPersonId',
  userId: 'yokoOnoUserId',
  estateId: 'johnLennonEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-yoko-ono-1.jpg',
  userGivenAndFamilyName: 'Yoko Ono',
  email: 'yokoOno@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '25',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const eleanorRigby = {
  _id: 'eleanorRigbyPersonId',
  userId: 'eleanorRigbyUserId',
  estateId: 'johnLennonEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-eleanor-rigby.jpg',
  userGivenAndFamilyName: 'Eleanor Rigby',
  email: 'eleanorRigby@email.com',
  role: 'Lawyer',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '25000',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const haywoodJude = {
  _id: 'haywoodJudePersonId',
  userId: 'haywoodJudeUserId',
  estateId: 'johnLennonEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-haywood-jude.jpg',
  userGivenAndFamilyName: 'Haywood Jude',
  email: 'haywoodJude@email.com',
  role: 'Asset Manager',
  readWriteAdmin: 'write',
  sharePercentage: '0',
  fees: '25000',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const maggieMae = {
  _id: 'maggieMaePersonId',
  userId: 'maggieMaeUserId',
  estateId: 'johnLennonEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-maggie-mae.jpg',
  userGivenAndFamilyName: 'Maggie Mae',
  email: 'maggieMae@email.com',
  role: 'Executor',
  readWriteAdmin: 'write',
  sharePercentage: '0',
  fees: '25000',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const sargeantPepper = {
  _id: 'sargeantPepperPersonId',
  userId: 'sargeantPepperUserId',
  estateId: 'johnLennonEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-sargeant-pepper.jpg',
  userGivenAndFamilyName: 'Sargeant Pepper',
  email: 'sargeantPepper@email.com',
  role: 'Accountant',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '25000',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const pennyLane = {
  _id: 'pennyLanePersonId',
  userId: 'pennyLaneUserId',
  estateId: 'johnLennonEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-penny-lane.jpg',
  userGivenAndFamilyName: 'Penny Lane',
  email: 'pennyLane@email.com',
  role: 'Witness',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '25000',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}