import { auctionTimerMinutes, auctionTimerSeconds, draftTimerMinutes, draftTimerSeconds } from './variableDefaults'

// Timer Functions
export const pauseCountDown = (setIsPaused) => {
  clearAllTimeouts()
  setIsPaused(true)
}

export const resetCountDown = (type, setIsPaused, setMinutes, setSeconds) => {
  setIsPaused(false)
  
  setMinutes(type === 'auction' ? auctionTimerMinutes : draftTimerMinutes)
  setSeconds(type === 'auction' ? auctionTimerSeconds : draftTimerSeconds)
}

export const clearAllTimeouts = () => {
  var id = window.setTimeout(function() {}, 0)

  while (id--) {
    window.clearTimeout(id) // will do nothing if no timeout with id is present
  }
}

export const startTimeout = (decrement) => {
  // console.log('startTimeout runs')
  clearAllTimeouts()

  const timer = setTimeout(decrement, 1000)
  // console.log('timer sets')
  return () => {
    // clearTimeout(timer)
  }
}