/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { sheirGreen } from './variableDefaults'

// Instantly handles click event; Accept TOS Checkbox
export const standardCheckboxBool = (title, value, setValue, disabled, color ) => {
  return (
    <>
      <FormControlLabel 
        control={
          <Checkbox 
            disabled={disabled}
            color={color}
            value={value} 
            // name={name}
            checked={value}
            onClick={(e) => setValue(!value)}
            sx={{ width: '40px' }} />
        } 
      />
      <Typography variant='p' sx={{ ml: 0, mr: 0 }}>
        {title}
      </Typography>
    </>
  )
}

// Accepts a form state rather than a Bool state
export const standardCheckboxForm = (title, name, value, form, setForm, disabled, color ) => {
  return (
    <>
      <FormControlLabel 
        control={
          <Checkbox 
            disabled={disabled}
            color={color}
            value={value} 
            name={name}
            checked={value}
            onClick={(e) => setForm({ ...form, [name]: !value })}
            sx={{ pl: 2, width: '40px' }} />
        } 
      />
      <Typography variant='p' sx={{ ml: 0, mr: 0, width: 'calc(100% - 60px)' }}>
        {title}
      </Typography>
    </>
  )
}

// Instantly handles click event; Accept TOS Checkbox
export const acceptTOSCheckbox = (name, value, setValue ) => {
  return (
    <>
      <FormControlLabel 
        control={
          <Checkbox 
            disabled={false}
            value={value} 
            name={name}
            checked={value}
            onClick={(e) => setValue(!value)}
            sx={{ pl: 2, width: '40px' }} />
        } 
      />
      <Typography variant='p' sx={{ ml: 0, mr: 0, width: 'calc(100% - 60px)' }}>
        Accept <a href='/tos' target="#" style={{ cursor: 'pointer', textDecoration: 'underline', color: sheirGreen }} >Terms of Service</a> and <a href='/privacy' target="#" style={{ cursor: 'pointer', textDecoration: 'underline', color: sheirGreen }} >Privacy Policy</a>.
      </Typography>
    </>
  )
}

// Accepts a form state rather than a Bool state
export const acceptTOSCheckboxForm = (name, value, form, setForm ) => {
  return (
    <>
      <FormControlLabel 
        control={
          <Checkbox 
            disabled={false}
            value={value} 
            name={name}
            checked={value}
            onClick={(e) => setForm({ ...form, [name]: !value })}
            sx={{ pl: 2, width: '40px' }} />
        } 
      />
      <Typography variant='p' sx={{ ml: 0, mr: 0, width: 'calc(100% - 60px)' }}>
        Accept <a href='/tos' target="#" style={{ cursor: 'pointer', textDecoration: 'underline', color: sheirGreen }} >Terms of Service</a> and <a href='/privacy' target="#" style={{ cursor: 'pointer', textDecoration: 'underline', color: sheirGreen }} >Privacy Policy</a>.
      </Typography>
    </>
  )
}