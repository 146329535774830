

// Sources:
// https://en.wikibooks.org/wiki/Monopoly/Properties_reference

// Constants
const startBidHistoryAndAuctionPlanningObj = {
  shoePersonId: 0, 
  hatPersonId: 0, 
  ironPersonId: 0,
  carPersonId: 0,
  thimblePersonId: 0,
}

const startDraftPlanningObj = {
  shoePersonId: 1000000000, 
  hatPersonId: 1000000000, 
  ironPersonId: 1000000000,
  carPersonId: 1000000000,
  thimblePersonId: 1000000000,
}


// Items

// Mediterranean Avenue (Dark Purple)
export const mrMonopolyMediterraneanAvenue = {
  _id: 'mrMonopolyMediterraneanAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Mediterranean Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-mediterranean-avenue-1.jpg',
  valuation: '60',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Mediterranean Avenue property. Pair it with the Baltic Avenue property for a monopoly on the dark purple squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Baltic Avenue (Dark Purple)
export const mrMonopolyBalticAvenue = {
  _id: 'mrMonopolyBalticAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Baltic Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-baltic-avenue-1.jpg',
  valuation: '60',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Baltic Avenue property. Pair it with the Mediterranean Avenue property for a monopoly on the dark purple squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Reading Railroad (Railroad)
export const mrMonopolyReadingRailroad = {
  _id: 'mrMonopolyReadingRailroadItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Reading Railroad',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-reading-railroad-1.jpg',
  valuation: '200',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My Reading Railroad equity. Pair it with the B&O, Pennsylvania, and Short Line for a railroad monopoly.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Oriental Avenue (Light Blue)
export const mrMonopolyOrientalAvenue = {
  _id: 'mrMonopolyOrientalAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Oriental Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-oriental-avenue-1.jpg',
  valuation: '100',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Oriental Avenue property. Pair it with Vermont Avenue and Connecticut Avenue for a monopoly on the light blue squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Vermont Avenue (Light Blue)
export const mrMonopolyVermontAvenue = {
  _id: 'mrMonopolyVermontAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Vermont Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-vermont-avenue-1.jpg',
  valuation: '100',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Vermont Avenue property. Pair it with Oriental Avenue and Connecticut Avenue for a monopoly on the light blue squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Connecticut Avenue (Light Blue)
export const mrMonopolyConnecticutAvenue = {
  _id: 'mrMonopolyConnecticutAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Connecticut Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-connecticut-avenue-1.jpg',
  valuation: '120',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Connecticut Avenue property. Pair it with Oriental Avenue and Vermont Avenue for a monopoly on the light blue squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// St. Charles Place (Pink)
export const mrMonopolyStCharlesPlace = {
  _id: 'mrMonopolyStCharlesPlaceItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'St. Charles Place',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-st-charles-place-1.jpg',
  valuation: '140',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My St. Charles Place property. Pair it with States Avenue and Virginia Avenue for a monopoly on the pink squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Electric Company (Utilities)
export const mrMonopolyElectricCompany = {
  _id: 'mrMonopolyElectricCompanyItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Electric Company',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-electric-company-1.jpg',
  valuation: '150',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 100% stake in the electric company. Pair it with water works for a monopoly on utilities.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// States Avenue (Pink)
export const mrMonopolyStatesAvenue = {
  _id: 'mrMonopolyStatesAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'States Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-states-avenue-1.jpg',
  valuation: '140',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My States Avenue property. Pair it with St. Charles Place and Virginia Avenue for a monopoly on the pink squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Virginia Avenue (Pink)
export const mrMonopolyVirginiaAvenue = {
  _id: 'mrMonopolyVirginiaAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Virginia Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-virginia-avenue-1.jpg',
  valuation: '160',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Virginia Avenue property. Pair it with States Avenue and St. Charles Place for a monopoly on the pink squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Pennsylvania Railroad (Railroad)
export const mrMonopolyPennsylvaniaRailroad = {
  _id: 'mrMonopolyPennsylvaniaRailroadItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Pennsylvania Railroad',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-pennsylvania-railroad-1.jpg',
  valuation: '200',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My Pennsylvania Railroad equity. Pair it with the B&O, Reading, and Short Line for a railroad monopoly.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// St. James Place (Orange)
export const mrMonopolyStJamesPlace = {
  _id: 'mrMonopolyStJamesPlaceItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'St. James Place',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-st-james-place-1.jpg',
  valuation: '180',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My St. James Place property. Pair it with New York Avenue and Tennessee Avenue for a monopoly on the orange squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Tennessee Avenue (Orange)
export const mrMonopolyTennesseeAvenue = {
  _id: 'mrMonopolyTennesseeAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Tennessee Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-tennessee-avenue-1.jpg',
  valuation: '180',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Tennessee Avenue property. Pair it with New York Avenue and St. James Place for a monopoly on the orange squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// New York Avenue (Orange)
export const mrMonopolyNewYorkAvenue = {
  _id: 'mrMonopolyNewYorkAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'New York Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-new-york-avenue-1.jpg',
  valuation: '200',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My New York Avenue property. Pair it with Tennessee Avenue and St. James Place for a monopoly on the orange squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Kentucky Avenue (Red)
export const mrMonopolyKentuckyAvenue = {
  _id: 'mrMonopolyKentuckyAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Kentucky Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-kentucky-avenue-1.jpg',
  valuation: '220',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Kentucky Avenue property. Pair it with Indiana Avenue and Illinois Avenue for a monopoly on the red squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Indiana Avenue (Red)
export const mrMonopolyIndianaAvenue = {
  _id: 'mrMonopolyIndianaAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Indiana Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-indiana-avenue-1.jpg',
  valuation: '220',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Indiana Avenue property. Pair it with Kentucky Avenue and Illinois Avenue for a monopoly on the red squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Illinois Avenue (Red)
export const mrMonopolyIllinoisAvenue = {
  _id: 'mrMonopolyIllinoisAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Illinois Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-illinois-avenue-1.jpg',
  valuation: '240',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Illinois Avenue property. Pair it with Kentucky Avenue and Indiana Avenue for a monopoly on the red squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// B&O Railroad (Railroad)
export const mrMonopolyBORailroad = {
  _id: 'mrMonopolyBORailroadItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'B&O Railroad',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-bo-railroad-1.jpg',
  valuation: '200',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My B&O Railroad equity. Pair it with the Reading, Pennsylvania, and Short Line for a railroad monopoly.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Atlantic Avenue (Yellow)
export const mrMonopolyAtlanticAvenue = {
  _id: 'mrMonopolyAtlanticAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Atlantic Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-atlantic-avenue-1.jpg',
  valuation: '260',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Atlantic Avenue property. Pair it with Ventnor Avenue and Marvin Gardens for a monopoly on the yellow squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Ventnor Avenue (Yellow)
export const mrMonopolyVentnorAvenue = {
  _id: 'mrMonopolyVentnorAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Ventnor Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-ventnor-avenue-1.jpg',
  valuation: '260',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Ventnor Avenue property. Pair it with Atlantic Avenue and Marvin Gardens for a monopoly on the yellow squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Water Works (Utilities)
export const mrMonopolyWaterWorks = {
  _id: 'mrMonopolyWaterWorksItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Water Works',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-water-works-1.jpg',
  valuation: '150',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 100% stake in the water works. Pair it with the electric company for a monopoly on utilities.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Marvin Gardens (Yellow)
export const mrMonopolyMarvinGardens = {
  _id: 'mrMonopolyMarvinGardensItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Marvin Gardens',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-marvin-gardens-1.jpg',
  valuation: '280',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Marvin Gardens property. Pair it with Ventnor Avenue and Atlantic Avenue for a monopoly on the yellow squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Pacific Avenue (Green)
export const mrMonopolyPacificAvenue = {
  _id: 'mrMonopolyPacificAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Pacific Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-pacific-avenue-1.jpg',
  valuation: '300',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Pacific Avenue property. Pair it with North Carolina Avenue and Pennsylvania Avenue for a monopoly on the green squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// North Carolina Avenue (Green)
export const mrMonopolyNorthCarolinaAvenue = {
  _id: 'mrMonopolyNorthCarolinaAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'North Carolina Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-north-carolina-avenue-1.jpg',
  valuation: '300',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My North Carolina Avenue property. Pair it with Pacific Avenue and Pennsylvania Avenue for a monopoly on the green squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Pennsylvania Avenue (Green)
export const mrMonopolyPennsylvaniaAvenue = {
  _id: 'mrMonopolyPennsylvaniaAvenueItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Pennsylvania Avenue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-pennsylvania-avenue-1.jpg',
  valuation: '320',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Pennsylvania Avenue property. Pair it with North Carolina Avenue and Pacific Avenue for a monopoly on the green squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Short Line (Railroad)
export const mrMonopolyShortLine = {
  _id: 'mrMonopolyShortLineItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Short Line Railroad',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-short-line-railroad-1.jpg',
  valuation: '200',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My Short Line Railroad equity. Pair it with the B&O, Pennsylvania, and Reading for a railroad monopoly.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Park Place (Dark Blue)
export const mrMonopolyParkPlace = {
  _id: 'mrMonopolyParkPlaceItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Park Place',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-park-place-1.jpg',
  valuation: '350',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Park Place property. Pair it with Boardwalk for a monopoly on the dark blue squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Boardwalk (Dark Blue)
export const mrMonopolyBoardwalk = {
  _id: 'mrMonopolyBoardwalkItemId',
  estateId: 'mrMonopolyEstateId',
  itemName: 'Boardwalk',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-monopoly-boardwalk-1.jpg',
  valuation: '400',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My Boardwalk property. Pair it with Park Place for a monopoly on the dark blue squares.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// export const item29 = {
//   _id: '',
//   estateId: 'mrMonopolyEstateId',
//   itemName: '',
//   imageURL: 'https://sheir-item-images.s3.amazonaws.com/',
//   valuation: '',
//   valuationDate: '2021-08-27T13:49:49.000Z',
//   category: 'Real Estate',
//   description: '',
//   allottedToName: 'Unassigned',
//   allottedToPersonId: 'NA',
//   giftFor: '',
//   bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
//   heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
//   heirDraftPlanning: { ...startDraftPlanningObj },
// }

// export const item30 = {
//   _id: '',
//   estateId: 'mrMonopolyEstateId',
//   itemName: '',
//   imageURL: 'https://sheir-item-images.s3.amazonaws.com/',
//   valuation: '',
//   valuationDate: '2021-08-27T13:49:49.000Z',
//   category: 'Real Estate',
//   description: '',
//   allottedToName: 'Unassigned',
//   allottedToPersonId: 'NA',
//   giftFor: '',
//   bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
//   heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
//   heirDraftPlanning: { ...startDraftPlanningObj },
// }

