import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { standardButton } from '../../helpers/buttons'
import { priceCardWidth, sheirBlue, sheirGreen } from '../../helpers/variableDefaults'

const PriceCard = (props) => {

  // Destructure Props
  const { price } = props

  // Navigate
  const navigate = useNavigate()

  // URL Path
  const urlPath = window.location.pathname

  // Navigate to /create-estate when Create Estate button Clicked
  const handleCreateEstate = async (e) => {
    e.preventDefault()
    navigate('/create-estate')
  }

  return (
    <Card 
      variant="outlined"
      sx={{ 
        width: '100%', maxWidth: priceCardWidth,
        border: 'solid 2px black', borderRadius: '20px',
        boxShadow: 6,
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" textAlign={'center'}
          sx={{
            mb: 2,
            fontWeight: 'bold',
            color: sheirBlue,
          }}
        >
          {price}
        </Typography>

        <Typography component="pre"
          textAlign={'center'}
          sx={{
            mb: 1,
            fontWeight: 'bold',
            // fontStyle: 'italic',
            color: sheirBlue,
          }}
        >
          {
            `Pricing`.replace(/ {4} +/g, '')
          }
        </Typography>

        <Typography component="pre"
          sx={{
            mb: 2,
            fontWeight: 'bold',
            fontStyle: 'italic',
            // color: sheirBlue,
          }}
        >
          {
            `— $350/year
            — 7-day free trial
            — Cancel anytime`.replace(/ {4} +/g, '')
          }
        </Typography>

        <Typography component="pre"
          textAlign={'center'}
          sx={{
            mb: 1,
            fontWeight: 'bold',
            // fontStyle: 'italic',
            color: sheirBlue,
          }}
        >
          {
            `Features`.replace(/ {4} +/g, '')
          }
        </Typography>

        <Typography component="pre" 
          sx={{
            fontStyle: 'italic',
            fontWeight: 'bold',
          }}
        >
          {/* the regex is for deleting the indentation spaces in the string literal` */}
          {
            `— Organize Estate
            — Visualize Assets
            — Determine Allotments
            — Draft or Auction Among Heirs
            — Two-Factor Authentication 
            — Encrypted Database`.replace(/ {4} +/g, '')
          }
        </Typography>

      </CardContent>
      <CardActions 
        sx={{
          mb: 2,
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {standardButton(
          'Create Estate', 
          'button',
          'contained',
          false,
          'warning', 
          0,
          0,
          0,
          '150px', 
          '45px', 
          handleCreateEstate
        )}
      </CardActions>
    </Card>
  )
}

export default PriceCard