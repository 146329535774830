
export const cosmoKramer = {
  _id: 'cosmoKramerPersonId',
  userId: 'cosmoKramerUserId',
  estateId: 'cosmoKramerEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-cosmo-kramer-1.jpg',
  userGivenAndFamilyName: 'Cosmo Kramer',
  email: 'cosmoKramer@email.com',
  role: 'Owner',
  readWriteAdmin: 'admin',
  sharePercentage: '0',
  fees: '0',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const georgeCostanza = {
  _id: 'georgeCostanzaPersonId',
  userId: 'georgeCostanzaUserId',
  estateId: 'cosmoKramerEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-george-costanza-1.jpg',
  userGivenAndFamilyName: 'George Costanza',
  email: 'nickCarraway@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '25',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const elaineBenes = {
  _id: 'elaineBenesPersonId',
  userId: 'elaineBenesUserId',
  estateId: 'cosmoKramerEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-elaine-benes-1.jpg',
  userGivenAndFamilyName: 'Elaine Benes',
  email: 'elaineBenes@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '25',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const jerrySeinfeld = {
  _id: 'jerrySeinfeldPersonId',
  userId: 'jerrySeinfeldUserId',
  estateId: 'cosmoKramerEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-jerry-seinfeld-1.jpg',
  userGivenAndFamilyName: 'Jerry Seinfeld',
  email: 'jerrySeinfeld@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '25',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const newman = {
  _id: 'newmanPersonId',
  userId: 'newmanUserId',
  estateId: 'cosmoKramerEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-newman-1.jpg',
  userGivenAndFamilyName: 'Newman',
  email: 'newman@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '25',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const jackieChiles = {
  _id: 'jackieChilesPersonId',
  userId: 'jackieChilesUserId',
  estateId: 'cosmoKramerEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-jackie-chiles.jpg',
  userGivenAndFamilyName: 'Jackie Chiles',
  email: 'jackieChiles@email.com',
  role: 'Lawyer',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '250',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const jPeterman = {
  _id: 'jPetermanPersonId',
  userId: 'jPetermanUserId',
  estateId: 'cosmoKramerEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-j-peterman.jpg',
  userGivenAndFamilyName: 'J Peterman',
  email: 'jPeterman@email.com',
  role: 'Asset Manager',
  readWriteAdmin: 'write',
  sharePercentage: '0',
  fees: '250',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const frankCostanza = {
  _id: 'frankCostanzaPersonId',
  userId: 'frankCostanzaUserId',
  estateId: 'cosmoKramerEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-frank-costanza.jpg',
  userGivenAndFamilyName: 'Frank Costanza',
  email: 'frankCostanza@email.com',
  role: 'Executor',
  readWriteAdmin: 'write',
  sharePercentage: '0',
  fees: '250',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const larryDavid = {
  _id: 'larryDavidPersonId',
  userId: 'larryDavidUserId',
  estateId: 'cosmoKramerEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-larry-david.jpg',
  userGivenAndFamilyName: 'Larry David',
  email: 'larryDavid@email.com',
  role: 'Accountant',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '250',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const mortySeinfeld = {
  _id: 'mortySeinfeldPersonId',
  userId: 'mortySeinfeldUserId',
  estateId: 'cosmoKramerEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-morty-seinfeld.jpg',
  userGivenAndFamilyName: 'Morty Seinfeld',
  email: 'mortySeinfeld@email.com',
  role: 'Witness',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '250',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}