
export const jayGatsby = {
  _id: 'jayGatsbyPersonId',
  userId: 'jayGatsbyUserId',
  estateId: 'jayGatsbyEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-jay-gatsby-1.jpg',
  userGivenAndFamilyName: 'Jay Gatsby',
  email: 'jayGatsby@email.com',
  role: 'Owner',
  readWriteAdmin: 'admin',
  sharePercentage: '0',
  fees: '0',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const nickCarraway = {
  _id: 'nickCarrawayPersonId',
  userId: 'nickCarrawayUserId',
  estateId: 'jayGatsbyEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-nick-carraway-1.jpg',
  userGivenAndFamilyName: 'Nick Carraway',
  email: 'nickCarraway@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '33',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const daisyBuchanan = {
  _id: 'daisyBuchananPersonId',
  userId: 'daisyBuchananUserId',
  estateId: 'jayGatsbyEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-daisy-buchanan-1.jpg',
  userGivenAndFamilyName: 'Daisy Buchanan',
  email: 'daisyBuchanan@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '33',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const owlEyes = {
  _id: 'owlEyesPersonId',
  userId: 'owlEyesUserId',
  estateId: 'jayGatsbyEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-owl-eyes-1.jpg',
  userGivenAndFamilyName: 'Owl Eyes',
  email: 'owlEyes@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '33',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const danCody = {
  _id: 'danCodyPersonId',
  userId: 'danCodyUserId',
  estateId: 'jayGatsbyEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-dan-cody.jpg',
  userGivenAndFamilyName: 'Dan Cody',
  email: 'danCody@email.com',
  role: 'Lawyer',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '250000',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const meyerWolfsheim = {
  _id: 'meyerWolfsheimPersonId',
  userId: 'meyerWolfsheimUserId',
  estateId: 'jayGatsbyEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-meyer-wolfsheim.jpg',
  userGivenAndFamilyName: 'Meyer Wolfsheim',
  email: 'meyerWolfsheim@email.com',
  role: 'Asset Manager',
  readWriteAdmin: 'write',
  sharePercentage: '0',
  fees: '250000',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const jordanBaker = {
  _id: 'jordanBakerPersonId',
  userId: 'jordanBakerUserId',
  estateId: 'jayGatsbyEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-jordan-baker.jpg',
  userGivenAndFamilyName: 'Jordan Baker',
  email: 'jordanBaker@email.com',
  role: 'Executor',
  readWriteAdmin: 'write',
  sharePercentage: '0',
  fees: '250000',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const ewingKlipspringer = {
  _id: 'ewingKlipspringerPersonId',
  userId: 'ewingKlipspringerUserId',
  estateId: 'jayGatsbyEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-ewing-klipspringer.jpg',
  userGivenAndFamilyName: 'Ewing Klipspringer',
  email: 'ewingKlipspringer@email.com',
  role: 'Accountant',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '250000',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const tomBuchanan = {
  _id: 'tomBuchananPersonId',
  userId: 'tomBuchananUserId',
  estateId: 'jayGatsbyEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-tom-buchanan.jpg',
  userGivenAndFamilyName: 'Tom Buchanan',
  email: 'tomBuchanan@email.com',
  role: 'Witness',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '250000',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}