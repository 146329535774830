/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import dayjs from 'dayjs'

import useWindowDimensions from '../../helpers/windowDimensions'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { dummyBoxWidth } from '../../helpers/globalHelpers'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { draftResultRoundColumnWidth, draftResultHeirColumnWidth, navbarHeight, estateNavbarHeight, footerHeight, positionChangeWidthSm, sheirGreen } from '../../helpers/variableDefaults'
import { auctionResultItemJSX } from '../../helpers/sharedDraftAuctionJSX'
import { getEventResults } from '../../helpers/getValuationTotals'
import { couldNotLoadPageError } from '../../helpers/errors'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Auction Results Page
const AuctionResults = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId } = useParams()

  // Payload
  const payload = getPayload()

  // URL Path
  const urlPath = window.location.pathname

  // Estate
  // console.log('props ->', props)
  // console.log('props.estate ->', props.estate)
  // console.log('props.estate.event[0].results ->', props.estate.event[0].results)
  // console.log('getEventResults(props.estate) ->', getEventResults(props.estate))
  const [estate, setEstate] = useState(props.hasOwnProperty('estate') ? props.estate : {})
  const [biddingPersons, setBiddingPersons] = useState(props.hasOwnProperty('biddingPersons') ? props.biddingPersons : [])

  // Loading
  const [loading, setLoading] = useState(false)

  // Errors
  const [errors, setErrors] = useState(false)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {

    if (!userIsAuthenticated() && urlPath !== '/demo') {

      // If user is not authenticated, redirect them to the homepage
      navigate('/')

    } else if (!props.hasOwnProperty('estate')) {
      // Get estate data if it has not been supplied in the props
      const getData = async () => {
        setLoading(true)
        try {
          // Retrieve the estate
          const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved Estate ->', retrievedEstate)

          // Find the Person in the estate that's associated with the User
          const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)

          // If there is a Person associated with the User, proceed to set states
          if (userIsInEstate.length > 0 && retrievedEstate.subscriptionIsActive) {
            
            // Set States
            setEstate(retrievedEstate)

            // Bidding Persons
            const heirPersonsOrderedArray = []
            for (let i = 0; i < retrievedEstate.event[0].draftOrder.length; i++) {
              heirPersonsOrderedArray.push(retrievedEstate.people.filter(person => person._id === retrievedEstate.event[0].draftOrder[i])[0])
            }
            setBiddingPersons(heirPersonsOrderedArray)

            setErrors(false)
            setLoading(false)
          } else {

            // If no person associated with the user, redirect to User profile
            navigate(`/user/${payload.sub}`)

            setErrors(false)
            setLoading(false)
          }

        } catch (error) {
          console.log(error)

          // If error retrieving estate data, redirect to User profile
          navigate(`/user/${payload.sub}`)

          setErrors(true)
          setLoading(false)
        }
      }

      getData()
    }

  }, [])


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {urlPath.includes('auction-results') && seoPageTags(
        '404'
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          height: '100vh',
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        <Box 
          sx={{ 
            // backgroundColor: 'pink',
            pb: 6,
            minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
            width: '100vw', 
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              WebkitAppearance: 'none',
              width: '0px', height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, .35)',
              webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',  
          }}
        >

          {/* Title */}
          <Typography
            sx={{
              pt: 4, pb: width > positionChangeWidthSm ? 1 : 1,
              pl: 0,
              fontSize: '26px',
              fontWeight: 'bold',
            }}
          >
            Auction Results
          </Typography>


          {/* Results */}
          {errors ?
            couldNotLoadPageError()
            :
            (estate.hasOwnProperty('event') && estate.hasOwnProperty('items')) && getEventResults(estate).map((item, index) => (
              <Box
                key={index}
                sx={{
                  py: 1,
                  width: '100%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',  
                }}
              >
                {auctionResultItemJSX('auctionResults', index + 1, item, estate, width)}
              </Box>
            ))
          }

        </Box>
      }
    </>
  )
}

export default AuctionResults