
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
import { securityQuestionAnswerTextField } from './textfields.js'
import { selectSecurityQuestion } from './selects.js'
import { securityQuestion1, securityQuestion2, securityQuestion3 } from './variableDefaults.js'

import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Box'



// Set Security Questions
const handleSetSecurityQuestion = (e, questionIndex, registerForm, setRegisterForm) => {

  // Destructure feeback object
  const registerFormEdit = { ...registerForm }

  // Set the answer to the specified question equal to the event target value
  if (registerFormEdit.securityQuestions.length >= questionIndex + 1) {
    registerFormEdit.securityQuestions[questionIndex].q = e.target.value
    registerFormEdit.securityQuestions[questionIndex].a = ''
  } else {
    registerFormEdit.securityQuestions.push(
      {
        q: e.target.value,
        a: '',
      }
    )
  }

  // set the Gift object with the updated feedback
  setRegisterForm({ ...registerForm, securityQuestions: registerFormEdit.securityQuestions })
}

// Set Security Answers
const handleSetSecurityAnswer = (e, questionIndex, registerForm, setRegisterForm) => {

  // Destructure feeback object
  const registerFormEdit = { ...registerForm }

  // Set the answer to the specified question equal to the event target value
  if (registerFormEdit.securityQuestions.length >= questionIndex + 1) {
    registerFormEdit.securityQuestions[questionIndex].a = e.target.value
  } 

  // set the Gift object with the updated feedback
  setRegisterForm({ ...registerForm, securityQuestions: registerFormEdit.securityQuestions })

}

const securityQuestionSelect = (questionIndex, registerForm, setRegisterForm) => {
  // console.log('questionIndex ->', questionIndex)
  // console.log('registerForm ->', registerForm)
  return (
    <>
      {/* Security Question — Question */}
      {selectSecurityQuestion(
        `securityQuestion${questionIndex + 1}`, 
        `Security Question ${questionIndex + 1}`,
        registerForm.securityQuestions.length === 3 ? false : questionIndex === 0 ? false : questionIndex === 1 ? !(registerForm.securityQuestions.length > 0 && registerForm.securityQuestions[0].hasOwnProperty('q') && registerForm.securityQuestions[0].q && registerForm.securityQuestions[0].hasOwnProperty('a') && registerForm.securityQuestions[0].a) : !(registerForm.securityQuestions.length > 0 && registerForm.securityQuestions[0].hasOwnProperty('q') && registerForm.securityQuestions[0].q && registerForm.securityQuestions[0].hasOwnProperty('a') && registerForm.securityQuestions[0].a && registerForm.securityQuestions.length > 1 && registerForm.securityQuestions[1].hasOwnProperty('q') && registerForm.securityQuestions[1].q && registerForm.securityQuestions[1].hasOwnProperty('a') && registerForm.securityQuestions[1].a), 
        registerForm.securityQuestions.length > questionIndex && registerForm.securityQuestions[questionIndex].hasOwnProperty('q') ? registerForm.securityQuestions[questionIndex].q : '', 
        (questionIndex === 0 ? securityQuestion1 : questionIndex === 1 ? securityQuestion2 : securityQuestion3 ).map(answer => <MenuItem value={answer} key={answer}>{answer}</MenuItem>), 
        handleSetSecurityQuestion, 
        registerForm,
        setRegisterForm,
        questionIndex
      )}
    </>
  )
}

const securityAnswerTextField = (questionIndex, form, setForm) => {
  return (
    <>

      {/* Security Question — Answer */}
      {securityQuestionAnswerTextField(
        `answerSecurityQuestion${questionIndex + 1}`, 
        'Answer', 
        form.securityQuestions.length > questionIndex && form.securityQuestions[questionIndex].hasOwnProperty('a') && form.securityQuestions[questionIndex].a ? form.securityQuestions[questionIndex].a : '',
        !(form.securityQuestions.length > questionIndex && form.securityQuestions[questionIndex].hasOwnProperty('q') && form.securityQuestions[questionIndex].q.length > 0),
        'outlined', 
        true, true, true, 
        'new-password', 
        form, 
        setForm, 
        handleSetSecurityAnswer,
        questionIndex
      )}
    </>
  )
}

// Security Question and Answer Elements for Registration (uses Grid)
export const securityQuestionAndAnswerGridItems = (form, setForm) => {
  return (
    <>

      <Grid item xs={12}>
        {securityQuestionSelect(0, form, setForm)}
      </Grid>
      <Grid item xs={12}>
        {securityAnswerTextField(0, form, setForm)}
      </Grid>
      <Grid item xs={12}>
        {securityQuestionSelect(1, form, setForm)}
      </Grid>
      <Grid item xs={12}>
        {securityAnswerTextField(1, form, setForm)}
      </Grid>
      <Grid item xs={12}>
        {securityQuestionSelect(2, form, setForm)}
      </Grid>
      <Grid item xs={12}>
        {securityAnswerTextField(2, form, setForm)}
      </Grid>
    
    </>
  )
}

// Security Question and Answer Elements for User Profile and Reset Password (uses Box)
export const securityQuestionAndAnswerBoxItems = (form, setForm, answersHidden = false) => {
  return (
    <>
      {/* Security Questions and Answers */}
      {(() => {
        const options = []
        for (let i = 0; i < 3; i++) {
          options.push(
            <Box key={i}
              sx={{
                width: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
              }}
            >
              {answersHidden ? 
                form.hasOwnProperty('securityQuestions') && form.securityQuestions[i].q.length > 0 
                &&
                <Box sx={{ width: '100%', mt: 2, mb: 1, fontSize: '18px' }} >
                  <Typography>{form.securityQuestions[i].q}</Typography>
                </Box>
                :
                <>
                  <Box sx={{ width: '100%', mt: 2, mb: 1 }} >
                    {securityQuestionSelect(i, form, setForm)}
                  </Box>
                  <Box textAlign={'left'} sx={{ width: '100%' }} >
                    {securityAnswerTextField(i, form, setForm)}
                  </Box>
                </>
              }
            </Box>
          )
        }
        return options
      })()}
    </>
  )
}