/* eslint-disable no-prototype-builtins */
// React
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import DraftPractice from '../draft/DraftPractice'
import AuctionPractice from '../auction/AuctionPractice'
import TestEstate from './TestEstate'
import PageNavbar from '../common/PageNavbar'
import Footer from '../common/Footer'
import NotFound from '../common/NotFound'

import { selectElement } from '../../helpers/selects'
import { standardButton } from '../../helpers/buttons'
import { navbarHeight, footerHeight, estatesTestDataArray, positionChangeWidthSm, distributionMethod, testProductImageDimension, auctionOrDraft } from '../../helpers/variableDefaults'
import { shuffleArray } from '../../helpers/globalHelpers'
import useWindowDimensions from '../../helpers/windowDimensions'


import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Test Event Page
const TestEvent = (props) => {

  // const {  } = props

  // Navigate
  const navigate = useNavigate()

  // URL Path
  const urlPath = window.location.pathname

  // Dynamic Window Dimensions
  const { height, width } = useWindowDimensions()

  // Params
  const { estateOwnerName, eventType, heirName } = useParams()
  // console.log('estateOwnerName ->', estateOwnerName)

  // Constants
  const estateToSet = { ...estatesTestDataArray.filter(estate => estate.estateOwnerName.toLowerCase() === estateOwnerName.split('-').join(' '))[0] }
  for (let i = 0; i < estateToSet.items.length; i++) {
    if (estateToSet.items[i].allottedToName !== 'Unassigned') {
      estateToSet.items[i].allottedToName = 'Unassigned'
    }
    if (estateToSet.items[i].hasOwnProperty('allottedToPersonId')) {
      delete estateToSet.items[i].allottedToPersonId
    }
    if (estateToSet.event[0].results.length > 0) {
      estateToSet.event[0].results = []
    }

    if (estateToSet.items[i].hasOwnProperty('giftFor') && estateToSet.items[i].giftFor.length > 0) {
      estateToSet.items[i].giftFor = ''
    }
  }
  // estateToSet.items.sort((a, b) => b.valuation - a.valuation)
  // console.log('sorted items ->', estateToSet.items)

  const personToSet = estateToSet.people.filter(person => person.userGivenAndFamilyName.toLowerCase() === heirName.split('-').join(' '))[0]



  useEffect(() => {
    // console.log('estate ->', estate)
    // console.log('userPerson ->', userPerson)
  }, [])

  // useEffect(() => {
  //   // console.log('estate ->', estate)
  //   // console.log('userPerson ->', userPerson)
  // }, [estate, userPerson, viewIndex])


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Test Event'
      )}

      {/* Body */}
      {eventType.toLowerCase() === 'auction' && estateToSet && personToSet ?
        <AuctionPractice estate={estateToSet} userPerson={personToSet}/>
        :
        eventType.toLowerCase() === 'draft' && estateToSet && personToSet ?
          <DraftPractice estate={estateToSet} userPerson={personToSet}/>
          :
          <NotFound />
      }
    </>
  )
}

export default TestEvent