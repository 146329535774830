
// Events
import { alexSpiliyachtesEvent, jayGatsbyEvent, blancheDevereauxEvent, cosmoKramerEvent, hollyGolightlyEvent, johnLennonEvent, albusDumbledoreEvent, lucyRicardoEvent, marilynMonroeEvent, jacquelineKennedyOnassisEvent, bruceWayneEvent, mrMonopolyEvent, homerSimpsonEvent } from './eventsTestData'

// People
import { alexSpiliyachtes, brinkerSpiliyachtes, eatonSpiliyachtes, mariaSpiliyachtes } from './persons/alexSpiliyachtisPersons'
import { jayGatsby, nickCarraway, daisyBuchanan, owlEyes, danCody, meyerWolfsheim, jordanBaker, ewingKlipspringer, tomBuchanan } from './persons/jayGatsbyPersons'
import { blancheDevereaux, dorothyZbornak, sophiaPetrillo, roseNylund } from './persons/blancheDevereauxPersons'
import { cosmoKramer, georgeCostanza, elaineBenes, jerrySeinfeld, newman, jackieChiles, jPeterman, frankCostanza, larryDavid, mortySeinfeld } from './persons/cosmoKramerPersons'
import { hollyGolightly, cat, doe } from './persons/hollyGolightlyPersons'
import { johnLennon, ringoStarr, georgeHarrison, paulMcCartney, yokoOno, eleanorRigby, haywoodJude, maggieMae, sargeantPepper, pennyLane } from './persons/johnLennonPersons'
import { albusDumbledore, harryPotter, ronWeasley, hermioneGranger, minervaMcGonagall, severusSnape, rubeusHagrid, remusLupin, argusFilch } from './persons/albusDumbledorePersons'
import { lucyRicardo, littleLucy, littleRicky } from './persons/lucyRicardoPersons'
import { marilynMonroe, mrPresident, joeDiMaggio, frankSinatra } from './persons/marilynMonroePersons'
import { jacquelineKennedyOnassis, johnFKennedyJr, carolineKennedy } from './persons/kennedyOnassisPersons'
import { bruceWayne, clarkKent, peterParker, jamesBond } from './persons/bruceWaynePersons'
import { mrMonopoly, shoe, hat, iron, car, thimble } from './persons/mrMonopolyPersons'
import { homerSimpson, bartSimpson, lisaSimpson, maggieSimpson } from './persons/homerSimpsonPersons'

// Items
import { pelorusAlexSpiliyachtes, amazonStockAlexSpiliyachtes, ferrariBlueAlexSpiliyachtes, crystalChandelierAlexSpiliyachtes, diamondMineAlexSpiliyachtes, fabergeEggAlexSpiliyachtes, googleStockAlexSpiliyachtes, mansionHollywoodHillsAlexSpiliyachtes, ranchJacksonHoleAlexSpiliyachtes, beachHouseMaltaAlexSpiliyachtes, microsoftStockAlexSpiliyachtes, mondrianPaintingAlexSpiliyachtes, mansionNYCAlexSpiliyachtes, oilRigsAlexSpiliyachtes, persianRugAlexSpiliyachtes, picassoPaintingAlexSpiliyachtes, privateIslandAlexSpiliyachtes, privateJetAlexSpiliyachtes, ferrariRedAlexSpiliyachtes, ritzCarltonViennaAlexSpiliyachtes, rubyCollectionAlexSpiliyachtes, subaruWhiteAlexSpiliyachtes, vanGoghPaintingAlexSpiliyachtes, ferrariYellowAlexSpiliyachtes, helicopterAlexSpiliyachtes, pradaJacketCollectionAlexSpiliyachtes, monetPaintingAlexSpiliyachtes, ivoryTradingCompanyAlexSpiliyachtes, napoleonsRoyalBidetAlexSpiliyachtes, pearlCollectionAlexSpiliyachtes, chateauBretagneAlexSpiliyachtes, bitcoinAlexSpiliyachtes } from './items/alexSpiliyachtisItems'
import { gatsbyMansion, gatsbyRollsRoycePhantom, gatsbyLeatherBooks, gatsbyYacht, gatsbySpeedBoat, gatsbyChandelier, gatsbySuitWhite, gatsbySuitBrown, gatsbySuitPink, gatsbyChampagneCollection, gatsbyLiquorCollection, gatsbySaloonDecor, gatsbySilkShirts, gatsbyCrystalDrinkingGlasses, gatsbySalonChairsSilk, gatsbySalonRug, gatsbySalonCouches, gatsbySalonChairsVelvet, gatsbyGoldCandelabra, gatsbyTableClock, gatsbyTheResurrection } from './items/jayGatsbyItems'
import { blancheMenOfBlanchesBoudoirCalendar, blanchDevereauxDriversLicense, blancheDiorEauDeParfum, blancheSilkRobe, blancheDiorHairMist, blancheDevereauxHouse, blancheLivingRoomFurniture, blancheBedspread, blancheKitchenCeramics, blanchePoolChairs, blancheRedDress, blancheSilverShawl, blanchePinkScarf, blancheWhiteNightgown, blancheBlueSuit, blancheGoldEarrings, blanchePearlNecklace, blancheBedroomPainting, blancheDiamondEarrings, blanchePortrait } from './items/blancheDevereauxItems'
import { hollyGolightlySleepMask, hollyGolightlyBlackDress, hollyGolightlySunglasses, hollyGolightlyWhiteScarf, hollyGolightlyCigaretteHolder, hollyGolightlyBrownCoat, hollyGolightlyBlackHat, hollyGolightlyPearlNecklace, hollyGolightlyTiara, hollyGolightlyBlackGloves, hollyGolightlyDiamondEarrings, hollyGolightlyWhiteNightshirt, hollyGolightlyBlackHandbag, hollyGolightlyPinkThrowPillows, hollyGolightlyWhiteCouch, hollyGolightlyBlackTelephone, hollyGolightlyBlackHighHeels, hollyGolightlyBrownHighHeels, hollyGolightlyPurpleTassleEarrings, hollyGolightlyAddressBook } from './items/hollyGolightlyItems'
import { johnLennonGentlyWeepingGuitar, johnLennonStrawberryFields, johnLennonSunglasses, johnLennonYellowSubmarine, johnLennonLucysDiamonds, johnLennonOctopusGarden, johnLennonSilverHammer, johnLennonBillsBungalow, johnLennonOldBrownShoe, johnLennonRockyRaccoon, johnLennonBathroomWindow, johnLennonGlassOnion, johnLennonFineFeeling, johnLennonEighthDay, johnLennonHoneyPie, johnLennonTicketToRide, johnLennonRevolution, johnLennonMonkey, johnLennonWarmGun, johnLennonNorwegianWood, johnLennonWindingRoad, johnLennonHand, johnLennonWalrus, johnLennonPennies, johnLennonPaperback, johnLennonSomething } from './items/johnLennonItems'
import { albusDumbledoreFakeGalleons, albusDumbledoreDeluminator, albusDumbledoreInvisibilityCloak, albusDumbledoreElderWand, albusDumbledoreFoeGlass, albusDumbledoreMaraudersMap, albusDumbledoreProbityProbe, albusDumbledoreRemembrall, albusDumbledoreRevealer, albusDumbledoreSneakoscope, albusDumbledoreExplodingSnaps, albusDumbledoreGobstones, albusDumbledoreWizardChess, albusDumbledoreSortingHat, albusDumbledoreMirrorOfErised, albusDumbledoreTwoWayMirror, albusDumbledoreMokeskinPouch, albusDumbledorePensieve, albusDumbledoreFlooPowder, albusDumbledorePortkey, albusDumbledoreTimeTurner, albusDumbledoreVanishingCabinet, albusDumbledoreGubraithianFire, albusDumbledoreOmnioculars } from './items/albusDumbledoreItems'
import { cosmoKramerPortrait, cosmoKramerSmokingJacket, cosmoKramerPipes, cosmoKramerSunglasses, cosmoKramerSuedeJacket, cosmoKramerKramerica, cosmoKramerRaincoats, cosmoKramerMoviefone, cosmoKramerCologne, cosmoKramerTieDispenser, cosmoKramerOilTankBladder, cosmoKramerCoffeeTableBook, cosmoKramerTheBro, cosmoKramerTitleist, cosmoKramerPBJRestaurant, cosmoKramerPizzaParlor, cosmoKramerBottleDeposit, cosmoKramerPersonalAnecdotes, cosmoKramerCarPeriscopes, cosmoKramerMuffinTops, cosmoKramerGarbageDisposal, cosmoKramerButterAftershave, cosmoKramerCatchupMustardBottle } from './items/cosmoKramerItems'
import { mrMonopolyMediterraneanAvenue, mrMonopolyBalticAvenue, mrMonopolyReadingRailroad, mrMonopolyOrientalAvenue, mrMonopolyVermontAvenue, mrMonopolyConnecticutAvenue, mrMonopolyStCharlesPlace, mrMonopolyElectricCompany, mrMonopolyStatesAvenue, mrMonopolyVirginiaAvenue, mrMonopolyPennsylvaniaRailroad, mrMonopolyStJamesPlace, mrMonopolyTennesseeAvenue, mrMonopolyNewYorkAvenue, mrMonopolyKentuckyAvenue, mrMonopolyIndianaAvenue, mrMonopolyIllinoisAvenue, mrMonopolyBORailroad, mrMonopolyAtlanticAvenue, mrMonopolyVentnorAvenue, mrMonopolyWaterWorks, mrMonopolyMarvinGardens, mrMonopolyPacificAvenue, mrMonopolyNorthCarolinaAvenue, mrMonopolyPennsylvaniaAvenue, mrMonopolyShortLine, mrMonopolyParkPlace, mrMonopolyBoardwalk } from './items/mrMonopolyItems'
import { bruceWayneBatsuit, bruceWayneBatmobile, bruceWayneManor, bruceWayneBatCape, bruceWayneMicrowaveEmitter, bruceWayneBatarangs, bruceWayneBatpod, bruceWayneBatcave, bruceWayneUtilityBelt, bruceWayneAlfred, bruceWayneWayneEnterprises, bruceWaynePrivateJet, bruceWayneHimalayasTrainingStudio, bruceWayneBatcomputer, bruceWayneBatwing, bruceWayneCashReserves, bruceWayneRecoverySpa, bruceWayneRobinsLoyalty, bruceWayneCatwomansLoyalty, bruceWayneFlockOfBats } from './items/bruceWayneItems'

// Helpers
import { shuffleArray } from '../../../helpers/globalHelpers'

// Estates

// Alex Spiliyachtes
export const alexSpiliyachtesEstate = {
  _id: 'alexSpiliyachtesEstateId',
  ownerUserId: 'alexSpiliyachtesUserId',
  estateName: 'The Alex Spiliyachtes Estate',
  estateOwnerName: 'Alex Spiliyachtes',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-alex.jpg',
  mission: 'To minimize sibling drama by distributing assets fairly and transparently among heirs.',
  totalTaxes: '0',
  subscriptionIsActive: true,
  event: [alexSpiliyachtesEvent],
  people: [alexSpiliyachtes, brinkerSpiliyachtes, eatonSpiliyachtes, mariaSpiliyachtes],
  items: [pelorusAlexSpiliyachtes, amazonStockAlexSpiliyachtes, ferrariBlueAlexSpiliyachtes, crystalChandelierAlexSpiliyachtes, diamondMineAlexSpiliyachtes, fabergeEggAlexSpiliyachtes, googleStockAlexSpiliyachtes, mansionHollywoodHillsAlexSpiliyachtes, ranchJacksonHoleAlexSpiliyachtes, beachHouseMaltaAlexSpiliyachtes, microsoftStockAlexSpiliyachtes, mondrianPaintingAlexSpiliyachtes, mansionNYCAlexSpiliyachtes, oilRigsAlexSpiliyachtes, persianRugAlexSpiliyachtes, picassoPaintingAlexSpiliyachtes, privateIslandAlexSpiliyachtes, privateJetAlexSpiliyachtes, ferrariRedAlexSpiliyachtes, ritzCarltonViennaAlexSpiliyachtes, rubyCollectionAlexSpiliyachtes, subaruWhiteAlexSpiliyachtes, vanGoghPaintingAlexSpiliyachtes, ferrariYellowAlexSpiliyachtes, helicopterAlexSpiliyachtes, pradaJacketCollectionAlexSpiliyachtes, monetPaintingAlexSpiliyachtes, ivoryTradingCompanyAlexSpiliyachtes, napoleonsRoyalBidetAlexSpiliyachtes, pearlCollectionAlexSpiliyachtes, chateauBretagneAlexSpiliyachtes, bitcoinAlexSpiliyachtes],
}

// Jay Gatsby
export const jayGatsbyEstate = {
  _id: 'jayGatsbyEstateId',
  ownerUserId: 'jayGatsbyUserId',
  estateName: 'The Jay Gatsby Estate',
  estateOwnerName: 'Jay Gatsby',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-jay-gatsby-1.jpg',
  mission: 'To live on as a symbol of hope, Old Sport.',
  totalTaxes: '6000000',
  subscriptionIsActive: true,
  event: [jayGatsbyEvent],
  people: [jayGatsby, nickCarraway, daisyBuchanan, owlEyes, danCody, meyerWolfsheim, jordanBaker, ewingKlipspringer, tomBuchanan],
  items: [gatsbyMansion, gatsbyRollsRoycePhantom, gatsbyLeatherBooks, gatsbyYacht, gatsbySpeedBoat, gatsbyChandelier, gatsbySuitWhite, gatsbySuitBrown, gatsbySuitPink, gatsbyChampagneCollection, gatsbyLiquorCollection, gatsbySaloonDecor, gatsbySilkShirts, gatsbyCrystalDrinkingGlasses, gatsbySalonChairsSilk, gatsbySalonRug, gatsbySalonCouches, gatsbySalonChairsVelvet, gatsbyGoldCandelabra, gatsbyTableClock, gatsbyTheResurrection],
}

// Blanche Devereaux
export const blancheDevereauxEstate = {
  _id: 'blancheDevereauxEstateId',
  ownerUserId: 'blancheDevereauxUserId',
  estateName: 'The Blanche Devereaux Estate',
  estateOwnerName: 'Blanche Devereaux',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-blanche-devereaux-1.jpg',
  mission: 'To minimize sibling drama by distributing assets fairly and transparently among heirs.',
  totalTaxes: '0',
  subscriptionIsActive: true,
  event: [blancheDevereauxEvent],
  people: [blancheDevereaux, dorothyZbornak, sophiaPetrillo, roseNylund],
  items: [blancheMenOfBlanchesBoudoirCalendar, blanchDevereauxDriversLicense, blancheDiorEauDeParfum, blancheSilkRobe, blancheDiorHairMist, blancheDevereauxHouse, blancheLivingRoomFurniture, blancheBedspread, blancheKitchenCeramics, blanchePoolChairs, blancheRedDress, blancheSilverShawl, blanchePinkScarf, blancheWhiteNightgown, blancheBlueSuit, blancheGoldEarrings, blanchePearlNecklace, blancheBedroomPainting, blancheDiamondEarrings, blanchePortrait],
}

// Holly Golightly
export const hollyGolightlyEstate = {
  _id: 'hollyGolightlyEstateId',
  ownerUserId: 'hollyGolightlyUserId',
  estateName: 'The Holly Golightly Estate',
  estateOwnerName: 'Holly Golightly',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-holly-golightly-1.jpg',
  mission: 'To minimize sibling drama by distributing assets fairly and transparently among heirs.',
  totalTaxes: '0',
  subscriptionIsActive: true,
  event: [hollyGolightlyEvent],
  people: [hollyGolightly, cat, doe],
  items: [hollyGolightlySleepMask, hollyGolightlyBlackDress, hollyGolightlySunglasses, hollyGolightlyWhiteScarf, hollyGolightlyCigaretteHolder, hollyGolightlyBrownCoat, hollyGolightlyBlackHat, hollyGolightlyPearlNecklace, hollyGolightlyTiara, hollyGolightlyBlackGloves, hollyGolightlyDiamondEarrings, hollyGolightlyWhiteNightshirt, hollyGolightlyBlackHandbag, hollyGolightlyPinkThrowPillows, hollyGolightlyWhiteCouch, hollyGolightlyBlackTelephone, hollyGolightlyBlackHighHeels, hollyGolightlyBrownHighHeels, hollyGolightlyPurpleTassleEarrings, hollyGolightlyAddressBook],
}

// John Lennon
export const johnLennonEstate = {
  _id: 'johnLennonEstateId',
  ownerUserId: 'johnLennonUserId',
  estateName: 'The John Lennon Estate',
  estateOwnerName: 'John Lennon',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-john-lennon-1.jpg',
  mission: 'For everyone to feel fine and let it be.',
  totalTaxes: '600000',
  subscriptionIsActive: true,
  event: [johnLennonEvent],
  people: [johnLennon, ringoStarr, georgeHarrison, paulMcCartney, yokoOno, eleanorRigby, haywoodJude, maggieMae, sargeantPepper, pennyLane],
  items: [johnLennonGentlyWeepingGuitar, johnLennonStrawberryFields, johnLennonSunglasses, johnLennonYellowSubmarine, johnLennonLucysDiamonds, johnLennonOctopusGarden, johnLennonSilverHammer, johnLennonBillsBungalow, johnLennonOldBrownShoe, johnLennonRockyRaccoon, johnLennonBathroomWindow, johnLennonGlassOnion, johnLennonFineFeeling, johnLennonEighthDay, johnLennonHoneyPie, johnLennonTicketToRide, johnLennonRevolution, johnLennonMonkey, johnLennonWarmGun, johnLennonNorwegianWood, johnLennonWindingRoad, johnLennonHand, johnLennonWalrus, johnLennonPennies, johnLennonPaperback, johnLennonSomething],
}

// Albus Dumbledore
export const albusDumbledoreEstate = {
  _id: 'albusDumbledoreEstateId',
  ownerUserId: 'albusDumbledoreUserId',
  estateName: 'The Albus Dumbledore Estate',
  estateOwnerName: 'Albus Dumbledore',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-dumbledore-1.jpg',
  mission: 'For the dark arts to never win.',
  totalTaxes: '60000',
  subscriptionIsActive: true,
  event: [albusDumbledoreEvent],
  people: [albusDumbledore, harryPotter, ronWeasley, hermioneGranger, minervaMcGonagall, severusSnape, rubeusHagrid, remusLupin, argusFilch],
  items: [albusDumbledoreFakeGalleons, albusDumbledoreDeluminator, albusDumbledoreInvisibilityCloak, albusDumbledoreElderWand, albusDumbledoreFoeGlass, albusDumbledoreMaraudersMap, albusDumbledoreProbityProbe, albusDumbledoreRemembrall, albusDumbledoreRevealer, albusDumbledoreSneakoscope, albusDumbledoreExplodingSnaps, albusDumbledoreGobstones, albusDumbledoreWizardChess, albusDumbledoreSortingHat, albusDumbledoreMirrorOfErised, albusDumbledoreTwoWayMirror, albusDumbledoreMokeskinPouch, albusDumbledorePensieve, albusDumbledoreFlooPowder, albusDumbledorePortkey, albusDumbledoreTimeTurner, albusDumbledoreVanishingCabinet, albusDumbledoreGubraithianFire, albusDumbledoreOmnioculars],
}

// Cosmo Kramer
export const cosmoKramerEstate = {
  _id: 'cosmoKramerEstateId',
  ownerUserId: 'cosmoKramerUserId',
  estateName: 'The Cosmo Kramer Estate',
  estateOwnerName: 'Cosmo Kramer',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-cosmo-kramer-1.jpg',
  mission: 'For everyone to feel good all the time.',
  totalTaxes: '600',
  subscriptionIsActive: true,
  event: [cosmoKramerEvent],
  people: [cosmoKramer, georgeCostanza, elaineBenes, jerrySeinfeld, newman, jackieChiles, jPeterman, frankCostanza, larryDavid, mortySeinfeld],
  items: [cosmoKramerPortrait, cosmoKramerSmokingJacket, cosmoKramerPipes, cosmoKramerSunglasses, cosmoKramerSuedeJacket, cosmoKramerKramerica, cosmoKramerRaincoats, cosmoKramerMoviefone, cosmoKramerCologne, cosmoKramerTieDispenser, cosmoKramerOilTankBladder, cosmoKramerCoffeeTableBook, cosmoKramerTheBro, cosmoKramerTitleist, cosmoKramerPBJRestaurant, cosmoKramerPizzaParlor, cosmoKramerBottleDeposit, cosmoKramerPersonalAnecdotes, cosmoKramerCarPeriscopes, cosmoKramerMuffinTops, cosmoKramerGarbageDisposal, cosmoKramerButterAftershave, cosmoKramerCatchupMustardBottle],
}

// Mr Monopoply
export const mrMonopolyEstate = {
  _id: 'mrMonopolyEstateId',
  ownerUserId: 'mrMonopolyUserId',
  estateName: 'The Mr Monopoly Estate',
  estateOwnerName: 'Mr Monopoly',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-mr-monopoly-1.jpg',
  mission: 'To minimize sibling drama by distributing assets fairly and transparently among heirs.',
  totalTaxes: '0',
  subscriptionIsActive: true,
  event: [mrMonopolyEvent],
  people: [mrMonopoly, shoe, hat, iron, car, thimble],
  items: [mrMonopolyMediterraneanAvenue, mrMonopolyBalticAvenue, mrMonopolyReadingRailroad, mrMonopolyOrientalAvenue, mrMonopolyVermontAvenue, mrMonopolyConnecticutAvenue, mrMonopolyStCharlesPlace, mrMonopolyElectricCompany, mrMonopolyStatesAvenue, mrMonopolyVirginiaAvenue, mrMonopolyPennsylvaniaRailroad, mrMonopolyStJamesPlace, mrMonopolyTennesseeAvenue, mrMonopolyNewYorkAvenue, mrMonopolyKentuckyAvenue, mrMonopolyIndianaAvenue, mrMonopolyIllinoisAvenue, mrMonopolyBORailroad, mrMonopolyAtlanticAvenue, mrMonopolyVentnorAvenue, mrMonopolyWaterWorks, mrMonopolyMarvinGardens, mrMonopolyPacificAvenue, mrMonopolyNorthCarolinaAvenue, mrMonopolyPennsylvaniaAvenue, mrMonopolyShortLine, mrMonopolyParkPlace, mrMonopolyBoardwalk],
}

// Bruce Wayne
export const bruceWayneEstate = {
  _id: 'bruceWayneEstateId',
  ownerUserId: 'bruceWayneUserId',
  estateName: 'The Bruce Wayne Estate',
  estateOwnerName: 'Bruce Wayne',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-bruce-wayne-4.jpg',
  mission: 'To minimize sibling drama by distributing assets fairly and transparently among heirs.',
  totalTaxes: '0',
  subscriptionIsActive: true,
  event: [bruceWayneEvent],
  people: [bruceWayne, clarkKent, peterParker, jamesBond],
  items: [bruceWayneBatsuit, bruceWayneBatmobile, bruceWayneManor, bruceWayneBatCape, bruceWayneMicrowaveEmitter, bruceWayneBatarangs, bruceWayneBatpod, bruceWayneBatcave, bruceWayneUtilityBelt, bruceWayneAlfred, bruceWayneWayneEnterprises, bruceWaynePrivateJet, bruceWayneHimalayasTrainingStudio, bruceWayneBatcomputer, bruceWayneBatwing, bruceWayneCashReserves, bruceWayneRecoverySpa, bruceWayneRobinsLoyalty, bruceWayneCatwomansLoyalty, bruceWayneFlockOfBats],
}

// Lucy Ricardo
export const lucyRicardoEstate = {
  _id: 'lucyRicardoEstateId',
  ownerUserId: 'lucyRicardoUserId',
  estateName: 'The Lucy Ricardo Estate',
  estateOwnerName: 'Lucy Ricardo',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-lucy-ricardo-1.jpg',
  mission: 'To minimize sibling drama by distributing assets fairly and transparently among heirs.',
  totalTaxes: '0',
  subscriptionIsActive: true,
  event: [lucyRicardoEvent],
  people: [lucyRicardo, littleLucy, littleRicky],
  items: [],
}

// Marilyn Monroe
export const marilynMonroeEstate = {
  _id: 'marilynMonroeEstateId',
  ownerUserId: 'marilynMonroeUserId',
  estateName: 'The Marilyn Monroe Estate',
  estateOwnerName: 'Marilyn Monroe',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-marilyn-monroe-1.jpg',
  mission: 'To minimize sibling drama by distributing assets fairly and transparently among heirs.',
  totalTaxes: '0',
  subscriptionIsActive: true,
  event: [marilynMonroeEvent],
  people: [marilynMonroe, mrPresident, joeDiMaggio, frankSinatra],
  items: [],
}

// Jacqueline Kennedy Onassis
export const jacquelineKennedyOnassisEstate = {
  _id: 'jacquelineKennedyOnassisEstateId',
  ownerUserId: 'jacquelineKennedyOnassisUserId',
  estateName: 'The Jacqueline Kennedy Onassis Estate',
  estateOwnerName: 'Jacqueline Kennedy Onassis',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-jacqueline-kennedy-onassis-1.jpg',
  mission: 'To minimize sibling drama by distributing assets fairly and transparently among heirs.',
  totalTaxes: '0',
  subscriptionIsActive: true,
  event: [jacquelineKennedyOnassisEvent],
  people: [jacquelineKennedyOnassis, johnFKennedyJr, carolineKennedy],
  items: [],
}

// Homer Simpson 
export const homerSimpsonEstate = {
  _id: 'homerSimpsonEstateId',
  ownerUserId: 'homerSimpsonUserId',
  estateName: 'The Homer Simpson Estate',
  estateOwnerName: 'Homer Simpson',
  estateOwnerProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-homer-simpson-1.jpg',
  mission: 'To minimize sibling drama by distributing assets fairly and transparently among heirs.',
  totalTaxes: '0',
  subscriptionIsActive: true,
  event: [homerSimpsonEvent],
  people: [homerSimpson, bartSimpson, lisaSimpson, maggieSimpson],
  items: [],
}


// Sherman McCoy



// Larry David

