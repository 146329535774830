/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import useWindowDimensions from '../../../helpers/windowDimensions'
import { positionChangeHeightSm, positionChangeWidthSm, positionChangeWidthMd, silentDistributionMethods, distributionMethod, liveOrAuto } from '../../../helpers/variableDefaults'
import { personItemTextField } from '../../../helpers/textfields'
import { selectElement } from '../../../helpers/selects'
import { eventDateElement } from '../../../helpers/dateAndTime'
import { heirAllotmentsJSX, allocationProcessJSX, transparencyLevelJSX } from '../../../helpers/ownerPersonSpaceTabs'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'

// Create Estate Settings Page
const Settings = (props) => {

  // Destructure Props
  const { options, setOptions } = props

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  const [sharesOver100, setSharesOver100] = useState(false)

  // Set sharePercentage for each Heir to be equal and add up to 100 upon page load
  useEffect(() => {

    // Set default share allocations to even splits
    const newPeople = options.metadata.people.map(person => person.role === 'Heir' ? { ...person, sharePercentage: (100 / (options.metadata.people.filter(person => person.role === 'Heir').length)).toFixed(2).toString() } : { ...person })
    // console.log('newPeople ->', newPeople)

    // Default event date is one year from now
    const today = new Date()
    const oneYearFromNow = new Date(new Date().setFullYear(today.getFullYear() + 1))
    // const oneWeekFromNow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)

    const newOptionsMetadata = { ...options.metadata } 
    newOptionsMetadata.people = newPeople
    newOptionsMetadata.startDate = oneYearFromNow

    // Set default estate name
    if (newPeople[0].hasOwnProperty('role') && newPeople[0].role === 'Owner' && newPeople[0].hasOwnProperty('givenName') && newPeople[0].givenName.length > 0 && newPeople[0].hasOwnProperty('familyName') && newPeople[0].familyName.length > 0) {
      newOptionsMetadata.estateName = `The ${newPeople[0].givenName} ${newPeople[0].familyName} Estate`
    } 
    
    setOptions({ ...options, metadata: newOptionsMetadata })

    // Collect Analytics
    customAnalyticsEvent(
      `onboarding_3_settings`, 
      `onboarding_3_settings`, 
      `onboarding_3_settings`, 
      `onboarding_3_settings`, 
      'Onboarding Step 3 — Settings'
    )

  }, [])

  // Updates view whenever estateObj updates
  // useEffect(() => {
  //   console.log('options ->', options)
  // }, [options])

  const handleSettingsChange = async (e, setPassedOptions, passedOptions, personIndex) => {
    // console.log('handleSettingsChange runs')
    setSharesOver100(false)

    // Create name and value variables
    let name
    let value

    // Assign values to name and value depending on the type of event target
    if (!e.target.name) {
      name = e.target.classList[1]
      value = e.target.classList[0].slice(5)
    } else {
      name = e.target.name
      value = e.target.value
    }
    // console.log('name ->', name)
    // console.log('value ->', value)
    // console.log('personIndex ->', personIndex)

    const newOptionsMetadata = passedOptions.hasOwnProperty('metadata') ? { ...passedOptions.metadata } : { ...passedOptions }

    if (personIndex > -1) {
      // For whatever reason, spreading doesn't separate the new obj from the old one, so this has to be done in a roundabout way until I learn of something better

      let sharePercentageTotal = 0
      await newOptionsMetadata.people.forEach((person, index) => {
        if (index !== personIndex) {
          sharePercentageTotal = sharePercentageTotal + parseFloat(person.sharePercentage)
        } else if (value) {
          sharePercentageTotal = sharePercentageTotal + parseFloat(value)
        }
      })
      // console.log('sharePercentageTotal ->', sharePercentageTotal)

      if (sharePercentageTotal <= 100) {
        newOptionsMetadata.people[personIndex][name] = value ? value : '0.00'
        setSharesOver100(false)
        setOptions({ ...options, metadata: newOptionsMetadata })
      } else {
        setSharesOver100(true)
      }
      
    } else {
      newOptionsMetadata[name] = value
      if (name === 'silentOrParticipatory' && (value === 'Silent' || value === 'Partial')) {
        newOptionsMetadata.distributionMethod = 'Allocate All'
        newOptionsMetadata.liveOrAuto = 'Auto'
      }
      setOptions({ ...options, metadata: newOptionsMetadata })
    }
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {seoPageTags(
        '404'
      )} */}

      {/* Body */}
      <Box sx={{ mt: 4, mb: 4, width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        
        {/* Estate Name */}
        <Box sx={{ mt: 0, mb: 1, width: '90%', maxWidth: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {personItemTextField('estateName', 'Estate Name*', 'text', options.metadata.estateName, '', false, true, handleSettingsChange, setOptions, options, -1)}
        </Box>

        {/*  Heir Allocations */}
        {options.metadata.people.filter(person => person.role === 'Heir').length > 0 &&
          heirAllotmentsJSX(options.metadata, setOptions, sharesOver100, handleSettingsChange, width)
        }

        {/* Transparency Level */}
        {transparencyLevelJSX('onboarding', options.metadata.silentOrParticipatory, options.metadata, setOptions, handleSettingsChange, width)}

        {/* Allocation Process */}
        {options.metadata.silentOrParticipatory === 'Participatory' && allocationProcessJSX('onboarding', options.metadata.distributionMethod, options.metadata.liveOrAuto, options.metadata, setOptions, handleSettingsChange, width)}

      </Box>
    </>
  )
}

export default Settings