/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'

import profPicDefault from '../../assets/seed-profpic-default-4.jpg'

import useWindowDimensions from '../../helpers/windowDimensions'
import { securityQuestionAndAnswerBoxItems } from '../../helpers/securityQuestionsAndAnswers'
import { validEmail, handleChange, Input } from '../../helpers/globalHelpers'
import { positionChangeWidthSm, profPicDefaultURL, positionChangeWidthMd, positionChangeHeightSm, navbarHeight, footerHeight, sheirGreen, sheirBlue, sheirYellow, bottomSteps, onboardingStartingPeopleArray } from '../../helpers/variableDefaults.js'
import { userIsAuthenticated, getPayload, getTokenFromLocalStorage, setProfPicToLocalStorage } from '../../helpers/storage.js'
import { standardButton, replaceImageWithIconButton } from '../../helpers/buttons.js'
import { standardErrorContainer } from '../../helpers/errors.js'
import { standardSpinner } from '../../helpers/spinners.js'
import { loginTextField } from '../../helpers/textfields'
import { handleImageSelect } from '../../helpers/profilePictureElements'

import { userProfilieSectionTitle, titleAndEditButton, personalDetailsTextFieldOrTypography, personalDetailsTypography, userProfileEstateElement } from '../../helpers/userProfileElements'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// User Profile Page
const UserProfile = () => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { userId } = useParams()

  // User
  const [user, setUser] = useState({})
  const [previousUser, setPreviousUser] = useState({})
  const [previousImage, setPreviousImage] = useState('') //For Deleting Previous Profile Picture

  // Estates
  const [estates, setEstates] = useState([])

  // Edit Modes
  const [editPersonalDetails, setEditPersonalDetails] = useState(false)
  const [editSecurityQuestions, setEditSecurityQuestions] = useState(false)

  // Loading
  const [loading, setLoading] = useState(false)
  const [loadingProfPic, setLoadingProfPic] = useState(false)
  const [loadingPersonalDetailsEdits, setLoadingPersonalDetailsEdits] = useState(false)
  const [loadingSecurityQuestionsEdits, setLoadingSecurityQuestionsEdits] = useState(false)

  // Errors
  const [uploadProfilePictureErrors, setUploadProfilePictureErrors] = useState(false)
  const [uploadPersonalEditsErrors, setUploadPersonalEditsErrors] = useState(false)
  const [uploadSecurityQuestionsErrors, setUpploadSecurityQuestionsErrors] = useState(false)

  const resetErrors = () => {
    setUploadProfilePictureErrors(false)
    setUploadPersonalEditsErrors(false)
    setUpploadSecurityQuestionsErrors(false)
  }

  const resetLoading = () => {
    setLoadingProfPic(false)
    setLoadingPersonalDetailsEdits(false)
    setLoadingSecurityQuestionsEdits(false)
  }

  // Update estate obj
  const updateEstateObj = async (retrievedUser) => {
    // Find Estates that the User Belongs to
    if (retrievedUser.hasOwnProperty('persons') && retrievedUser.persons.length > 0) {
      const userEstates = []

      for (let i = 0; i < retrievedUser.persons.length; i++) {
        const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${retrievedUser.persons[i].estateId}`, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })

        // console.log('retrievedEstate ->', retrievedEstate)

        if (retrievedUser.persons[i].role !== 'Heir' || (retrievedEstate.hasOwnProperty('event') && retrievedEstate.event[0].hasOwnProperty('silentOrParticipatory') && retrievedEstate.event[0].silentOrParticipatory !== 'Silent')) {
          const retrievedEstateWithUserRole = { 
            ...retrievedEstate, 
            userRole: retrievedUser.persons[i].role, 
            userIsCustomer: retrievedUser._id === retrievedEstate.customerUserId,
            silentOrParticipatory: retrievedEstate.event[0].silentOrParticipatory,
            currentUserId: userId,
            userPersonId: retrievedUser.persons[i]._id,
          }
          userEstates.push(retrievedEstateWithUserRole)
        }
      }
      // console.log('userEstates ->', userEstates)

      setEstates(userEstates)

      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  // Do this so that the answers to the frontend never touch the frontend and are not saved as part of the User state
  const getUserSecurityQuestions = async () => {
    
    const { data: retrievedQuestions } =  await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${userId}/security-questions`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    })
    // console.log('retrievedQuestions ->', retrievedQuestions)

    return retrievedQuestions
  }

  // UseEffect — Get User data and set estates state
  useEffect(() => {

    if (!userIsAuthenticated() || !userId) {
      navigate(`/login`)

    } else {
      const fetchData = async () => {
        setLoading(true)
        // console.log('userId ->', userId)
        try {
          const { data: retrievedUser } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${userId}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })

          // console.log('retrievedUser ->', retrievedUser)

          if (retrievedUser._id === userId) {
            
            // Do this so that the answers to the security questions never touch the frontend and are not saved as part of the User state
            retrievedUser.securityQuestions = await getUserSecurityQuestions() 
            
            // Set User State
            setUser(retrievedUser)
            setPreviousUser(retrievedUser)
            setPreviousImage(retrievedUser.profilePicture)

            updateEstateObj(retrievedUser)

          } else {
            useNavigate('/login')
            setLoading(false)
          }

        } catch (err) {
          // console.log(err)

          useNavigate('/login')
          setLoading(false)
        }
      }
  
      fetchData()
    }
  }, [])

  // Updates view whenever estates state changes
  useEffect(() => {
    // console.log('update estates state useEffect runs')
  }, [estates])

  // Upload modifications in real time to server
  const uploadModifiedUser = async (modifiedPropertiesObj) => {
    // console.log('uploadModifiedUser runs')
    
    try {

      // PUT request with updated profile object
      // await axios.put(`/api/users/${userId}`, newForm, {
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/${userId}`, modifiedPropertiesObj, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })

      // update local storage
      if (modifiedPropertiesObj.hasOwnProperty('profilePicture')) {
        window.localStorage.removeItem('profPic')
        setProfPicToLocalStorage(modifiedPropertiesObj.profilePicture)
      }

      if (modifiedPropertiesObj.hasOwnProperty('profilePicture') || modifiedPropertiesObj.hasOwnProperty('givenName') || modifiedPropertiesObj.hasOwnProperty('familyName') || modifiedPropertiesObj.hasOwnProperty('email')) {
        updateEstateObj({ ...user, modifiedPropertiesObj })
      }
      
    } catch (error) {
      // console.log(error)

      // error message posting new profile
      resetLoading()

      if (modifiedPropertiesObj.hasOwnProperty('profilePicture')) {
        setUploadProfilePictureErrors(true)
      } else if (modifiedPropertiesObj.hasOwnProperty('givenName')) {
        setUploadPersonalEditsErrors(true)
      } else if (modifiedPropertiesObj.hasOwnProperty('securityQuestions')) {
        setUpploadSecurityQuestionsErrors(true)
      }
      
    }

  }

  const resetPasswordPressed = () => {
    // console.log('resetPasswordPressed')
    resetErrors()
    navigate('/reset-password')
  }

  const editPersonalDetailsPressed = () => {
    // console.log('editPersonalDetailsPressed')

    setUser(previousUser)
    
    resetErrors()
    setEditSecurityQuestions(false)

    setEditPersonalDetails(!editPersonalDetails)
  }

  const personalDetailsChangeHandler = async (e, setErrors, setFormData, formData) => {
    // console.log('personalDetailsChangeHandler')

    const { name, value } = e.target

    setErrors(false)

    setFormData({ ...formData, [name]: value })
  }

  const removeSecurityAnswersFromUser = async () => {
    const userObj = { ...user }
    const userQuestions = await userObj.securityQuestions.map(question => {
      question.a = ''
      return question
    })
    userObj.securityQuestions = userQuestions
    setUser(userObj)
  }

  const editSecurityQuestionsPressed = async () => {
    // console.log('editSecurityQuestionsPressed')
    
    if (editSecurityQuestions) {
      await removeSecurityAnswersFromUser()
    }

    resetErrors()
    setEditPersonalDetails(false)

    setEditSecurityQuestions(!editSecurityQuestions)
  }

  const savePersonalDetailsPressed = async () => {
    // console.log('savePersonalDetailsPressed')

    setLoadingPersonalDetailsEdits(true)

    // Modify User Object to Backend
    const modifiedPropertiesObj = { 
      givenName: user.givenName,
      familyName: user.familyName,
      email: user.email,
    }
    await uploadModifiedUser(modifiedPropertiesObj)

    setPreviousUser(user)

    setLoadingPersonalDetailsEdits(false)
    setEditPersonalDetails(false)
  }

  const saveSecurityQuestionsPressed = async () => {
    // console.log('saveSecurityQuestionsPressed')

    setLoadingSecurityQuestionsEdits(true)

    // Modify User Object to Backend
    const modifiedPropertiesObj = { 
      securityQuestions: user.securityQuestions,
    }
    await uploadModifiedUser(modifiedPropertiesObj)

    await removeSecurityAnswersFromUser()
    setEditSecurityQuestions(false)
    setLoadingSecurityQuestionsEdits(false)

  }

  const handleSettingsPressed = async (e, estate) => {
    e.preventDefault()
    // console.log('handleSettingsPressed runs')

    try {
      setLoading(true)

      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/customers/${estate._id}`, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })
      // console.log('data ->', data)

      window.location.href = data.url

      // setLoading(false)

    } catch (error) {
      // console.log('error connecting to customer portal ->', error)

      setLoading(false)
    }
  }


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'User Profile'
      )}

      {/* Body */}

      {loading ?
        <Box sx={{ 
          width: '100%',
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        : 
        <Box 
          sx={{ 
            width: '100%',
            minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`, 
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
          }}
        >
          <Box sx={{ mt: 4, mb: 4, width: '100%', maxWidth: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

            {/* This must be first input So that the file upload only fires when you press the button */}
            <Box sx={{ width: '100%' }}>
              <Input type="text" autoFocus="autoFocus" />
            </Box>

            {/* Profile Picture */}
            {replaceImageWithIconButton(
              user.profilePicture, 
              previousImage,
              setPreviousImage,
              width > 399 ? 200 : width > 299 ? 200 : 175, 
              'icon-button-file', 
              'replace-prof-pic', 
              handleImageSelect,
              -1,
              loadingProfPic, 
              setLoadingProfPic, 
              resetErrors, 
              user._id, 
              uploadModifiedUser, 
              user, 
              setUser, 
              'profilePicture', 
              uploadProfilePictureErrors
            )}

            {/* Error Message, if errors */}
            {uploadProfilePictureErrors &&
              standardErrorContainer(
                'Error uploading image. Please try again.',
                0,
                0
              )
            }


            {/* My Estates */}
            <Box sx={{ mt: width < positionChangeWidthSm ? -1 : -1, mb: 0, width: '90%', maxWidth: '450px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                {userProfilieSectionTitle('Estates')}
              </Box>
              <Box 
                sx={{
                  backgroundColor: 'whitesmoke',
                  mt: 1,
                  pl: 3, pr: 3, pt: 1, pb: 1,
                  borderRadius: '5px',
                  boxShadow: 3,
                  border: 2,
                  borderColor: 'black',
                  display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
              >
                {/* Estates that the User is a Person in */}
                {estates.length > 0 ? 
                  estates.map((estate, estateIndex) => 
                    userProfileEstateElement(estate, estateIndex, width, handleSettingsPressed)
                  )
                  :
                  <Typography>
                    You are not currently in any estates.
                  </Typography>

                }
                
              </Box>
            </Box>

            {/* Personal Details (First Name, Last Name, Email, Password) */}
            <Box sx={{ mt: width < positionChangeWidthSm ? 2 : 4, mb: 0, width: '90%', maxWidth: '450px' }}>
              {/* Personal Details — Title and Edit/Save/Cancel Buttons */}
              {titleAndEditButton('Personal Details', editPersonalDetails, user, savePersonalDetailsPressed, editPersonalDetailsPressed )}
              
              {/* Error Message, if errors */}
              {uploadPersonalEditsErrors &&
                standardErrorContainer(
                  'Error uploading changes. Please try again.',
                  0,
                  0
                )
              }
              <Box 
                sx={{
                  backgroundColor: editPersonalDetails ? 'white' : 'whitesmoke',
                  mt: 1,
                  pl: 3, pr: 3, pt: 1, pb: 1,
                  borderRadius: '5px',
                  boxShadow: 3,
                  border: editPersonalDetails ? 0 : 2,
                  borderColor: 'black',
                  display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
              >
                {loadingPersonalDetailsEdits ?
                  standardSpinner()
                  :
                  <>
                    
                    {/* First Name */}
                    {personalDetailsTextFieldOrTypography('givenName', 'First Name', user, setUser, editPersonalDetails, setUploadPersonalEditsErrors)}
                    
                    {/* Last Name */}
                    {personalDetailsTextFieldOrTypography('familyName', 'Last Name', user, setUser, editPersonalDetails, setUploadPersonalEditsErrors)}
                    
                    {/* Email */}
                    {personalDetailsTextFieldOrTypography('email', 'Email', user, setUser, editPersonalDetails, setUploadPersonalEditsErrors)}
                    
                    {/* Password and Reset Password Button */}
                    {!editPersonalDetails &&
                      <Box 
                        sx={{
                          mt: editPersonalDetails ? 2 : 0,
                          width: '100%',
                          display: 'flex', flexDirection: 'row', justifyContent: 'center',
                        }}
                      >
                        {personalDetailsTypography('Password')}
                        
                        <Box textAlign={'right'} sx={{ width: '50%' }}>
                          {standardButton(
                            'Reset Password', 
                            'button',
                            'text',
                            false,
                            'secondary', 
                            0,
                            0,
                            0,
                            '137px',
                            '30px',
                            resetPasswordPressed
                          )}
                        </Box>
                      </Box>
                    }
                    
                  </>
                }
                
              </Box>
            </Box>

            {/* Security Questions Title and Edit Security Questions Button */}
            <Box sx={{ mt: width < positionChangeWidthSm ? 2 : 4, mb: 0, pb: 4, width: '90%', maxWidth: '450px' }}>
              {/* Security Questions — Title and Edit/Save Button */}
              {titleAndEditButton('Security Questions', editSecurityQuestions, user, saveSecurityQuestionsPressed, editSecurityQuestionsPressed )}
              
              {/* Error Message, if errors */}
              {uploadSecurityQuestionsErrors &&
                standardErrorContainer(
                  'Error uploading changes. Please try again.',
                  0,
                  0
                )
              }
              <Box 
                sx={{
                  backgroundColor: editSecurityQuestions ? 'white' : 'whitesmoke',
                  mt: 1,
                  pl: 3, pr: 3, pt: 1, pb: 1,
                  borderRadius: '5px',
                  boxShadow: 3,
                  border: editSecurityQuestions ? 0 : 2,
                  borderColor: 'black',
                  display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
              >
                {loadingSecurityQuestionsEdits ?
                  standardSpinner()
                  :
                  <>

                    {/* Security Questions and Answers */}
                    {securityQuestionAndAnswerBoxItems(user, setUser, !editSecurityQuestions)}
                    
                  </>
                }
                
              </Box>
            </Box>
          </Box>
        </Box>
      }
    </>
  )
}

export default UserProfile