/* eslint-disable no-prototype-builtins */

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'

// Standard Switch element
export const switchElement = (name, checked, disabled, changeHandler) => {
  return (
    <Switch
      disabled={disabled}
      checked={checked}
      size='large'
      name={name}
      onChange={(e) => changeHandler(e)}
      inputProps={{ 'aria-label': 'controlled' }}
      sx={{ ml: 1 }}
    />
  )
}

// Prompt for the switch, and also the switch
export const switchWithTitle = (name, title, checked, disabled, changeHandler ) => {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Typography 
        sx={{ 
          // width: '150px', 
        }}
      >
        {title}
      </Typography>

      {switchElement(name, checked, disabled, changeHandler)}

    </Box>
  )
}