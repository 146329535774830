

// Sources:

// Constants
const startBidHistoryAndAuctionPlanningObj = {
  georgeCostanzaPersonId: 0, 
  elaineBenesPersonId: 0, 
  jerrySeinfeldPersonId: 0,
  newmanPersonId: 0,
}

const startDraftPlanningObj = {
  georgeCostanzaPersonId: 1000000000, 
  elaineBenesPersonId: 1000000000, 
  jerrySeinfeldPersonId: 1000000000,
  newmanPersonId: 1000000000,
}


// Items

// Kramer Portrait
export const cosmoKramerPortrait = {
  _id: 'cosmoKramerPortraitItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Kramer Portrait',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-portrait-1.jpg',
  valuation: '5000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Art',
  description: 'A portrait of Kramer',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Smoking Jacket
export const cosmoKramerSmokingJacket = {
  _id: 'cosmoKramerSmokingJacketItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Smoking Jacket',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-smoking-jacket-1.jpg',
  valuation: '250',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'Kramer\'s smoking jacket, for when he lights up the pipe.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Pipes
export const cosmoKramerPipes = {
  _id: 'cosmoKramerPipesItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Pipes',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-pipes-1.jpg',
  valuation: '200',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Keepsake',
  description: 'My collection of two pipes, used for looking professorial.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Sunglasses
export const cosmoKramerSunglasses = {
  _id: 'cosmoKramerSunglassesItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Sunglasses',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-sunglasses-1.jpg',
  valuation: '150',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My indoor sunglasses',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Jerry's Suede Jacket
export const cosmoKramerSuedeJacket = {
  _id: 'cosmoKramerSuedeJacketItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Jerry\'s Suede Jacket',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-suede-jacket-1.jpg',
  valuation: '800',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'The suede jacket that was ruined in the rain when Jerry met Elaine\'s dad.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Kramerica Industries
export const cosmoKramerKramerica = {
  _id: 'cosmoKramerKramericaItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Kramerica Industries',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-kramerica-industries-1.jpg',
  valuation: '3000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 100% stake in Kramerica Industries.',
  allottedToName: 'Newman',
  allottedToPersonId: 'newmanPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Morty's Raincoats
export const cosmoKramerRaincoats = {
  _id: 'cosmoKramerRaincoatsItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Morty\'s Raincoats',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-raincoats-1.jpg',
  valuation: '4000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 50% stake in the proceeds from selling Morty\'s raincoats. So far all we have is inventory.',
  allottedToName: 'Jerry Seinfeld',
  allottedToPersonId: 'jerrySeinfeldPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// 555-Filk
export const cosmoKramerMoviefone = {
  _id: 'cosmoKramerMoviefoneItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: '555-Filk',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-moviefone-1.jpg',
  valuation: '1500',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 100% in 555-FILK, a competitor of 555-FILM, for providing callers with movie times and venues',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Beach-scented Cologne
export const cosmoKramerCologne = {
  _id: 'cosmoKramerCologneItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Beach-scented Cologne',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-beach-cologne-1.jpg',
  valuation: '8000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 15% stake in a beach-scented cologne venture.',
  allottedToName: 'Jerry Seinfeld',
  allottedToPersonId: 'jerrySeinfeldPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Roll-Out Tie Dispensers for Public Restrooms
export const cosmoKramerTieDispenser = {
  _id: 'cosmoKramerTieDispenserItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Roll-Out Tie Dispenser',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-tie-dispenser-1.jpg',
  valuation: '3000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 100% stake in my roll-out tie dispenser for public restrooms business.',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'Art Vandelay',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Oil Tank Bladder
export const cosmoKramerOilTankBladder = {
  _id: 'cosmoKramerOilTankBladderItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Oil Tank Bladder',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-oil-bladder-1.jpg',
  valuation: '10000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 100% stake in my oil tank bladder business.',
  allottedToName: 'Newman',
  allottedToPersonId: 'newmanPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Coffee Table Book About Coffee Tables
export const cosmoKramerCoffeeTableBook = {
  _id: 'cosmoKramerCoffeeTableBookItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Coffee Table Book About Coffee Tables',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-coffee-table-book-1.jpg',
  valuation: '10000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My royalties for the coffee table book about coffee tables.',
  allottedToName: 'Elaine Benes',
  allottedToPersonId: 'elaineBenesPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// The Bro
export const cosmoKramerTheBro = {
  _id: 'cosmoKramerTheBroItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'The Bro',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-the-bro-1.jpg',
  valuation: '7500',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'All of the designs and intellectual property for The Bro, a brassiere for men.',
  allottedToName: 'George Costanza',
  allottedToPersonId: 'georgeCostanzaPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// The Titleist
export const cosmoKramerTitleist = {
  _id: 'cosmoKramerTitleistItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'The Titleist',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-titleist-1.jpg',
  valuation: '5',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Keepsake',
  description: 'The golf ball that Kramer strikes into the ocean that lands in a whale\'s blowhole.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// P.B. & J's Restaurant
export const cosmoKramerPBJRestaurant = {
  _id: 'cosmoKramerPBJRestaurantItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'P.B. & J\'s Restaurant',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-pbj-restaurant-1.jpg',
  valuation: '2000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'Concept for a gourmet peanut-butter-and-jelly sandwich restaurant.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Make Your Own Pie Pizza Parlor
export const cosmoKramerPizzaParlor = {
  _id: 'cosmoKramerPizzaParlorItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Pizza Parlor',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-pizza-restaurant-1.jpg',
  valuation: '6000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'Concept for a make-your-own-pizza parlor, to facilitate male unbonding.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Bottle Deposit (50,000 Used Glass Bottles)
export const cosmoKramerBottleDeposit = {
  _id: 'cosmoKramerBottleDepositItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: '50,000 Used Glass Bottles',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-bottle-deposit-1.jpg',
  valuation: '2500',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My stake in mine and Newman\'s joint venture to collect used bottles and sell them in Michigan.',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'Martin Van Nostrand',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// All of Kramer's Personal Anecdotes
export const cosmoKramerPersonalAnecdotes = {
  _id: 'cosmoKramerPersonalAnecdotesItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Personal Anecdotes',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-personal-anecdotes-1.jpg',
  valuation: '800',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'All of my personal anecdotes become yours',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Car Periscopes
export const cosmoKramerCarPeriscopes = {
  _id: 'cosmoKramerCarPeriscopesItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Car Periscopes',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-car-periscope-1.jpg',
  valuation: '2800',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'The intellectual property for my car periscopes operation.',
  allottedToName: 'George Costanza',
  allottedToPersonId: 'georgeCostanzaPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Muffin Tops
export const cosmoKramerMuffinTops = {
  _id: 'cosmoKramerMuffinTopsItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Muffin Tops',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-muffin-tops-1.jpg',
  valuation: '4200',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My stake in a bakery that sells muffin tops without the stumps.',
  allottedToName: 'Elaine Benes',
  allottedToPersonId: 'elaineBenesPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Bathtub Garbage Disposal
export const cosmoKramerGarbageDisposal = {
  _id: 'cosmoKramerGarbageDisposalItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Bathtub Garbage Disposal',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-bathtub-garbage-disposal-1.jpg',
  valuation: '400',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'The garbage disposal in my bathtub, perfect for making a salad while bathing.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Butter Aftershave
export const cosmoKramerButterAftershave = {
  _id: 'cosmoKramerButterAftershaveItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Butter Aftershave',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-butter-aftershave-1.jpg',
  valuation: '2500',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'A 100% stake in my butter-aftershave brand. Just don\'t wear it for too long in the sun, you might cook like a chicken.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Catchup and Mustard Bottle
export const cosmoKramerCatchupMustardBottle = {
  _id: 'cosmoKramerCatchupMustardBottleItemId',
  estateId: 'cosmoKramerEstateId',
  itemName: 'Catchup Mustard Bottle',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-kramer-catchup-mustard-bottle-1.jpg',
  valuation: '4500',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 100% stake in and designs for a catch-up-and-mustard-in-the-same-bottle brand.',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'Bob Sacamano',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// export const item25 = {
//   _id: '',
//   estateId: 'cosmoKramerEstateId',
//   itemName: '',
//   imageURL: 'https://sheir-item-images.s3.amazonaws.com/',
//   valuation: '',
//   valuationDate: '2021-08-27T13:49:49.000Z',
//   category: '',
//   description: '',
//   allottedToName: 'Unassigned',
//   allottedToPersonId: 'NA',
//   giftFor: '',
//   bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
//   heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
//   heirDraftPlanning: { ...startDraftPlanningObj },
// }