/* eslint-disable no-prototype-builtins */
import React from 'react'
import axios from 'axios'

import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import StarIcon from '@mui/icons-material/Star'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import SendIcon from '@mui/icons-material/Send'
import PropTypes from 'prop-types'
import Avatar from '@mui/material/Avatar'

import CheckIcon from '@mui/icons-material/Check'

import { Link } from 'react-router-dom'

import FAQ from '../components/faq/FAQ.js'

import { TabPanel } from './formatting.js'

import { standardSpinner } from './spinners.js'
import { standardButton } from './buttons.js'
import { missionTextTypography } from './typographies.js'
import { personTypes, positionChangeWidthSm, sheirBlue, widthInsideTabPanel, positionChangeWidthMd, positionChangeHeightSm, tooltipInfoDimension, sheirGreen, sheirChecklist, sheirChecklistAllocateAll } from './variableDefaults.js'
import { getTokenFromLocalStorage } from './storage.js'
import { getAllHeirsAreReady, getAllHeirsWithinAllotment, getUnassignedItems } from './getValuationTotals.js'
import { standardInfoTooltip } from './tooltips.js'
import { videoInsert } from './videos.js'
import { faqTextTypography } from './typographies.js'

import profPicDefault from '../assets/seed-profpic-default-4.jpg'

import dateFormat from 'dateformat'

const personTypeTitle = (title) => {
  return (
    <Typography sx={{ mt: title === 'Owner' ? 0 : 0, fontSize: '18px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <strong>{title}</strong>
    </Typography>
  )
}

const peopleTabPersonElement = (person, personIndex, isTestEstate) => {
  // console.log('peopleTabPersonElement runs')
  return (
    <Box key={personIndex}
      sx={{
        // backgroundColor: 'blue',
        height: '80px',
        width: '100%',
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
      }}
    >
      {/* Avatar — links to user's profile */}
      {!isTestEstate ?
        <Link to={`/estate/${person.estateId}/${person._id}`}>
          <Avatar sx={{ ml: 0, height: 70, width: 70,  boxShadow: 3, border: 0, borderColor: 'black' }} alt={person.role} src={person.userProfilePicture ? person.userProfilePicture : profPicDefault} />
        </Link>
        :
        <Avatar sx={{ ml: 0, height: 70, width: 70,  boxShadow: 3, border: 0, borderColor: 'black' }} alt={person.role} src={person.userProfilePicture ? person.userProfilePicture : profPicDefault} />
      }

      {/* Links to Person's Personal Space */}
      <Box sx={{ width: 'calc(100% - 80px)' }}>
        <Typography align= 'left' sx={{ color: sheirBlue, fontWeight: 'bold', ml: 2, width: '100%', fontSize: '18px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical' }}>
          {!isTestEstate ?
            <Link to={`/estate/${person.estateId}/${person._id}`} sx={{ color: sheirBlue }}>
              {person.userGivenAndFamilyName}
            </Link>
            :
            person.userGivenAndFamilyName
          }
        </Typography>
      </Box>
      
    </Box>
  )
}

export const peopleTab = (people, panelName, value, index, width, loading, errors, isTestEstate ) => {
  // console.log('peopleTab')
  return (
    <TabPanel value={value} index={index}>
      {loading ?
        standardSpinner()
        : errors ?
          <Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center', my: '10%' }} >
            <Typography variant='p' sx={{ color: 'red' }}>
              Error! Could not fetch data!
            </Typography>
          </Container>
          : 
          <>
            {/* Owner */}
            <Box key={-1}
              sx={{
                backgroundColor: 'whitesmoke',
                px: 2, py: 1,
                // mt: 1,
                width: widthInsideTabPanel, 
                maxWidth: '400px',
                boxShadow: 3, borderRadius: 3,
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >
              {personTypeTitle('Owner')}
              {people.filter(personInRole => personInRole.role === 'Owner').map((person, personIndex) => (
                peopleTabPersonElement(person, personIndex, isTestEstate)
              ))}
            </Box>
            

            {/* Other People */}
            {personTypes.map((personType) => (
              people.filter(personInRole => personInRole.role === personType).length > 0 &&
                <Box key={personType}
                  sx={{
                    backgroundColor: 'whitesmoke',
                    px: 2, py: 1,
                    mt: 2,
                    width: widthInsideTabPanel, 
                    maxWidth: '400px',
                    boxShadow: 3, borderRadius: 3,
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                  }}
                >
                  {/* Title */}
                  {personTypeTitle(`${personType}${personType === 'Witness' ? 'es' : 's'}`)}

                  {people.filter(personInRole => personInRole.role === personType)
                    .sort((a, b) => a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName))
                    .map((person, personIndex) => (

                      peopleTabPersonElement(person, personIndex, isTestEstate)
                    
                    ))
                  }
                </Box>
            ))}
          </>
      }
    </TabPanel>
  )
}



const heirAllotmentElement = (person, personIndex, width, waitingForHeirs = false) => {
  return (
    <Box key={personIndex} 
      sx={{  
        // backgroundColor: 'yellow',
        mt: 1, mb: 1,
        width: '100%', minWidth: '275px',
        // height: '80px',
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
      }}
    >
      <Box
        sx={{  
          // backgroundColor: 'yellow',
          // mt: 1, mb: 1,
          width: '100%', 
          // minWidth: '275px',
          // height: '80px',
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start',
        }}
      >
        <Typography
          sx={{
            // ml: width < positionChangeWidthSm ? 2 : 4,
            ml: 0,
            pl: width < positionChangeWidthSm ? 2 : 0,
            width: '100%',
            minWidth: '100px',
            fontSize: width < positionChangeWidthSm ? '16px' : '18px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: person.role !== 'Heir' || !waitingForHeirs ? 'black' : person.isReadyForEvent ? 'black' : 'red',
          }}
        >
          {person.userGivenAndFamilyName}
          {/* {person.role !== 'Heir' ? `${person.userGivenAndFamilyName}` : `${person.userGivenAndFamilyName} — ${person.isReadyForEvent ? 'Ready' : 'Not Ready'}`} */}
        </Typography>

        {person.role === 'Heir' &&
          <Typography 
            sx={{ 
              // mr: 1, 
              mr: 0,
              pr: width < positionChangeWidthSm ? 2 : 0,
              fontSize: width < positionChangeWidthSm ? '16px' : '18px',
            }}
          >
            {person.sharePercentage}%
          </Typography>
        }

        {/* If it's a Gift, Tax, or Fee */}
        {person.role !== 'Heir' && person.role !== 'Owner' && 
          // person.role !== 'Gifts' &&
          <Typography 
            sx={{ 
              // mr: 1, 
              mr: 0,
              pr: width < positionChangeWidthSm ? 2 : 0,
              fontSize: width < positionChangeWidthSm ? '16px' : '18px',
            }}
          >
            ${Math.floor(person.fees).toLocaleString('en-US', { minimumFractionDigits: 0 })}
          </Typography>
        }
      </Box>

      {/* Gifted Item in parentheses on next line if it's a Gift */}
      {person.role === 'Gifts' &&
        <Typography 
          textAlign={'right'}
          // component="pre"
          sx={{ 
            // backgroundColor: 'yellow',
            pr: width < positionChangeWidthSm ? 2 : 0, mb: 1,
            width: '100%',
            fontSize: width < positionChangeWidthSm ? '16px' : '18px',
          }}
        >
          ({person.giftedItem})
        </Typography>
      }
    </Box>
  )
}

const auctionDraftLiveAutoElements = (title, value, width, tooltipHTML) => {
  return (
    <Box 
      sx={{
        width: '100%',
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
      }}
    >
      <Box
        sx={{
          mt: 1,
          minWidth: `calc(100px + ${tooltipInfoDimension})`,
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            // ml: width < positionChangeWidthSm ? 2 : 4,
            ml: 0,
            mt: 0,
            // width: '100%',
            // minWidth: '100px',
            fontSize: width < positionChangeWidthSm ? '16px' : '18px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography>

        {/* Tooltip */}
        {tooltipHTML && tooltipHTML.length > 0 && standardInfoTooltip(tooltipHTML)}
      </Box>
      

      <Typography 
        sx={{ 
          mr: 0, 
          mt: 1,
          fontSize: width < positionChangeWidthSm ? title === 'Date:' ? '14px' : '16px' : '18px',
          color: value === 'All Items Not Added' || value === 'Waiting for Heirs' || value === 'Items Not Assigned' || value === 'Add First Item' ? 'red' : sheirBlue,
          // display: 'flex', flexDirection: 'row', alignItems: 'flex-end',
        }}
      >
        {value}
      </Typography>
    </Box>
  )
}

const settingsTabButton = (title, event, navigate, setLoading, setErrors, disabled) => {

  const handleSettingsTabButton = async () => {

    if (title === 'View Results') {
      if (event.distributionMethod === 'Auction') {
        navigate(`/estate/${event.estateId}/auction-results`)
      } else if (event.distributionMethod === 'Draft') {
        navigate(`/estate/${event.estateId}/draft-results`)
      }
    } else if (title === 'Practice') {
      // if (event.distributionMethod === 'Auction') {
      //   navigate(`/estate/${event.estateId}/auction-practice`)
      // } else if (event.distributionMethod === 'Draft') {
      //   navigate(`/estate/${event.estateId}/draft-practice`)
      // }
      navigate(`/demo`)
    } else if (title === 'Enter Event') {
      if (event.distributionMethod === 'Auction') {
        navigate(`/estate/${event.estateId}/live-auction`)
      } else if (event.distributionMethod === 'Draft') {
        navigate(`/estate/${event.estateId}/live-draft`)
      }
    } else if (title === 'Execute Draft' || title === 'Execute Auction') {
      try {
        setLoading(true)
        setErrors(false)
        await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/events/${event._id}/execute-auto-event`, {}, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })

        navigate(`/estate/${event.estateId}/${event.distributionMethod.toLowerCase()}-results`)

        setLoading(false)
        setErrors(false)
      } catch (error) {
        // console.log(error)
        
        setLoading(false)
        setErrors(true)
      }
    }
  }

  return (
    <Box 
      sx={{ 
        // backgroundColor: 'yellow',
        mt: 1,
        width: '100%',
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
      }}
    >
      {standardButton(
        title, 
        'button',
        'contained',
        disabled,
        'primary', 
        1,
        2,
        0,
        '165px', 
        '45px', 
        handleSettingsTabButton
      )}
    </Box>
  )
}

const allotmentProcessElements = (estate, event, width, userPerson, navigate, setLoading, setErrors, persons = []) => {
  return (
    <Box 
      sx={{  
        backgroundColor: 'whitesmoke',
        mt: 0, mb: 1, px: 2, py: (event.distributionMethod === 'Allocate All' || event.silentOrParticipatory === 'Silent') ? 3 : 1,
        width: '100%',
        // height: '80px',
        // width: '90vw', maxWidth: '280px',
        boxShadow: 3, borderRadius: 3,
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      }}
    >
      {personTypeTitle('Settings')}
      {auctionDraftLiveAutoElements(
        'Transparency Level:', 
        event.silentOrParticipatory, 
        width,
        `
          <span style="font-weight:bold; color:${sheirGreen};">Silent</span>
          <br/>
          The estate owner allocates items <b>WITHOUT heir involvement</b>. Lawyers, Accountants, Executors, and Witnesses will be able to view the estate and the allocation, but heirs will not, and no estate-related emails or updates will be sent to the heirs.   
          <br/><br/>
          <span style="font-weight:bold; color:${sheirGreen};">Partial</span>
          <br/>
          Heirs will be able to rank their preferences for unassigned items, but they will not be able to see the full estate or the valuations of the items. 
          <br/><br/>
          <span style="font-weight:bold; color:${sheirGreen};">Participatory</span>
          <br/>
          Heirs will be able to view the estate and participate in the allocation process by ranking their preferences for items to receive, and by taking part in an auction or draft for all unassigned items. 
        `
      )}

      {event.silentOrParticipatory === 'Participatory' && auctionDraftLiveAutoElements(
        'Distribution Method:', 
        event.distributionMethod, 
        width
      )}
      {event.silentOrParticipatory === 'Participatory' && event.distributionMethod !== 'Allocate All' && auctionDraftLiveAutoElements(
        'Live or Auto?', 
        event.liveOrAuto, 
        width, 
        `
          <span style="font-weight:bold; color:${sheirGreen};">Live</span>
          <br/>
          When all heirs are ready the owner will open a draft room and start the event when all heirs have entered the event space 
          <br/><br/>
          <span style="font-weight:bold; color:${sheirGreen};">Auto</span>
          <br/>
          When all heirs are ready, the owner will click an 'Execute' button, and the items will be allotted automatically based on pre-draft rankings or pre-auction point allocations
        `
      )}
      {/* {auctionDraftLiveAutoElements('Date:', dateFormat(event.startDate, width < positionChangeWidthSm ? 'mm/dd/yy, h:MM TT' : 'mmmm d, yyyy, h:MM TT'), width)} */}
      {auctionDraftLiveAutoElements(
        'Status:', 
        (event.silentOrParticipatory === 'Participatory' && event.distributionMethod !== 'Allocate All') ? !event.allItemsAdded ? 'All Items Not Added' : !getAllHeirsAreReady(persons) ? 'Waiting for Heirs' : event.hasFinished ? `${event.distributionMethod} Completed` : `Ready for ${event.distributionMethod}` : (estate.hasOwnProperty('items') && estate.items.length > 0) ? getUnassignedItems(estate).length > 0 ? 'Items Not Assigned' : 'All Items Assigned' : 'Add First Item', 
        width, 
        (event.silentOrParticipatory === 'Participatory' && event.distributionMethod !== 'Allocate All') ?
          `
            <span style="font-weight:bold; color:${sheirGreen};">All Items Not Added</span>
            <br/>
            When the owner has not yet added all items 
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">Waiting for Heirs</span>
            <br/>
            When at least one heir has not indicated that they are ready for the event
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">Ready for Draft/Auction</span>
            <br/>
            Everyone is ready for the event to start
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">Draft/Auction Completed</span>
            <br/>
            The event has completed
          `
          :
          `
            <span style="font-weight:bold; color:${sheirGreen};">Add First Item</span>
            <br/>
            When the owner has not yet an item 
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">Items Not Assigned</span>
            <br/>
            When there are Unassigned items in the estate
            <br/><br/>
            <span style="font-weight:bold; color:${sheirGreen};">All Items Assigned</span>
            <br/>
            All items in the estate have been assigned.
          `
      )}
      
      {(event.silentOrParticipatory === 'Participatory' && event.distributionMethod !== 'Allocate All') &&
        // userPerson.role === 'Heir' && 
        settingsTabButton(
          event.hasFinished ? 'View Results' : event.hasStarted ? event.liveOrAuto === 'Auto' ? `Execute ${event.distributionMethod}` : 'Enter Event' : 'Practice', 
          event, 
          navigate,
          setLoading, 
          setErrors,
          estate.items.length === 0
          || (getUnassignedItems(estate).length < event.draftOrder.length && !event.hasFinished)
          || event.draftOrder.length < 2
          || (event.hasStarted && !event.hasFinished && event.liveOrAuto === 'Auto' && userPerson.readWriteAdmin !== 'admin')
        )
      }

    </Box>
  )
}

export const settingsTab = (people, event, estate, userPerson, panelName, value, index, width, loading, errors, setLoading, setErrors, navigate) => {
  
  const checklistItemJSX = (checklistItemIndex, checklistItem, width, isFinished, isCurrent) => {

    return (
      <Box
        key={checklistItemIndex}
        sx={{
          my: 1,
          width: '100%',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
        }}
      >
        {/* Icon, Checklist Item */}
        <Box
          sx={{
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {isFinished ?
            <CheckIcon 
              fontSize='large'
              sx={{
                color: isFinished ? 'darkolivegreen' : isCurrent ? sheirBlue : 'black',
                border: 2, borderRadius: '50%', boxShadow: 3, borderColor: isFinished ? 'darkolivegreen' : isCurrent ? sheirBlue : 'black',
              }}
            />
            :
            <Box 
              sx={{
                width: '35px', height: '35px',
                border: 2, borderRadius: '50%', boxShadow: 3, borderColor: isCurrent ? sheirBlue : 'black',
              }}
            />
          }
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '18px',
              ml: 2,
              color: isFinished ? 'darkolivegreen' : isCurrent ? sheirBlue : 'black',
            }}
          >
            {checklistItem.toDo}
          </Typography>
        </Box>

        {/* Description */}
        {isCurrent &&
          <Box
            sx={{
              my: 2,
              width: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
            }}
          >
            {checklistItem.description.length > 0 && faqTextTypography(checklistItem.description, '18px', 'Lato')}
            {checklistItem.videoURL.length > 0 && videoInsert(checklistItem.videoURL, checklistItem.toDo, '95%', width)}
            {checklistItem.writtenInstructions.length > 0 && checklistItem.description.length === 0 && faqTextTypography(checklistItem.writtenInstructions, '18px', 'Lato')}
          </Box>
        }

      </Box>
    )
  }
  
  return (
    <TabPanel value={value} index={index}>
      {loading ?
        standardSpinner()
        : errors ?
          <Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center', my: '10%' }} >
            <Typography variant='p' sx={{ color: 'red' }}>
              Error! Could not fetch data!
            </Typography>
          </Container>
          : 
          <Box 
            sx={{ 
              // height: `calc(100% - ${navbarHeight})`, 
              width: '100vw', 
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            <Box
              sx={{
                // backgroundColor: 'yellow',
                width: widthInsideTabPanel, maxWidth: '400px',
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >

              {/* Settings */}
              {allotmentProcessElements(estate, event, width, userPerson, navigate, setLoading, setErrors, people)}

              {/* Process */}
              <Box
                sx={{
                  backgroundColor: 'whitesmoke',
                  mt: 2,
                  px: 2, py: 1,
                  boxShadow: 3, borderRadius: 3,
                  width: '100%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {personTypeTitle('Process')}
                {(event.silentOrParticipatory === 'Participatory' && event.distributionMethod !== 'Allocate All') &&
                  sheirChecklist.map((checklistItem, checklistItemIndex) => (
                    checklistItemJSX(
                      checklistItemIndex, 
                      checklistItem, 
                      width, 
                      checklistItemIndex === 0 && event.allItemsAdded
                      || checklistItemIndex === 1 && event.hasStarted
                      || checklistItemIndex === 2 && event.hasFinished
                      , 
                      checklistItemIndex === 0 && !event.allItemsAdded
                      || checklistItemIndex === 1 && event.allItemsAdded && !event.hasStarted
                      || checklistItemIndex === 2 && event.allItemsAdded && event.hasStarted && !event.hasFinished
                      || checklistItemIndex === 3 && event.allItemsAdded && event.hasStarted && event.hasFinished
                    )
                  ))
                }
                {(event.silentOrParticipatory === 'Silent' || event.distributionMethod === 'Allocate All') &&
                  sheirChecklistAllocateAll.map((checklistItem, checklistItemIndex) => (
                    checklistItemJSX(
                      checklistItemIndex, 
                      checklistItem, 
                      width, 
                      checklistItemIndex === 0 && estate.hasOwnProperty('items') && estate.items.length > 0
                      || checklistItemIndex === 1 && estate.hasOwnProperty('items') && estate.items.length > 0 && getUnassignedItems(estate).length === 0
                      || checklistItemIndex === 2 && estate.hasOwnProperty('items') && estate.items.length > 0 && getUnassignedItems(estate).length === 0 && getAllHeirsWithinAllotment(estate)
                      , 
                      checklistItemIndex === 0 && (!estate.hasOwnProperty('items') || estate.items.length < 1)
                      || checklistItemIndex === 1 && estate.hasOwnProperty('items') && estate.items.length > 0 && getUnassignedItems(estate).length > 0
                      || checklistItemIndex === 2 && estate.hasOwnProperty('items') && estate.items.length > 0 && getUnassignedItems(estate).length === 0 && !getAllHeirsWithinAllotment(estate)
                      || checklistItemIndex === 3 && estate.hasOwnProperty('items') && estate.items.length > 0 && getUnassignedItems(estate).length === 0 && getAllHeirsWithinAllotment(estate)
                    )
                  ))
                }
              </Box>

              {/* Heir Allocations */}
              <Box
                sx={{
                  backgroundColor: 'whitesmoke',
                  mt: 2,
                  px: 2, py: 1,
                  boxShadow: 3, borderRadius: 3,
                  width: '100%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {personTypeTitle('Heir Allocations')}
                {people.filter(personInRole => personInRole.role === 'Heir').length > 0 ?
                  people.filter(personInRole => personInRole.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage).map((person, personIndex) => (
                    heirAllotmentElement(person, personIndex, width, (event.allItemsAdded || event.silentOrParticipatory === 'Partial') && !getAllHeirsAreReady(people))
                  ))
                  :
                  <>
                    <Typography 
                      sx={{
                        width: '81%', maxWidth: '540px',
                        textAlign: 'center',
                        color: sheirBlue,
                        fontSize: '16px',
                      }}
                    >
                      <em>*No heirs in estate yet.</em>
                    </Typography>
                  </>
                }
              </Box>

              {/* Fees */}
              <Box
                sx={{
                  backgroundColor: 'whitesmoke',
                  mt: 2,
                  px: 2, py: 1,
                  boxShadow: 3, borderRadius: 3,
                  width: '100%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {personTypeTitle('Fees')}
                {people.filter(personInRole => personInRole.role !== 'Heir' && personInRole.role !== 'Owner').length > 0 ?
                  people.filter(personInRole => personInRole.role !== 'Heir' && personInRole.role !== 'Owner').sort((a, b) => b.fees - a.fees).map((person, personIndex) => (
                    heirAllotmentElement(person, personIndex, width)
                  ))
                  :
                  <>
                    <Typography 
                      sx={{
                        width: '81%', maxWidth: '540px',
                        textAlign: 'center',
                        color: sheirBlue,
                        fontSize: '16px',
                      }}
                    >
                      <em>*No fees accounted for yet.</em>
                    </Typography>
                  </>
                }
              </Box>

              {/* Taxes */}
              <Box
                sx={{
                  backgroundColor: 'whitesmoke',
                  mt: 2,
                  px: 2, py: 1,
                  boxShadow: 3, borderRadius: 3,
                  width: '100%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {personTypeTitle('Taxes')}
                {heirAllotmentElement({ userGivenAndFamilyName: 'Estimated Taxes', fees: estate.totalTaxes, role: 'Taxes' }, -1, width)}
              </Box>

              {/* Gifts */}
              <Box
                sx={{
                  backgroundColor: 'whitesmoke',
                  mt: 2, 
                  mb: 2,
                  px: 2, py: 1,
                  boxShadow: 3, borderRadius: 3,
                  width: '100%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {personTypeTitle('Gifts')}
                {estate.items.filter(item => item.allottedToName === 'Gift').length > 0 ?
                  estate.items.filter(item => item.allottedToName === 'Gift').sort((a, b) => b.valuation - a.valuation).map((item, itemIndex) => (
                    heirAllotmentElement({ userGivenAndFamilyName: item.giftFor ? item.giftFor : 'Gift', fees: item.valuation, role: 'Gifts', giftedItem: item.itemName }, itemIndex, width)
                  ))
                  :
                  <>
                    <Typography 
                      sx={{
                        width: '81%', maxWidth: '540px',
                        textAlign: 'center',
                        color: sheirBlue,
                        fontSize: '16px',
                      }}
                    >
                      <em>*No gifts to non-heirs.</em>
                    </Typography>
                  </>
                }
              </Box>

            </Box>

            {/* FAQ */}
            <FAQ fromComponent='familyHQ' role='NA' transparencyLevel={estate.hasOwnProperty('event') && estate.event[0].silentOrParticipatory === 'Participatory' ? 'Participatory' : 'Silent'} distributionMethod={estate.hasOwnProperty('event') && (estate.event[0].distributionMethod === 'Allocate All' || estate.event[0].distributionMethod === 'Auction' || estate.event[0].distributionMethod === 'Draft') ? estate.event[0].distributionMethod : 'NA'} />
          </Box>
      }
    </TabPanel>
  )
}

export const missionTab = (mission, panelName, value, index, width, loading, errors) => {
  // console.log('mission ->', mission)
  return (
    <TabPanel value={value} index={index}>
      {loading ?
        standardSpinner()
        : errors ?
          <Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center', my: '10%' }} >
            <Typography variant='p' sx={{ color: 'red' }}>
              Error! Could not fetch data!
            </Typography>
          </Container>
          : 
          <>
            {/* <Typography 
              sx={{ 
                mb: 1, mt: 1, 
                width: '100%',
                fontSize: '18px', fontWeight: 'bold', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center',
              }}
            >
              Mission
            </Typography> */}

            <Box 
              sx={{
                backgroundColor: 'lightBlue',
                width: widthInsideTabPanel, maxWidth: '400px',
                mt: 1, ml: 1, mr: 1,
                pl: 3, pr: 3, pt: 2, pb: 2,
                borderRadius: '5px',
                boxShadow: 3,
                border: 2,
                borderColor: 'black',
                display: 'flex', flexDirection: 'row', justifyContent: 'center',
              }}
            >
              {missionTextTypography(mission, width < positionChangeWidthSm ? '16px' : '18px', 'Lato')}
            </Box>
          </>
      }
    </TabPanel>
  )
}