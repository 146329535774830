

// Sources:
// https://en.wikipedia.org/wiki/Magical_objects_in_Harry_Potter

// Constants
const startBidHistoryAndAuctionPlanningObj = {
  harryPotterPersonId: 0, 
  ronWeasleyPersonId: 0, 
  hermioneGrangerPersonId: 0,
}

const startDraftPlanningObj = {
  harryPotterPersonId: 1000000000, 
  ronWeasleyPersonId: 1000000000, 
  hermioneGrangerPersonId: 1000000000,
}


// Items

// Fake Galleons
export const albusDumbledoreFakeGalleons = {
  _id: 'albusDumbledoreFakeGalleonsItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Fake Galleons',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-fake-galleons-1.jpg',
  valuation: '5000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Liquidity',
  description: 'Fake galleons, used for surreptitious communication in difficult times.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Deluminator
export const albusDumbledoreDeluminator = {
  _id: 'albusDumbledoreDeluminatorItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Deluminator',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-deluminator-1.jpg',
  valuation: '300000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'An item I invented. It is used to remove or absorb (as well as return) the light from any light source to provide cover to the user',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Invisibility Cloak
export const albusDumbledoreInvisibilityCloak = {
  _id: 'albusDumbledoreInvisibilityCloak',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Invisibility Cloak',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-invisibility-cloak-1.jpg',
  valuation: '50000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My invisibility cloak. Harry, I know you already have one, so maybe it should go to one of the others.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Elder Wand
export const albusDumbledoreElderWand = {
  _id: 'albusDumbledoreElderWandItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Elder Wand',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-elder-wand-1.jpg',
  valuation: '500000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'The most powerful wand in the universe. Use it wisely.',
  allottedToName: 'Harry Potter',
  allottedToPersonId: 'harryPotterPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Foe-glass
export const albusDumbledoreFoeGlass = {
  _id: 'albusDumbledoreFoeGlassItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Foe-Glass',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-foe-glass-1.jpg',
  valuation: '80000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'A mirror that detects and shows its owner\'s enemies in or out of focus, depending on how close they are',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Marauder's Map
export const albusDumbledoreMaraudersMap = {
  _id: 'albusDumbledoreMaraudersMapItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'The Marauder\'s Map',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-marauders-map-1.jpg',
  valuation: '35000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'A magical map of Hogwarts',
  allottedToName: 'Harry Potter',
  allottedToPersonId: 'harryPotterPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Probity Probe
export const albusDumbledoreProbityProbe = {
  _id: 'albusDumbledoreProbityProbeItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Probity Probe',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-probity-probe-1.jpg',
  valuation: '20000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'Detects spells of concealment and hidden magical objects',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Remembrall
export const albusDumbledoreRemembrall = {
  _id: 'albusDumbledoreRemembrallItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Remembrall',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-remembrall-1.jpg',
  valuation: '250',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'A small, clear orb, about the size of a large marble, containing smoke that turns red when it detects that the person holding it has forgotten something. It does not tell the holder what has been forgotten.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// A Revealer
export const albusDumbledoreRevealer = {
  _id: 'albusDumbledoreRevealerItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Revealer',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-revealer-1.jpg',
  valuation: '5000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'A bright red eraser used to make invisible ink appear.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Sneakoscope
export const albusDumbledoreSneakoscope = {
  _id: 'albusDumbledoreSneakoscopeItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Sneakoscope',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-sneakoscope-1.jpg',
  valuation: '45000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'A miniature glass spinning-top that emits shrill noises in the presence of deception, for instance, when an untrustworthy person is near or when a deceitful event takes place nearby.',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'Mad-Eye Moody',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Exploding Snaps
export const albusDumbledoreExplodingSnaps = {
  _id: 'albusDumbledoreExplodingSnapsItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Exploding Snaps',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-exploding-snaps-1.jpg',
  valuation: '50',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Keepsake',
  description: 'My exploding snaps deck of all of my favorite wizards over the years. In case you don\'t know it, exploding snaps is a wizarding card game in which the cards spontaneously explode during games',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Gobstones
export const albusDumbledoreGobstones = {
  _id: 'albusDumbledoreGobstonesItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Gobstones',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-gobstones-1.jpg',
  valuation: '400',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Keepsake',
  description: 'My gobstones from when I won the faculty tournament several years ago. In case you don\'t know it, gobstones is a game like marbles or petanque, except the balls spit foul-smelling liquid in the face of the opponent when they lose.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Wizard's Chess Set
export const albusDumbledoreWizardChess = {
  _id: 'albusDumbledoreWizardChessItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Wizard\'s Chess Set',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-wizard-chess-1.jpg',
  valuation: '25000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'A full wizard\'s chess set.',
  allottedToName: 'Ron Weasley',
  allottedToPersonId: 'ronWeasleyPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Sorting Hat
export const albusDumbledoreSortingHat = {
  _id: 'albusDumbledoreSortingHatItem',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'The Sorting Hat',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-sorting-hat-1.jpg',
  valuation: '200000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'The magical hat that sees character and chooses the house that a first year student will belong to.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// The Mirror of Erised
export const albusDumbledoreMirrorOfErised = {
  _id: 'albusDumbledoreMirrorOfErisedItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'The Mirror of Erised',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-mirror-of-erised-1.jpg',
  valuation: '120000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'The mirror that shows not your face but your heart\'s desire',
  allottedToName: 'Ron Weasley',
  allottedToPersonId: 'ronWeasleyPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Two-Way Mirror
export const albusDumbledoreTwoWayMirror = {
  _id: 'albusDumbledoreTwoWayMirrorItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Two-Way Mirror',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-two-way-mirror-1.jpg',
  valuation: '60000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'For surreptitious communication. Like video-chat without the need for cellular reception. Activated by holding one of them and saying the name of the other possessor, causing his or her face to appear on the caller\'s mirror and vice versa',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'Sirius Black',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Mokeskin Pouch
export const albusDumbledoreMokeskinPouch = {
  _id: 'albusDumbledoreMokeskinPouchItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Mokeskin Pouch',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-mokeskin-pouch-1.jpg',
  valuation: '80000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'A draw-string pouch that can be operated only by the owner',
  allottedToName: 'Hermione Granger',
  allottedToPersonId: 'hermioneGrangerPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Pensieve
export const albusDumbledorePensieve = {
  _id: 'albusDumbledorePensieveItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Pensieve',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-pensieve-1.jpg',
  valuation: '500000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'A stone basin used to review memories in three dimensional virtual reality. A witch or wizard can extract their own or another\'s memories, store them in the Pensieve, and review them later',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Floo Powder
export const albusDumbledoreFlooPowder = {
  _id: 'albusDumbledoreFlooPowderItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Floo Powder',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-floo-powder-1.jpg',
  valuation: '30000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'Glittering powder used by wizards to travel and communicate using fireplaces',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Portkey (Leather Boot)
export const albusDumbledorePortkey = {
  _id: 'albusDumbledorePortkeyItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Portkey (Leather Boot)',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-portkey-1.jpg',
  valuation: '25000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'Created by using the Portus spell, a Portkey can be set to transport anybody who touches it to a designated location or to become active at a predetermined time and transport itself and anyone touching it to its set destination',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Time-Turner
export const albusDumbledoreTimeTurner = {
  _id: 'albusDumbledoreTimeTurnerItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Time-Turner',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-time-turner-1.jpg',
  valuation: '380000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'A device used for short-term time travel.',
  allottedToName: 'Hermione Granger',
  allottedToPersonId: 'hermioneGrangerPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Vanishing Cabinet
export const albusDumbledoreVanishingCabinet = {
  _id: 'albusDumbledoreVanishingCabinetItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Vanishing Cabinets',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-vanishing-cabinet-1.jpg',
  valuation: '180000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'Always used in twos, a person who steps into either cabinet will instantly emerge from the other',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Gubraithian Fire
export const albusDumbledoreGubraithianFire = {
  _id: 'albusDumbledoreGubraithianFireItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Gubraithian Fire',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-gubraithian-fire-1.jpg',
  valuation: '150000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'An everlasting magical fire that may only be created by extremely skilled wizards',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'Tom Riddle',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Omnioculars
export const albusDumbledoreOmnioculars = {
  _id: 'albusDumbledoreOmniocularsItemId',
  estateId: 'albusDumbledoreEstateId',
  itemName: 'Omnioculars',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-dumbledore-omnioculars-1.jpg',
  valuation: '500',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'Used mainly for watching quidditch, omnioculars, besides having the magnification capabilities of binoculars, have many other useful features. For example, they have the ability to slow down or replay something seen through the lenses',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// export const item26 = {
//   _id: '',
//   estateId: 'albusDumbledoreEstateId',
//   itemName: '',
//   imageURL: 'https://sheir-item-images.s3.amazonaws.com/',
//   valuation: '',
//   valuationDate: '2021-08-27T13:49:49.000Z',
//   category: '',
//   description: '',
//   allottedToName: 'Unassigned',
//   allottedToPersonId: 'NA',
//   giftFor: '',
//   bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
//   heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
//   heirDraftPlanning: { ...startDraftPlanningObj },
// }