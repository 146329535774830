/* eslint-disable no-prototype-builtins */
import React, { useState, useCallback, useEffect, useRef } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useDrag, useDrop } from 'react-dnd'

import update from 'immutability-helper'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import SendIcon from '@mui/icons-material/Send'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

import { ExpandMore } from '../../../helpers/cardHelpers'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../../helpers/storage'
import { getHeirAllotmentsTotal, getHeirAllotmentsTotalByEstate, getHeirShareTotal, getHeirAuctionPlanningPointsAllottedTotal } from '../../../helpers/getValuationTotals'
import { positionChangeHeightSm, positionChangeWidthSm, sheirGreen, sheirBlue } from '../../../helpers/variableDefaults'
import useWindowDimensions from '../../../helpers/windowDimensions'
import { personItemTextField } from '../../../helpers/textfields'
import { modifyInDatabase } from '../../../helpers/modifyInDatabase'
import { upDownArrowElement } from '../../../helpers/buttons'
import { dragAndDropIconElement } from '../../../helpers/buttons'
import { standardErrorContainer } from '../../../helpers/errors'

const HeirDraftRankingsItemCard = ({ item, person, userIsPerson, estate, setEstate, ranking, indexOfRanking, indexOnEstateItems, draftCards, setDraftCards, savedDraftRankings, setSavedDraftRankings }) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // const { item, person, estate, setEstate, ranking, indexOfRanking, indexOnEstateItems, draftCards, setDraftCards, moveCard } = props

  // Payload
  const payload = getPayload()

  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId, personId } = useParams()

  // URL Path
  const urlPath = window.location.pathname

  // Tracks whether the card is expanded
  const [expanded, setExpanded] = useState(false)

  // Edit Item Error
  const [editItemError, setEditItemError] = useState(false)

  // Changes the card expanded state
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  // Drag and Drop
  const nullRef = useRef(null) // Disable drag and drop on mobile
  const ref = useRef(null)

  // Executes the drag/drop for the heir draft planning
  const moveCard = (dragIndex, hoverIndex, estate, setEstate, personId, modifyInDatabase) => {
    // console.log('dragIndex ->', dragIndex)
    // console.log('hoverIndex ->', hoverIndex)

    const newEstateItems = [ ...estate.items ]
    
    // Index of dragged item on estate items
    const indexOnEstateItems = estate.items.map(e => e.heirDraftPlanning[personId]).indexOf(dragIndex + 1)
    // console.log('indexOnEstateItems ->', indexOnEstateItems)
    // console.log('estateItem Dragged ->', estate.items[indexOnEstateItems].itemName)
    
    // Change the specified draft planning ranking for the person id on the item at the specified index on estate items to hoverIndex + 1
    const newHeirDraftPlanningObj = { ...estate.items[indexOnEstateItems]['heirDraftPlanning'], [personId]: hoverIndex + 1 }
    // console.log('newHeirDraftPlanningObj ->', newHeirDraftPlanningObj)
    // console.log('newHeirDraftPlanningObj item name ->', estate.items[indexOnEstateItems].itemName)

    // Modify ranking in database
    // modifyInDatabase('items', estate.items[indexOnEstateItems]._id, { heirDraftPlanning: newHeirDraftPlanningObj }, setEditItemError)
    
    // Modify Rankings for a items ranked between the drag index and the hover index
    const dragHoverIndexDifference = dragIndex - hoverIndex
    // console.log('dragHoverIndexDifference ->', dragHoverIndexDifference)
    for (let i = 1; i < Math.abs(dragHoverIndexDifference) + 1; i++) {
      // console.log('i ->', i)
      const indexToModifyInReverse = estate.items.map(e => e.heirDraftPlanning[personId]).indexOf(dragHoverIndexDifference < 0 ? hoverIndex + 2 - i : hoverIndex + Math.abs(dragHoverIndexDifference) + 1 - i )
      // console.log('indexToModifyInReverse ->', indexToModifyInReverse)
      // console.log('changePositionDraftPlanningObj item name ->', estate.items[indexToModifyInReverse].itemName)
      const changePositionDraftPlanningObj = { ...estate.items[indexToModifyInReverse]['heirDraftPlanning'], [personId]: dragHoverIndexDifference < 0 ? hoverIndex + 1 - i : hoverIndex + Math.abs(dragHoverIndexDifference) + 2 - i }
      // console.log('changePositionDraftPlanningObj ->', changePositionDraftPlanningObj)
      
      // modifyInDatabase('items', estate.items[indexToModifyInReverse]._id, { heirDraftPlanning: changePositionDraftPlanningObj }, setEditItemError)
      newEstateItems[indexToModifyInReverse]['heirDraftPlanning'] = changePositionDraftPlanningObj
    }
    newEstateItems[indexOnEstateItems]['heirDraftPlanning'] = newHeirDraftPlanningObj
    setEstate({ ...estate, items: newEstateItems })

    // const draftCardItemNamesOrder = draftCards.map(item => item.itemName)
    // console.log('draftCardItemNamesOrder ->', draftCardItemNamesOrder)

    // const savedDraftRankingsItemNames = savedDraftRankings.map(item => item.itemName)
    // console.log('savedDraftRankingsItemNames ->', savedDraftRankingsItemNames)

  }

  // Drop Handler
  const [{ handlerId }, drop] = useDrop({
    
    // Component Type (lower case)
    accept: 'heirdraftrankingsitemcard',
    
    // Monitoring
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },

    // Hover Handler
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.indexOfRanking // Start index
      const hoverIndex = indexOfRanking // Index that's being hovered over

      // Don't replace items with themselves
      if (dragIndex === hoverIndex ) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      // moveCard(dragIndex, hoverIndex, indexOnEstateItems)
      moveCard(dragIndex, hoverIndex, estate, setEstate, personId, modifyInDatabase)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.indexOfRanking = hoverIndex
    },
  })

  // Drag Handler
  const [{ isDragging }, drag, preview] = useDrag({
    
    // Component Type (lower case)
    type: 'heirdraftrankingsitemcard',

    // Item properties to retrieve
    item: () => {
      // console.log('is dragging')
      return { ranking, indexOfRanking }
    },

    // Monitor
    collect: (monitor) => ({
      // console.log('is monitoring')
      isDragging: monitor.isDragging(),
    }),
  })

  // Combine it all — Set the drag and drop to the ref
  drag(drop(ref))

  // JSX for the Ranking Number
  const rankingJSX = () => {
    return (
      <Box
        sx={{
          // backgroundColor: 'yellow',
          ml: width < positionChangeWidthSm ? 0 : 1,
          width: '100%', maxWidth: '40px',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <Typography
          textAlign={width < positionChangeWidthSm ? 'center' : 'center'}
          sx={{
            width: '95%',
            fontSize: width < positionChangeWidthSm ? '16px' : '18px',
            fontWeight: 'bold',
            // color: sheirBlue,
          }}
        >
          {ranking}
        </Typography>
      </Box>
    )
  }

  // JSX for the Up and Down Arrows and for the 'Drag/Drop' indicator icon
  const upDownDragRankJSX = (estate) => {
    return (
      <Box
        sx={{
          mx: 1, my: 1,
          width: '100%', maxWidth: '40px',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Up Button */}
        {upDownArrowElement(
          'up', 
          !userIsPerson || ranking === 1 || estate.event[0].hasBegunTimer || estate.event[0].hasFinished, 
          handleRankingChange, 
          setEstate, 
          estate, 
          indexOfRanking
        )}

        {/* Drag and Drop or Ranking*/}
        {width < positionChangeWidthSm ? 
          rankingJSX()
          :
          dragAndDropIconElement(!userIsPerson || estate.event[0].hasBegunTimer || estate.event[0].hasFinished, ref)
        }
        {/* {width < positionChangeWidthSm && rankingJSX()} */}
        

        {/* Down Button */}
        {upDownArrowElement(
          'down', 
          !userIsPerson || ranking === estate.items.filter(itemToFilter => itemToFilter.allottedToName === 'Unassigned').length || estate.event[0].hasBegunTimer || estate.event[0].hasFinished, 
          handleRankingChange, 
          setEstate, 
          estate, 
          indexOfRanking
        )}
      </Box>
    )
  }

  // Using Arrow Keys — executing rankings change
  const handleRankingChange = async (e, setEstate, estate, itemIndex, direction) => {
    e.preventDefault()
    // console.log('handlePointsChange runs')
    // console.log('itemIndex ->', itemIndex)

    const newHeirDraftPlanningObj = { ...estate.items[indexOnEstateItems]['heirDraftPlanning'], [personId]: direction === 'up' ? itemIndex : itemIndex + 2 }
    // console.log('newHeirDraftPlanningObj ->', newHeirDraftPlanningObj)
    // console.log('newHeirDraftPlanningObj item name ->', estate.items[indexOnEstateItems].itemName)
    // modifyInDatabase('items', estate.items[indexOnEstateItems]._id, { heirDraftPlanning: newHeirDraftPlanningObj }, setEditItemError)
    
    const indexToModifyInReverse = estate.items.map(e => e.heirDraftPlanning[personId]).indexOf(direction === 'up' ? itemIndex : itemIndex + 2)
    // console.log('indexToModifyInReverse ->', indexToModifyInReverse)
    const changePositionDraftPlanningObj = { ...estate.items[indexToModifyInReverse]['heirDraftPlanning'], [personId]: direction === 'up' ? itemIndex + 1 : itemIndex + 1 }
    // console.log('changePositionDraftPlanningObj ->', changePositionDraftPlanningObj)
    // console.log('changePositionDraftPlanningObj item name ->', estate.items[indexToModifyInReverse].itemName)
    // modifyInDatabase('items', estate.items[indexToModifyInReverse]._id, { heirDraftPlanning: changePositionDraftPlanningObj }, setEditItemError)
    
    const newEstateItems = [ ...estate.items ]
    newEstateItems[indexOnEstateItems]['heirDraftPlanning'] = newHeirDraftPlanningObj
    newEstateItems[indexToModifyInReverse]['heirDraftPlanning'] = changePositionDraftPlanningObj

    setEstate({ ...estate, items: newEstateItems })
    
  }


  return (
    <Card align="left" 
      ref={(!userIsPerson || width < positionChangeWidthSm || estate.event[0].hasBegunTimer || estate.event[0].hasFinished) ? nullRef : ref} // Drag/drop enabled on Desktop
      // ref={(width < positionChangeWidthSm || estate.event[0].hasBegunTimer || estate.event[0].hasFinished) ? nullRef : nullRef} // Full disabled drag/drop
      // ref={preview}
      // data-handler-id={handlerId}
      sx={{ 
        // cursor: 'move',
        backgroundColor: 'whitesmoke',
        width: '95%', boxShadow: 3, borderRadius: 3,
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex', flexDirection: width < positionChangeWidthSm ? 'row' : 'row', justifyContent: width < positionChangeWidthSm ? 'flex-start' : 'space-between', alignItems: 'center',
        }}
      >
        <Box
          sx={{
            // height: width < positionChangeWidthSm ? '300px' : '200px',
            width: '100%',
            display: 'flex', flexDirection: width < positionChangeWidthSm ? 'row' : 'row', justifyContent: width < positionChangeWidthSm ? 'center' : 'flex-start', alignItems: 'center',
          }}
        >
          {/* Ranking */}
          {width >= positionChangeWidthSm ? 
            rankingJSX()
            :
            upDownDragRankJSX(estate)
          }

          {/* Image */}
          <CardMedia
            component="img"
            height={width < 375 ? '75px' : width < 425 ? '100px' : '150px'}
            image={item.imageURL}
            alt={item.itemName}
            sx={{ 
              m: 1,
              borderRadius: 3,
              boxShadow: 3,
              display: 'inline-block',
              width: width < 375 ? '75px' : width < 425 ? '100px' : '150px',
            }}
          />

          {/* Title, Category, Valuation */}
          <Box
            sx={{
              // backgroundColor: 'yellow',
              ml: width < positionChangeWidthSm ? 0 : 1,
              width: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {/* Item Name */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                // backgroundColor: 'yellow',
                width: '95%',
                fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {item.itemName}
            </Typography>

            {/* Category */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                width: '95%',
                fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
              }}
            >
              {item.category}
            </Typography>

            {/* Valuation */}
            {estate.hasOwnProperty('event') && estate.event[0].hasOwnProperty('silentOrParticipatory') && estate.event[0].silentOrParticipatory === 'Participatory' &&
              <Typography
                textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
                sx={{
                  width: '95%',
                  fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
                  color: (Math.floor(item.valuation) > (getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person))) ? 'red' : sheirBlue,
                }}
              >
                ${Math.floor(item.valuation).toLocaleString('en-US', { minimumFractionDigits: 0 })}
              </Typography>
            }

            {/* Percent of Share */}
            {/* {estate.hasOwnProperty('event') && estate.event[0].hasOwnProperty('silentOrParticipatory') && estate.event[0].silentOrParticipatory === 'Participatory' && */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                width: '95%',
                fontSize: width < 400 ? '14px' : width < positionChangeWidthSm ? '16px' : '18px',
              }}
            >
              ({((Math.floor(item.valuation) / getHeirShareTotal(estate, person.sharePercentage)) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%)
            </Typography>
            {/* } */}

          </Box>

        </Box>
        
        {/* Arrows and drag indicator on right side for larger screens */}
        {width >= positionChangeWidthSm && 
          upDownDragRankJSX(estate)
        }

      </Box>

      {/* Error Message */}
      {editItemError &&
        standardErrorContainer('Error Updating Item. Please check Internet connection.', 0, 0)
      }

      {/* Item above Remaining */}
      {(Math.floor(item.valuation) > (getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person))) &&
        standardErrorContainer('Valuation above remaining allotment.', 0, 0)
      }
      
      {/* Expand More Icon */}
      <CardActions disableSpacing
        sx={{
          // backgroundColor: 'yellow',
          width: '100%',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >

        {/* Expand More */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon 
            // color='primary' 
          />
        </ExpandMore>

      </CardActions>

      {/* Longer Description — Part that expands when Expand More is Pressed */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            component="pre"
            sx={{
              fontFamily: 'Lato',
              fontSize: '16px',
            }}
          >
            {item.description}
          </Typography>
        </CardContent>
      </Collapse>
      

    </Card>
  )
}

export default HeirDraftRankingsItemCard