/* eslint-disable no-prototype-builtins */
import React from 'react'
import axios from 'axios'

import { getTokenFromLocalStorage } from './storage'

export const modifyInDatabase = async (pathName, id, modificationsObj, setErrors) => {

  // console.log('modificationsObj ->', modificationsObj)
  setErrors(false)

  try {
    // PUT request with updated Person properties
    await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/${pathName}/${id}`, modificationsObj, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    })
    
    setErrors(false)
  } catch (error) {
    // console.log(error)
    setErrors(true)
  }
}