/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import dayjs from 'dayjs'
import io from 'socket.io-client'

import useWindowDimensions from '../../helpers/windowDimensions'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { draftTimerMinutes, draftTimerSeconds, positionChangeWidthDraft } from '../../helpers/variableDefaults'
import { scrollToBottomOfMessages, createPassItem } from '../../helpers/sharedDraftAuctionJSX.js'
import { getUnassignedItems, getPersonDrafting, getUnassignedItemsWithValuationUnderRemaining, getRemainingGreaterThanValuation } from '../../helpers/getValuationTotals'
import { pauseCountDown, resetCountDown, clearAllTimeouts, startTimeout } from '../../helpers/timer'
import { socket, initiateSocketIO, initateLiveEventConnection } from '../../helpers/socket'
import { computerDraftJSX, mobileDraftJSX } from '../../helpers/draftEventJSX.js'
import { couldNotLoadPageError } from '../../helpers/errors'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'  

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Live Draft Page
const LiveDraft = () => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId } = useParams()

  // Payload
  const payload = getPayload()

  // Estate
  const [estate, setEstate] = useState({}) // the estate
  const [userPerson, setUserPerson] = useState({}) // the Person in the estate associated with the User
  const [draftOrderPersons, setDraftOrderPersons] = useState([]) // the Persons of the people drafting in their draft order
  const [connectedPersons, setConnectedPersons] = useState([]) // Persons connected to the socket

  const [itemHighlighted, setItemHighlighted] = useState({}) // the item that the user has highlighted to draft
  const [passItem, setPassItem] = useState({}) // a "Pass" Item
  const [autoSelect, setAutoSelect] = useState(false) // Boolean — if true, then auto-select is on; if false, then auto-select is off

  const [messages, setMessages] = useState([]) // the messages array for the message board
  const [messageText, setMessageText] = useState('') // the text for a newly drafted message

  // Timer
  const [ eventHasBegun, setEventHasBegun ] = useState(false) // Boolean — if true, the event has begun; if false, the event has not yet begun
  const [ eventHasFinished, setEventHasFinished ] = useState(false) // Boolean — if true, the event has finished; if false, the event has not yet finished
  const [ minutes, setMinutes ] = useState(-1) // minutes remaining on the timer
  const [ seconds, setSeconds ] =  useState(-1) // seconds remaining on the timer
  const [ isPaused, setIsPaused ] = useState(false) // Boolean — if true, the timer has paused; if false, the timer is not paused

  // Loading
  const [loading, setLoading] = useState(false) // Boolean — if true, the view is loading; if false, the view is not loading

  // Errors
  const [errors, setErrors] = useState(false) // Boolean — if true, there are errors; if false, there are no errors

  // For scrolling to bottom of message display automatically
  const messagesEndRef = useRef(null)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {

    if (!userIsAuthenticated()) {

      // Navigate to the homepage if the user is not authenticated
      navigate('/')

    } else {

      // Retrieve the estate data
      const getData = async () => {
        setLoading(true)
        try {
          // Retrieve the estate
          const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved Estate ->', retrievedEstate)

          // If the event has finished, navigate to the results page
          if (retrievedEstate.event[0].hasFinished) {
            // console.log('should redirect to draft results')
            // console.log('estateId ->', estateId)
            navigate(`/estate/${estateId}/draft-results`)
          
          } else if (!retrievedEstate.event[0].hasStarted) {
            
            navigate(`/estate/${estateId}/draft-practice`)
          
          } else {

            // Find the Person in the estate associated with the user
            const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)

            if (userIsInEstate.length > 0 && retrievedEstate.subscriptionIsActive) {
              
              // Set States
              setUserPerson(userIsInEstate[0])
              setEstate(retrievedEstate)

              // Draft Order Persons
              const heirPersonsOrderedArray = []
              for (let i = 0; i < retrievedEstate.event[0].draftOrder.length; i++) {
                heirPersonsOrderedArray.push(retrievedEstate.people.filter(person => person._id === retrievedEstate.event[0].draftOrder[i])[0])
              }
              setDraftOrderPersons(heirPersonsOrderedArray)

              // Pass Item
              const passItemObj = createPassItem(retrievedEstate._id, heirPersonsOrderedArray)
              setPassItem(passItemObj)

              // Item Highlighted
              setItemHighlighted(getUnassignedItemsWithValuationUnderRemaining(retrievedEstate, userIsInEstate[0]).length > 0 ? getUnassignedItemsWithValuationUnderRemaining(retrievedEstate, userIsInEstate[0]).sort((a, b) => b['heirDraftPlanning'][userIsInEstate[0]._id] === a['heirDraftPlanning'][userIsInEstate[0]._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][userIsInEstate[0]._id] - b['heirDraftPlanning'][userIsInEstate[0]._id])[0] : { ...passItemObj })

              // console.log('socket ->', socket)
              // console.log('socket.connected ->', socket.connected)

              if (!socket.connected) {
                // Initiate Live Event Connection
                const liveEventConnection = await initateLiveEventConnection(estateId)
                // console.log('live event connection ->', liveEventConnection)

                // Initiate Socket IO Connection
                const joinRoom = await initiateSocketIO(userIsInEstate[0], estateId, socket)
                // console.log('join room ->', joinRoom)
              } else {
                // If socket connection already exists, must refresh to disconnect in order to make a fresh new connection
                window.location.reload()
              }
              
              // Set more states if necessary
              if (retrievedEstate.event[0].hasBegunTimer) {
                setEventHasBegun(true)
              }
              if (retrievedEstate.event[0].timer.minutes > -1) {
                setMinutes(retrievedEstate.event[0].timer.minutes)
              }
              if (retrievedEstate.event[0].timer.seconds > -1) {
                setSeconds(retrievedEstate.event[0].timer.seconds)
              }

              setErrors(false)
              setLoading(false)
            } else {

              // Navigate to the user page if the user is not associated with any Person in the estate
              navigate(`/user/${payload.sub}`)

              setErrors(false)
              setLoading(false)
            }
          }

        } catch (error) {
          // console.log(error)

          // Navigate to the user profile if the estate cannot be retrieved
          navigate(`/user/${payload.sub}`)

          setErrors(true)
          setLoading(false)
        }
      }

      getData()
    }

  }, [])

  useEffect(() => {
    // console.log('socket useEffect runs')

    // socket.onAny((event, ...args) => {
    //   console.log('socket onAny ->', event, args)
    // })

    // New Connection Listener
    socket.on('new_connection', (data) => {
      // console.log('new connection ->', data)
      // console.log('data connected people ->', data.connectedPeople)
      // console.log('data messageBoard ->', data.messageBoard)
      setConnectedPersons(data.connectedPeople)
      // setMessages(data.messageBoard)
    })

    // New Disconnection Listener
    socket.on('new_disconnection', (data) => {
      // console.log('new disconnection ->', data)
      // console.log('data connected people ->', data.connectedPeople)
      // console.log('data messageBoard ->', data.messageBoard)
      setConnectedPersons(data.connectedPeople)
      // setMessages(data.messageBoard)
    })

    // Receive Message Listener
    socket.on('receive_message', (data) => {
      // console.log('receive message ->', data)
      setMessages(data)
    })

    // Timer Listener
    socket.on('timer', (data) => {
      // console.log('timer socket data ->', data)

      // if eventHasBegun is false, set it to true
      if (!eventHasBegun) {
        setEventHasBegun(true)
      }

      // Set the timer
      setMinutes(data.minutes)
      setSeconds(data.seconds === seconds ? data.seconds + 1 : data.seconds)
    
    })

    // Item Drafted Listener
    socket.on('item_drafted', async (data) => {
      // console.log('item drafted socket data ->', data)

      // Set new estate
      setEstate(data.newEstate)

      // Set new messages
      setMessages(data.messageBoard)

      // console.log('data.newEstate ->', data.newEstate)
      // console.log('data.newEstate.items ->', data.newEstate.items)
      const newItemsForHighlighting = [ ...data.newEstate.items ]
      // console.log('newItemsForHighlighting ->', newItemsForHighlighting)
      
      // Create heir persons array for Pass Item
      const heirPersonsOrderedArray = []
      for (let i = 0; i < data.newEstate.event[0].draftOrder.length; i++) {
        heirPersonsOrderedArray.push(data.newEstate.people.filter(person => person._id === data.newEstate.event[0].draftOrder[i])[0])
      }

      // Highlight new item; Has to be done like this, so don't mess with it
      setUserPerson( person => {

        const passItemInsideEstate = createPassItem(person.estateId, heirPersonsOrderedArray)

        if (getUnassignedItemsWithValuationUnderRemaining(data.newEstate, person).filter(item => item._id === itemHighlighted._id).length === 0) {
          // console.log('new highlighted item should set')
          // console.log('userPerson inside ->', person)

          // console.log('Unassigned and undrafted ->', newItemsForHighlighting.filter(item => item.allottedToName === 'Unassigned' && getRemainingGreaterThanValuation(item, data.newEstate, person)))
          // console.log('highlighted possibilities ->', newItemsForHighlighting.filter(item => item.allottedToName === 'Unassigned' && getRemainingGreaterThanValuation(item, data.newEstate, person)).length > 0 ? newItemsForHighlighting.filter(item => item.allottedToName === 'Unassigned' && getRemainingGreaterThanValuation(item, data.newEstate, person)).sort((a, b) => b['heirDraftPlanning'][person._id] === a['heirDraftPlanning'][person._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][person._id] - b['heirDraftPlanning'][person._id]) : { ...passItemInsideEstate })
          // console.log('highlighted item ->', newItemsForHighlighting.filter(item => item.allottedToName === 'Unassigned' && getRemainingGreaterThanValuation(item, data.newEstate, person)).length > 0 ? newItemsForHighlighting.filter(item => item.allottedToName === 'Unassigned' && getRemainingGreaterThanValuation(item, data.newEstate, person)).sort((a, b) => b['heirDraftPlanning'][person._id] === a['heirDraftPlanning'][person._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][person._id] - b['heirDraftPlanning'][person._id])[0] : { ...passItemInsideEstate })
          setItemHighlighted(newItemsForHighlighting.filter(item => item.allottedToName === 'Unassigned' && getRemainingGreaterThanValuation(item, data.newEstate, person)).length > 0 ? newItemsForHighlighting.filter(item => item.allottedToName === 'Unassigned' && getRemainingGreaterThanValuation(item, data.newEstate, person)).sort((a, b) => b['heirDraftPlanning'][person._id] === a['heirDraftPlanning'][person._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][person._id] - b['heirDraftPlanning'][person._id])[0] : { ...passItemInsideEstate })
        }
        return person
      })

      // console.log('has finished ->', data.hasFinished)
      // Set event has finished to true if it has finished
      if (data.hasFinished) {
        setEventHasFinished(data.hasFinished)
      } 

      // Reset timer on client side
      setMinutes(data.minutes)
      setSeconds(data.seconds === seconds ? data.seconds + 1 : data.seconds)

    })
  }, [socket])

  // Refresh view when states change
  useEffect(() => {
    // console.log('estate items ->', estate.items)
    // console.log('userPerson ->', userPerson)
    // console.log('itemHighlighted ->', itemHighlighted)
    // console.log('draftOrderPersons ->', draftOrderPersons)
    // console.log('connectedPersons useEffect ->', connectedPersons)
  }, [itemHighlighted, connectedPersons, eventHasFinished])

  // Timer — refresh view when seconds changes; start a new timeout or assign all remaining items to be liquidated
  useEffect(() => {
    // console.log('seconds ->', seconds)
    // console.log('seconds useEffect eventHasBegun ->', eventHasBegun)
    // console.log('seconds useEffect eventHasFinished ->', eventHasFinished)

    // Decrement timer if the event is in process
    if (eventHasBegun && !eventHasFinished) {
      // console.log('timer should decrement')
      startTimeout(decrementTimer)
    } 
    
  }, [seconds])

  // Scroll to bottom of messages when a new message appears
  useEffect(() => {
    if (width >= positionChangeWidthDraft) {
      scrollToBottomOfMessages(messagesEndRef)
    }
  }, [messages])

  // Start the timer when the user Press the Start button
  const handleStartTimer = () => {
    // console.log('handleStartTimer runs')

    // Set the eventHasBegun to true
    setEventHasBegun(true)

    // Emit the start timer signal to the socket
    socket.emit('start_timer', { eventId: estate.event[0]._id })

  }

  // Execute exit draft when the user clicks the Exit button
  const handleExitDraft = () => {
    // console.log('handleExitDraft runs')

    navigate(`/estate/${estateId}/family-hq`)
    // navigate(-1)
  }

  // Decrement the timer and execute autodrafting when appropriate
  const decrementTimer = () => {
    // console.log('decrementTimer runs')
    // console.log('isPaused ->', isPaused)
    // console.log('seconds ->', seconds)

    if (seconds > 0 && !isPaused) {
      if ((draftOrderPersons.length > 0 && estate.hasOwnProperty('event') && ((autoSelect && getPersonDrafting(estate, draftOrderPersons)._id === userPerson._id) || (getPersonDrafting(estate, draftOrderPersons)._id === userPerson._id && getUnassignedItemsWithValuationUnderRemaining(estate, userPerson).length === 0))) && (seconds <= (draftTimerSeconds - 5))) {
        // Execute auto-draft if the criteria are met
        if (getUnassignedItems(estate).length > 0) {
          // console.log('auto-select should fire')
          executeItemDrafted()
        } else {
          // Decrement the timer
          // console.log('timeout decrements -1')
          setSeconds(seconds - 1)
        }
      } else {
        // Decrement the timer
        // console.log('timeout decrements -1')
        setSeconds(seconds - 1)
      }
    } else if (seconds === 0 && !isPaused) {
      if (minutes === 0) {
        // Auto-selection occurs on backend
        // console.log('auto-select occurring on backend')
      } else {
        // Decrement the timer
        // console.log('timeout decrements 59')
        setMinutes(minutes - 1)
        setSeconds(59)
      }
    } else if (!isPaused) {
      // Set the timer to the start time
      setMinutes(draftTimerMinutes)
      setSeconds(draftTimerSeconds)
    }
  }

  // Execute an item being drafted
  const executeItemDrafted = () => {

    // Pause the countdown
    if (seconds !== draftTimerSeconds && minutes !== draftTimerMinutes) {
      pauseCountDown(setIsPaused)
    }

    // Person drafting
    // const personDrafting = getPersonDrafting(estate, draftOrderPersons)

    // Item to be drafted
    const assignItemHighlighted = { ...itemHighlighted }

    // Assign the allotted to name and allotted to ID of the person drafting to the item
    assignItemHighlighted.allottedToName = userPerson.userGivenAndFamilyName
    assignItemHighlighted.allottedToPersonId = userPerson._id

    // Emit draft_item
    socket.emit('draft_item', assignItemHighlighted)

  }

  // Draft Item click handler
  const handleDraftItemPressed = async (e) => {
    e.preventDefault()

    executeItemDrafted()
  }

  // Highlight Item click handler
  const highlightNewItem = (e, item, index) => {
    e.preventDefault()
    // console.log('highlightNewItem')
    // console.log('item ->', item)

    if (item._id !== itemHighlighted._id && getRemainingGreaterThanValuation(item, estate, userPerson)) {
      setItemHighlighted(getUnassignedItemsWithValuationUnderRemaining(estate, userPerson).length > 0 ? getUnassignedItems(estate).sort((a, b) => b['heirDraftPlanning'][userPerson._id] === a['heirDraftPlanning'][userPerson._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][userPerson._id] - b['heirDraftPlanning'][userPerson._id])[index] : { ...passItem })
    }
  }

  // Send Message click handler
  const handleSendMessage = (e) => {
    e.preventDefault()
    // console.log('handleSendMessage runs')
    // console.log('e.target.value ->', e.target.value)

    // Create the newMessage object
    const newMessage = {
      timestamp: new Date(),
      // fromName: userPerson.userGivenAndFamilyName,
      // fromName: userPerson.userGivenAndFamilyName.substring(0, userPerson.userGivenAndFamilyName.indexOf(' ') + 2),
      fromName: userPerson.userGivenAndFamilyName.substring(0, userPerson.userGivenAndFamilyName.indexOf(' ')),
      message: messageText,
    }

    // Send the message with socket.io
    socket.emit('send_message', { newMessage, estateId })

    // Reset the messageText to empty
    setMessageText('')
  }

  // New Message textfield change handler
  const handleChangeMessageText = (e) => {
    e.preventDefault()
    // console.log('handleChangeMessageText runs')
    // console.log('e.target.value ->', e.target.value)
    setMessageText(e.target.value)
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Live Draft'
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          height: '100vh',
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        errors ? 
          <Box
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              height: '100vh',
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
            }}
          >
            {couldNotLoadPageError()}
          </Box>
          :
          width >= positionChangeWidthDraft ?

            // More detailed draft view for wider screens
            computerDraftJSX(
              'live', 
              estate, 
              userPerson, 
              autoSelect, setAutoSelect, 
              itemHighlighted, highlightNewItem, setItemHighlighted, 
              width, 
              draftOrderPersons, 
              handleDraftItemPressed, 
              passItem, 
              minutes, seconds, 
              eventHasBegun, handleStartTimer, handleExitDraft, 
              messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef,
              connectedPersons
            )

            :

            // Simpler draft view for narrower screens
            mobileDraftJSX(
              'live', 
              estate, 
              userPerson, 
              autoSelect, 
              itemHighlighted, highlightNewItem, setItemHighlighted, 
              width, 
              draftOrderPersons, 
              handleDraftItemPressed, 
              passItem, 
              minutes, seconds, 
              eventHasBegun, handleStartTimer, handleExitDraft, 
              messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef,
              connectedPersons
            )
          
      }
    </>
  )
}

export default LiveDraft