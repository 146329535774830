/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { sheirGreen, sheirBlue } from './variableDefaults'

// FAQ Text
export const faqTextTypography = (text, size = '20px', font = 'Caveat') => {
  return (
    <Typography
      component="pre" //in Order to read multiline strings
      sx={{
        fontFamily: font,
        fontSize: size,
      }}
    >
      {`${text}`.replace(/ {4} +/g, '')}
    </Typography>
  )
}

// Stylized Mission Text
export const missionTextTypography = (text, size = '20px', font = 'Caveat') => {
  return (
    <Typography
      component="pre" //in Order to read multiline strings
      sx={{
        fontFamily: font,
        fontSize: size,
      }}
    >
      {text}
    </Typography>
  )
}

export const saveSuccessfulTypography = () => {
  return (
    <Typography 
      component='pre'
      textAlign={'center'}
      sx={{
        mt: 2, mb: 2,
        width: '90%',
        fontStyle: 'italic',
        color: sheirBlue,
      }}
    >
      
      {`Saved!`.replace(/ {4} +/g, '')}
        
    </Typography>
  )
}