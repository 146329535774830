/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import SendIcon from '@mui/icons-material/Send'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { ExpandMore } from '../../../helpers/cardHelpers'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../../helpers/storage'
import { getHeirAllotmentsTotal, getHeirAllotmentsTotalByEstate, getHeirShareTotal, getHeirAuctionPlanningPointsAllottedTotal } from '../../../helpers/getValuationTotals'
import { positionChangeHeightSm, positionChangeWidthSm, sheirGreen, sheirBlue } from '../../../helpers/variableDefaults'
import useWindowDimensions from '../../../helpers/windowDimensions'
import { personItemTextField } from '../../../helpers/textfields'
import { modifyInDatabase } from '../../../helpers/modifyInDatabase'
import { standardErrorContainer } from '../../../helpers/errors'
import { saveSuccessfulTypography } from '../../../helpers/typographies'
import { standardButton } from '../../../helpers/buttons'

const HeirAuctionPlanningItemCard = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { item, person, estate, setEstate, pointsOver1000, setPointsOver1000 } = props

  // Payload
  const payload = getPayload()

  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId, personId } = useParams()

  // URL Path
  const urlPath = window.location.pathname

  // Tracks whether the card is expanded
  const [expanded, setExpanded] = useState(false)

  // Item
  const [newItem, setNewItem] = useState({ ...item })

  // Edit Item Error
  const [saveItemSuccessful, setSaveItemSuccessful] = useState(false)
  const [editItemError, setEditItemError] = useState(false)

  // Changes the card expanded state
  const handleExpandClick = () => {
    setExpanded(!expanded)
    setSaveItemSuccessful(false)
    setPointsOver1000(false)
  }

  // useEffect(() => {
  
  // }, [])

  const changeInItemAllotment = (previousAllotment, newAllotment) => {
    return newAllotment - previousAllotment
  }

  const handleSavePressed = async () => {
    // console.log('handleSavePressed')

    await modifyInDatabase('items', newItem._id, { ['heirAuctionPlanning']: newItem.heirAuctionPlanning }, setEditItemError)

    const newEstateItems = [ ...estate.items ]
    const itemIndexOnEstateObj = newEstateItems.map(estateItem => estateItem._id).indexOf(newItem._id)
    newEstateItems[itemIndexOnEstateObj] = { ...newItem }
    setEstate({ ...estate, items: newEstateItems })

    setSaveItemSuccessful(true)
  }

  // Handle auction planning points change
  const handlePointsChange = async (e, setCurrentItem, currentItem, itemIndex) => {
    // console.log('handlePointsChange runs')
    setPointsOver1000(false)
    setSaveItemSuccessful(false)

    // Create name and value variables
    let name
    let value

    // Assign values to name and value depending on the type of event target
    if (!e.target.name) {
      name = e.target.classList[1]
      value = Math.floor(e.target.classList[0].slice(5))
    } else {
      name = e.target.name
      value = Math.floor(e.target.value)
    }
    // console.log('name ->', name)
    // console.log('value ->', value)
    // console.log('itemIndex ->', itemIndex)

    if (!isNaN(value)) {
      // For whatever reason, spreading doesn't separate the new obj from the old one, so this has to be done in a roundabout way until I learn of something better
      const newEstate = { ...estate }

      let pointsAllottedTotal = 0
      await newEstate.items.forEach((countingItem, countingItemIndex) => {
        if (countingItemIndex !== itemIndex) {
          pointsAllottedTotal = pointsAllottedTotal + countingItem[name][personId]
        } else if (value) {
          pointsAllottedTotal = pointsAllottedTotal + value
        }
      })
      // console.log('pointsAllottedTotal ->', pointsAllottedTotal)

      if (pointsAllottedTotal <= 1000) {
        const newHeirAuctionPlanningObj = { ...newItem[name], [personId]: (value && value >= 0) ? value : 0 }
        // console.log('newHeirAuctionPlanningObj ->', newHeirAuctionPlanningObj)

        setNewItem({ ...newItem, [name]: newHeirAuctionPlanningObj })
      } else {
        setPointsOver1000(true)
      }
    }
    
    
  }


  return (
    <Card align="left" 
      sx={{ 
        backgroundColor: 'whitesmoke',
        width: '95%', boxShadow: 3, borderRadius: 3,
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: width < positionChangeWidthSm ? 'flex-start' : 'space-between', alignItems: 'center',
        }}
      >
        <Box
          sx={{
            // height: width < positionChangeWidthSm ? '300px' : '200px',
            width: '100%',
            display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: width < positionChangeWidthSm ? 'center' : 'flex-start', alignItems: 'center',
          }}
        >
          {/* Image */}
          <CardMedia
            component="img"
            height={width < positionChangeWidthSm ? '150px' : '150px'}
            image={item.imageURL}
            alt={item.itemName}
            sx={{ 
              m: 1,
              borderRadius: 3,
              boxShadow: 3,
              display: 'inline-block',
              width: '150px',
            }}
          />

          {/* Title, Category, Valuation */}
          <Box
            sx={{
              // backgroundColor: 'yellow',
              ml: width < positionChangeWidthSm ? 0 : 1,
              width: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {/* Item Name */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                // backgroundColor: 'yellow',
                width: '95%',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {item.itemName}
            </Typography>

            {/* Category */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                width: '95%',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
              }}
            >
              {item.category}
            </Typography>

            {/* Valuation */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                width: '95%',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                color: (Math.floor(item.valuation) > (getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person))) ? 'red' : sheirBlue,
              }}
            >
              ${Math.floor(item.valuation).toLocaleString('en-US', { minimumFractionDigits: 0 })}
            </Typography>

            {/* Percent of Share */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                width: '95%',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
              }}
            >
              ({((Math.floor(item.valuation) / getHeirShareTotal(estate, person.sharePercentage)) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%)
            </Typography>

          </Box>

        </Box>
        
        <Box
          sx={{
            // width: '100%',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
          }}
        >
          {/* Points */}
          <Box 
            sx={{ 
              mr: width < positionChangeWidthSm ? 0 : 1, mt: width < positionChangeWidthSm ? 2 : 0,
              display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'space-between' : 'center', alignItems: 'center', 
            }}
          >
            {personItemTextField(
              'heirAuctionPlanning', 
              width < positionChangeWidthSm ? 'Points' : '', 'text', 
              newItem.heirAuctionPlanning[personId] === 0 ? '' : newItem.heirAuctionPlanning[personId], 
              '0', 
              estate.event[0].hasBegunTimer 
              || estate.event[0].hasFinished 
              // || (Math.floor(item.valuation) > (getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person)))
              , 
              !(estate.event[0].hasBegunTimer || estate.event[0].hasFinished), 
              handlePointsChange, 
              setNewItem, 
              newItem, 
              estate.items.map(e => e._id).indexOf(newItem._id)
            )}
            {width >= positionChangeWidthSm && <Typography sx={{ ml: 1 }}><strong> points</strong></Typography>}
          </Box>


          {/* Advisory */}
          <Typography 
            // textAlign={'left'}
            sx={{
              // backgroundColor: 'yellow',
              mt: 1,
              width: '95%', maxWidth: '540px',
              textAlign: 'center',
              color: pointsOver1000 ? 'red' : 'black',
              fontSize: '12px',
              display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'center' : 'flex-start',
            }}
          >
            {pointsOver1000 ? <em>*1000 points limit</em> : `${getHeirAuctionPlanningPointsAllottedTotal(estate, person._id) + changeInItemAllotment(estate.items[estate.items.map(i => i._id).indexOf(newItem._id)].heirAuctionPlanning[personId], newItem.heirAuctionPlanning[personId])} / 1000` }
          </Typography>

          {/* Save Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Save', 
              'button',
              'contained',
              estate.items[estate.items.map(i => i._id).indexOf(newItem._id)].heirAuctionPlanning[personId] === newItem.heirAuctionPlanning[personId],
              // item.heirAuctionPlanning[personId] === newItem.heirAuctionPlanning[personId],
              'primary', 
              2,
              0,
              0,
              '70px', 
              '30px', 
              handleSavePressed
            )}
          </Box>

          {/* Save Successful Typography */}
          {/* {saveItemSuccessful && saveSuccessfulTypography()} */}
        </Box>

      </Box>

      {/* Error Message */}
      {editItemError &&
        standardErrorContainer('Error Updating Item. Please check Internet connection.', 0, 0)
      }

      {(Math.floor(item.valuation) > (getHeirShareTotal(estate, person.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, person))) &&
        standardErrorContainer('Valuation above remaining allotment.', 0, 0)
      }
      
      {/* Expand More Icon */}
      <CardActions disableSpacing
        sx={{
          // backgroundColor: 'yellow',
          width: '100%',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >

        {/* Expand More */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon 
            // color='primary' 
          />
        </ExpandMore>

      </CardActions>

      {/* Longer Description — Part that expands when Expand More is Pressed */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            component="pre"
            sx={{
              fontFamily: 'Lato',
              fontSize: '16px',
            }}
          >
            {item.description}
          </Typography>
        </CardContent>
      </Collapse>
      

    </Card>
  )
}

export default HeirAuctionPlanningItemCard