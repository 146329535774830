/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'

import DraftPractice from '../draft/DraftPractice'
import AuctionPractice from '../auction/AuctionPractice'
import TestEstate from './TestEstate'
import PageNavbar from '../common/PageNavbar'
import Footer from '../common/Footer'

import { selectElement } from '../../helpers/selects'
import { standardButton } from '../../helpers/buttons'
import { navbarHeight, footerHeight, estatesTestDataArray, positionChangeWidthSm, distributionMethod, testProductImageDimension, auctionOrDraft } from '../../helpers/variableDefaults'
import { shuffleArray } from '../../helpers/globalHelpers'
import useWindowDimensions from '../../helpers/windowDimensions'


import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Demo Page
const Demo = () => {
  // console.log('estatesTestDataArray ->', estatesTestDataArray)

  // use Naviage
  const navigate = useNavigate()

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // URL Path
  const urlPath = window.location.pathname
  // console.log('urlPath ->', urlPath)

  // States
  const [heirsArray, setHeirsArray] = useState([])
  const [heirSelectedIndex, setHeirSelectedIndex] = useState(0)
  const [estatesArray, setEstatesArray] = useState(shuffleArray([ ...estatesTestDataArray ]))
  const [estateSelectedIndex, setEstateSelectedIndex] = useState(0)


  useEffect(() => {
    
  }, [])

  // Use Effects
  useEffect(() => {
    // console.log('estatesArray useEffect runs')
    // console.log('estatesArray event ->', estatesArray[estateSelectedIndex].event[0])
    
  }, [estatesArray])

  useEffect(() => {
    // console.log('estateSelectedIndex useEffect runs')
    // console.log('estatesArray event ->', estatesArray[estateSelectedIndex].event[0])

    setHeirSelectedIndex(0)
    setHeirsArray(shuffleArray([ ...estatesArray[estateSelectedIndex].people.filter(person => person.role === 'Heir') ]))
    

  }, [estateSelectedIndex])

  useEffect(() => {
    // console.log('heirSelectedIndex useEffect runs')
    
  }, [heirSelectedIndex])

  const handleSelectEstate = async (estateIndex) => {
    // console.log('handleSelectEstate runs')
    // console.log('distribution method previous selection ->', estatesArray[estateSelectedIndex].event[0].distributionMethod)


    if (estatesArray[estateSelectedIndex].event[0].distributionMethod !== estatesArray[estateIndex].event[0].distributionMethod) {
      const newEstatesArray = [ ...estatesArray ]

      newEstatesArray[estateIndex].event[0].distributionMethod = estatesArray[estateSelectedIndex].event[0].distributionMethod

      if (estatesArray[estateSelectedIndex].event[0].distributionMethod === 'Allocate All') {
        newEstatesArray[estateIndex].event[0].liveOrAuto = 'Auto'
        newEstatesArray[estateIndex].event[0].silentOrParticipatory = 'Silent'
      } else {
        newEstatesArray[estateIndex].event[0].liveOrAuto = 'Live'
        newEstatesArray[estateIndex].event[0].silentOrParticipatory = 'Participatory'
      }

      setEstatesArray(newEstatesArray)
    }

    setEstateSelectedIndex(estateIndex)

  }

  const handleDraftOrAuction = async (e) => {
    // console.log('handleDraftOrAuction runs')
    // console.log('e.target.name ->', e.target.name)
    // console.log('e.target.value ->', e.target.value)

    const newEstatesArray = [ ...estatesArray ]

    newEstatesArray[estateSelectedIndex].event[0].distributionMethod = e.target.value

    if (e.target.value === 'Allocate All') {
      newEstatesArray[estateSelectedIndex].event[0].liveOrAuto = 'Auto'
      newEstatesArray[estateSelectedIndex].event[0].silentOrParticipatory = 'Silent'
    } else {
      newEstatesArray[estateSelectedIndex].event[0].liveOrAuto = 'Live'
      newEstatesArray[estateSelectedIndex].event[0].silentOrParticipatory = 'Participatory'
    }

    setEstatesArray(newEstatesArray)
  }

  const handleStartDemo = async () => {
    // console.log('handleStartDemo runs')

    if (estatesArray[estateSelectedIndex].event[0].distributionMethod === 'Auction' || estatesArray[estateSelectedIndex].event[0].distributionMethod === 'Draft') {
      // console.log('auction view should set')
      window.scrollTo(0, 0)

      const estateOwnerName = estatesArray[estateSelectedIndex].estateOwnerName.split(' ').join('-').toLowerCase()

      const heirName = heirsArray[heirSelectedIndex].userGivenAndFamilyName.split(' ').join('-').toLowerCase()

      const eventType = estatesArray[estateSelectedIndex].event[0].distributionMethod.toLowerCase()
      
      navigate(`/demo/${estateOwnerName}/${eventType}/${heirName}`)

      // Collect Analytics
      // console.log('custom event lable ->', `test_product_auction_${estatesArray[estateSelectedIndex].estateOwnerName.split(' ').pop()}_${heirsArray[heirSelectedIndex].userGivenAndFamilyName.split(' ')[0]}`.toLowerCase())
      customAnalyticsEvent(
        `demo_${eventType}_${estatesArray[estateSelectedIndex].estateOwnerName.split(' ').pop()}_${heirsArray[heirSelectedIndex].userGivenAndFamilyName.split(' ')[0]}`.toLowerCase(), 
        `demo_${eventType}_${estatesArray[estateSelectedIndex].estateOwnerName.split(' ').pop()}_${heirsArray[heirSelectedIndex].userGivenAndFamilyName.split(' ')[0]}`.toLowerCase(), 
        `demo_${eventType}`, 
        `demo_${eventType}_${estatesArray[estateSelectedIndex].estateOwnerName.split(' ').pop()}_${heirsArray[heirSelectedIndex].userGivenAndFamilyName.split(' ')[0]}`.toLowerCase(), 
        `Demo — ${estatesArray[estateSelectedIndex].event[0].distributionMethod}`
      )
    } else if (estatesArray[estateSelectedIndex].event[0].distributionMethod === 'Allocate All') {
      // console.log('should navigate to test estate')
      window.scrollTo(0, 0)

      const estateOwnerName = estatesArray[estateSelectedIndex].estateOwnerName.split(' ').join('-').toLowerCase()

      navigate(`/demo/${estateOwnerName}/test-estate`)

      // Collect Analytics
      customAnalyticsEvent(
        `demo_estate_${estatesArray[estateSelectedIndex].estateOwnerName.split(' ').join('_')}`.toLowerCase(), 
        `demo_estate_${estatesArray[estateSelectedIndex].estateOwnerName.split(' ').join('_')}`.toLowerCase(), 
        'demo_estate', 
        `demo_estate_${estatesArray[estateSelectedIndex].estateOwnerName.split(' ').join('_')}`.toLowerCase(), 
        `Demo — Estate — ${estatesArray[estateSelectedIndex].estateOwnerName}`
      )

    }
  }


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Demo'
      )}
      {/* Necessary because no nav bar in live event views */}
      <PageNavbar />

      <Box 
        sx={{ 
          pb: 8,
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`, 
          width: '100vw', 
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
        }}
      >

        {/* Distribution Method Select */}
        <Box
          sx={{
            mt: 4, mb: 2,
            backgroundColor: 'whitesmoke',
            width: '100%',
            maxWidth: '600px',
            boxShadow: 3,
            borderRadius: width < positionChangeWidthSm ? 0 : 5,
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <Box
            sx={{
              mt: 2, mb: 2,
              width: '100%',
              maxWidth: '300px',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {estatesArray.length > 0 && selectElement('distributionMethod', 'Distribution Method', estatesArray[estateSelectedIndex].event[0].distributionMethod, false, true, distributionMethod, handleDraftOrAuction, estatesArray, setEstatesArray, -1)}
          </Box>
        </Box>

        {/* Choose Estate */}
        <Box 
          sx={{ 
            my: 2, py: 2,
            backgroundColor: 'whitesmoke',
            width: '100%',
            maxWidth: '600px', 
            boxShadow: 3,
            borderRadius: width < positionChangeWidthSm ? 0 : 5,
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
          }}
        >
          {/* Title */}
          <Box 
            sx={{ 
              mb: 2,
              width: '95%',
              fontSize: '20px',
              fontWeight: 'bold',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
            }}
          >
            Select an Estate
          </Box>

          {/* Estates */}
          <Box 
            sx={{ 
              width: '95%',
              display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'space-around' : 'space-between', alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {estatesArray.length > 0 && estatesArray.map((estate, estateIndex) => (
              <Box
                key={estateIndex}
                onClick={() => handleSelectEstate(estateIndex)}
                sx={{
                  my: 1, mx: 0,
                  py: 1,
                  backgroundColor: 'whitesmoke',
                  width: '100%',
                  maxWidth: '275px',
                  boxShadow: 3,
                  borderRadius: 5,
                  cursor: 'pointer',
                  border: estateSelectedIndex === estateIndex ? '2px solid #2979ff' : '0px solid #2979ff',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {/* Image */}
                <Box component='img' src={estate.estateOwnerProfilePicture} alt='Estate Owner Image' 
                  sx= {{ 
                    ml: 1, mr: 1,
                    height: testProductImageDimension, width: testProductImageDimension, objectFit: 'cover', boxShadow: 2, 
                  }} 
                /> 

                {/* Title */}
                <Box
                  textAlign={'center'}
                  sx={{
                    // backgroundColor: 'yellow',
                    pt: 1, pb: 1,
                    width: '95%',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {estate.estateName}
                </Box>

              </Box>
            ))}

          </Box>


        </Box>


        {/* Choose Person to Bid/Draft For */}
        {(estatesArray[estateSelectedIndex].event[0].distributionMethod === 'Auction' || estatesArray[estateSelectedIndex].event[0].distributionMethod === 'Draft') &&
          <Box 
            sx={{ 
              my: 2, py: 2,
              backgroundColor: 'whitesmoke',
              width: '100%',
              maxWidth: '600px', 
              boxShadow: 3,
              borderRadius: width < positionChangeWidthSm ? 0 : 5,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
            }}
          >
            {/* Title */}
            <Box 
              sx={{ 
                mb: 2,
                width: '95%',
                fontSize: '20px',
                fontWeight: 'bold',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
              }}
            >
              {estatesArray[estateSelectedIndex].event[0].distributionMethod === 'Auction' ? 'Bid' : 'Draft'} As
            </Box>

            {/* People */}
            <Box 
              sx={{ 
                width: '95%',
                display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'space-around' : 'space-between', alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              {heirsArray.map((heirsArrayPerson, heirsArrayPersonIndex) => (
                <Box
                  key={heirsArrayPersonIndex}
                  onClick={() => setHeirSelectedIndex(heirsArrayPersonIndex)}
                  sx={{
                    my: 1, mx: 0,
                    py: 1,
                    backgroundColor: 'whitesmoke',
                    width: '90%',
                    maxWidth: '275px',
                    boxShadow: 3,
                    borderRadius: 5,
                    cursor: 'pointer',
                    border: heirSelectedIndex === heirsArrayPersonIndex ? '2px solid #2979ff' : '0px solid #2979ff',
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                  }}
                >
                  {/* Image */}
                  <Box component='img' src={heirsArrayPerson.userProfilePicture} alt='Heir Image' 
                    sx= {{ 
                      ml: 1, mr: 1,
                      height: testProductImageDimension, width: testProductImageDimension, objectFit: 'cover', boxShadow: 2, 
                    }} 
                  /> 

                  {/* Title */}
                  <Box
                    textAlign={'center'}
                    sx={{
                      // backgroundColor: 'yellow',
                      pt: 1, pb: 1,
                      width: '95%',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {heirsArrayPerson.userGivenAndFamilyName}
                  </Box>

                </Box>
              ))}

            </Box>

          </Box>
        }

        {/* Start Button */}
        <Box
          sx={{
            // pl: 2,
            pt: 2,
            width: '100%', 
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {standardButton(
            `Start ${estatesArray[estateSelectedIndex].event[0].distributionMethod === 'Draft' || estatesArray[estateSelectedIndex].event[0].distributionMethod === 'Auction' ? estatesArray[estateSelectedIndex].event[0].distributionMethod : 'Demo'}`, 
            'button',
            'contained',
            false,
            'primary', 
            0,
            0,
            0,
            '180px', 
            '50px', 
            handleStartDemo
          )}
        </Box>
      </Box>

      <Footer />
        
    </>
  )
}

export default Demo