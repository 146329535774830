
const startBidHistoryAndAuctionPlanningObj = {
  brinkerSpiliyachtesPersonId: 0, 
  eatonSpiliyachtesPersonId: 0, 
  mariaSpiliyachtesPersonId: 0,
}

const startDraftPlanningObj = {
  brinkerSpiliyachtesPersonId: 1000000000, 
  eatonSpiliyachtesPersonId: 1000000000, 
  mariaSpiliyachtesPersonId: 1000000000,
}

export const pelorusAlexSpiliyachtes = {
  _id: 'pelorusItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Pelorus',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-yacht.jpg',
  valuation: '110000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'Mega-yacht originally designed for Mohamed Bin Salman that Alex bought in 2018, sold in 2022 when FTX went under, and repurchased again in 2023 when his portfolio rebounded.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const amazonStockAlexSpiliyachtes = {
  _id: 'amazonStockItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Amazon Stock',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-amazon.jpg',
  valuation: '800000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Liquidity',
  description: 'Amazon stock that I bought in 1998 and has since 1000x\'d for me.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const ferrariBlueAlexSpiliyachtes = {
  _id: 'ferrariBlueItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Ferrari — Blue',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blue-ferrari.jpg',
  valuation: '150000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: '2009 Ferrari in mint condition because I have too many cars and never drove it.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const crystalChandelierAlexSpiliyachtes = {
  _id: 'crystalChandelierItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Crystal Chandelier',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-crystal-chandelier.jpg',
  valuation: '35000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'Made from pure Lalique crystal, bought from Cristofle in 1978.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const diamondMineAlexSpiliyachtes = {
  _id: 'diamondMineItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Diamond Mine — Canadian Arctic',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-diamond-mine-canadian-arctic.jpg',
  valuation: '27000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 32% stake in a diamond mine located in the Canadian Arctic',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const fabergeEggAlexSpiliyachtes = {
  _id: 'fabergeEggItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Fabergé Egg',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-faberge-egg.jpg',
  valuation: '3500000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'An original Fabergé Egg bought at auction through Sotheby\'s in 2004.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const googleStockAlexSpiliyachtes = {
  _id: 'googleStockItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Google Stock',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-google-logo.jpg',
  valuation: '3000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Liquidity',
  description: 'Google stock that I bought in 2002 and has since 3000x\'d for me.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const mansionHollywoodHillsAlexSpiliyachtes = {
  _id: 'mansionHollywoodHillsItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Mansion — Hollywood Hills',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-hollywood-hills-mansion.jpg',
  valuation: '60000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My mansion in the Hollywood Hills, used mostly for entertaining celebrities; just a 10 minute helicopter ride to LAX and also to Staples Center.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const ranchJacksonHoleAlexSpiliyachtes = {
  _id: 'ranchJacksonHoleItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Ranch — Jackson Hole',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-jackson-hole-ranch.jpg',
  valuation: '10000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My ranch in Wyoming, where I did my cattle farming. Includes a bomb shelter with 3-years of food and an underground well, for when The Event occurs.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const beachHouseMaltaAlexSpiliyachtes = {
  _id: 'beachHouseMaltaItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Beach House — Malta',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-malta-beach-house.jpg',
  valuation: '5000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My beach house in Malta, where I spent 60 days every year so that I could claim it as my personal residence and thus be privy to the tax benefits. Whoever ends up with this will have an inside track to Maltese citizenship.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const microsoftStockAlexSpiliyachtes = {
  _id: 'microsoftStockItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Microsoft Stock',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-microsoft.jpg',
  valuation: '5000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Liquidity',
  description: 'Microsoft stock that I bought in 1992 and has since 10000x\'d for me.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const mondrianPaintingAlexSpiliyachtes = {
  _id: 'mondrianPaintingItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Mondrian Painting',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-mondrian-painting.jpg',
  valuation: '95000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Art',
  description: 'An original Mondrian bought through Larry Gagosian in 1996.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const mansionNYCAlexSpiliyachtes = {
  _id: 'mansionNYCItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Mansion — NYC',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-nyc-mansion.jpg',
  valuation: '70000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My mansion Upper East Side NYC mansion, formerly owned by my old friend Sherman McCoy, and on the same street as Holly Golightly from Breakfast at Tiffany\'s.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const oilRigsAlexSpiliyachtes = {
  _id: 'oilRigsItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Oil Rigs — Gulf of Mexico',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-oil-rig.jpg',
  valuation: '40000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 41% stake in an oil company that focuses on extracting and refining.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const persianRugAlexSpiliyachtes = {
  _id: 'persianRugItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Persian Rug',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-persian-rug.jpg',
  valuation: '250000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'Rug gifted to me by the sheikh of Iran in 1989.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const picassoPaintingAlexSpiliyachtes = {
  _id: 'picassoPaintingItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Picasso Painting',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-picasso-dora-maar.jpg',
  valuation: '115000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Art',
  description: 'An original Picasso portrait of Dora Maar bought through Anne Friedman in 2008.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const privateIslandAlexSpiliyachtes = {
  _id: 'privateIslandItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Private Island',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-private-island.jpg',
  valuation: '55000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My private island in the US Virgin Islands, where I \'relaxed\'; used to belong to my good friend Montgomery Burns.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const privateJetAlexSpiliyachtes = {
  _id: 'privateJetItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Private Jet',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-private-jet.jpg',
  valuation: '8000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'Private jet — a classic Gulfstream.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const ferrariRedAlexSpiliyachtes = {
  _id: 'ferrariRedItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Ferrari — Red',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-red-ferrari.jpg',
  valuation: '175000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: '2015 Ferrari in mint condition because I have too many cars and never drove it.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const ritzCarltonViennaAlexSpiliyachtes = {
  _id: 'ritzCarltonViennaItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Ritz Carlton — Vienna',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-ritz-carlton-vienna.jpg',
  valuation: '2000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 3% stake in the Ritz Carlton in Vienna.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const rubyCollectionAlexSpiliyachtes = {
  _id: 'rubyCollectionItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Ruby Collection',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-rubies.jpg',
  valuation: '1250000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'The ruby collection that I amassed throughout my adulthood.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const subaruWhiteAlexSpiliyachtes = {
  _id: 'subaruWhiteItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Subaru — White',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-subaru.jpg',
  valuation: '17500',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'Used Subaru with 35000 miles on it — the only car I actually drive.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const vanGoghPaintingAlexSpiliyachtes = {
  _id: 'vanGoghPaintingItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Van Gogh Painting',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-van-gogh.jpg',
  valuation: '115000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Art',
  description: 'An original Van Gogh self portrait bought through Larry Gagosian in 2003.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const ferrariYellowAlexSpiliyachtes = {
  _id: 'ferrariYellowItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Ferrari — Yellow',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-yellow-ferrari.jpg',
  valuation: '195000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: '2005 Ferrari in mint condition because I have too many cars and never drove it.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const helicopterAlexSpiliyachtes = {
  _id: 'helicopterItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Helicopter',
  imageURL: 'https://sheir-bizops-images.s3.amazonaws.com/seed-category-vehicle.jpg',
  valuation: '3000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'Helicopter — used mostly for traversing LA when at the Hollywood Hills mansion.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const pradaJacketCollectionAlexSpiliyachtes = {
  _id: 'pradaJacketCollectionItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Prada Jacket Collection',
  imageURL: 'https://sheir-bizops-images.s3.amazonaws.com/seed-category-clothing.jpg',
  valuation: '900000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My collection of vintage Prada jackets.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const monetPaintingAlexSpiliyachtes = {
  _id: 'monetPaintingId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Monet Painting',
  imageURL: 'https://sheir-bizops-images.s3.amazonaws.com/seed-category-art.jpg',
  valuation: '25000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Art',
  description: 'An original Monet waterlilies painting bought through Anne Friedman in 1999.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const ivoryTradingCompanyAlexSpiliyachtes = {
  _id: 'ivoryTradingCompanyItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Ivory Trading Company',
  imageURL: 'https://sheir-bizops-images.s3.amazonaws.com/seed-category-equity.jpg',
  valuation: '45000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My ivory trading operation based in the Serenghetti and certain parts of Asia.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const napoleonsRoyalBidetAlexSpiliyachtes = {
  _id: 'napoleonsRoyalBidetItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Napoleon\'s Royal Bidet',
  imageURL: 'https://sheir-bizops-images.s3.amazonaws.com/seed-category-furniture.jpg',
  valuation: '350000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'The bidet that Napoleon Bonaparte used, bought at auction through Sotheby\'s in 2018.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const pearlCollectionAlexSpiliyachtes = {
  _id: 'pearlCollectionItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Pearl Collection',
  imageURL: 'https://sheir-bizops-images.s3.amazonaws.com/seed-category-jewelry.jpg',
  valuation: '650000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'Pearls collected by hand by my staff at the oyster bed off my chateau in Bretagne.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const chateauBretagneAlexSpiliyachtes = {
  _id: 'chateauBretagneItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Chateau — Bretagne',
  imageURL: 'https://sheir-bizops-images.s3.amazonaws.com/seed-category-real-estate.jpg',
  valuation: '7500000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'Estate in Bretagne that I bought in 2001 and use primarily for oyster farming.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

export const bitcoinAlexSpiliyachtes = {
  _id: 'bitcoinItemId',
  estateId: 'alexSpiliyachtesEstateId',
  itemName: 'Bitcoin',
  imageURL: 'https://sheir-bizops-images.s3.amazonaws.com/seed-category-stock.jpg',
  valuation: '55000000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Liquidity',
  description: 'Bitcoin that I bought in 2009 and has since 10000x\'d for me.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}