

// Sources:

// Constants
const startBidHistoryAndAuctionPlanningObj = {
  ringoStarrPersonId: 0, 
  georgeHarrisonPersonId: 0, 
  paulMcCartneyPersonId: 0,
  yokoOnoPersonId: 0,
}

const startDraftPlanningObj = {
  ringoStarrPersonId: 1000000000, 
  georgeHarrisonPersonId: 1000000000, 
  paulMcCartneyPersonId: 1000000000,
  yokoOnoPersonId: 1000000000,
}


// Items


// Gently Weeping Guitar
export const johnLennonGentlyWeepingGuitar = {
  _id: 'johnLennonGentlyWeepingGuitarItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Gently Weeping Guitar',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-gently-weeping-guitar-1.jpg',
  valuation: '3000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'My guitar that gently weeps for me when I feel sad.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Strawberry Fields
export const johnLennonStrawberryFields = {
  _id: 'johnLennonStrawberryFieldsItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Strawberry Fields',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-strawberry-fields-1.jpg',
  valuation: '200000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'My strawberry far in upstate New York, near Woodstock.',
  allottedToName: 'Paul McCartney',
  allottedToPersonId: 'paulMcCartneyPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Sunglasses
export const johnLennonSunglasses = {
  _id: 'johnLennonSunglassesItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Sunglasses',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-sunglasses-1.jpg',
  valuation: '20000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My famous sunglasses.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Yellow Submarine
export const johnLennonYellowSubmarine = {
  _id: 'johnLennonYellowSubmarineItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Yellow Submarine',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-yellow-submarine-1.jpg',
  valuation: '1800000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Vehicle',
  description: 'The yellow submarine that we all live in.',
  allottedToName: 'George Harrison',
  allottedToPersonId: 'georgeHarrisonPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Lucy's Diamonds
export const johnLennonLucysDiamonds = {
  _id: 'johnLennonLucysDiamondsItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Lucy\'s Diamonds',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-diamonds-1.jpg',
  valuation: '150000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'The diamonds surrounding Lucy when she\'s in the sky.',
  allottedToName: 'Yoko Ono',
  allottedToPersonId: 'yokoOnoPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Octopus Garden
export const johnLennonOctopusGarden = {
  _id: 'johnLennonOctopusGardenItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Octopus Garden',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-octopus-garden-1.jpg',
  valuation: '50000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Animal',
  description: 'A garden filled with octopi, just off the beach, not far from Bill\'s Bungalow',
  allottedToName: 'Paul McCartney',
  allottedToPersonId: 'paulMcCartneyPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Maxwell's Silver Hammer
export const johnLennonSilverHammer = {
  _id: 'johnLennonSilverHammerItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Maxwell\'s Silver Hammer',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-silver-hammer-1.jpg',
  valuation: '60000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'The silver hammer that Maxwell likes to use. Made of 100% pure silver.',
  allottedToName: 'George Harrison',
  allottedToPersonId: 'georgeHarrisonPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Bill's Bungalow
export const johnLennonBillsBungalow = {
  _id: 'johnLennonBillsBungalowItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Bill\'s Bungalow',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-bungalow-1.jpg',
  valuation: '1600000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'Bill\'s bungalow, not far from the beach where I have my octopus garden.',
  allottedToName: 'Yoko Ono',
  allottedToPersonId: 'yokoOnoPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Old Brown Shoe
export const johnLennonOldBrownShoe = {
  _id: 'johnLennonOldBrownShoeItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Old Brown Shoe',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-old-brown-shoe-1.jpg',
  valuation: '5',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My old brown shoe that the dog likes to chew on.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Rocky Raccoon
export const johnLennonRockyRaccoon = {
  _id: 'johnLennonRockyRaccoonItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Rocky Raccoon',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-rocky-raccoon-1.jpg',
  valuation: '2000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Animal',
  description: 'My pet raccoon, Rocky.',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'Bob Dylan',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Bathroom Window
export const johnLennonBathroomWindow = {
  _id: 'johnLennonBathroomWindowItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Bathroom Window',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-bathroom-window-1.jpg',
  valuation: '4200',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'My bathroom window that she likes to come in through.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Glass Onion
export const johnLennonGlassOnion = {
  _id: 'johnLennonGlassOnionItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Glass Onion',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-glass-onion-1.jpg',
  valuation: '3000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'The glass onion that I like to keep on the table in my living room',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'Penny Lane',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// A Fine Feeling
export const johnLennonFineFeeling = {
  _id: 'johnLennonFineFeelingItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'A Fine Feeling',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-fine-feeling-1.jpg',
  valuation: '0',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'Just smile. It will give you a nice feeling.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// An Eighth Day of the Week
export const johnLennonEighthDay = {
  _id: 'johnLennonEighthDayItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Eighth Day of the Week',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-day-8-1.jpg',
  valuation: '1700000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'An extra day of the week — it\'s the secret to how I was so prolific.',
  allottedToName: 'Paul McCartney',
  allottedToPersonId: 'paulMcCartneyPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Honey Pie Recipe
export const johnLennonHoneyPie = {
  _id: 'johnLennonHoneyPieItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Honey Pie Recipe',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-honey-pie-1.jpg',
  valuation: '200',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Keepsake',
  description: 'The recipe for my world famous honey pie.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// A Ticket to Ride
export const johnLennonTicketToRide = {
  _id: 'johnLennonTicketToRideItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'A Ticket to Ride',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-ticket-to-ride-1.jpg',
  valuation: '300',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'Here, have my tickets to ride, and please be careful.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// A Revolution
export const johnLennonRevolution = {
  _id: 'johnLennonRevolutionItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'A Revolution (Painting)',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-revolution-1.jpg',
  valuation: '1600000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Art',
  description: 'You say want a revolution? Well this is my painting of the United States Revolution.',
  allottedToName: 'Ringo Starr',
  allottedToPersonId: 'ringoStarrPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// My Monkey With Nothing to Hide
export const johnLennonMonkey = {
  _id: 'johnLennonMonkeyItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Monkey With Nothing To Hide',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-monkey-1.jpg',
  valuation: '30000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Animal',
  description: 'My monkey with nothing to hide.',
  allottedToName: 'Yoko Ono',
  allottedToPersonId: 'yokoOnoPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Happiness' Warm Gun
export const johnLennonWarmGun = {
  _id: 'johnLennonWarmGunItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Warm Gun',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-warm-gun-1.jpg',
  valuation: '15000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'Have my warm gun, otherwise known as happiness.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Norwegian Wood
export const johnLennonNorwegianWood = {
  _id: 'johnLennonNorwegianWoodItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Norwegian Wood Trading Company',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-norwegian-wood-1.jpg',
  valuation: '1300000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Equity',
  description: 'My 20% stake in a Norwegian wood trading company.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// The Long and Winding Road
export const johnLennonWindingRoad = {
  _id: 'johnLennonWindingRoadItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Long and Winding Road',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-winding-road-1.jpg',
  valuation: '800000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'A long and winding road that I own in upstate New York, near Woodstock. The foliage there is beautiful in the fall.',
  allottedToName: 'Ringo Starr',
  allottedToPersonId: 'ringoStarrPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// My Hand to Hold
export const johnLennonHand = {
  _id: 'johnLennonHandItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'My Hand to Hold',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-hand-1.jpg',
  valuation: '5000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Keepsake',
  description: 'My cadaverous hand to hold.',
  allottedToName: 'Gift',
  allottedToPersonId: 'NA',
  giftFor: 'Mick Jagger',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// The Walrus
export const johnLennonWalrus = {
  _id: 'johnLennonWalrusItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'The Walrus',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-walrus-1.jpg',
  valuation: '600000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Animal',
  description: 'My walrus alter ego. Great for winters on the ice. Tusks still very sharp.',
  allottedToName: 'Yoko Ono',
  allottedToPersonId: 'yokoOnoPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// A Lane of Pennies
export const johnLennonPennies = {
  _id: 'johnLennonPenniesItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Lane of Pennies',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-pennies-1.jpg',
  valuation: '400000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Liquidity',
  description: 'My lane of pennies. Everyone misinterprets Penny Lane — it\'s about my literal lane that\'s filled with pennies, not the groupie.',
  allottedToName: 'George Harrison',
  allottedToPersonId: 'georgeHarrisonPersonId',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Paperback Writer's Signed Paperback
export const johnLennonPaperback = {
  _id: 'johnLennonPaperbackItemId',
  estateId: 'johnLennonEstateId',
  itemName: 'Paperback',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-paperback-1.jpg',
  valuation: '12',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Art',
  description: 'A paperback, signed by the paperback writer.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Something
export const johnLennonSomething = {
  _id: 'johnLennonSomething',
  estateId: 'johnLennonEstateId',
  itemName: 'Something',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-john-lennon-something-1.jpg',
  valuation: '300',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'Here, have something, but I don\'t know what it is.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}


// export const item30 = {
//   _id: '',
//   estateId: 'johnLennonEstateId',
//   itemName: '',
//   imageURL: 'https://sheir-item-images.s3.amazonaws.com/',
//   valuation: '',
//   valuationDate: '2021-08-27T13:49:49.000Z',
//   category: '',
//   description: '',
//   allottedToName: 'Unassigned',
//   allottedToPersonId: 'NA',
//   giftFor: '',
//   bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
//   heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
//   heirDraftPlanning: { ...startDraftPlanningObj },
// }

