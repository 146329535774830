/* eslint-disable no-prototype-builtins */
// React
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import circlesSpinningGIF from '../../assets/spinner-circles-orbiting-g.gif'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { navbarHeight, estateNavbarHeight, footerHeight, estatesTestDataArray, estateOwnerUsersTestDataArray } from '../../helpers/variableDefaults'
import useWindowDimensions from '../../helpers/windowDimensions'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

import PageNavbar from '../common/PageNavbar'
import NavEstate from '../common/NavEstate'
import Footer from '../common/Footer'
import NotFound from '../common/NotFound'

import FamilyHQ from '../estate/FamilyHQ'
import ThePot from '../estate/ThePot'
import ItemsIndex from '../item/ItemsIndex'
import PersonSpace from '../person/PersonSpace'
import AddItem from '../item/AddItem'
import AuctionResults from '../auction/AuctionResults'
import DraftResults from '../draft/DraftResults'

// Test Estate
const TestEstate = (props) => {

  // const {  } = props

  // Navigate
  const navigate = useNavigate()

  // URL Path
  const urlPath = window.location.pathname

  // Dynamic Window Dimensions
  const { height, width } = useWindowDimensions()

  // Params
  const { estateOwnerName } = useParams()
  // console.log('estateOwnerName ->', estateOwnerName)

  // States
  const estateToSet = { ...estatesTestDataArray.filter(estate => estate.estateOwnerName.toLowerCase() === estateOwnerName.split('-').join(' '))[0] }
  if (estateToSet.items.length > 0 && (estateToSet.items.length === estateToSet.items.filter(item => item.allottedToName === 'Unassigned').length || estateToSet.event[0].results.length > 0)) {
    console.log('page reloads')
    window.location.reload()
  }
  const [estate, setEstate] = useState(estateToSet)
  const [userPerson, setUserPerson] = useState(estateToSet.people.filter(person => person.userGivenAndFamilyName.toLowerCase() === estateOwnerName.split('-').join(' '))[0])
  const [itemSelected, setItemSelected] = useState({ })

  const [viewIndex, setViewIndex] = useState(0)



  useEffect(() => {
    // console.log('estate ->', estate)
    // console.log('userPerson ->', userPerson)
    // if (estate.items.length > 0 && (estate.items.length === estate.items.filter(item => item.allottedToName === 'Unassigned').length || estate.event[0].results.length > 0)) {
    //   window.location.reload()
    // }
  }, [])

  useEffect(() => {
    // console.log('estate ->', estate)
    // console.log('userPerson ->', userPerson)
  }, [estate, userPerson, viewIndex, itemSelected])

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Test Estate'
      )}

      {(!estate || !userPerson) ?
        <NotFound />
        :
        <>
          {/* Necessary because no nav bar in live event views */}
          <PageNavbar />
          <NavEstate isTestEstate={true} viewIndex={viewIndex} setViewIndex={setViewIndex} />

          {/* Body */}
          <Box 
            sx={{ 
              minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`, 
              width: '100vw', 
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
            }}
          >
            {/* Family HQ */}
            {viewIndex === 0 &&
              // <Typography variant='h4'>Family HQ!</Typography>
              <FamilyHQ isTestEstate={true} testEstate={estate} testUserPerson={userPerson} />
            }


            {/* The Pot */}
            {viewIndex === 1 &&
              // <Typography variant='h4'>The Pot!</Typography>
              <ThePot isTestEstate={true} testEstate={estate} />
            }


            {/* Items Index */}
            {viewIndex === 2 &&
              // <Typography variant='h4'>Items Index!</Typography>
              <ItemsIndex isTestEstate={true} testEstate={estate} testUserPerson={userPerson} viewIndex={viewIndex} setViewIndex={setViewIndex} testItemSelected={itemSelected} setTestItemSelected={setItemSelected} />
            }


            {/* Person Space */}
            {viewIndex === 3 &&
              <Typography variant='h4'>Person Space!</Typography>
            }


            {/* Add Item */}
            {viewIndex === 4 &&
              // <Typography variant='h4'>Add Item!</Typography>
              <AddItem isTestEstate={true} testEstate={estate} setTestEstate={setEstate} testUserPerson={userPerson} testItemSelected={{}} setTestItemSelected={setItemSelected} viewIndex={viewIndex} setViewIndex={setViewIndex} />
            }


            {/* Edit Item */}
            {viewIndex === 5 &&
              // <Typography variant='h4'>Edit Item!</Typography>
              <AddItem isTestEstate={true} testEstate={estate} setTestEstate={setEstate} testUserPerson={userPerson} testItemSelected={itemSelected} setTestItemSelected={setItemSelected} viewIndex={viewIndex} setViewIndex={setViewIndex} />
            }


            {/* Draft Results */}
            {viewIndex === 6 &&
              <Typography variant='h4'>Draft Results!</Typography>
            }


            {/* Auction Results */}
            {viewIndex === 7 &&
              <Typography variant='h4'>Auction Results!</Typography>
            }
          </Box>

          <Footer />
        </>
      }
    </>
  )
}

export default TestEstate