

// Sources:
// https://www.mentalfloss.com/article/54393/7-must-have-items-rue-mcclanahans-estate-sale

// Constants

const startBidHistoryAndAuctionPlanningObj = {
  dorothyZbornakPersonId: 0, 
  sophiaPetrilloPersonId: 0, 
  roseNylundPersonId: 0,
}

const startDraftPlanningObj = {
  dorothyZbornakPersonId: 1000000000, 
  sophiaPetrilloPersonId: 1000000000, 
  roseNylundPersonId: 1000000000,
}


// Items

// Men of Blanche's Boudoir Calendar
export const blancheMenOfBlanchesBoudoirCalendar = {
  _id: 'blancheMenOfBlanchesBoudoirCalendarItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Men of Blanch\'s Boudoir Calendar',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-calendar-1.jpg',
  valuation: '0',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Keepsake',
  description: 'A calendar featuring all of the various men who have occupied Blanche\'s boudoir.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Driver's License
export const blanchDevereauxDriversLicense = {
  _id: 'blanchDevereauxDriversLicenseItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Driver\'s License',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-drivers-license-1.jpg',
  valuation: '0',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Keepsake',
  description: 'Blanche\'s driver\'s license',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Miss Dior Chérie Eau De Parfum
export const blancheDiorEauDeParfum = {
  _id: 'blancheDiorEauDeParfumItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Dior Eau de Parfum Collection',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-dior-eau-de-parfum-1.jpg',
  valuation: '70000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'My collection of 100 bottles of Miss Dior Cherie Eau de Parfum.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Silk Robe
export const blancheSilkRobe = {
  _id: 'blancheSilkRobeItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Silk Robe',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-silk-robe-1.jpg',
  valuation: '15000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'Blanche\'s colorful silk morning bathrobe',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Miss Dior Hair Mist
export const blancheDiorHairMist = {
  _id: 'blancheDiorHairMistItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Hair Mist Collection — Dior',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-dior-hair-mist-1.jpg',
  valuation: '100000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'My collection of 350 bottles of Miss Dior Cherie hair mist',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Golden Girls House
export const blancheDevereauxHouse = {
  _id: 'blancheDevereauxHouseItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Miami House',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-golden-girls-house-1.jpg',
  valuation: '1500000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Real Estate',
  description: 'Blanche\'s Miami house wear all of the golden girls spent their golden years.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Living Room Furniture
export const blancheLivingRoomFurniture = {
  _id: 'blancheLivingRoomFurnitureItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Living Room Furniture',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-living-room-furniture-1.jpg',
  valuation: '80000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'The furniture in the living room of Blanche\'s Miami house.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Palm-Leaf Bedspread
export const blancheBedspread = {
  _id: 'blancheBedspreadItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Palm Leaf Bedspread',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-bedspread-1.jpg',
  valuation: '150000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'Blanche\'s iconic palm-leaf bedspread',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Kitchen Ceramics
export const blancheKitchenCeramics = {
  _id: 'blancheKitchenCeramicsItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Kitchen Ceramics and Molds',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-kitchen-ceramics-1.jpg',
  valuation: '8000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'The ceramics and molds above the cabinetry in Blanche\'s kitchen',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Pool Chairs
export const blanchePoolChairs = {
  _id: 'blanchePoolChairsItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Pool Chairs',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-pool-chairs-1.jpg',
  valuation: '300',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'The chairs beside the pool outside Blanche\'s Miami house.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Red Dress
export const blancheRedDress = {
  _id: 'blancheRedDressItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Red Dress',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-red-dress-1.jpg',
  valuation: '35000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'Blanche\'s sexy red dress.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Silver Shawl
export const blancheSilverShawl = {
  _id: 'blancheSilverShawlItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Silver Shawl',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-silver-shawl-1.jpg',
  valuation: '15000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'Blanche\'s silver shawl, worn mostly during the holiday season.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Pink Scarf
export const blanchePinkScarf = {
  _id: 'blanchePinkScarfItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Pink Scarf',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-pink-scarf-1.jpg',
  valuation: '220',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'Blanche\'s pink scarf.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// White Nightgown
export const blancheWhiteNightgown = {
  _id: 'blancheWhiteNightgownItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'White Nightgown',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-white-nightgown-1.jpg',
  valuation: '5000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'Blanche\'s white nightgown, made from the finest silk lace.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Blue Suit
export const blancheBlueSuit = {
  _id: 'blancheBlueSuitItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Blue Suit',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-blue-suit-1.jpg',
  valuation: '70000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'Blanche\'s iconic blue suit',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Gold Earrings
export const blancheGoldEarrings = {
  _id: 'blancheGoldEarringsItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Gold Earrings',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-gold-earrings-1.jpg',
  valuation: '200000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'Blanche\'s iconic gold earrings.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Pearl Necklace
export const blanchePearlNecklace = {
  _id: 'blanchePearlNecklaceItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Pearl Necklace',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-pearl-necklace-1.jpg',
  valuation: '750000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'A pearl necklace passed down in the Devereaux family for six generations.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Bedroom Painting
export const blancheBedroomPainting = {
  _id: 'blancheBedroomPaintingItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Painting — Bedroom',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-bedroom-painting-1.jpg',
  valuation: '1200000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Art',
  description: 'A painting of Blanche\'s bedroom done by a top artist of the time, who also happens to be featured in The Men of Blanche\'s Boudoir calendar.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Diamond Earrings
export const blancheDiamondEarrings = {
  _id: 'blancheDiamondEarringsItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Diamond Earrings',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-diamond-earrings-1.jpg',
  valuation: '600000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'Blanche\'s diamond earrings that have been passed down the Devereaux line for six generations, along with the pearl necklace.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Blanche Portrait
export const blanchePortrait = {
  _id: 'blanchePortraitItemId',
  estateId: 'blancheDevereauxEstateId',
  itemName: 'Portrait of Blanche',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-blanche-portrait-1.jpg',
  valuation: '800000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Art',
  description: 'A portrait of Blanche done by a prominent painter of the time, who also happens to feature in The Men of Blanche\'s Boudoir calendar.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}