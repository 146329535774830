/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import dayjs from 'dayjs'

import QueueItem from '../components/auction/QueueItem.js'

import useWindowDimensions from './windowDimensions'
import { standardButton } from './buttons'
import { standardSpinner } from './spinners'
import { dummyBoxWidth } from './globalHelpers'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from './storage'
import { draftQueueMinWidth, totalAllottedRemainingHeight, autoSelectHeight, draftNextHeight, onTheClockHeight, chatHeight, draftResultCardWidth, draftResultCardHeight, draftResultRoundColumnWidth, draftResultHeirColumnWidth, draftResultHeirColumnHeight, draftResultPickNumberColumnWidth, draftResultImageDimension, draftNextImageDimension, sheirBlue, navbarHeight, estateNavbarHeight, footerHeight, positionChangeWidthSm, sheirGreen, draftTimerMinutes, draftTimerSeconds, passDraftPickImageBlackURL, positionChangeWidthDraft } from './variableDefaults'
import { scrollToBottomOfMessages,totalAllottedRemainingJSX, draftQueueItemJSX, onTheClockJSX, draftResultsSingleRoundJSX, chatJSX, attendanceSidePanelJSX, attendanceDotJSX } from './sharedDraftAuctionJSX.js'
import { standardCheckboxBool, standardCheckboxForm } from './checkboxes'
import { getHeirShareTotal, getHeirAllotmentsTotal, getHeirAllotmentsTotalByEstate, getUnassignedItems, getEventResults, getPersonDrafting, getAllottedPoints, getAllottedPointsByEstate, getUnassignedItemsWithValuationUnderRemaining, getRemainingGreaterThanValuation, getAtLeastOnePersonCanStillDraft, getIndexOfItemInEstate } from './getValuationTotals'
import { pauseCountDown, resetCountDown, clearAllTimeouts, startTimeout } from './timer'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export const computerDraftJSX = (fromTab, estate, userPerson, autoSelect, setAutoSelect, itemHighlighted, highlightNewItem, setItemHighlighted, width, draftOrderPersons, handleDraftItemPressed, passItem, minutes, seconds, eventHasBegun, handleStartTimer, handleExitDraft, messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef, connectedPersons = []) => {
  return (
    <Box 
      sx={{ 
        height: '100vh', width: '100vw',
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', 
      }}
    >
      {/* Draft Planning */}
      <Box
        sx={{
          // backgroundColor: 'yellow',
          width: draftQueueMinWidth,
          height: '100%',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Total, Allotted, Remaining */}
        {userPerson.role === 'Heir' &&
          <Box
            sx={{
              // backgroundColor: 'lightblue',
              height: totalAllottedRemainingHeight,
              width: `calc(100% - 10px)`, 
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
            }}
          >
            {totalAllottedRemainingJSX('Share', Math.floor(userPerson.sharePercentage) === 0 ? 0 : getHeirShareTotal(estate, userPerson.sharePercentage), 'money', width)}
            {totalAllottedRemainingJSX('Allotted', estate.hasOwnProperty('items') ? getHeirAllotmentsTotalByEstate(estate, userPerson) : 0, 'money', width)}
            {totalAllottedRemainingJSX('Remaining', (!(estate.hasOwnProperty('items')) || Math.floor(userPerson.sharePercentage) === 0) ? 0 : getHeirShareTotal(estate, userPerson.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, userPerson), 'money', width)}
          </Box>
        }

        {/* Finished Drafting */}
        {userPerson.role === 'Heir' &&
          <Box
            sx={{
              // backgroundColor: 'lightgreen',
              height: autoSelectHeight,
              width: `calc(100% - 10px)`, 
              borderTop: '1px solid gray',
              borderBottom: '1px solid gray',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {standardCheckboxBool('Auto Draft', autoSelect, setAutoSelect, false, 'secondary')}
          </Box>
        }

        {/* Draft Next */}
        {userPerson.role === 'Heir' &&
          <Box
            sx={{
              // backgroundColor: 'plum',
              height: draftNextHeight,
              width: `calc(100% - 10px)`, 
              borderBottom: '1px solid gray',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {/* Title */}
            <Typography
              sx={{
                // mb: 2,
                // fontSize: '24px',
                fontWeight: 'bold',
              }}
            >
              Draft Next
            </Typography>

            {/* Image + Description */}
            {estate.hasOwnProperty('items') && itemHighlighted && draftQueueItemJSX( itemHighlighted, itemHighlighted, autoSelect, 'draftNext', width, -1)}

            {/* Draft Button */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {standardButton(
                itemHighlighted.category === 'Pass' ? 'Pass' : 'Draft', 
                'button',
                'contained',
                (itemHighlighted.category === 'Pass' && (getPersonDrafting(estate, draftOrderPersons)._id !== userPerson._id 
                  || getUnassignedItems(estate).length === 0)) 
                  || autoSelect 
                  || getPersonDrafting(estate, draftOrderPersons)._id !== userPerson._id 
                  || !getRemainingGreaterThanValuation(itemHighlighted, estate, userPerson) 
                  || (seconds <= 0 && minutes <= 0)
                ,
                itemHighlighted.category === 'Pass' ? 'warning' : 'primary', 
                0,
                0,
                0,
                '130px', 
                '40px', 
                handleDraftItemPressed
              )}
            </Box>
          </Box>
        }

        {/* Attendance */}
        {/* {userPerson.role !== 'Heir' && fromTab === 'live' && eventHasBegun &&
          attendanceSidePanelJSX('Draft', estate, connectedPersons)
        } */}


        {/* Draft Queue */}
        <Box
          sx={{
            // backgroundColor: 'lightpink',
            pb: 2,
            // height: (userPerson.role !== 'Heir' && (fromTab === 'practice' || !eventHasBegun)) ? '100%' : `calc(100vh - ${totalAllottedRemainingHeight} - ${autoSelectHeight} - ${draftNextHeight})`,
            height: (userPerson.role !== 'Heir') ? '100%' : `calc(100vh - ${totalAllottedRemainingHeight} - ${autoSelectHeight} - ${draftNextHeight})`,
            width: `calc(100% - 10px)`, 
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              WebkitAppearance: 'none',
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, .35)',
              webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {/* Title */}
          <Typography
            sx={{
              py: 1,
              // fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            {userPerson.role === 'Heir' ? 'Draft Queue' : 'Available'}
          </Typography>

          {/* Items */}
          {estate.hasOwnProperty('items') && getUnassignedItems(estate).sort((a, b) => (userPerson.role !== 'Heir' || b['heirDraftPlanning'][userPerson._id] === a['heirDraftPlanning'][userPerson._id]) ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][userPerson._id] - b['heirDraftPlanning'][userPerson._id]).map((item, index) => (
            <Box
              key={index}
              onClick={(e) => highlightNewItem(e, item, index)}
              sx={{
                // backgroundColor: 'yellow',
                cursor: getRemainingGreaterThanValuation(item, estate, userPerson) ? 'pointer' : 'normal',
                width: '100%', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {/* {draftQueueItemJSX(estate, item, itemHighlighted, autoSelect, 'draftQueue', userPerson, width, index)} */}
              <QueueItem item={item} person={userPerson} estate={estate} highlighted={item._id === itemHighlighted._id} enoughRemaining={ getRemainingGreaterThanValuation(item, estate, userPerson)} distributionMethod={'Draft'} />
            </Box>
          ))}
          {estate.hasOwnProperty('items') && 
            <Box
              key={'Pass'}
              onClick={() => setItemHighlighted(passItem)}
              sx={{
                // backgroundColor: 'yellow',
                cursor: 'pointer',
                width: '100%', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {/* {draftQueueItemJSX(estate, passItem, itemHighlighted, autoSelect, 'draftQueue', userPerson, width, getUnassignedItems(estate).length + 1)} */}
              <QueueItem item={passItem} person={userPerson} estate={estate} highlighted={itemHighlighted.category === 'Pass'} enoughRemaining={ true } distributionMethod={'Draft'} />
            </Box>
          }
        </Box>

      </Box>

      {/* On the clock, Results, and Chat */}
      <Box
        sx={{
          // backgroundColor: 'orange',
          width: `calc(100% - ${draftQueueMinWidth})`,
          height: '100%',
          // borderLeft: '1px solid gray',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* On the Clock */}
        {estate.hasOwnProperty('event') && estate.hasOwnProperty('items') && onTheClockJSX('liveDraft', minutes === -1 ? draftTimerMinutes : minutes, seconds === -1 ? draftTimerSeconds : seconds, estate, draftOrderPersons, userPerson, eventHasBegun, handleStartTimer, handleExitDraft, width, connectedPersons)}


        {/* Results */}
        <Box
          sx={{
            // backgroundColor: 'azure',
            height: `calc(100vh - ${onTheClockHeight} - ${chatHeight})`,
            width: '100%', 
            // width: `calc(100% - 10px)`, 
            borderLeft: '1px solid lightgray',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              WebkitAppearance: 'none',
              width: '8px', height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, .35)',
              webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
          }}
        >

          {/* Waiting for event */}
          {!eventHasBegun && fromTab === 'live' &&
            attendanceSidePanelJSX('Draft', estate, connectedPersons, true)
          }

          {/* Heirs */}
          {(eventHasBegun || fromTab === 'practice') && draftOrderPersons.length > 0 &&
            <Box
              sx={{
                fontWeight: 'bold',
                width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
              }}
            >
              {dummyBoxWidth(draftResultRoundColumnWidth)}

              {draftOrderPersons.map((heirPerson, heirDraftOrderIndex) => (
                <Box 
                  key={heirDraftOrderIndex}
                  sx={{
                    // width: '100%',
                    minWidth: draftResultHeirColumnWidth,
                    pt: 2,
                    pl: -1,
                    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  }}
                >
                  {fromTab === 'live' && connectedPersons.length > 0 && attendanceDotJSX('10px', connectedPersons, heirPerson)}
                  <Typography
                    sx={{
                      // backgroundColor: 'orange',
                      // pt: 2,
                      // fontSize: '24px',
                      fontWeight: 'bold',
                      // minWidth: draftResultHeirColumnWidth,
                      display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    }}
                  >
                    {heirPerson.userGivenAndFamilyName}
                  </Typography>
                </Box>
              ))}
            </Box>
          }

          {/* Rounds */}
          
          {(eventHasBegun || fromTab === 'practice') && (estate.hasOwnProperty('event') && estate.hasOwnProperty('items')) && getEventResults(estate).map((item, index) => (
            draftResultsSingleRoundJSX('liveDraft', index, getEventResults(estate), getUnassignedItems(estate), estate, draftOrderPersons, minutes, seconds, width)
          ))}
          {(eventHasBegun || fromTab === 'practice') && (estate.hasOwnProperty('event') && estate.hasOwnProperty('items')) && draftOrderPersons.length > 0 && getUnassignedItems(estate).map((item, index) => (
            (((index + 1) % draftOrderPersons.length === 0) || (index + 1 === (getUnassignedItems(estate).length) && ((index + 1) % draftOrderPersons.length !== 0))) 
            && 
            draftResultsSingleRoundJSX('liveDraft', (Math.floor(index / draftOrderPersons.length)) + (getEventResults(estate).length), getEventResults(estate), getUnassignedItems(estate), estate, draftOrderPersons, minutes === -1 ? draftTimerMinutes : minutes, seconds === -1 ? draftTimerSeconds : seconds, width)
          ))}
        </Box> 

        {/* Chat */}
        {chatJSX((estate.hasOwnProperty('event') && messages.length > 0) ? messages : [], messageText, handleChangeMessageText, handleSendMessage, messagesEndRef, width, 'Draft', connectedPersons, estate)}

      </Box>
    </Box>
  )
}



export const mobileDraftJSX = (fromTab, estate, userPerson, autoSelect, itemHighlighted, highlightNewItem, setItemHighlighted, width, draftOrderPersons, handleDraftItemPressed, passItem, minutes, seconds, eventHasBegun, handleStartTimer, handleExitDraft, messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef, connectedPersons) => {
  return (
    <Box
      sx={{
        height: '100vh', width: '100vw',
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
      }}
    >
      {/* Round #, Pick #, Overall Pick # */}
      {estate.hasOwnProperty('event') && estate.hasOwnProperty('items') && onTheClockJSX('liveDraft', minutes === -1 ? draftTimerMinutes : minutes, seconds === -1 ? draftTimerSeconds : seconds, estate, draftOrderPersons, userPerson, eventHasBegun, handleStartTimer, handleExitDraft, width, connectedPersons)}

      {/* Waiting for people to arrive */}
      {!eventHasBegun && fromTab === 'live' &&
        attendanceSidePanelJSX('Draft', estate, connectedPersons, true)
      }

      {/* MessageBoard */}
      {(eventHasBegun || fromTab === 'practice') && estate.hasOwnProperty('event') && 
        // messages.filter(message => message.fromName.startsWith('Round')).length > 0 && 
        chatJSX(messages.filter(message => message.fromName.startsWith('Round')).length > 0 ? messages.filter(message => message.fromName.startsWith('Round')).slice(messages.filter(message => message.fromName.startsWith('Round')).length - 1) : [], messageText, handleChangeMessageText, handleSendMessage, messagesEndRef, width, 'Draft', connectedPersons, estate)
      }

      {/* Draft Next */}
      {userPerson.role === 'Heir' && (eventHasBegun || fromTab === 'practice')  &&
        <Box
          sx={{
            // backgroundColor: 'plum',
            py: 1.5,
            height: draftNextHeight,
            width: `calc(100% - 10px)`, 
            maxWidth: '400px',
            borderBottom: '1px solid gray',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Title */}
          <Typography
            sx={{
              // mb: 2,
              // fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            Draft Next
          </Typography>

          {/* Remaining $ */}
          {/* {userPerson.role === 'Heir' && totalAllottedRemainingJSX('Remaining', (!(estate.hasOwnProperty('items')) || (userPerson.hasOwnProperty('allotments') && userPerson.allotments.length === 0 && Math.floor(userPerson.sharePercentage) === 0)) ? 0 : (userPerson.hasOwnProperty('allotments') && userPerson.allotments.length === 0) ? getHeirShareTotal(estate, userPerson.sharePercentage) : getHeirShareTotal(estate, userPerson.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, userPerson), 'money', width)} */}

          {/* Image + Description */}
          {estate.hasOwnProperty('items') && itemHighlighted && draftQueueItemJSX( itemHighlighted, itemHighlighted, autoSelect, 'draftNext', width, -1)}

          {/* Draft Button */}
          <Box 
            sx={{ 
              pt: .5,
              display: 'flex', flexDirection: 'column', 
            }}
          >
            {standardButton(
              itemHighlighted.category === 'Pass' ? 'Pass' : 'Draft', 
              'button',
              'contained',
              (itemHighlighted.category === 'Pass' && (getPersonDrafting(estate, draftOrderPersons)._id !== userPerson._id 
                || getUnassignedItems(estate).length === 0)) 
                || autoSelect 
                || getPersonDrafting(estate, draftOrderPersons)._id !== userPerson._id 
                || !getRemainingGreaterThanValuation(itemHighlighted, estate, userPerson) 
                || (seconds <= 0 && minutes <= 0)
              ,
              itemHighlighted.category === 'Pass' ? 'warning' : 'primary', 
              0,
              0,
              0,
              '130px', 
              '40px', 
              handleDraftItemPressed
            )}
          </Box>
        </Box>
      }

      {/* Draft Queue */}
      {(eventHasBegun || fromTab === 'practice')  &&
        <Box
          sx={{
            // backgroundColor: 'lightpink',
            pb: 2,
            // height: userPerson.role === 'Heir' ? `calc(100vh - ${totalAllottedRemainingHeight} - ${autoSelectHeight} - ${draftNextHeight})` : '100%',
            width: `calc(100% - 10px)`, 
            maxWidth: '400px',
            // overflowY: 'scroll',
            // '&::-webkit-scrollbar': {
            //   WebkitAppearance: 'none',
            //   width: '8px',
            // },
            // '&::-webkit-scrollbar-thumb': {
            //   borderRadius: '4px',
            //   backgroundColor: 'rgba(0, 0, 0, .35)',
            //   webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            // },
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          
          {/* Title */}
          <Typography
            sx={{
              py: 1,
              // fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            {userPerson.role === 'Heir' ? 'Draft Queue' : 'Available'}
          </Typography>

          {/* Items */}
          {estate.hasOwnProperty('items') && getUnassignedItems(estate).sort((a, b) => (userPerson.role !== 'Heir' || b['heirDraftPlanning'][userPerson._id] === a['heirDraftPlanning'][userPerson._id]) ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][userPerson._id] - b['heirDraftPlanning'][userPerson._id]).map((item, index) => (
            <Box
              key={index}
              onClick={(e) => highlightNewItem(e, item, index)}
              sx={{
                // backgroundColor: 'yellow',
                cursor: getRemainingGreaterThanValuation(item, estate, userPerson) ? 'pointer' : 'normal',
                width: '100%', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {/* {draftQueueItemJSX(estate, item, itemHighlighted, autoSelect, 'draftQueue', userPerson, width, index)} */}
              <QueueItem item={item} person={userPerson} estate={estate} highlighted={item._id === itemHighlighted._id} enoughRemaining={ getRemainingGreaterThanValuation(item, estate, userPerson)} distributionMethod={'Draft'} />
            </Box>
          ))}
          {estate.hasOwnProperty('items') && 
            <Box
              key={'Pass'}
              onClick={() => setItemHighlighted(passItem)}
              sx={{
                // backgroundColor: 'yellow',
                cursor: 'normal',
                width: '100%', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {/* {draftQueueItemJSX(estate, passItem, itemHighlighted, autoSelect, 'draftQueue', userPerson, width, getUnassignedItems(estate).length + 1)} */}
              <QueueItem item={passItem} person={userPerson} estate={estate} highlighted={itemHighlighted.category === 'Pass'} enoughRemaining={ true } distributionMethod={'Draft'} />
            </Box>
          }
        </Box>
      }
    
    </Box>
  )
}