/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import SendIcon from '@mui/icons-material/Send'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { ExpandMore } from '../../../helpers/cardHelpers'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../../helpers/storage'
import { getHeirAllotmentsTotal, getHeirShareTotal } from '../../../helpers/getValuationTotals'
import { positionChangeHeightSm, positionChangeWidthSm, sheirGreen, sheirBlue } from '../../../helpers/variableDefaults'
import useWindowDimensions from '../../../helpers/windowDimensions'

const HeirAllotmentsItemCard = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { item, person, estate } = props

  // Payload
  const payload = getPayload()

  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId, personId } = useParams()

  // URL Path
  const urlPath = window.location.pathname

  // Tracks whether the card is expanded
  const [expanded, setExpanded] = useState(false)

  // Changes the card expanded state
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  // useEffect(() => {
  
  // }, [])


  return (
    <Card align="left" 
      sx={{ 
        backgroundColor: 'whitesmoke',
        width: width < positionChangeWidthSm ? '80%' : '95%', boxShadow: 3, borderRadius: 3,
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: width < positionChangeWidthSm ? 'flex-start' : 'space-between', alignItems: 'center',
        }}
      >
        <Box
          sx={{
            // height: width < positionChangeWidthSm ? '300px' : '200px',
            width: '100%',
            display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: width < positionChangeWidthSm ? 'center' : 'flex-start', alignItems: 'center',
          }}
        >
          {/* Image */}
          <CardMedia
            component="img"
            height={width < positionChangeWidthSm ? '150px' : '150px'}
            image={item.imageURL}
            alt={item.itemName}
            sx={{ 
              m: 1, mt: width < positionChangeWidthSm ? 2 : 0,
              borderRadius: 3,
              boxShadow: 3,
              display: 'inline-block',
              width: '150px',
            }}
          />

          {/* Title, Category, Valuation */}
          <Box
            sx={{
              // backgroundColor: 'yellow',
              ml: width < positionChangeWidthSm ? 0 : 1,
              width: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {/* Item Name */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                // backgroundColor: 'yellow',
                width: '95%',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {item.itemName}
            </Typography>

            {/* Category */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                width: '95%',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
              }}
            >
              {item.category}
            </Typography>

            {/* Valuation */}
            <Typography
              textAlign={width < positionChangeWidthSm ? 'center' : 'left'}
              sx={{
                width: '95%',
                fontSize: width < positionChangeWidthSm ? '16px' : '18px',
                color: sheirBlue,
              }}
            >
              ${Math.floor(item.valuation).toLocaleString('en-US', { minimumFractionDigits: 0 })}
            </Typography>

          </Box>

        </Box>

        {/* Percent of Share */}
        <Typography
          textAlign={width < positionChangeWidthSm ? 'center' : 'right'}
          sx={{
            mr: width < positionChangeWidthSm ? 0 : 2,
            fontSize: width < positionChangeWidthSm ? '16px' : '18px',
            // color: sheirGreen,
          }}
        >
          {((Math.floor(item.valuation) / getHeirShareTotal(estate, person.sharePercentage)) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
        </Typography>

      </Box>
      
      {/* Expand More Icon */}
      <CardActions disableSpacing
        sx={{
          // backgroundColor: 'yellow',
          width: '100%',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >

        {/* Expand More */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon 
            // color='primary' 
          />
        </ExpandMore>

      </CardActions>

      {/* Longer Description — Part that expands when Expand More is Pressed */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            component="pre"
            sx={{
              fontFamily: 'Lato',
              fontSize: '16px',
            }}
          >
            {item.description}
          </Typography>
        </CardContent>
      </Collapse>
      
    </Card>
  )
}

export default HeirAllotmentsItemCard