/* eslint-disable no-prototype-builtins */
import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import update from 'immutability-helper'

import FilterItemsIndex from '../../item/FilterItemsIndex'

import { standardSpinner } from '../../../helpers/spinners'
import { descriptionTextField } from '../../../helpers/textfields'
import { getTokenFromLocalStorage } from '../../../helpers/storage'
import { heirPlanningTab, heirAllotmentsTab, heirNotesTab } from '../../../helpers/heirPersonSpaceTabs'
import { heirPersonSpaceTabs, heirAllotmentsSortByArray, superSmallScoreboardWidth } from '../../../helpers/variableDefaults'
import { getEstateTotalValue, getHeirAllotmentsTotal, getHeirShareTotal, categoryPercentOfTotal } from '../../../helpers/getValuationTotals'
import { modifyInDatabase } from '../../../helpers/modifyInDatabase'
import useWindowDimensions from '../../../helpers/windowDimensions'
import { makeTabIndex } from '../../../helpers/formatting'

import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'

// Heir Person Space Page
const HeirPersonSpace = (props) => {

  // Destructure Props
  const { userIsPerson, person, estate, personId, estateId, setPerson, setEstate, hover, setHover, itemsAtLoad, setItemsAtLoad, loading, setLoading, errors, setErrors, payload, navigate, width, heirAllotmentsSortBy, setHeirAllotmentsSortBy, filteredItems, setFilteredItems, filtersObj, setFiltersObj, showFilters, setShowFilters, filtersInUse, handleFilterIconPressed, handleResetPressed, setSaveSwitchSuccessful } = props

  //Keeps track of which tab we are in, default is Wishlist at index 0
  const [value, setValue] = useState(0)

  // Keeps track of whether heir shares add up to more than 100
  const [pointsOver1000, setPointsOver1000] = useState(false)
  const [hasModifiedAuctionPlanning, setHasModifiedAuctionPlanning] = useState(false)

  // Draft Ranking Drag and Drop
  const [draftCards, setDraftCards] = useState([ ...estate.items.filter(itemToFilter => itemToFilter.allottedToName === 'Unassigned').sort((a, b) => b['heirDraftPlanning'][person._id] === a['heirDraftPlanning'][person._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][person._id] - b['heirDraftPlanning'][person._id])])
  const [savedDraftRankings, setSavedDraftRankings] = useState([ ...estate.items.filter(itemToFilter => itemToFilter.allottedToName === 'Unassigned').sort((a, b) => b['heirDraftPlanning'][person._id] === a['heirDraftPlanning'][person._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][person._id] - b['heirDraftPlanning'][person._id])])

  // Settings Changes
  const [newNotes, setNewNotes] = useState(person.notes)

  // Error Editing Notes tab
  const [saveNotesSuccessful, setSaveNotesSuccessful] = useState(false)
  const [editNotesError, setEditNotesError] = useState(false)
  const [saveRankingsError, setSaveRankingsError] = useState(false)

  useEffect(() => {

    // const savedDraftRankingsItemNames = savedDraftRankings.map(item => item.itemName)
    // console.log('savedDraftRankingsItemNames ->', savedDraftRankingsItemNames)
    
    // Collect Analytics
    customAnalyticsEvent(
      `person_space_heir`, 
      `person_space_heir`, 
      'person_space_heir', 
      `person_space_heir`, 
      'Person Space — Heir'
    )

  }, [])

  // Update view every time estate changes
  useEffect(() => {
    if (estate.event[0].distributionMethod === 'Draft' || estate.event[0].silentOrParticipatory === 'Partial') {
      setDraftCards(estate.items.filter(itemToFilter => itemToFilter.allottedToName === 'Unassigned').sort((a, b) => b['heirDraftPlanning'][person._id] === a['heirDraftPlanning'][person._id] ? b['valuation'] - a['valuation'] : a['heirDraftPlanning'][person._id] - b['heirDraftPlanning'][person._id]))
    }
  }, [estate, pointsOver1000])

  useEffect(() => {
    
  }, [hasModifiedAuctionPlanning, savedDraftRankings])

  // Handles tab changes
  const handleTabChange = (event, newValue) => {
    setSaveSwitchSuccessful(false)
    setValue(newValue)

    // Collect Analytics
    customAnalyticsEvent(
      `heir_space_${newValue === 0 ? 'allotments' : newValue === 1 ? 'planning' : 'notes'}_tab`, 
      `heir_space_${newValue === 0 ? 'allotments' : newValue === 1 ? 'planning' : 'notes'}_tab`, 
      `heir_space_${newValue === 0 ? 'allotments' : newValue === 1 ? 'planning' : 'notes'}_tab`, 
      `heir_space_${newValue === 0 ? 'allotments' : newValue === 1 ? 'planning' : 'notes'}_tab`, 
      `Heir Space — ${newValue === 0 ? 'Allotments' : newValue === 1 ? 'Planning' : 'Notes'} Tab`
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {seoPageTags(
        '404'
      )} */}

      {/* Body */}
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>


        {estate && estate.hasOwnProperty('event') && estate.event[0].hasOwnProperty('silentOrParticipatory') && estate.event[0].silentOrParticipatory === 'Partial' ?
          heirPlanningTab(userIsPerson, estate, setEstate, person, setPerson, pointsOver1000, setPointsOver1000, hasModifiedAuctionPlanning, setHasModifiedAuctionPlanning, itemsAtLoad, setItemsAtLoad, value, 1, width, loading, errors, draftCards, setDraftCards, savedDraftRankings, setSavedDraftRankings, saveRankingsError, setSaveRankingsError, setSaveSwitchSuccessful)
          :
          <>
            {/* Tabs */}
            <Box 
              sx={{ 
                // backgroundColor: 'yellow',
                mt: 2, 
                width: '100%',
                // maxWidth: '300px', 
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              }}
            >
              <Tabs
                value={value}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
                variant={width < superSmallScoreboardWidth ? 'scrollable' : 'standard'}
                sx={{ 
                  // backgroundColor: 'yellow',
                  // width: '90vw', maxWidth: '375px',
                  width: width < superSmallScoreboardWidth ? '100%' : '325px',
                  borderTop: 1, borderColor: 'divider',
                  // display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                }}
              >
                {heirPersonSpaceTabs.map((tabName, tabIndex) => (
                  <Tab key={tabIndex} label={tabName} {...makeTabIndex(tabIndex)} />
                ))}
              </Tabs>
            </Box>


            {/* Tab Panels */}

            {/* Allotments */}
            {person.hasOwnProperty('allotments')
              && (
                showFilters ? 
                  <FilterItemsIndex fromTab={'heirSpace'} estate={estate} filteredItems={filteredItems} setFilteredItems={setFilteredItems} filtersObj={filtersObj} setFiltersObj={setFiltersObj} showFilters={showFilters} setShowFilters={setShowFilters} allottedToNames={[person.userGivenAndFamilyName]} />
                  :
                  heirAllotmentsTab(userIsPerson, 'heirSpace', estate, person, value, 0, hover, setHover, width, loading, errors, heirAllotmentsSortBy, setHeirAllotmentsSortBy, filteredItems, setFilteredItems, filtersObj, setFiltersObj, showFilters, setShowFilters, filtersInUse, handleFilterIconPressed, handleResetPressed)
              )
            }

            {/* Planning */}
            {person.hasOwnProperty('allotments')
              && heirPlanningTab(userIsPerson, estate, setEstate, person, setPerson, pointsOver1000, setPointsOver1000, hasModifiedAuctionPlanning, setHasModifiedAuctionPlanning, itemsAtLoad, setItemsAtLoad, value, 1, width, loading, errors, draftCards, setDraftCards, savedDraftRankings, setSavedDraftRankings, saveRankingsError, setSaveRankingsError, setSaveSwitchSuccessful)
            }
            
            {/* Notes */}
            {person.hasOwnProperty('notes')
              && heirNotesTab(person, setPerson, value, 2, width, loading, errors, newNotes, setNewNotes, editNotesError, setEditNotesError, saveNotesSuccessful, setSaveNotesSuccessful, setSaveSwitchSuccessful)
            }
          </>
        }
      </Box>
    </>
  )
}

export default HeirPersonSpace