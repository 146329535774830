/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import SendIcon from '@mui/icons-material/Send'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { ExpandMore } from '../../helpers/cardHelpers'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { getHeirAllotmentsTotal, getHeirShareTotal } from '../../helpers/getValuationTotals'
import { positionChangeHeightSm, positionChangeWidthSm, sheirGreen, sheirBlue, positionChangeWidthAuction, superSmallAuctionBlockWidth, superSmallAuctionBlockCardDimension, auctionBlockCardDimension } from '../../helpers/variableDefaults'
import useWindowDimensions from '../../helpers/windowDimensions'

const AuctionBlockCard = (props) => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Destructure Props
  const { item, person, estate } = props

  // Payload
  const payload = getPayload()

  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId, personId } = useParams()

  // URL Path
  const urlPath = window.location.pathname

  // Tracks whether the card is expanded
  const [expanded, setExpanded] = useState(false)

  // Changes the card expanded state
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  // useEffect(() => {
  
  // }, [])


  return (
    <Card 
      align="left" 
      sx={{ 
        backgroundColor: 'whitesmoke',
        width: '80%', boxShadow: 3, borderRadius: 3,
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
      }}
    >
      {/* Everything above 'Expand More' */}
      <Box
        sx={{
          width: '100%',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Image; Title, Category, Valuation */}
        <Box
          sx={{
            // backgroundColor: 'blue',
            width: '100%',
            display: 'flex', flexDirection: width >= positionChangeWidthAuction ? 'column' : 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Image */}
          <CardMedia
            component="img"
            height={width < superSmallAuctionBlockWidth ? superSmallAuctionBlockCardDimension : auctionBlockCardDimension}
            image={item.imageURL}
            alt={item.itemName}
            sx={{ 
              m: 1, mt: width < superSmallAuctionBlockWidth ? 1 : 2,
              borderRadius: 3,
              boxShadow: 3,
              display: 'inline-block',
              width: width < superSmallAuctionBlockWidth ? superSmallAuctionBlockCardDimension : auctionBlockCardDimension,
            }}
          />

          {/* Title, Category, Valuation, Target Bid */}
          <Box
            sx={{
              // backgroundColor: 'yellow',
              ml: 0,
              pl: width >= positionChangeWidthAuction ? 0 : 1,
              width: '100%',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: width >= positionChangeWidthAuction ? 'center' : 'flex-start',
            }}
          >
            {/* Item Name */}
            <Typography
              textAlign={width >= positionChangeWidthAuction ? 'center' : 'left'}
              sx={{
                // backgroundColor: 'yellow',
                width: '95%',
                fontSize: width < superSmallAuctionBlockWidth ? '16px' : '16px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {item.itemName}
            </Typography>

            {/* Category */}
            <Typography
              textAlign={width >= positionChangeWidthAuction ? 'center' : 'left'}
              sx={{
                width: '95%',
                fontSize: width < superSmallAuctionBlockWidth ? '14px' : '16px',
              }}
            >
              {item.category}
            </Typography>

            {/* Valuation */}
            <Typography
              textAlign={width >= positionChangeWidthAuction ? 'center' : 'left'}
              sx={{
                width: '95%',
                fontSize: width < superSmallAuctionBlockWidth ? '14px' : '16px',
                color: sheirBlue,
              }}
            >
              ${Math.floor(item.valuation).toLocaleString('en-US', { minimumFractionDigits: 0 })}
            </Typography>

            {/* Target Bid */}
            {person.role === 'Heir' &&
              <Typography
                textAlign={width >= positionChangeWidthAuction ? 'center' : 'left'}
                sx={{
                  width: '95%',
                  fontSize: width < superSmallAuctionBlockWidth ? '14px' : '16px',
                }}
              >
                {ReactHtmlParser(`Target Bid: <span style="font-weight:bold; color:#2979ff;">${item.heirAuctionPlanning[person._id]}</span>`)}
              </Typography>
            }

          </Box>

        </Box>

      </Box>
      
      {/* Expand More Icon */}
      <CardActions disableSpacing
        sx={{
          // backgroundColor: 'yellow',
          width: '100%',
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
      >

        {/* Expand More */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon 
            // color='primary' 
          />
        </ExpandMore>

      </CardActions>

      {/* Longer Description — Part that expands when Expand More is Pressed */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            component="pre"
            sx={{
              fontFamily: 'Lato',
              fontSize: '16px',
              // maxHeight: '80px',
              // overflowY: 'scroll',
              // '&::-webkit-scrollbar': {
              //   WebkitAppearance: 'none',
              //   width: '4px',
              // },
              // '&::-webkit-scrollbar-thumb': {
              //   borderRadius: '4px',
              //   backgroundColor: 'rgba(0, 0, 0, .35)',
              //   webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
              // }, 
            }}
          >
            {item.description}
          </Typography>
        </CardContent>
      </Collapse>
      
    </Card>
  )
}

export default AuctionBlockCard