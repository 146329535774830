/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { standardSpinner } from '../../../helpers/spinners'

import { Input, dummyBox, validEmail } from '../../../helpers/globalHelpers'
import { positionChangeHeightSm, positionChangeWidthSm, positionChangeWidthMd, personTypes, privilegesOptions } from '../../../helpers/variableDefaults'
import { deletePersonItemIconButton } from '../../../helpers/buttons'
import useWindowDimensions from '../../../helpers/windowDimensions'
import { personItemTextField } from '../../../helpers/textfields'
import { selectElement } from '../../../helpers/selects'
import { getTokenFromLocalStorage } from '../../../helpers/storage'


// Gift Item
const PersonItemOwnerSpace = (props) => {
  
  // Destructure Props
  const { userPerson, estate, setEstate, itemIndex, fromComponent, peopleToDelete, setPeopleToDelete, peopleToModify, setPeopleToModify } = props
  // console.log('estate ->', estate)
  // console.log('userPerson ->', userPerson.role)

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  const uploadModifiedPrivileges = async (personId, modifiedPropertiesObj) => {
    try {

      // PUT request with updated profile object
      // await axios.put(`/api/users/${userId}`, newForm, {
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/persons/${personId}`, modifiedPropertiesObj, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })
      
    } catch (error) {
      // console.log(error)
    }
  }

  // For handling changes to the person item
  const handlePersonItemChange = async (e, setEstate, estate, itemIndex) => {
    // console.log('handlePersonItemChange runs')
    // console.log('estate ->', estate)
    // console.log('setEstate ->', setEstate)

    // Create name and value variables
    let name
    let value

    // Assign values to name and value depending on the type of event target
    if (!e.target.name) {
      name = e.target.classList[1]
      value = e.target.classList[0].slice(5)
    } else {
      name = e.target.name
      value = e.target.value
    }
    // console.log('name ->', name)
    // console.log('value ->', value)
  
    const newArray = [ ...estate.people ]
    newArray[itemIndex][name] = value

    if (name === 'readWriteAdmin' || name === 'role') {
      const modifiedPropertiesObj = { [name]: value }

      // Asset Managers start automatically with write permissions, and heirs cant have permissions above read-only
      if (name === 'role' && (value === 'Asset Manager' || value === 'Lawyer' || value === 'Executor') && newArray[itemIndex].readWriteAdmin === 'read-only') {
        modifiedPropertiesObj.readWriteAdmin = 'write'
        newArray[itemIndex].readWriteAdmin = 'write'
      } else if (name === 'role' && value === 'Heir' && newArray[itemIndex].readWriteAdmin !== 'read-only') {
        modifiedPropertiesObj.readWriteAdmin = 'read-only'
        newArray[itemIndex].readWriteAdmin = 'read-only'
      }

      // Set sharePercentage to 0 if not Heir
      if (name === 'role' && value !== 'Heir') {
        modifiedPropertiesObj.sharePercentage = '0'
        newArray[itemIndex].sharePercentage = '0'
      }
      
      await uploadModifiedPrivileges(newArray[itemIndex]._id, modifiedPropertiesObj)
    }
  
    setEstate({ ...estate, people: newArray })
    
  } 

  // Handle deleting an item within an option
  const handleDeleteItem = async (e) => {
    e.preventDefault()
    
    // Get item index from the event currentTarget classList
    const selectedItemIndexString = e.currentTarget.classList[0].charAt(0)
    const selectedItemIndex = parseInt(selectedItemIndexString)

    setPeopleToDelete([ ...peopleToDelete, estate.people[itemIndex]._id ])

    // Destructure the onboardingObj.people array
    const newArray = [ ...estate.people ]
    
    // Splice the specified item from peopleArray
    if (selectedItemIndex > -1 ) { 
      newArray.splice(selectedItemIndex, 1)
    }

    // setOnboardingObj with the newArray
    // setEstate({ ...estate, people: newArray })
  }
  
  return (
    <>
      {/* This must be first input So that the file upload only fires when you press the button */}
      <Box sx={{ width: '100%' }}>
        <Input type="text" autoFocus="autoFocus" />
      </Box>
      
      {/* Person Item */}
      <Box 
        sx={{ 
          backgroundColor: 'whitesmoke', 
          mt: 1, mb: 2, boxShadow: 3, borderRadius: '5px', width: '100%', 
          display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: width < positionChangeWidthSm ? 'center' : 'space-between', alignItems: width < positionChangeWidthSm ? 'center' : 'flex-start', 
        }}
      >

        {/* Person Name */}
        <Box textAlign={'center'} sx={{ mt: 1, mb: 1, width: width < positionChangeWidthSm ? '90%' : '30%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {/* <Typography textAlign={'center'}> */}
          {estate.people[itemIndex].userGivenAndFamilyName}
          {/* </Typography> */}
        </Box>

        {/* Email */}
        <Box sx={{ mt: 1, mb: 1, width: width < positionChangeWidthSm ? '90%' : '20%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {/* <Typography textAlign={'center'}> */}
          {estate.people[itemIndex].email}
          {/* </Typography> */}
        </Box>

        {/* Role */}
        <Box textAlign={'center'} sx={{ mt: 1, mb: 1, width: width < positionChangeWidthSm ? '90%' : '20%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {estate.people[itemIndex].role === 'Owner' ? 
            estate.people[itemIndex].role 
            : 
            selectElement('role', 'Role', estate.people[itemIndex].role, (estate.people[itemIndex].role === 'Owner' || estate.event[0].hasStarted), !(estate.people[itemIndex].role === 'Owner' || estate.event[0].hasStarted), (estate.people[itemIndex].role === 'Owner') ? ['Owner'] : personTypes, handlePersonItemChange, estate, setEstate, itemIndex)
          }
        </Box>

        {/* Privileges */}
        <Box textAlign={'center'} sx={{ mt: 1, mb: 1, width: width < positionChangeWidthSm ? '90%' : '20%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {estate.people[itemIndex].role === 'Owner' ? 
            estate.people[itemIndex].readWriteAdmin 
            : 
            selectElement('readWriteAdmin', 'Privileges', estate.people[itemIndex].readWriteAdmin, (estate.people[itemIndex].role === 'Owner' || estate.event[0].hasStarted), !(estate.people[itemIndex].role === 'Owner' || estate.event[0].hasStarted), estate.people[itemIndex].role === 'Owner' ? ['admin'] : estate.people[itemIndex].role === 'Asset Manager' ? ['admin', 'write'] : estate.people[itemIndex].role === 'Heir' ? ['write', 'read-only'] :  privilegesOptions, handlePersonItemChange, estate, setEstate, itemIndex)
          }
        </Box>
        

        {/* Delete item button if more than one item */}
        {deletePersonItemIconButton(
          itemIndex, 
          handleDeleteItem, 
          (estate.people[itemIndex].role === 'Owner' 
          || estate.people[itemIndex].userId === estate.customerUserId
          || (estate.people[itemIndex].email === userPerson.email && estate.people[itemIndex].role === 'Owner') 
          || estate.event[0].hasStarted
          )
        )}
        
      </Box>
    </>
  )
}

export default PersonItemOwnerSpace