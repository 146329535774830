import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import { faqTextTypography } from '../../helpers/typographies'
import useWindowDimensions from '../../helpers/windowDimensions'
import { sheirBlue, sheirGreen } from '../../helpers/variableDefaults'
import { videoInsert } from '../../helpers/videos'

// FAQ Page
const FAQ = (props) => {

  // console.log('props.fromComponent ->', props.fromComponent)
  // Destructure Props
  const { fromComponent, role, transparencyLevel, distributionMethod } = props

  // Window Dimensions
  const { height, width } = useWindowDimensions()
  // console.log('width ->', width)

  // URL Path
  // const urlPath = window.location.pathname

  // Standard Question and Answer Element
  // Here and not in a helper file for convenience — it only appears here
  const questionAnswerComponent = (question = '', answer = '', expandedDefault = false, isVideo = false, videoLink = '', writtenInstructions = '', altText = '', cardWidth = '95%' , width = 0) => {
    return (
      // Instantiate an Accordion
      <Accordion
        defaultExpanded={expandedDefault}
        sx={{ 
          width: '100%',
          boxShadow: 4, 
        }}
      >
        {/* Question appears on Accordion Summary */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: 'lightblue',
            border: 2,
            borderBottom: 2,
            borderColor: 'black',
          }}
        >
          <Typography>
            <strong>{question}</strong>
          </Typography>
        </AccordionSummary>

        {/* Answer Appears in Accordion Details */}
        <AccordionDetails
          sx={{
            backgroundColor: 'lightyellow',
            border: 2,
            borderTop: 0,
            borderColor: 'black',
            display: 'flex', flexDirection: 'column',
          }}
        >
          {answer.length > 0 && faqTextTypography(answer, '18px', 'Lato')}
          {isVideo && videoInsert(videoLink, altText, cardWidth, width)}
          {writtenInstructions.length > 0 && faqTextTypography(writtenInstructions, '18px', 'Lato')}
        </AccordionDetails>

      </Accordion>
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {fromComponent === 'faq' && 
        seoPageTags(
          'FAQ'
        )
      } */}

      {/* FAQ Body */}
      <Container sx={{ 
        // backgroundColor: 'orange',
        // height: `calc(100vh - ${navbarHeight})`, 
        width: '100vw', 
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
      }}>

        {/* This Box is necessary for scrolling and view height normalization */}
        <Box
          sx={{
            // backgroundColor: 'orange',
            width: '100vw',
            height: '100%',
            maxHeight: '100%',
            overflow: 'auto',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >

          {/* Box sets max width and new background color */}
          <Box
            sx={{
              backgroundColor: 'white',
              boxShadow: 4,
              mb: fromComponent === 'faq' ? 4 : 0, mt: 0, pt: (fromComponent === 'faq' || fromComponent === 'familyHQ') ? 4 : 6, pb: 2,
              width: '100vw',
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start',
            }}
          >

            {/* Box sets smaller interior width for the question accordions */}
            <Box
              sx={{ 
                pb: 8,
                width: .90, 
                maxWidth: '600px',
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* Title */}
              {(fromComponent === 'home' || fromComponent === 'familyHQ' || (fromComponent === 'personSpace' && !(role === 'Heir' && distributionMethod === 'Allocate All'))) && 
                <Typography>
                  <h3>FAQ</h3>
                </Typography>
              }

              {/* FAQ Accordions */}

              {/* Panel 1 */}
              {(fromComponent === 'faq' || fromComponent === 'home') && 
                questionAnswerComponent(
                  'What is sHeir?',
                  'sHeir is a digital platform for organizing estates and dividing them evenly among heirs.',
                  true
                )
              }

              {/* Panel 2 */}
              {(fromComponent === 'faq' || fromComponent === 'home') && 
                questionAnswerComponent(
                  'Why use sHeir?',
                  'Dividing estates can be stressful and contentious. sHeir offers a solution to this by offering a transparent way to divide the estate such that none of the beneficiaries exceeds their allotments, and so that each heir ends up with the things they want most.',
                  false
                )
              }
              
              {/* Panel 3 */}
              {(fromComponent === 'faq' || fromComponent === 'home') && 
                questionAnswerComponent(
                  'Is sHeir secure?',
                  `Yes, all sign-ins require multiple steps, and sensitive data, such as valuations for items, is encrypted.`,
                  false
                )
              }

              {/* Title */}
              {(fromComponent === 'home') && 
                <Typography
                  sx={{
                    mt: 2,
                  }}
                >
                  <h3>Distribution Process</h3>
                </Typography>
              }

              {/* Panel 4 */}
              {(fromComponent === 'faq' || fromComponent === 'home' || fromComponent === 'familyHQ' || (fromComponent === 'personSpace' && role !== 'Heir')) && 
                questionAnswerComponent(
                  `What are transparency levels?`,
                  ``,
                  false,
                  true,
                  'https://www.youtube.com/embed/3hThVRumNBM',
                  `
                    'Silent' — Heirs cannot view the estate and will not be notified about changes to it. 'Allocate All' is the only distribution method available for Silent estates.

                    'Partial' — Heirs will be able to rank their preferences for unassigned items, but they will not be able to see the full estate or the valuations of the items.
                    
                    'Participatory' — Heirs can view the estate and can indicate preferences for certain items. 'Allocate All', 'Auction, and 'Draft' are the distribution methods available for Participatory estates.

                    *We recommend starting with a 'Silent' estate, and, if you'd like, you can change it to 'Participatory' later on after items have been added and valuations have been set.
                  `,
                  'How to Execute an Event',
                  '100%',
                  width
                )
              }

              {/* Panel 5 */}
              {(fromComponent === 'faq' || fromComponent === 'home' || fromComponent === 'familyHQ' || (fromComponent === 'personSpace' && role !== 'Heir')) && 
                questionAnswerComponent(
                  `What are the distribution methods?`,
                  ``,
                  false,
                  true,
                  'https://www.youtube.com/embed/EfFN9oREqZw',
                  `
                    Allocate All
                    1) Add all of your items.
                    2) Allocate them.
                    3) Even things out.
                    4) Show the results to your estate lawyer, who will create the necessary legal documents.

                    Auctions and Drafts:
                    1) The estate owner or asset manager creates an account and adds in all of the estate items. 
                    2) Each item is either pre-allotted or marked as 'Unassigned'. 
                    3) After all items are added and all heirs are ready, the heirs will participate in either a draft or an auction for the 'Unassigned' items in the estate but will not be able to bid on or draft items beyond their allotted shares of the total assets, ensuring nobody oversteps.
                    4) Show the results to your estate lawyer, who will create the necessary legal documents.

                    * 'Allocate All' is only option for 'Silent' estates and is the default method for 'Participatory' estates.
                    ** Auctions and Drafats are only possible for 'Participatory' estates.
                  `,
                  'How to Execute an Event',
                  '100%',
                  width
                )
              }

              {/* Panel 6 */}
              {(fromComponent === 'faq' || fromComponent === 'home' || (fromComponent === 'familyHQ' && transparencyLevel === 'Participatory' && distributionMethod !== 'Allocate All') || (fromComponent === 'personSpace' && role !== 'Heir' && distributionMethod !== 'Allocate All')) && 
                questionAnswerComponent(
                  `How are auctions and drafts executed?`,
                  ``,
                  false,
                  true,
                  'https://www.youtube.com/embed/EfFN9oREqZw',
                  `
                    1) The estate 'Owner' adds all items to the estate and marks 'All Items Added'
                    2) Heirs prepare for the auction or draft and then mark themselves as ready for the event.
                    3a) For auto-events the owner will press the 'Execute Auction' or 'Execute Draft' button, and items will be allotted instantaneously based on the heirs' pre-event planning.
                    3b) For live events, the owner clicks the 'Start' button when everyone has arrived in the event space, and the events will continue until completion.
                    4) When the event has finished, the results will exist as a ledger, giving everyone peace of mind.
                  `,
                  'How to Execute an Event',
                  '100%',
                  width
                )
              }
              
              {/* Panel 7 */}
              {(fromComponent === 'faq' || fromComponent === 'home' || (fromComponent === 'familyHQ' && transparencyLevel === 'Participatory' && distributionMethod !== 'Allocate All')) && 
                questionAnswerComponent(
                  'What are the rules for an auction?',
                  `— Before the auction can start, each heir must navigate to the 'Planning' tab in the person space, pre-assign 1000 points to the various 'Unassigned' items in the estate, and mark themselves as ready for the event.
                  — For both live and auto auctions, items appear 'On the block' in order from highest to lowest monetary valuation.
                  — After each bid, the 'Time Remaining' will be reset to 30 seconds. 
                  — When 'Time Remaining' hits zero, the item will be allotted to the highest bidder.
                  — In an auto-auction, if two heirs have the same highest bid, it will be distributed to the heir with a higher remaining monetary share. If the remaining monetary shares are equal, then the item will be distributed randomly to one of the highest bidders. (In a live auction, it's impossible to have a tie for the highest bid.)
                  — If the item's valuation is above an heir's remaining monetary share of total assets, the heir will not be allowed to bid on the item.
                  — No heir can bid more than 1000 points in an auction. This includes points bid on items that were allotted to someone else, so it is in an heir's interest to only bid on items they actually want.
                  — To get a hang of how auctions work, it helps to practice by pressing the 'Demo' button at the top of the homepage and participating in mock auctions in fictional estates.`,
                  false
                )
              }
              
              {/* Panel 8 */}
              {(fromComponent === 'faq' || fromComponent === 'home' || (fromComponent === 'familyHQ' && transparencyLevel === 'Participatory' && distributionMethod !== 'Allocate All')) && 
                questionAnswerComponent(
                  'What are the rules for a draft?',
                  `— Before the draft can start, each heir must navigate to the 'Planning' tab in the person space, pre-rank the various 'Unassigned' items in the estate from most-to-least desired, and mark themselves as ready for the event.
                  — The order in which heirs draft is randomly assigned by sHeir's backend server.
                  — All drafts are 'snake drafts', meaning the person with the last pick in the current round will have the first pick in the next round.
                  — It is impossible to draft an item with a valuation that's higher than an heir's remaining monetary share.
                  — If an heir can no longer bid an any remaining items, they must 'Pass' their pick.
                  — The draft finishes when nobody can draft any remaining items.
                  — All undrafted items will be marked for Liquidation.
                  — To get a hang of how drafts work, it helps to practice by pressing the 'Demo' button at the top of the homepage and participating in mock drafts in fictional estates.`,
                  false
                )
              }

              {/* Title */}
              {fromComponent === 'home' && 
                <Typography
                  sx={{
                    mt: 2,
                  }}
                >
                  <h3>How do I...</h3>
                </Typography>
              }

              {/* Panel 9 */}
              {(fromComponent === 'faq' || fromComponent === 'home') && 
                questionAnswerComponent(
                  'How do I create an estate?',
                  ``,
                  false,
                  true,
                  'https://www.youtube.com/embed/QGW1FJmGTxs',
                  `
                    1) On the homepage, click 'Create Estate'
                    2) Log in or create an account if you are not already signed in
                    3) Add people to the estate and press 'Next'
                      — If it is your own estate, set yourself as the 'Owner'
                      — If you are making the estate for a client, mark yourself as the 'Lawyer', 'Asset Manager', etc. and mark the client as the 'Owner'
                      — You will be able to add more people later in case you don't want to add everyone right now.
                    4) Write a Mission Statement and press 'Next'
                    5) Determine your estate Settings and press 'Next'
                      — We recommend starting with 'Silent' for the Transparency Level.
                      — These can be changed later, so don't worry if you're not yet sure whether you want an auction or a draft.
                    6) Review everything and press 'Next'
                    7) Submit your subscription preferences and payment details and click 'Create'
                      — Do NOT navigate away from the screen until payment has completed processing
                      — You will be automatically redirected to the estate's 'Family HQ' when payment finishes processing
                    8) Explore the estate tabs
                    9) Add your first item.
                  `,
                  'How to Create an Estate',
                  '100%',
                  width
                )
              }

              {/* Panel 10 */}
              {(fromComponent === 'faq' || fromComponent === 'home') && 
                questionAnswerComponent(
                  `How do I access my account after being added to an estate?`,
                  ``,
                  false,
                  true,
                  'https://www.youtube.com/embed/JaaZlaG1Hz8',
                  `
                    1) Open the 'You Have Been Added to a New Estate' email from sHeir.
                    2a) Click 'sign in' if you already have a sHeir account with THIS EMAIL ADDRESS, and then skip to step #6.
                    2b) Click 'reset password' if you do not already have a sHeir account with THIS EMAIL ADDRESS.
                    3) Input the email address from which you received the email into the reset password textbox, and press 'Send Reset Link'
                    4) Open the 'Password Reset Link' email from sHeir, and click the link inside the email.
                    5) Set a password, select security questions and answers, and click 'Reset Password'.
                    6) Sign in by entering your email address and password.
                    7) Answer a security question.
                    8) Select on the person icon in the upper right corner and click 'Profile'.
                    9) Click on the name of the estate in the 'Estates' section of your profile.
                  `,
                  'How to Access a New Account',
                  '100%',
                  width
                )
              }

              {/* Panel 11 */}
              {(fromComponent === 'faq' || fromComponent === 'home') && 
                questionAnswerComponent(
                  `How do I reset my password?`,
                  ``,
                  false,
                  true,
                  'https://www.youtube.com/embed/iLbVPOLxkuc',
                  `
                    1) Navigate to www.sheir.estate/login
                    2) Input the email address associated with your account into the reset password textbox, and press 'Send Reset Link'
                    3) Open the 'Password Reset Link' email from sHeir, and click the link inside the email.
                    4) Set a password, select security questions and answers, and click 'Reset Password'.
                    5) Sign in by entering your email address and password.
                    6) Answer a security question.
                  `,
                  'How to Access a New Account',
                  '100%',
                  width
                )
              }

              {/* Panel 12 */}
              {(fromComponent === 'faq' || fromComponent === 'home' || fromComponent === 'familyHQ' || (fromComponent === 'personSpace' && role !== 'Heir') || (fromComponent === 'itemsIndex' && role === 'write')) && 
                questionAnswerComponent(
                  'How do I add items to my estate?',
                  ``,
                  (fromComponent === 'itemsIndex' && role === 'write') ? true : false,
                  true,
                  'https://www.youtube.com/embed/T-K1MJn07Ts',
                  `
                    1) Navigate to the 'Items' tab in your estate.
                    2) Click 'Add Item' to add an item, or click the pencil icon next to an item to edit an existing item.
                    3) Input the item's details.
                    4) Scroll down and press 'Save'.
                  `,
                  'How to Add Items to an Estate',
                  '100%',
                  width
                )
              }

              {/* Panel 13 */}
              {(fromComponent === 'faq' || fromComponent === 'home' || fromComponent === 'familyHQ' || (fromComponent === 'personSpace' && role !== 'Heir')) && 
                questionAnswerComponent(
                  'How do I add people to my estate?',
                  ``,
                  false,
                  true,
                  'https://www.youtube.com/embed/zTkwKFKAlRg',
                  `
                    1) The estate owner navigates to their 'My Planning' space and selects the 'People' tab.
                    2) Click 'Add Another Person'.
                    3) Input the person's details.
                    4) Click 'Save'.

                    * If the new person is an 'Heir' and the estate is 'Silent', no emails will be sent. Otherwise a 'You Have Been Added to an Estate' email will be sent to the new person.
                  `,
                  'How to Add People to an Estate',
                  '100%',
                  width
                )
              }

              {/* Panel 14 */}
              {(fromComponent === 'faq' || fromComponent === 'home' || fromComponent === 'familyHQ' || (fromComponent === 'personSpace' && role !== 'Heir')) && 
                questionAnswerComponent(
                  `How do I adjust my estate's settings?`,
                  ``,
                  false,
                  true,
                  'https://www.youtube.com/embed/idyu_1KUk6s',
                  `
                    1) The estate owner navigates to their 'My Planning' space and selects the 'Settings' tab.
                    2) Change estate name, transparency level, distribution method, heir allotments, fees, and taxes, and then press 'Save' if necessary.
                    3) Navigate to Family HQ to view the newly saved settings
                  `,
                  'How to Adjust Settings for an Estate',
                  '100%',
                  width
                )
              }

              {/* Panel 15 */}
              {(fromComponent === 'faq' || fromComponent === 'home' || (fromComponent === 'familyHQ' && transparencyLevel === 'Participatory') || (fromComponent === 'personSpace' && role === 'Heir' && transparencyLevel === 'Participatory' && distributionMethod !== 'Allocate All')) && 
                questionAnswerComponent(
                  `How do I, as an heir, prepare for an auction or draft?`,
                  ``,
                  (fromComponent === 'personSpace' && role === 'Heir') ? true : false,
                  true,
                  'https://www.youtube.com/embed/tg80xBxgeUw',
                  `
                    1) Navigate to your 'My Planning' space and select the planning tab.
                    2a) If it's an auction, assign all of your available 1000 points to various items. These will be your bids for auto-auctions and your target bids for live auctions.
                    2b) If it's a draft, pre-rank the items in order from most-to-least desired.
                    3) In Family HQ, click the 'Practice' button to get the hang of how the draft/auction works. Here, in the 'Practice' space, you will be bidding/drafting against robot versions of other heirs, not real people.
                    4) Adjust your event planning as necessary.
                    5) Mark yourself as 'Ready' when you feel prepared.
                    6) When all heirs have marked themselves as ready, the event can be executed.
                  `,
                  'How to Prepare for an Event',
                  '100%',
                  width
                )
              }


            </Box>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default FAQ