

// Sources:

// Constants
const startBidHistoryAndAuctionPlanningObj = {
  catPersonId: 0, 
  doePersonId: 0, 
}

const startDraftPlanningObj = {
  catPersonId: 1000000000, 
  doePersonId: 1000000000, 
}


// Items

// Sleep Mask
export const hollyGolightlySleepMask = {
  _id: 'hollyGolightlySleepMaskItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Sleep Mask',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-sleep-mask-1.jpg',
  valuation: '30',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'The mask I wear every night — and day — for sleeping and napping',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Black Dress
export const hollyGolightlyBlackDress = {
  _id: 'hollyGolightlyBlackDressItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Black Dress',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-black-dress-1.jpg',
  valuation: '30000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My black party dress — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Sunglasses — Oliver Goldsmith Cateye Sunglasses
export const hollyGolightlySunglasses = {
  _id: 'hollyGolightlySunglassesItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Sunglasses',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-sunglasses-1.jpg',
  valuation: '5000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My Oliver Goldsmith Cateye Sunglasses — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// White Scarf
export const hollyGolightlyWhiteScarf = {
  _id: 'hollyGolightlyWhiteScarfItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'White Scarf',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-white-scarf-1.jpg',
  valuation: '500',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My white scarf, made of silk and often adorning my hat — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Cigarette Holder
export const hollyGolightlyCigaretteHolder = {
  _id: 'hollyGolightlyCigaretteHolderItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Cigarette Holder',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-cigarette-holder-1.jpg',
  valuation: '15',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'My cigarette holder. Even though I don\'t like to smoke it looks very cool.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Brown Coat
export const hollyGolightlyBrownCoat = {
  _id: 'hollyGolightlyBrownCoatItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Brown Coat',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-brown-coat-1.jpg',
  valuation: '15000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My brown coat — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Black Hat
export const hollyGolightlyBlackHat = {
  _id: 'hollyGolightlyBlackHatItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Black Hat',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-black-hat-1.jpg',
  valuation: '8000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My black hat dress — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Pearl Necklace
export const hollyGolightlyPearlNecklace = {
  _id: 'hollyGolightlyPearlNecklaceItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Pearl Necklace',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-pearl-necklace-1.jpg',
  valuation: '250000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'My pearl necklace — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Tiara
export const hollyGolightlyTiara = {
  _id: 'hollyGolightlyTiaraItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Diamond Tiara',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-tiara-1.jpg',
  valuation: '200000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'My diamond tiara — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Black Gloves
export const hollyGolightlyBlackGloves = {
  _id: 'hollyGolightlyBlackGlovesItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Black Gloves',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-black-gloves-1.jpg',
  valuation: '2500',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My black gloves — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Diamond Earrings
export const hollyGolightlyDiamondEarrings = {
  _id: 'hollyGolightlyDiamondEarringsItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Diamond Earrings',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-diamond-earrings-1.jpg',
  valuation: '300000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'My diamond earrings — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// White Nightshirt
export const hollyGolightlyWhiteNightshirt = {
  _id: 'hollyGolightlyWhiteBedshirtItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'White Nightshirt',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-white-nightshirt-1.jpg',
  valuation: '30',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My white nightshirt that\'s often covered in cat hair.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Black Handbag
export const hollyGolightlyBlackHandbag = {
  _id: 'hollyGolightlyBlackHandbagItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Black Handbag',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-black-handbag-1.jpg',
  valuation: '35000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My black handbag — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Pink Throw Pillows
export const hollyGolightlyPinkThrowPillows = {
  _id: 'hollyGolightlyPinkThrowPillowsItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Pink Throw Pillows',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-pink-pillows-1.jpg',
  valuation: '40',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'My pink throw pillows.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// White Couch
export const hollyGolightlyWhiteCouch = {
  _id: 'hollyGolightlyWhiteCouchItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'White Couch',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-white-couch-1.jpg',
  valuation: '650',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Furniture',
  description: 'My white couch',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Black Telephone
export const hollyGolightlyBlackTelephone = {
  _id: 'hollyGolightlyBlackTelephoneItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Telephone',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-telephone-1.jpg',
  valuation: '200',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'The black dialpad telephone that I make all of my important phonecalls with.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// High Heels — Black
export const hollyGolightlyBlackHighHeels = {
  _id: 'hollyGolightlyBlackHighHeelsItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'High Heels — Black',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-black-high-heels-1.jpg',
  valuation: '3000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My black high heels — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// High Heels Brown
export const hollyGolightlyBrownHighHeels = {
  _id: 'hollyGolightlyBrownHighHeelsItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'High Heels — Brown',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-brown-high-heels-1.jpg',
  valuation: '2500',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Clothing',
  description: 'My brown high heels — a gift from a very rich man whose name I forget but who is in the address book.',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Purple Tassle Earrings
export const hollyGolightlyPurpleTassleEarrings = {
  _id: 'hollyGolightlyPurpleTassleEarringsItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Purple Tassle Earrings',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-purple-tassle-earrings-1.jpg',
  valuation: '40',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Jewelry',
  description: 'My tassle earrings that I wear when I\'m alone in my apartment',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}

// Address Book — Richest Bachelors in New York
export const hollyGolightlyAddressBook = {
  _id: 'hollyGolightlyAddressBookItemId',
  estateId: 'hollyGolightlyEstateId',
  itemName: 'Address Book',
  imageURL: 'https://sheir-item-images.s3.amazonaws.com/seed-holly-golightly-address-book-1.jpg',
  valuation: '100000',
  valuationDate: '2021-08-27T13:49:49.000Z',
  category: 'Miscellaneous',
  description: 'My address book containing the names and phone numbers of all of the richest bachelors in New York',
  allottedToName: 'Unassigned',
  allottedToPersonId: 'NA',
  giftFor: '',
  bidHistory: { ...startBidHistoryAndAuctionPlanningObj },
  heirAuctionPlanning: { ...startBidHistoryAndAuctionPlanningObj },
  heirDraftPlanning: { ...startDraftPlanningObj },
}