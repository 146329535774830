
import axios from 'axios'
import io from 'socket.io-client'

import { getTokenFromLocalStorage } from './storage'

// Socket
export const socket = io(`${process.env.REACT_APP_SOCKET_URL}/`, {
// export const socket = io(`http://localhost:4000/`, {
// export const socket = io(`http://192.168.1.12:4000`, {
  transports: ['websocket'],
  autoConnect: false, 
})

export const initateLiveEventConnection = async (estateId) => {
  // console.log('initateLiveEventConnection runs')
  try {
    const connectedToServer = await axios.put(`${process.env.REACT_APP_SERVER_URL}/live/${estateId}/live-event-connection`, {}, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    })
    // console.log('connectedToServer ->', connectedToServer.data)

    return connectedToServer.data

  } catch (error) {
    // console.log(error)
    // console.log('couldnt connect to server')
  }
}

export const initiateSocketIO = async (person, estateId, specifiedSocket) => {
  // console.log('initiateSocketIO runs')

  try {

    const connected = await specifiedSocket.connect()
    // console.log('connected value ->', connected)
    // console.log('connected ->', connected.connected)
    // console.log('connect runs')

    return 'Joined'
    
  } catch (error) {
    // console.log(error)
    // console.log('couldnt connect to socket')
  }


  

  // await axios.put(`${process.env.REACT_APP_SERVER_URL}/live/${estateId}/live-event-connection`, {}, {
  //   headers: {
  //     Authorization: `Bearer ${getTokenFromLocalStorage()}`,
  //   },
  // })

  // const connected = await specifiedSocket.connect()
  // console.log('connected ->', connected)
  // console.log('connect runs')

  // if (connected === 'Connected') {
  //   const joined = await specifiedSocket.emit('join_room', { estateId: estateId, personId: person._id })
  //   console.log('joined ->', joined)
  //   console.log('join room runs')
  // }

}