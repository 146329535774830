/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import useWindowDimensions from './windowDimensions'
import { standardButton } from './buttons'
import { standardSpinner } from './spinners'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from './storage'
import { draftQueueMinWidth, auctionResultsMinWidth, totalAllottedRemainingHeight, autoSelectHeight, draftNextHeight, onTheClockHeight, chatHeight, sheirBlue, navbarHeight, estateNavbarHeight, footerHeight, positionChangeWidthSm, positionChangeWidthLg, auctionTimerMinutes, auctionTimerSeconds, positionChangeWidthAuction } from './variableDefaults'
import { totalAllottedRemainingJSX, chatJSX, draftQueueItemJSX, timeRemainingJSX, biddingSpaceJSX, attendanceSidePanelJSX, allHeirsPresent } from './sharedDraftAuctionJSX'
import { standardCheckboxBool, standardCheckboxForm } from './checkboxes'
import { NumericFormatCustom } from './formatting'
import { isNumeric, Input, getKeyByValue } from './globalHelpers'
import { resultsMenuSelectElement } from './selects'
import { getHeirShareTotal, getHeirAllotmentsTotal, getHeirAllotmentsTotalByEstate, getAllottedPoints, getAllottedPointsByEstate, getTotalAllottedPointsByEstate, getUnassignedItems, getHighestBid, getUserBid, getUserTargetBid, getHighestBidderId, getPersonInEstateFromId, getEventResults, getRemainingGreaterThanValuation, getPointsRemainingGreaterThanBid, getPointsRemainingGreaterThanBidCustom, getPersonInEstateFromName } from './getValuationTotals'
import { pauseCountDown, resetCountDown, startTimeout } from './timer'

import AuctionBlockCard from '../components/auction/AuctionBlockCard'
import AuctionResults from '../components/auction/AuctionResults'
import QueueItem from '../components/auction/QueueItem'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send'
import CheckIcon from '@mui/icons-material/Check'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AddIcon from '@mui/icons-material/Add'


export const computerAuctionJSX = (fromTab, estate, userPerson, autoSelect, setAutoSelect, eventHasBegun, eventHasFinished, handleStartTimer, handleExitDraft, minutes, seconds, width, onTheBlockItem, handleSubmitBidPressed, customBid, handleCustomBidText, messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef, heirNames, resultsName, handleResultsSelect, openResultsMenu, setOpenResultsMenu, heirPersons, connectedPersons = []) => {
  const getResultsPerson = () => {
    return estate.people.filter(person => person.userGivenAndFamilyName === resultsName)
  }
  
  return (
    <Box 
      sx={{ 
        height: '100vh', width: '100vw',
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', 
      }}
    >

      {/* This must be first input So that the file upload only fires when you press the button */}
      <Box sx={{ width: '0px' }}>
        <Input type="text" autoFocus="autoFocus" />
      </Box>

      {/* Auction Planning */}
      <Box
        sx={{
          // backgroundColor: 'yellow',
          width: draftQueueMinWidth,
          height: '100%',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Money — Total, Allotted, Remaining */}
        {userPerson.role === 'Heir' &&
          <Box
            sx={{
              // backgroundColor: 'lightblue',
              height: totalAllottedRemainingHeight,
              width: `calc(100% - 10px)`, 
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
            }}
          >
            {totalAllottedRemainingJSX('Share', Math.floor(userPerson.sharePercentage) === 0 ? 0 : getHeirShareTotal(estate, userPerson.sharePercentage), 'money', width)}
            {totalAllottedRemainingJSX('Allotted', (!(estate.hasOwnProperty('items')) ? 0 : getHeirAllotmentsTotalByEstate(estate, userPerson)), 'money', width)}
            {totalAllottedRemainingJSX('Remaining', (!(estate.hasOwnProperty('items')) || (Math.floor(userPerson.sharePercentage) === 0)) ? 0 : getHeirShareTotal(estate, userPerson.sharePercentage) - getHeirAllotmentsTotalByEstate(estate, userPerson), 'money', width)}
          </Box>
        }

        {/* Points — Total, Allotted, Remaining */}
        {userPerson.role === 'Heir' &&
          <Box
            sx={{
              // backgroundColor: 'lightblue',
              height: totalAllottedRemainingHeight,
              width: `calc(100% - 10px)`, 
              borderTop: '1px solid gray',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
            }}
          >
            {totalAllottedRemainingJSX('Points', 1000, 'points', width)}
            {totalAllottedRemainingJSX('Allotted', (!(estate.hasOwnProperty('items'))) ? 0 : getTotalAllottedPointsByEstate(estate, userPerson), 'points', width)}
            {totalAllottedRemainingJSX('Remaining', (!(estate.hasOwnProperty('items'))) ? 1000 : 1000 - getTotalAllottedPointsByEstate(estate, userPerson), 'points', width)}
          </Box>
        }

        {/* Auto Select */}
        {userPerson.role === 'Heir' &&
          <Box
            sx={{
              // backgroundColor: 'lightgreen',
              height: autoSelectHeight,
              width: `calc(100% - 10px)`, 
              borderTop: '1px solid gray',
              borderBottom: '1px solid gray',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {standardCheckboxBool('Auto Bid', autoSelect, setAutoSelect, false, 'secondary')}
          </Box>
        }

        {/* Attendance */}
        {/* {userPerson.role !== 'Heir' && fromTab === 'live' && eventHasBegun &&
          attendanceSidePanelJSX('Auction', estate, connectedPersons, false)
        } */}


        {/* Up Next */}
        <Box
          sx={{
            // backgroundColor: 'lightpink',
            pb: 3,
            // height: `calc(100vh - ${totalAllottedRemainingHeight} - ${totalAllottedRemainingHeight} - ${autoSelectHeight})`,
            // height: userPerson.role === 'Heir' ? `calc(100vh - ${totalAllottedRemainingHeight} - ${totalAllottedRemainingHeight} - ${autoSelectHeight})` : '100%',
            // height: (userPerson.role !== 'Heir' && (fromTab === 'practice' || !eventHasBegun)) ? '100%' : `calc(100vh - ${totalAllottedRemainingHeight} - ${totalAllottedRemainingHeight} - ${autoSelectHeight})`,
            height: (userPerson.role !== 'Heir') ? '100%' : `calc(100vh - ${totalAllottedRemainingHeight} - ${totalAllottedRemainingHeight} - ${autoSelectHeight})`,
            width: `calc(100% - 10px)`, 
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              WebkitAppearance: 'none',
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, .35)',
              webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {/* Title */}
          <Typography
            sx={{
              py: 1,
              // fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            Up Next
          </Typography>

          {estate.hasOwnProperty('items') && getUnassignedItems(estate).sort((a, b) => b['valuation'] - a['valuation']).map((item, index) => (
            <Box
              key={index}
              // onClick={(e) => highlightNewItem(e, item, index)}
              sx={{
                // backgroundColor: 'yellow',
                // cursor: 'pointer',
                width: '100%', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {estate.hasOwnProperty('items') && getUnassignedItems(estate).length > 0 &&
                // draftQueueItemJSX(estate, item, onTheBlockItem, autoSelect, 'auctionQueue', userPerson, width, index)
                <QueueItem item={item} person={userPerson} estate={estate} highlighted={item._id === onTheBlockItem._id} enoughRemaining={ getRemainingGreaterThanValuation(item, estate, userPerson) && getPointsRemainingGreaterThanBidCustom(getHighestBid(item) + 1, estate, userPerson, item)} distributionMethod={'Auction'} />
              }
            </Box>
          ))}

        </Box>

      </Box>

      {/* On the Block and Chat */}
      <Box
        sx={{
          // backgroundColor: 'orange',
          width: `calc(100% - ${draftQueueMinWidth} - ${auctionResultsMinWidth})`,
          height: '100%',
          borderLeft: '1px solid gray',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Timer */}
        {eventHasBegun === false ?
          <Box
            sx={{
              backgroundColor: 'lightgray',
              // pl: 2,
              height: onTheClockHeight,
              width: '100%', 
              fontWeight: 'bold',
              fontSize: '20px',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              color: 'white',
            }}
          >
            {standardButton(
              'Start Auction', 
              'button',
              'contained',
              // fromTab === 'live' && (userPerson.readWriteAdmin !== 'admin' || !allHeirsPresent(heirPersons, connectedPersons)),
              false,
              'primary', 
              0,
              0,
              0,
              '150px', 
              '40px', 
              handleStartTimer
            )}
          </Box>
          :
          <Box
            sx={{
              backgroundColor: 'black',
              width: '100%',
              height: onTheClockHeight,
              color: 'white',
              fontWeight: 'bold',
              fontSize: '20px',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {estate.hasOwnProperty('items') && getUnassignedItems(estate).length > 0 
              ? 
              timeRemainingJSX('auction', minutes === -1 ? auctionTimerMinutes : minutes, seconds === -1 ? auctionTimerSeconds : seconds, width, estate, userPerson)
              :
              <Box
                sx={{
                  height: onTheClockHeight,
                  width: '100%', 
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    // backgroundColor: 'bisque',
                    pb: 1,
                    // height: '100%',
                    width: '100%', 
                    // fontWeight: 'bold',
                    fontSize: '18px',
                    display: 'flex', flexDirection: 'row', justifyContent: 'center',
                    // color: 'white',
                  }}
                >
                  Auction Completed
                </Box>

                {standardButton(
                  'Exit', 
                  'button',
                  'contained',
                  false,
                  'primary', 
                  0,
                  0,
                  0,
                  '130px', 
                  '40px', 
                  handleExitDraft
                )}
              </Box>
            }

          </Box>
        }
        
        {/* Bidding Space */}
        {!eventHasBegun && fromTab === 'live' ?
          <Box
            sx={{
              // backgroundColor: 'azure',
              // height: '100%',
              // pb: 2,
              height: `calc(100vh - ${onTheClockHeight} - ${chatHeight})`,
              width: '100%',
              borderLeft: '1px solid lightgray',
              boxShadow: 3,
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                WebkitAppearance: 'none',
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '4px',
                backgroundColor: 'rgba(0, 0, 0, .35)',
                webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
              }, 
              display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-start',
            }}
          >
            {attendanceSidePanelJSX('Auction', estate, connectedPersons, true)}
          </Box>
          :
          !eventHasFinished ?
            <Box
              sx={{
                // backgroundColor: 'azure',
                // height: '100%',
                // pb: 2,
                height: `calc(100vh - ${onTheClockHeight} - ${chatHeight})`,
                width: '100%',
                borderLeft: '1px solid lightgray',
                boxShadow: 3,
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  WebkitAppearance: 'none',
                  width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '4px',
                  backgroundColor: 'rgba(0, 0, 0, .35)',
                  webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
                }, 
                display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-start',
              }}
            >
              {/* On the Block */}
              <Box
                sx={{
                  // backgroundColor: 'pink',
                  pt: 8, pb: 2,
                  width: '50%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                }}
              >
                {estate.hasOwnProperty('items') && estate.items.length > 0 && onTheBlockItem && onTheBlockItem.hasOwnProperty('bidHistory') && <AuctionBlockCard item={onTheBlockItem} person={userPerson} estate={estate} />}
              </Box>

              {/* Bidding Space */}
              {estate.hasOwnProperty('items') && onTheBlockItem && onTheBlockItem.hasOwnProperty('bidHistory') && biddingSpaceJSX(
                estate, 
                userPerson, 
                onTheBlockItem, 
                autoSelect, 
                // handlePlusButtonPressed, 
                handleSubmitBidPressed, 
                customBid, 
                // getHighestBid,
                handleCustomBidText, 
                minutes, 
                seconds, 
                eventHasBegun,
                width,
                'live',
                connectedPersons
              )}
            </Box>
            :
            <Box
              sx={{
                // backgroundColor: 'azure',
                // height: '100%',
                // pb: 2,
                height: `calc(100vh - ${onTheClockHeight} - ${chatHeight})`,
                width: '100%',
                borderLeft: '1px solid lightgray',
                boxShadow: 3,
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  WebkitAppearance: 'none',
                  width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '4px',
                  backgroundColor: 'rgba(0, 0, 0, .35)',
                  webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
                }, 
                display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-start',
              }}
            >
              {/* Results */}
              {fromTab === 'practice' ?
                <AuctionResults estate={estate} biddingPersons={estate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage)} />
                :
                <AuctionResults />
              }
              
              
            </Box>
        }

        {/* Chat */}
        {chatJSX((estate.hasOwnProperty('event') && messages.length > 0) ? messages : [], messageText, handleChangeMessageText, handleSendMessage, messagesEndRef, width, 'Auction', connectedPersons, estate)}
      
      </Box>

      {/* Results */}
      <Box
        sx={{
          // backgroundColor: 'azure',
          // height: `calc(100vh - ${chatHeight})`,
          pt: 2,
          height: '100%',
          width: auctionResultsMinWidth, 
          // width: `calc(100% - 10px)`, 
          borderLeft: '1px solid lightgray',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >

        {/* Select */}
        {heirNames.length > 0 && resultsName &&
          resultsMenuSelectElement('heirResults', 'Allotments', resultsName, false, true, heirNames, handleResultsSelect, openResultsMenu, setOpenResultsMenu)
        }

        {/* Money — Total, Allotted, Remaining */}
        <Box
          sx={{
            // backgroundColor: 'lightblue',
            // py: 1,
            height: totalAllottedRemainingHeight,
            minHeight: totalAllottedRemainingHeight,
            width: `calc(100% - 10px)`, 
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
          }}
        >
          {totalAllottedRemainingJSX('Share', (!(estate.hasOwnProperty('items')) || !(estate.hasOwnProperty('people'))) ? 0 : (getResultsPerson().length === 0 || Math.floor(getResultsPerson()[0].sharePercentage) === 0) ? 0 : getHeirShareTotal(estate, getResultsPerson()[0].sharePercentage), 'auctionResultsMoney', width)}
          {totalAllottedRemainingJSX('Allotted', (!(estate.hasOwnProperty('items')) || !(estate.hasOwnProperty('people'))) ? 0 : getHeirAllotmentsTotalByEstate(estate, getResultsPerson()[0]), 'auctionResultsMoney', width)}
          {totalAllottedRemainingJSX('Remaining', (!(estate.hasOwnProperty('items')) || !(estate.hasOwnProperty('people')) || (Math.floor(getResultsPerson()[0].sharePercentage) === 0)) ? 0 : getHeirShareTotal(estate, getResultsPerson()[0].sharePercentage) - getHeirAllotmentsTotalByEstate(estate, getResultsPerson()[0]), 'auctionResultsMoney', width)}
        </Box>

        {/* Points — Total, Allotted, Remaining */}
        <Box
          sx={{
            // backgroundColor: 'lightblue',
            height: totalAllottedRemainingHeight,
            minHeight: totalAllottedRemainingHeight,
            // py: 1,
            width: `calc(100% - 10px)`, 
            borderTop: '1px solid gray',
            borderBottom: '1px solid gray',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
          }}
        >
          {totalAllottedRemainingJSX('Points', 1000, 'auctionResultsPoints', width)}
          {totalAllottedRemainingJSX('Allotted', (!(estate.hasOwnProperty('items')) || !(estate.hasOwnProperty('people'))) ? 0 : getTotalAllottedPointsByEstate(estate, getResultsPerson()[0]), 'auctionResultsPoints', width)}
          {totalAllottedRemainingJSX('Remaining', (!(estate.hasOwnProperty('items')) || !(estate.hasOwnProperty('people'))) ? 1000 : 1000 - getTotalAllottedPointsByEstate(estate, getResultsPerson()[0]), 'auctionResultsPoints', width)}
        </Box>

        {/* Allotments for the Heir */}
        <Box
          sx={{
            width: '100%',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              WebkitAppearance: 'none',
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '0px',
              backgroundColor: 'rgba(0, 0, 0, .35)',
              webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {/* Title */}
          <Typography
            sx={{
              py: 1,
              // fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            Allotments
          </Typography>

          {estate.hasOwnProperty('items') && estate.hasOwnProperty('people') && resultsName && estate.items.filter(item => item.allottedToName === resultsName).sort((a, b) => b['bidHistory'][getPersonInEstateFromName(estate, resultsName)._id] === a['bidHistory'][getPersonInEstateFromName(estate, resultsName)._id] ? b['valuation'] - a['valuation'] : b['bidHistory'][getPersonInEstateFromName(estate, resultsName)._id] - a['bidHistory'][getPersonInEstateFromName(estate, resultsName)._id]).map((item, index) => (
            <Box
              key={index}
              // onClick={(e) => highlightNewItem(e, item, index)}
              sx={{
                // backgroundColor: 'yellow',
                // cursor: 'pointer',
                width: '100%', 
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {draftQueueItemJSX(estate, item, false, autoSelect, 'auctionResultsList', getPersonInEstateFromName(estate, resultsName), width, index)}
            </Box>
          ))}
        </Box>
      </Box>

    </Box>
  )
}

export const mobileAuctionJSX = (fromTab, estate, userPerson, autoSelect, eventHasBegun, eventHasFinished, handleStartTimer, handleExitDraft, minutes, seconds, width, onTheBlockItem, handleSubmitBidPressed, customBid, handleCustomBidText, messages, messageText, handleChangeMessageText, handleSendMessage, messagesEndRef, heirPersons, connectedPersons) => {
  return (
    <Box
      sx={{
        height: '100vh', width: '100vw',
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
      }}
    >

      {/* Timer */}
      {eventHasBegun === false ?
        <Box
          sx={{
            backgroundColor: 'black',
            // pl: 2,
            height: onTheClockHeight,
            minHeight: onTheClockHeight,
            // height: '300px',
            width: '100%', 
            fontWeight: 'bold',
            fontSize: '20px',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            color: 'white',
          }}
        >
          {standardButton(
            'Start Auction', 
            'button',
            'contained',
            // fromTab === 'live' && (userPerson.readWriteAdmin !== 'admin' || !allHeirsPresent(heirPersons, connectedPersons)),
            false,
            'primary', 
            0,
            0,
            0,
            '150px', 
            '40px', 
            handleStartTimer
          )}
        </Box>
        :
        <Box
          sx={{
            backgroundColor: 'black',
            width: '100%',
            height: onTheClockHeight,
            minHeight: onTheClockHeight,
            color: 'white',
            fontWeight: 'bold',
            fontSize: '20px',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {estate.hasOwnProperty('items') && getUnassignedItems(estate).length > 0 
            ? 
            timeRemainingJSX('auction', minutes === -1 ? auctionTimerMinutes : minutes, seconds === -1 ? auctionTimerSeconds : seconds, width, estate, userPerson)
            :
            <Box
              sx={{
                height: onTheClockHeight,
                minHeight: onTheClockHeight,
                width: '100%', 
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  // backgroundColor: 'bisque',
                  pb: 1,
                  // height: '100%',
                  width: '100%', 
                  // fontWeight: 'bold',
                  fontSize: '18px',
                  display: 'flex', flexDirection: 'row', justifyContent: 'center',
                  // color: 'white',
                }}
              >
                Auction Completed
              </Box>

              {standardButton(
                'Exit', 
                'button',
                'contained',
                false,
                'primary', 
                0,
                0,
                0,
                '130px', 
                '40px', 
                handleExitDraft
              )}
            </Box>
          }

        </Box>
      }

      {/* MessageBoard */}
      {!eventHasFinished && 
        estate.hasOwnProperty('event') &&
        // messages.filter(message => message.fromName.startsWith('Allotment')).length > 0 &&
        chatJSX(messages.filter(message => message.fromName.startsWith('Allotment')).length > 0 ? messages.filter(message => message.fromName.startsWith('Allotment')).slice(messages.filter(message => message.fromName.startsWith('Allotment')).length - 1) : [], messageText, handleChangeMessageText, handleSendMessage, messagesEndRef, width, 'Auction', connectedPersons, estate)
      }
      
      {!eventHasBegun && fromTab === 'live' &&
        attendanceSidePanelJSX('Auction', estate, connectedPersons, true)
      }

      {/* Item */}
      {(eventHasBegun || fromTab === 'practice') && !eventHasFinished && 
        <Box
          sx={{
            // backgroundColor: 'pink',
            pt: 4, 
            // pb: 2,
            width: '90%', 
            maxWidth: '400px',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {estate.hasOwnProperty('items') && estate.items.length > 0 && onTheBlockItem && onTheBlockItem.hasOwnProperty('bidHistory') && <AuctionBlockCard item={onTheBlockItem} person={userPerson} estate={estate} />}
        </Box>
      }

      {/* Bidding Space */}
      {(eventHasBegun || fromTab === 'practice') && !eventHasFinished && estate.hasOwnProperty('items') && onTheBlockItem && onTheBlockItem.hasOwnProperty('bidHistory') && biddingSpaceJSX(
        estate, 
        userPerson, 
        onTheBlockItem, 
        autoSelect, 
        // handlePlusButtonPressed, 
        handleSubmitBidPressed, 
        customBid, 
        // getHighestBid,
        handleCustomBidText, 
        minutes, 
        seconds, 
        eventHasBegun,
        width,
        'live',
        connectedPersons
      )}

      {/* Up Next */}
      {(eventHasBegun || fromTab === 'practice') &&
        <Box
          sx={{
            pb: 4,
            width: '100%',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {/* Title */}
          {!eventHasFinished && 
            <Typography
              sx={{
                py: 1,
                // fontSize: '24px',
                fontWeight: 'bold',
              }}
            >
              Up Next
            </Typography>
          }

          {!eventHasFinished && estate.hasOwnProperty('items') && getUnassignedItems(estate).sort((a, b) => b['valuation'] - a['valuation']).map((item, index) => (
            <Box
              key={index}
              // onClick={(e) => highlightNewItem(e, item, index)}
              sx={{
                // backgroundColor: 'yellow',
                // cursor: 'pointer',
                width: '95%', 
                maxWidth: '350px',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {estate.hasOwnProperty('items') && getUnassignedItems(estate).length > 0 && 
                // draftQueueItemJSX(estate, item, onTheBlockItem, autoSelect, 'auctionQueue', userPerson, width, index)
                <QueueItem item={item} person={userPerson} estate={estate} highlighted={item._id === onTheBlockItem._id} enoughRemaining={ getRemainingGreaterThanValuation(item, estate, userPerson) && getPointsRemainingGreaterThanBidCustom(getHighestBid(item) + 1, estate, userPerson, item)} distributionMethod={'Auction'} />
              }
            </Box>
          ))}

          {eventHasFinished &&
            <AuctionResults estate={estate} biddingPersons={estate.people.filter(person => person.role === 'Heir').sort((a, b) => b.sharePercentage === a.sharePercentage ? a.userGivenAndFamilyName.localeCompare(b.userGivenAndFamilyName) : b.sharePercentage - a.sharePercentage)} />
          }
        </Box>
      }
    
    </Box>
  )
}