/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import dayjs from 'dayjs'

import useWindowDimensions from '../../helpers/windowDimensions'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { dummyBoxWidth } from '../../helpers/globalHelpers'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { draftResultRoundColumnWidth, draftResultHeirColumnWidth, sheirBlue, navbarHeight, estateNavbarHeight, footerHeight, positionChangeWidthSm, sheirGreen, draftTimerMinutes, draftTimerSeconds, passDraftPickImageBlackURL } from '../../helpers/variableDefaults'
import { draftResultsSingleRoundJSX, draftResultItemJSX } from '../../helpers/sharedDraftAuctionJSX'
import { getPersonDraftingIndex, getHeirShareTotal, getHeirAllotmentsTotal, getHeirAllotmentsTotalByEstate, getUnassignedItems, getEventResults, getPersonDrafting, getAllottedPoints, getAllottedPointsByEstate, getUnassignedItemsWithValuationUnderRemaining, getRemainingGreaterThanValuation, getAtLeastOnePersonCanStillDraft, getIndexOfItemInEstate } from '../../helpers/getValuationTotals'
import { couldNotLoadPageError } from '../../helpers/errors'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Draft Results Page
const DraftResults = () => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId } = useParams()

  // Payload
  const payload = getPayload()

  // URL Path
  const urlPath = window.location.pathname

  // Estate
  const [estate, setEstate] = useState({})
  const [userPerson, setUserPerson] = useState({})
  const [draftOrderPersons, setDraftOrderPersons] = useState([])

  // Loading
  const [loading, setLoading] = useState(false)

  // Errors
  const [errors, setErrors] = useState(false)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {

    if (!userIsAuthenticated()) {

      // Redirect user to homepage if not authenticated
      navigate('/')

    } else {

      // Get estate data if authenticated
      const getData = async () => {
        setLoading(true)

        // Retrieve the estate
        try {
          const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved Estate ->', retrievedEstate)

          // Find the Person in the estate associated with the User
          const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)

          // If there is a Person in the estate associated with the User, proceed to set states
          if (userIsInEstate.length > 0 && retrievedEstate.subscriptionIsActive) {
            
            // Set States
            setUserPerson(userIsInEstate[0])
            setEstate(retrievedEstate)

            // Draft Order Persons
            const heirPersonsOrderedArray = []
            for (let i = 0; i < retrievedEstate.event[0].draftOrder.length; i++) {
              heirPersonsOrderedArray.push(retrievedEstate.people.filter(person => person._id === retrievedEstate.event[0].draftOrder[i])[0])
            }
            setDraftOrderPersons(heirPersonsOrderedArray)

            setErrors(false)
            setLoading(false)
          } else {

            // If no Person in the estate is associated with the user, redirect to user profile
            navigate(`/user/${payload.sub}`)

            setErrors(false)
            setLoading(false)
          }

        } catch (error) {
          // console.log(error)

          // If problems retrieving estate data, redirect to user profile
          navigate(`/user/${payload.sub}`)

          setErrors(true)
          setLoading(false)
        }
      }

      getData()
    }

  }, [])


  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {urlPath.includes('draft-results') && seoPageTags(
        'Draft Results'
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          height: '100vh',
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        <Box 
          sx={{ 
            // backgroundColor: 'pink',
            pb: 6,
            minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
            width: '100vw', 
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              WebkitAppearance: 'none',
              width: '0px', height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, .35)',
              webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',  
          }}
        >

          {/* Title */}
          <Typography
            sx={{
              pt: 4, pb: width > positionChangeWidthSm ? 0 : 1,
              pl: ((draftOrderPersons.length) * Math.floor(draftResultHeirColumnWidth.substring(0, draftResultHeirColumnWidth.indexOf('p')))) + Math.floor(draftResultRoundColumnWidth.substring(0, draftResultRoundColumnWidth.indexOf('p'))) < width ?  7 : 0,
              fontSize: '26px',
              fontWeight: 'bold',
            }}
          >
            Draft Results
          </Typography>

          {errors && couldNotLoadPageError()}

          {/* Heirs */}
          {!errors && width > positionChangeWidthSm && draftOrderPersons.length > 0 &&
            <Box
              sx={{
                // backgroundColor: 'orange',
                fontWeight: 'bold',
                width: '100%',
                display: 'flex', flexDirection: 'row', justifyContent: ((draftOrderPersons.length) * Math.floor(draftResultHeirColumnWidth.substring(0, draftResultHeirColumnWidth.indexOf('p')))) + Math.floor(draftResultRoundColumnWidth.substring(0, draftResultRoundColumnWidth.indexOf('p'))) < width ? 'center' : 'flex-start', alignItems: 'center',
              }}
            >
              {dummyBoxWidth(draftResultRoundColumnWidth)}

              {draftOrderPersons.map((heirPerson, heirDraftOrderIndex) => (
                <Typography
                  key={heirDraftOrderIndex}
                  sx={{
                    // backgroundColor: 'orange',
                    pt: 2,
                    // fontSize: '24px',
                    fontWeight: 'bold',
                    minWidth: draftResultHeirColumnWidth,
                    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                  }}
                >
                  {heirPerson.userGivenAndFamilyName}
                </Typography>
              ))}
            </Box>
          }

          {/* Rounds — wide screen */}
          {!errors && width > positionChangeWidthSm && (estate.hasOwnProperty('event') && estate.hasOwnProperty('items')) && getEventResults(estate).map((item, index) => (
            draftResultsSingleRoundJSX('draftResults', index, getEventResults(estate), getUnassignedItems(estate), estate, draftOrderPersons, draftTimerMinutes, draftTimerSeconds, width)
          ))}


          {/* Rounds — mobile */}
          {!errors && width <= positionChangeWidthSm && (estate.hasOwnProperty('event') && estate.hasOwnProperty('items')) && getEventResults(estate).map((item, index) => (
            <Box
              key={index}
              sx={{
                py: 1,
                width: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',  
              }}
            >
              {draftResultItemJSX('draftResults', index + 1, Math.ceil((index + 1) / draftOrderPersons.length), getPersonDraftingIndex(index, draftOrderPersons), item, estate, draftOrderPersons, width)}
            </Box>
          ))}

        </Box>
      }
    </>
  )
}

export default DraftResults