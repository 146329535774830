/* eslint-disable no-prototype-builtins */
import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Chip from '@mui/material/Chip'

import { Input } from './globalHelpers'
import { getRemainingGreaterThanValuation, getPersonInEstateFromName } from './getValuationTotals'



// Common Select Element (with Labels)
export const selectElement = (elementName, elementLabel, elementValue, disabled, focused, elementArrayOptions, changeHandler, form, setForm, index) => {
  // const upperCaseElementName = elementName.charAt(0).toUpperCase() + elementName.slice(1)
  return (
    <Box
      sx={{
        width: '90%',
      }}
    >
      <FormControl required fullWidth size="small" focused={focused}>
        <InputLabel id={`${elementName}-label`}>{elementLabel}</InputLabel>
        <Select
          labelId={`${elementName}-label`}
          id={`${elementName}`}
          disabled={disabled}
          name={`${elementName}`}
          value={elementValue}
          label={elementLabel}
          onChange={(e) => changeHandler(e, setForm, form, index)}
          className={elementValue ? `${elementName} text-${elementValue}` : `${elementName} text-` }
        >
          {elementArrayOptions.map(option => <MenuItem value={option} disabled={elementName === 'liveOrAuto' && option === 'Live'} key={option} sx={{ backgroundColor: elementName === 'allottedToName' && getRemainingGreaterThanValuation() ? 'lightcoral' : '' }}>{option}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  )
}

// Select Element for Allotting Items (with Labels)
export const allotmentSelectElement = (elementName, elementLabel, elementValue, disabled, focused, elementArrayOptions, changeHandler, form, setForm, index, estate) => {
  // const upperCaseElementName = elementName.charAt(0).toUpperCase() + elementName.slice(1)
  // console.log('estate ->', estate)
  // console.log('elementArrayOptions ->', elementArrayOptions)
  return (
    <Box
      sx={{
        width: '90%',
      }}
    >
      <FormControl required fullWidth size="small" focused={focused}>
        <InputLabel id={`${elementName}-label`}>{elementLabel}</InputLabel>
        <Select
          labelId={`${elementName}-label`}
          id={`${elementName}`}
          disabled={disabled}
          name={`${elementName}`}
          value={elementValue}
          label={elementLabel}
          onChange={(e) => changeHandler(e, setForm, form, index)}
          className={elementValue ? `${elementName} text-${elementValue}` : `${elementName} text-` }
        >
          {elementArrayOptions.map(option => 
            <MenuItem 
              value={option} 
              key={option} 
              sx={{ 
                backgroundColor: 
                  elementName === 'allottedToName' && 
                  estate && estate.hasOwnProperty('people') && 
                  getPersonInEstateFromName(estate, option) &&
                  !getRemainingGreaterThanValuation(form.valuation.length > 0 ? form : { valuation: '0' }, estate, getPersonInEstateFromName(estate, option)) 
                    ? 
                    '#FFA6A1' 
                    : 
                    ''
                , 
              }}
            >
              {option}
            </MenuItem>)
          }
        </Select>
      </FormControl>
    </Box>
  )
}

// Security Questions (custom disabled)
export const selectSecurityQuestion = (name, label, disabled, value, item, changeHandler, form, setForm, questionIndex) => {
  return (
    <FormControl required fullWidth>
      <InputLabel 
        id={`${name}-label`} 
        sx={{ 
          color: disabled ? 'gray' : 'black', 
        }}
      >
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        autoFocus={false}
        size='medium'
        id={name}
        name={name}
        label={label}
        value={value}
        onChange={(e) => changeHandler(e, questionIndex, form, setForm)}
        sx={{
          // width: '100%',
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {item}
      </Select>
    </FormControl>
  )
}

// Immediately Set State
export const selectSortCategory = (name, label, disabled, value, sortByArray, setState) => {
  return (
    <FormControl >
      <InputLabel 
        id={`${name}-label`} 
        sx={{ 
          color: disabled ? 'gray' : 'black', 
        }}
      >
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        autoFocus={false}
        size='small'
        id={name}
        name={name}
        label={label}
        value={value}
        onChange={(e) => setState(e.target.value)}
        sx={{
          // width: '100%',
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {sortByArray.map(option => <MenuItem value={option} key={option}>{option}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

// Used in filters to Select multiple 'Allotted To' names for items to filter by
export const selectMultiple = (name, label, disabled, value, selectOptionsArray, changeHandler, form, setForm, setErrors) => {
  return (
    <FormControl fullWidth size='medium'>
      <InputLabel id={name}>
        {label}
      </InputLabel>
      <Select
        labelId={name}
        label={label}
        id={name}
        disabled={disabled}
        autoFocus={true}
        multiple
        name={name}
        value={value}
        onChange={(e) => changeHandler(e, setErrors, setForm, form)}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {selectOptionsArray.map((option) => (
          <MenuItem
            key={option}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

// Common Select Element (with Labels)
export const resultsMenuSelectElement = (elementName, elementLabel, elementValue, disabled, focused, elementArrayOptions, changeHandler, openResultsMenu, setOpenResultsMenu) => {
  // console.log('elementValue ->', elementValue)
  // const upperCaseElementName = elementName.charAt(0).toUpperCase() + elementName.slice(1)
  return (
    <Box
      sx={{
        width: '90%',
      }}
    >
      {/* This must be first input So that the file upload only fires when you press the button */}
      {/* <Box sx={{ width: '0px' }}>
        <Input type="text" autoFocus="autoFocus" />
      </Box> */}

      <FormControl required fullWidth size="small" focused={focused}>
        <InputLabel id={`${elementName}-label`}>{elementLabel}</InputLabel>
        <Select
          labelId={`${elementName}-label`}
          id={`${elementName}`}
          disabled={disabled}
          name={`${elementName}`}
          value={elementValue}
          label={elementLabel}
          // onOpen={() => setOpenResultsMenu(true)}
          // onOpen={(e) => changeHandler(e, 'open')}
          onClick={() => setOpenResultsMenu(!openResultsMenu)}
          // onClick={(e) => changeHandler(e, 'click')}
          onClose={(e) => changeHandler(e, 'close')}
          // onClose={() => setOpenResultsMenu(false)}
          // onChange={(e) => changeHandler(e, 'change')}
          className={elementValue ? `${elementName} text-${elementValue}` : `${elementName} text-` }
          MenuProps={{ 
            open: openResultsMenu, 
          }}
        >
          {elementArrayOptions.map(option => <MenuItem value={option} key={option}>{option}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  )
}