import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { missionTextField } from '../../../helpers/textfields'

import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'

// Create Estate Mission Page
const Mission = (props) => {

  // Destructure Props
  const { options, setOptions } = props

  useEffect(() => {
    
    // Collect Analytics
    customAnalyticsEvent(
      `onboarding_2_mission`, 
      `onboarding_2_mission`, 
      'onboarding_2_mission', 
      `onboarding_2_mission`, 
      'Onboarding Step 2 — Mission'
    )

  }, [])

  // Updates view whenever onboardingObj updates
  useEffect(() => {
    // console.log('options ->', options)
  }, [options])

  const handleSetMission = async (e) => {
    e.preventDefault()

    const newOptionsMetadata = { ...options.metadata } 

    newOptionsMetadata.mission = e.target.value

    setOptions({ ...options, metadata: newOptionsMetadata })
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {seoPageTags(
        '404'
      )} */}

      {/* Body */}
      <Box
        sx={{
          mt: 2, mb: 2,
          width: '100%',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {/* Title */}
        <Typography sx={{ mb: 1, mt: 3, fontSize: '20px' }}>
          <strong>Mission Statement</strong>
        </Typography>

        {/* Note TextField */} 
        {missionTextField(
          'mission', 
          options.metadata.mission, 
          10, 
          'What is the goal for the estate? (Example: "To minimize sibling drama by distributing assets fairly and transparently among heirs.")', 
          handleSetMission
        )}

      </Box>
    </>
  )
}

export default Mission