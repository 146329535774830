// React
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

// MUI
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import MenuIcon from '@mui/icons-material/Menu'
import SendIcon from '@mui/icons-material/Send'
import Person2Icon from '@mui/icons-material/Person2'


// Import logo image — sheir word logo w transparent background
import logoSheir from '../../assets/sheir-black.png'

// Default profile pic — purple, white background
import profPicDefault from '../../assets/seed-profpic-default-4.jpg'

// Helper methods
import { userIsAuthenticated, getProfPicFromLocalStorage, getPayload, getTokenFromLocalStorage } from '../../helpers/storage'
import { navbarHeight, navbarBgDefaultColor, navMenuOptionsLoggedIn, navMenuOptionsNotLoggedIn, profPicDefaultURL, positionChangeWidthSm } from '../../helpers/variableDefaults'
import useWindowDimensions from '../../helpers/windowDimensions'

// The navbar appears at the top of the website on all pages except for unwrap views
const PageNavbar  = () => {

  // Navigate
  const navigate = useNavigate()

  // Payload
  const payload = getPayload()

  // URL Path
  const urlPath = window.location.pathname

  // Dynamic Window Dimensions
  const { height, width } = useWindowDimensions()

  // Params
  const { estateId, personId } = useParams()

  // Keeps track of whether the menu should be open or closed
  const [anchorElUser, setAnchorElUser] = useState(null)

  //Navigate to different pages depending on which menu item is clicked
  const handleNavClick = async (event) => {

    // converting page name to lower case and removing spaces
    const pageName = event.currentTarget.innerText.toLowerCase().replace(/ /g,'')

    // It's important to use .includes instead of '===' because '===' doesn't work on mobile for some reason
    if (
      pageName.includes('login') 
      || pageName.includes('pricing') 
      || pageName.includes('about') 
    ) {
      handleCloseUserMenu()

      //If login, faq, giftideas, or findfriends, navigate to those pages
      navigate(`/${pageName}`)

    } else if (pageName.includes('createestate')) {
      handleCloseUserMenu()

      // Navigate to Create Estate
      navigate('/create-estate')
    
    } else if (pageName.includes('demo')) {
      handleCloseUserMenu()

      // Navigate to Demo
      navigate('/demo')
    
    } else if (pageName.includes('profile')) {
      handleCloseUserMenu()

      //If profile, navigate to the user's profile
      if (userIsAuthenticated()) {
        navigate(`/user/${payload.sub}`)
      } else {
        navigate('/login')
      }
    
    } else if (pageName.includes('logout')) {
      handleCloseUserMenu()

      //Remove token, profpic, username, email, and currentProfile from local storage upon logout
      window.localStorage.removeItem('sheir')
      window.localStorage.removeItem('profPic')
      window.localStorage.removeItem('email')

      //Navigate to the login screen upon logout
      navigate('/login')

    } else {
      // console.log('pagename not found')
    }
  }

  // Opens the user menu on the right hand side
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  // Closes the user menu on the right hand side
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <>
      <AppBar position="static" component="nav"
        style={{ 
          background: navbarBgDefaultColor,
          boxShadow: 'none', 
        }}
      >
        <Box>
          <Toolbar 
            sx={{ 
              width: '100%',
              height: navbarHeight,
              fontFamily: 'Lato',
              display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', 
            }}
          >
            
            {/* Logo */}
            <Box sx={{ ml: 1, display: 'flex', alignItems: 'flex-end' }}>

              {/* Logo image */}
              <Box as={Link} to={'/'} sx={{ width: 55 }}>
                <Box component='img' src={logoSheir} alt="Logo" />
              </Box>

            </Box>

            {/* Menu */}
            <Box sx={{ justifyContent: 'end', alignItems: 'center', display: 'flex' }}>
              
              {/* Menu Options */}
              <Box sx={{ flexGrow: 0 }}>

                {/* Profile image */}
                <Tooltip title="Open menu">
                  <IconButton 
                    onClick={handleOpenUserMenu} // Menu opens on click
                    onMouseOver={handleOpenUserMenu} //Menu opens on hover
                    aria-controls="menu-appbar"
                    aria-owns="menu-appbar"
                    sx={{ 
                      p: 0, ml: 2, mr: width < positionChangeWidthSm ? 1 : 2, 
                      boxShadow: (userIsAuthenticated() && getProfPicFromLocalStorage() && getProfPicFromLocalStorage() !== profPicDefaultURL ) ? 2 : 0, 
                    }}
                  >
                    {(userIsAuthenticated() && getProfPicFromLocalStorage() && getProfPicFromLocalStorage() !== profPicDefaultURL ) ?
                      <Avatar alt="sheir profile pic" src={getProfPicFromLocalStorage() ? getProfPicFromLocalStorage() : profPicDefault} />
                      :
                      <Person2Icon sx={{ color: 'black' }}/>
                    }
                    
                  </IconButton>
                </Tooltip>

                {/* The Menu */}
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  MenuListProps={{ onMouseLeave: handleCloseUserMenu }} //Menu closes when mouse no longer hover over it
                  onClose={handleCloseUserMenu}
                >
                  {/* If user is logged in, shows logged in menu; if user is in estate, shows in estate menu; otherwise it shows logged out menu on right side of navbar */}
                  {(userIsAuthenticated() ? navMenuOptionsLoggedIn : navMenuOptionsNotLoggedIn).map((page, i) => (
                    <MenuItem 
                      key={i} 
                      disabled={page === ''}
                      onClick={handleNavClick}
                      sx={{
                        // backgroundColor: 'yellow',
                        // height: width < positionChangeWidthSm ? '30px' : '',
                      }}
                    >
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
            
          </Toolbar>
        </Box>
      </AppBar>
    </>
  )
}

export default PageNavbar