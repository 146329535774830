/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import useWindowDimensions from '../../helpers/windowDimensions'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated } from '../../helpers/storage'
import { itemsIndexSortByArray, familyHQTabs, positionChangeWidthSm, positionChangeWidthMd, sheirBlue, navbarHeight, estateNavbarHeight, footerHeight } from '../../helpers/variableDefaults'
import { selectSortCategory } from '../../helpers/selects'
import { couldNotLoadPageError } from '../../helpers/errors'
import { switchWithTitle } from '../../helpers/switches'
import { modifyInDatabase } from '../../helpers/modifyInDatabase'
import { saveSuccessfulTypography } from '../../helpers/typographies'

import ItemsIndexCard from './ItemsIndexCard'
import FilterItemsIndex from './FilterItemsIndex'

import FAQ from '../faq/FAQ'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ReplayIcon from '@mui/icons-material/Replay'
import RefreshIcon from '@mui/icons-material/Refresh'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// The Pot Page
const ItemsIndex = (props) => {

  // Destructure Props
  const { isTestEstate, testEstate, testUserPerson, viewIndex, setViewIndex, testItemSelected, setTestItemSelected } = props
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId } = useParams()

  // Payload
  const payload = getPayload()

  // Estate
  const [estate, setEstate] = useState({})
  const [userCanWrite, setUserCanWrite] = useState(false) // If user has write or admin privileges, show the 'Add Item' button
  const [userIsAdmin, setUserIsAdmin] = useState(false)
  const [itemsIndexSortBy, setItemsIndexSortBy] = useState('Valuation ⬇') // How the items are sorted
  const [filteredItems, setFilteredItems] = useState([]) // Items after filters are added
  const [filtersObj, setFiltersObj] = useState({
    minValuation: 0,
    maxValuation: 0,
    categories: [],
    allottedTo: [],
  })
  const [showFilters, setShowFilters] = useState(false) // If true, the FilterItemsIndex Modal pops up
  const [allottedToNames, setAllottedToNames] = useState([]) // Array of all allottedToNames among the current items 

  // Switch
  const [switchIsChecked, setSwitchIsChecked] = useState(false)
  const [saveSwitchSuccessful, setSaveSwitchSuccessful] = useState(false)

  // Loading
  const [loading, setLoading] = useState(false)

  // Errors
  const [errors, setErrors] = useState(false)

  // UseEffect — Send to user profile view if User is not a Person in the estate
  useEffect(() => {

    if (isTestEstate) {
      setEstate(testEstate)
      setFilteredItems(testEstate.items)
      setUserCanWrite(testUserPerson.readWriteAdmin === 'write' || testUserPerson.readWriteAdmin === 'admin')
      setUserIsAdmin(testUserPerson.readWriteAdmin === 'admin')
      setSwitchIsChecked(testEstate.event[0].allItemsAdded)
      setAllottedToNames([...new Set(testEstate.items.map(item => item.allottedToName).sort((a, b) => a.localeCompare(b)))])
      setFiltersObj({ ...filtersObj, 
        maxValuation: Math.floor(testEstate.items.sort((a, b) => b.valuation - a.valuation)[0].valuation), 
      })
      
      setErrors(false)
      setLoading(false)

    } else if (!userIsAuthenticated()) {
      navigate('/')
    } else {
      const getData = async () => {
        setLoading(true)
        try {
          const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved Estate ->', retrievedEstate)

          const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)

          if (userIsInEstate.length > 0 && retrievedEstate.subscriptionIsActive && (userIsInEstate[0].role !== 'Heir' || (retrievedEstate.hasOwnProperty('event') && retrievedEstate.event[0].hasOwnProperty('silentOrParticipatory') && retrievedEstate.event[0].silentOrParticipatory === 'Participatory'))) {
            // console.log('userIsInEstate people ->', retrievedEstate.people)
            // Set States
            setEstate(retrievedEstate)
            setFilteredItems(retrievedEstate.hasOwnProperty('items') && retrievedEstate.items.length > 0 ? retrievedEstate.items : [])
            setUserCanWrite(userIsInEstate[0].readWriteAdmin === 'write' || userIsInEstate[0].readWriteAdmin === 'admin')
            setUserIsAdmin(userIsInEstate[0].readWriteAdmin === 'admin')
            setSwitchIsChecked(retrievedEstate.hasOwnProperty('event') && retrievedEstate.event[0].allItemsAdded)
            setAllottedToNames(
              (!retrievedEstate.hasOwnProperty('items') || retrievedEstate.items.length === 0) ? 
                [] 
                : 
                retrievedEstate.hasOwnProperty('people') && retrievedEstate.people.filter(person => person.role === 'Heir').length > 0 ? 
                  [...new Set(retrievedEstate.items.map(item => item.allottedToName).sort((a, b) => a.localeCompare(b)))] 
                  : 
                  retrievedEstate.hasOwnProperty('items') && retrievedEstate.items.filter(item => item.allottedToName === 'Gift' && item.giftFor.length > 0).length > 0 ? 
                    [...new Set(retrievedEstate.items.map(item => item.allottedToName).sort((a, b) => a.localeCompare(b)))].concat([...new Set(retrievedEstate.items.filter(item => item.allottedToName === 'Gift' && item.giftFor.length > 0).map(giftedItem => giftedItem.giftFor).sort((a, b) => a.localeCompare(b)))]) 
                    : 
                    []
            )
            setFiltersObj({ ...filtersObj, 
              maxValuation: retrievedEstate.hasOwnProperty('items') && retrievedEstate.items.length > 0 ? Math.floor(retrievedEstate.items.sort((a, b) => b.valuation - a.valuation)[0].valuation) : 0, 
            })
            
            setErrors(false)
            setLoading(false)
          } else {
            navigate(`/user/${payload.sub}`)
            setErrors(false)
            setLoading(false)
          }

        } catch (error) {
          // console.log(error)

          navigate(`/user/${payload.sub}`)

          setErrors(true)
          setLoading(false)
        }
      }

      getData()
    }

  }, [])

  useEffect(() => {
    // console.log('filtersObj ->', filtersObj)
    // console.log('allottedToNames ->', allottedToNames)

  }, [showFilters, filtersObj])

  // If true, then 'Reset' button will show
  const filtersInUse = () => {
    return !(filtersObj.minValuation === 0 && filtersObj.maxValuation === Math.floor(estate.items.sort((a, b) => b.valuation - a.valuation)[0].valuation) && filtersObj.allottedTo.length === 0 && filtersObj.categories.length === 0)
  }

  // Add Item Pressed button handler
  const handleAddItemPressed = () => {
    if (isTestEstate) {
      setTestItemSelected({})
      setViewIndex(4)
    } else {
      setSaveSwitchSuccessful(false)

      navigate(`/estate/${estateId}/add`)
    }
  }

  // Filter Icon Pressed button handler
  const handleFilterIconPressed = () => {
    // console.log('handleFilterIconPressed')
    setSaveSwitchSuccessful(false)

    setShowFilters(true)
  }

  // Reset Pressed button handler
  const handleResetPressed = () => {
    // console.log('handleResetPressed')

    setSaveSwitchSuccessful(false)

    setFilteredItems(estate.items)
    setItemsIndexSortBy('Valuation ⬇')
    setFiltersObj({
      minValuation: 0,
      maxValuation: Math.floor(estate.items.sort((a, b) => b.valuation - a.valuation)[0].valuation),
      categories: [],
      allottedTo: [],
    })
  }

  // Switch Handlers — All Items Added; Heir Is Ready
  const handleSwitchChange = async (e) => {
    e.preventDefault()

    const { name } = e.target
    const value = e.target.checked

    setErrors(false)

    if (name === 'allItemsAdded') {
      const newEvent = [{ ...estate.event[0], [name]: value }]

      await modifyInDatabase('events', newEvent[0]._id, { [name]: value }, setErrors)

      setEstate({ ...estate, event: newEvent })

      setSwitchIsChecked(value)

      setSaveSwitchSuccessful(true)

    }
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Items Index'
      )}

      {/* Body */}
      {loading ?
        <Box sx={{ 
          width: '100%',
          minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        errors ? 
          <Box
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              height: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
            }}
          >
            {couldNotLoadPageError()}
          </Box>
          :
          <Box
            sx={{
              // backgroundColor: 'green',
              pt: 3, pb: 4,
              width: '100%',
              minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}
          > 
            {/* Show Filters Modal */}
            {showFilters ?
              <FilterItemsIndex fromTab={'itemsIndex'} estate={estate} filteredItems={filteredItems} setFilteredItems={setFilteredItems} filtersObj={filtersObj} setFiltersObj={setFiltersObj} showFilters={showFilters} setShowFilters={setShowFilters} allottedToNames={allottedToNames} />
              :
              <>
                {/* Items Index Title */}
                <Box
                  sx={{
                    mb: 4,
                    width: '100%',
                    display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'column', justifyContent: 'center', alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      // backgroundColor: 'yellow',
                      mb: width < positionChangeWidthSm ? 0 : -2,
                      fontSize: '24px',
                      fontWeight: 'bold',
                    }}
                  >
                    Index of Items
                  </Typography>
                  
                  {userCanWrite &&
                    standardButton(
                      'Add Item', 
                      'button',
                      'text',
                      false,
                      'primary', 
                      2,
                      0,
                      0,
                      '150px', 
                      '45px', 
                      handleAddItemPressed
                    )
                  }

                  {/* All Items Added Switch */}
                  {(estate.hasOwnProperty('event') && estate.event[0].hasOwnProperty('silentOrParticipatory') && estate.event[0].silentOrParticipatory === 'Participatory' && estate.event[0].distributionMethod !== 'Allocate All') && 
                    <Box 
                      sx={{ 
                        pt: userCanWrite ? 0 : 1, 
                        // color: sheirBlue,
                        display: 'flex', flexDirection: 'column', 
                      }}
                    >
                      {switchWithTitle(
                        'allItemsAdded', 
                        'All Items Have Been Added', 
                        switchIsChecked, 
                        !userIsAdmin || estate.items.length === 0 || (estate.hasOwnProperty('event') && estate.event[0].hasBegunTimer), 
                        handleSwitchChange
                      )}

                      {/* Save Estate Name Successful */}
                      {saveSwitchSuccessful &&
                        saveSuccessfulTypography()
                      }
                    </Box>
                  }
                </Box>

                {/* Items */}
                {!estate.hasOwnProperty('items') || estate.items.length === 0 ?
                  <>
                    <Typography 
                      sx={{ 
                        width: '95%', maxWidth: '600px',
                        color: sheirBlue, mt: 4, fontWeight: 'bold', 
                      }}
                    >
                      No items are in the estate yet. If you have write or admin privileges, click the &apos;Add Item&apos; button above to get started.
                    </Typography>

                    <FAQ fromComponent='itemsIndex' role={userCanWrite ? 'write' : 'NA'} transparencyLevel={estate.hasOwnProperty('event') && estate.event[0].silentOrParticipatory === 'Participatory' ? 'Participatory' : 'Silent'} distributionMethod={estate.hasOwnProperty('event') && (estate.event[0].distributionMethod === 'Allocate All' || estate.event[0].distributionMethod === 'Auction' || estate.event[0].distributionMethod === 'Draft') ? estate.event[0].distributionMethod : 'NA'} />
                  </>
                  :
                  <Box sx={{ mt: 0, mb: 2, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Box
                      sx={{
                        mb: width < positionChangeWidthSm ? 1 : 1,
                        width: width < positionChangeWidthSm ? '80%' : '95%',
                        // maxWidth: '300px',
                        display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'space-between' : 'space-between', alignItems: 'center',
                      }}
                    >
                      {/* Select */}
                      {selectSortCategory('sortItemsIndex', 'Sort by:', false, itemsIndexSortBy, itemsIndexSortByArray, setItemsIndexSortBy)}

                      <Box
                        sx={{
                          display: 'flex', flexDirection: 'row', justifyContent: width < positionChangeWidthSm ? 'center' : 'flex-end', alignItems: 'center',
                        }}
                      >
                        {/* Filter Button */}
                        <IconButton
                          onClick={handleFilterIconPressed} 
                          sx={{ 
                            width: '30px', height: '30px', 
                            mb: 1, mt: 1, mr: width < positionChangeWidthSm ? 1 : 1, ml: 1,bottom: 0, left: 0, 
                            border: 2, borderColor: 'white', boxShadow: 3, 
                          }} 
                        >
                          <FilterAltIcon 
                            fontSize="small" 
                            color="primary"
                            sx={{ 

                            }} 
                          />
                        </IconButton>

                        {/* Reset Button */}
                        {filtersInUse() &&
                          standardButton(
                            'Reset', 
                            'button',
                            'text',
                            false,
                            'primary', 
                            0,
                            0,
                            width < positionChangeWidthSm ? -1 : 0,
                            '55px', 
                            '30px', 
                            handleResetPressed
                          )
                        }

                      </Box>
                      
                    </Box>

                    {/* Number of Items that meet criteria */}
                    <Box 
                      sx={{
                        // backgroundColor: 'yellow',
                        width: width < positionChangeWidthSm ? '80%' : '95%', maxWidth: '600px',
                        mb: 1.2, mr: width < positionChangeWidthSm ? 2 : 4, ml: 1, mt: -1, pr: 0,
                        fontSize: '12px',
                        fontStyle: 'italic',
                        display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
                      }}
                    >
                      {filteredItems.length} items
                    </Box>

                    {/* The Items that meet the criteria are sorted and mapped */}
                    {estate.hasOwnProperty('items') && filteredItems.length > 0 && 
                      filteredItems.sort((a, b) => itemsIndexSortBy === 'Valuation ⬇' ? b.valuation - a.valuation : itemsIndexSortBy === 'Valuation ⬆' ? a.valuation - b.valuation : itemsIndexSortBy === 'Allotment ⬇' ? a.allottedToName.localeCompare(b.allottedToName) : itemsIndexSortBy === 'Allotment ⬆' ? b.allottedToName.localeCompare(a.allottedToName) : itemsIndexSortBy === 'Category' ? a[itemsIndexSortBy.toLowerCase()].localeCompare(b[itemsIndexSortBy.toLowerCase()]) : itemsIndexSortBy === 'A ➡ Z' ? a.itemName.localeCompare(b.itemName) : b.itemName.localeCompare(a.itemName)).map((item, itemIndex) => (
                        <Box key={itemIndex}
                          sx={{
                            width: '100%',
                            mb: 2,
                            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                          }}
                        >
                          <ItemsIndexCard item={item} estate={estate} userCanWrite={userCanWrite} isTestEstate={isTestEstate} viewIndex={viewIndex} setViewIndex={setViewIndex} setTestItemSelected={setTestItemSelected} />
                        </Box>
                      ))
                    }

                    {/* Message if no items meet search criteria */}
                    {filtersInUse() && filteredItems.length === 0 &&
                      <Typography 
                        sx={{ 
                          color: sheirBlue, mt: 4, fontWeight: 'bold', 
                        }}
                      >
                        No items fit your search criteria
                      </Typography>
                    }

                    {/* Message if no items in estate */}
                    {!filtersInUse() && filteredItems.length === 0 &&
                      <Typography 
                        sx={{ 
                          color: sheirBlue, mt: 4, fontWeight: 'bold', 
                        }}
                      >
                        No items in Estate yet.
                      </Typography>
                    }

                  </Box>
                }
              </>
            }
          </Box>
      }
    </>
  )
}

export default ItemsIndex