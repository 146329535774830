import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { standardSpinner } from '../../../helpers/spinners'
import { descriptionTextField } from '../../../helpers/textfields'
import { getTokenFromLocalStorage } from '../../../helpers/storage'
import { modifyInDatabase } from '../../../helpers/modifyInDatabase'
import { standardErrorContainer } from '../../../helpers/errors'
import { saveSuccessfulTypography } from '../../../helpers/typographies'
import { standardButton } from '../../../helpers/buttons'

import { seoPageTags, customAnalyticsEvent } from '../../../helpers/analytics'

// Asset Manager Person Space Page
const AssetManagerPersonSpace = (props) => {

  // Destructure Props
  const { userIsPerson, person, estate, personId, estateId, setPerson, setEstate, loading, setLoading, errors, setErrors, payload, navigate, width, setSaveSwitchSuccessful } = props
  
  // Settings Changes
  const [newNotes, setNewNotes] = useState(person.notes)

  // Error Editing Notes tab
  const [saveNotesSuccessful, setSaveNotesSuccessful] = useState(false)
  const [editNotesError, setEditNotesError] = useState(false)
  const [editError, setEditError] = useState(false)
  
  useEffect(() => {
    
    // Collect Analytics
    customAnalyticsEvent(
      `person_space_asset_manager`, 
      `person_space_asset_manager`, 
      'person_space_asset_manager', 
      `person_space_asset_manager`, 
      'Person Space — Asset Manager'
    )

  }, [])
  
  const handleNotesChange = async (e) => {
    // console.log('handleNotesChange')

    setSaveSwitchSuccessful(false)
    setEditNotesError(false)
    setSaveNotesSuccessful(false)

    setNewNotes(e.target.value)
  }

  const handleCancelNotesPressed = async (e) => {
    // console.log('handleCancelNotesPressed')

    setNewNotes(person.notes)
  }

  const handleSaveNotesPressed = async (e) => {
    // console.log('handleSaveMissionPressed')

    modifyInDatabase('persons', person._id, { 'notes': newNotes ? newNotes : '' }, setEditNotesError)

    setPerson({ ...person, 'notes': newNotes })

    setSaveNotesSuccessful(true)

  }

  const saveAndCancelButtonsOwnerNotesTab = (name, disabled, handleCancel, handleSave) => {
    return (
      <>
        <Box
          sx={{
            mt: 1,
            mb: 1,
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Cancel/Delete Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Cancel', 
              'button',
              'contained',
              disabled,
              'secondary', 
              1,
              1,
              2,
              '80px', 
              '35px', 
              handleCancel
            )}
          </Box>


          {/* Save Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Save', 
              'button',
              'contained',
              disabled,
              'primary', 
              1,
              1,
              2,
              '80px', 
              '35px', 
              handleSave
            )}
          </Box>

        </Box>
      </>
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {seoPageTags(
        '404'
      )} */}

      {/* Body */}

      {loading ?
        standardSpinner()
        : errors ?
          <Box maxWidth='md' sx={{ display: 'flex', justifyContent: 'center', my: '10%' }} >
            <Typography variant='p' sx={{ color: 'red' }}>
              Error! Could not fetch data!
            </Typography>
          </Box>
          : 
          
          <Box sx={{ mt: 1, mb: 2, width: '100vw', maxWidth: '600px', minWidth: '250px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            
            {/* Description */}
            {descriptionTextField(
              'notes', 
              newNotes, 
              10, 
              'Write notes here...', 
              handleNotesChange,
              setPerson,
              person
            )}

            {/* Save and Cancel Buttons */}
            {person.notes !== newNotes && newNotes.length > 0 &&
              saveAndCancelButtonsOwnerNotesTab('notes', person.notes === newNotes, handleCancelNotesPressed, handleSaveNotesPressed)
            }

            {/* Save Notes Successful */}
            {saveNotesSuccessful && !editNotesError &&
              saveSuccessfulTypography()
            }

            {/* Error Text */}
            {editNotesError &&
              standardErrorContainer(
                `*Upload error. Check Internet connection.`,
                0,
                0
              )
            }

          </Box>

      }
    </>
  )
}

export default AssetManagerPersonSpace