/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import useWindowDimensions from '../../helpers/windowDimensions'
import { standardButton } from '../../helpers/buttons'
import { standardSpinner } from '../../helpers/spinners'
import { getPayload, getTokenFromLocalStorage, userIsAuthenticated, setProfPicToLocalStorage } from '../../helpers/storage'
import { Input } from '../../helpers/globalHelpers'
import { personSpaceProfilePicture } from '../../helpers/profilePictureElements'
import { positionChangeWidthSm, sheirBlue, navbarHeight, estateNavbarHeight, footerHeight } from '../../helpers/variableDefaults'
import { heirAllotmentsTab } from '../../helpers/heirPersonSpaceTabs'
import { couldNotLoadPageError } from '../../helpers/errors'
import { switchWithTitle, switchElement } from '../../helpers/switches'
import { modifyInDatabase } from '../../helpers/modifyInDatabase'
import { getHeirAuctionPlanningPointsAllottedTotal } from '../../helpers/getValuationTotals'
import { saveSuccessfulTypography } from '../../helpers/typographies'

import AssetManagerPersonSpace from './types/AssetManagerPersonSpace'
import ExecutorPersonSpace from './types/ExecutorPersonSpace'
import HeirPersonSpace from './types/HeirPersonSpace'
import LawyerPersonSpace from './types/LawyerPersonSpace'
import AccountantPersonSpace from './types/AccountantPersonSpace'
import OwnerPersonSpace from './types/OwnerPersonSpace'
import WitnessPersonSpace from './types/WitnessPersonSpace'

import FilterItemsIndex from '../item/FilterItemsIndex'

import FAQ from '../faq/FAQ'

//mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Person Space Page
const PersonSpace = () => {
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate
  const navigate = useNavigate()

  // Params
  const { estateId, personId } = useParams()

  // Payload
  const payload = getPayload()

  // Estate
  const [estate, setEstate] = useState({})

  // Items at Load — for sorting purposes in auction person space (we don't want it to update every time the estate state updates)
  const [itemsAtLoad, setItemsAtLoad] = useState([])

  // Person
  const [person, setPerson] = useState({})
  const [previousImage, setPreviousImage] = useState('') // For deleting previous profile picture when the User changes the picture
  const [userIsPerson, setUserIsPerson] = useState(false) // Indicates whether the User is viewing their own Person Space or somebody else's
  const [userPersonInEstate, setUserPersonInEstate] = useState({}) // The person object for the User viewing the page

  // Switch
  const [switchIsChecked, setSwitchIsChecked] = useState(false)
  const [saveSwitchSuccessful, setSaveSwitchSuccessful] = useState(false)

  // Pie Chart
  const [hover, setHover] = useState(null) //For hover effect in Pie Chart

  // Heir Allotments Sort By
  const [heirAllotmentsSortBy, setHeirAllotmentsSortBy] = useState('Valuation ⬇')

  // Filters
  const [filteredItems, setFilteredItems] = useState([])
  const [filtersObj, setFiltersObj] = useState({
    minValuation: 0,
    maxValuation: 0,
    categories: [],
    allottedTo: [],
  })
  const [showFilters, setShowFilters] = useState(false)

  // Loading
  const [loading, setLoading] = useState(false)
  const [loadingProfPic, setLoadingProfPic] = useState(false)

  // Errors
  const [errors, setErrors] = useState(false)
  const [pageLoadErrors, setPageLoadErrors] = useState(false)
  const [uploadProfilePictureErrors, setUploadProfilePictureErrors] = useState(false)

  // Reset Errors
  const resetErrors = () => {
    setErrors(false)
    setPageLoadErrors(false)
    setUploadProfilePictureErrors(false)
  }

  const resetLoading = () => {
    setLoading(false)
    setLoadingProfPic(false)
  }

  // Get Person With Allotments
  const getPersonWithAllotments = async () => {
    // console.log('getPersonWithAllotments')

    const { data: retrievedPerson } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/persons/${personId}`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    })

    // console.log('retrievedPerson ->', retrievedPerson)

    if (retrievedPerson.userId === payload.sub) {
      setUserIsPerson(true)
    }

    setPerson(retrievedPerson)
    setPreviousImage(retrievedPerson.userProfilePicture)

    // console.log('person allotments ->', retrievedPerson.allotments)
    // console.log('categoryPercentOfTotal ->', categoryPercentOfTotal(retrievedPerson))
    return retrievedPerson

  }

  // UseEffect — Set States; Send to user profile view if User is not a Person in the estate
  useEffect(() => {

    if (!userIsAuthenticated()) {
      navigate('/')
    } else {
      const getData = async () => {
        setLoading(true)
        try {
          const { data: retrievedEstate } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/estates/${estateId}`, {
            headers: {
              Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
          })
          // console.log('retrieved Estate ->', retrievedEstate)
          // console.log('retrievedEstate.people.length ->', retrievedEstate.people.length)

          const userIsInEstate = await retrievedEstate.people.filter(person => person.userId === payload.sub)
          const personIsInEstate = await retrievedEstate.people.filter(person => person._id === personId)
          
          // console.log('userIsInEstate.length ->', userIsInEstate.length)
          // console.log('personId ->', personId)
          // console.log('personIsInEstate.length ->', personIsInEstate.length)
          // console.log('retrievedEstate.subscriptionIsActive ->', retrievedEstate.subscriptionIsActive)

          if (userIsInEstate.length > 0 && personIsInEstate.length > 0 && retrievedEstate.subscriptionIsActive && (userIsInEstate[0].role !== 'Heir' || (retrievedEstate.hasOwnProperty('event') && retrievedEstate.event[0].hasOwnProperty('silentOrParticipatory') && retrievedEstate.event[0].silentOrParticipatory !== 'Silent'))) {
            console.log('user Is In Estate')
            // Set States

            const personWithAllotments = await getPersonWithAllotments()
            // console.log('person with allotments retrieved')
            
            setSwitchIsChecked(personWithAllotments.readWriteAdmin === 'admin' ? retrievedEstate.hasOwnProperty('event') && retrievedEstate.event[0].allItemsAdded : personWithAllotments.role === 'Heir' ? personWithAllotments.isReadyForEvent : false)
            // console.log('switch is checked')

            setEstate(retrievedEstate)
            // console.log('Estate is set')

            setUserPersonInEstate(userIsInEstate[0])
            // console.log('userPersonInEstate is set')

            // For sorting allotted items
            if (personWithAllotments.role === 'Heir' && personWithAllotments.hasOwnProperty('allotments') && personWithAllotments.allotments.length > 0) {
              setFilteredItems(retrievedEstate.items.filter(itemToFilter => itemToFilter.allottedToName === personWithAllotments.userGivenAndFamilyName))
              setFiltersObj({ ...filtersObj, 
                maxValuation: Math.floor(retrievedEstate.items.filter(itemToFilter => itemToFilter.allottedToName === personWithAllotments.userGivenAndFamilyName).sort((a, b) => b.valuation - a.valuation)[0].valuation), 
              })
            }
            // console.log('heirs sorted')

            // For Auction Planning
            if (personWithAllotments.role === 'Heir' && personWithAllotments.hasOwnProperty('allotments') && personWithAllotments.allotments.length > 0) {
              let sortedItems = []
              sortedItems = [ ...retrievedEstate.items ]
                .filter(itemToFilter => itemToFilter.allottedToName === 'Unassigned')
                .sort((a, b) => 
                  b['heirAuctionPlanning'][personId] === a['heirAuctionPlanning'][personId] 
                    ? 
                    b['valuation'] - a['valuation'] 
                    : 
                    b['heirAuctionPlanning'][personId] - a['heirAuctionPlanning'][personId]
                )
              setItemsAtLoad(sortedItems)
              // console.log('items sorted')
            } else {
              setItemsAtLoad([ ...retrievedEstate.items ])
              // console.log('items sorted')
            }

            setPageLoadErrors(false)
            setLoading(false)
          } else {
            navigate(`/user/${payload.sub}`)

            setPageLoadErrors(false)
            setLoading(false)
          }

        } catch (error) {
          // console.log(error)

          navigate(`/user/${payload.sub}`)

          setPageLoadErrors(true)
          setLoading(false)
        }
      }

      getData()
    }

  }, [])

  useEffect(() => {
    // console.log('filtersObj ->', filtersObj)
    // console.log('allottedToNames ->', allottedToNames)

  }, [showFilters, filtersObj, switchIsChecked])

  // Indicates if there are filters in Use; If there are, then Reset button shows
  const filtersInUse = () => {
    if (estate.items.filter(itemToFilter => itemToFilter.allottedToName === person.userGivenAndFamilyName).length === 0) {
      return false
    } else {
      return !(filtersObj.minValuation === 0 && filtersObj.maxValuation === Math.floor(estate.items.filter(itemToFilter => itemToFilter.allottedToName === person.userGivenAndFamilyName).sort((a, b) => b.valuation - a.valuation)[0].valuation) && filtersObj.allottedTo.length === 0 && filtersObj.categories.length === 0)
    }
  }

  // Handles filter icon pressed in Heir Person Space Allotments tab
  const handleFilterIconPressed = () => {
    // console.log('handleFilterIconPressed')
    setShowFilters(true)
  }

  // Resets filters in Heir Person Space Allotments tab
  const handleResetPressed = () => {
    // console.log('handleResetPressed')

    setFilteredItems(estate.items.filter(item => item.allottedToName === person.userGivenAndFamilyName))
    setHeirAllotmentsSortBy('Valuation ⬇')
    setFiltersObj({
      minValuation: 0,
      maxValuation: Math.floor(estate.items.filter(itemToFilter => itemToFilter.allottedToName === person.userGivenAndFamilyName).sort((a, b) => b.valuation - a.valuation)[0].valuation),
      categories: [],
      allottedTo: [],
    })
  }

  // handle Add Item for Persons with 'write' or 'admin' privileges
  const handleAddItemPressed = () => {
    navigate(`/estate/${estateId}/add`)
  }

  // Switch Handlers — All Items Added; Heir Is Ready
  const handleSwitchChange = async (e) => {
    e.preventDefault()

    const { name } = e.target
    const value = e.target.checked

    setErrors(false)

    if (name === 'allItemsAdded') {
      const newEvent = [{ ...estate.event[0], [name]: value }]

      await modifyInDatabase('events', newEvent[0]._id, { [name]: value }, setErrors)

      setEstate({ ...estate, event: newEvent })

      setSwitchIsChecked(value)

      setSaveSwitchSuccessful(true)


      // Collect Analytics
      customAnalyticsEvent(
        `all_items_added_${value}`, 
        `all_items_added_${value}`, 
        'all_items_added', 
        `all_items_added_${value}`, 
        'All Items Added'
      )

    } else if (name === 'isReadyForEvent') {

      await modifyInDatabase('persons', personId, { [name]: value }, setErrors)

      setSwitchIsChecked(value)

      setSaveSwitchSuccessful(true)

      // Collect Analytics
      customAnalyticsEvent(
        `heir_is_ready_${value}`, 
        `heir_is_ready_${value}`, 
        'heir_is_ready', 
        `heir_is_ready_${value}`, 
        'Heir Is Ready'
      )
    }
  }

  // Uploads modifications to the server
  const uploadModifiedUser = async (modifiedPropertiesObj) => {
    // console.log('uploadModifiedUser runs')
    
    try {

      // PUT request with updated profile object
      // await axios.put(`/api/users/${userId}`, newForm, {
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/persons/${person._id}`, modifiedPropertiesObj, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })

      // update local storage
      if (modifiedPropertiesObj.hasOwnProperty('userProfilePicture')) {
        window.localStorage.removeItem('profPic')
        setProfPicToLocalStorage(modifiedPropertiesObj.userProfilePicture)
      }
      
    } catch (error) {
      // console.log(error)

      // error message posting new profile
      resetLoading()

      if (modifiedPropertiesObj.hasOwnProperty('userProfilePicture')) {
        setUploadProfilePictureErrors(true)
      } 
    }

  }

  // Add Item Button JSX
  const addItemButton = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {standardButton(
          'Add Item', 
          'button',
          'text',
          false,
          'primary', 
          0,
          0,
          2,
          '150px', 
          '45px', 
          handleAddItemPressed
        )}
      </Box>
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Person Space'
      )}

      {/* Body */}
      {loading || !person.hasOwnProperty('role') || person.role === '' ?
        <Box sx={{ 
          width: '100%',
          minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
        }}>
          {standardSpinner()}
        </Box>
        :
        pageLoadErrors ? 
          <Box
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              height: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
            }}
          >
            {couldNotLoadPageError()}
          </Box>
          :
          <Box 
            sx={{ 
              pt: 4, pb: 4,
              width: '100%', 
              minHeight: `calc(100vh - ${navbarHeight} - ${estateNavbarHeight} - ${footerHeight})`,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
            }}
          >
            <Box sx={{ mt: 0, mb: (person.role === 'Owner' || person.role === 'Heir') ? 1 : 4, width: '100%', maxWidth: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

              {/* This must be first input So that the file upload only fires when you press the button */}
              <Box sx={{ width: '100%' }}>
                <Input type="text" autoFocus="autoFocus" />
              </Box>

              {/* Profile Picture */}
              {personSpaceProfilePicture(userIsPerson, person.userProfilePicture, previousImage, setPreviousImage, person, setPerson, uploadModifiedUser, width, loadingProfPic, setLoadingProfPic, uploadProfilePictureErrors, resetErrors )}

              {/* Name */}
              <Typography
                textAlign={'center'}
                sx={{
                  mt: person.userId === payload.sub ? -2 : 3, pb: 0, width: '90%', 
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              >
                {person.userGivenAndFamilyName}
              </Typography>

              {/* Role */}
              <Typography
                textAlign={'center'}
                sx={{
                  mt: 0, mb: 0, width: '90%', 
                  fontSize: '16px',
                }}
              >
                {person.role}
              </Typography>

              {/* Fees */}
              {person.role !== 'Owner' 
              && person.role !== 'Heir' 
              && Math.floor(person.fees) > 0 
              // && (userIsPerson || userPersonInEstate.role === 'Owner' || userPersonInEstate.role === 'Heir') 
              &&
                <Typography
                  textAlign={'center'}
                  sx={{
                    mt: 0, mb: 0, width: '90%', 
                    fontSize: '16px',
                  }}
                >
                  Fee: <span style={{ color: sheirBlue }}>${Math.floor(person.fees).toLocaleString('en-US', { minimumFractionDigits: 0 })}</span>
                </Typography>
              }

              {/* Estate Name */}
              <Typography
                textAlign={'center'}
                sx={{
                  mt: 0, mb: 0, width: '90%', 
                  fontSize: '16px',
                }}
              >
                {estate.estateName}
              </Typography>

              {/* Add Item Button */}
              {userIsPerson && (person.readWriteAdmin === 'admin' || person.readWriteAdmin === 'write') &&
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {addItemButton()}
                </Box>
              }

              {/* All Items Added Switch */}
              {(estate.hasOwnProperty('event') && estate.event[0].hasOwnProperty('silentOrParticipatory') && estate.event[0].silentOrParticipatory !== 'Silent' && !(estate.event[0].silentOrParticipatory === 'Participatory' && estate.event[0].distributionMethod === 'Allocate All') ) && ((person.readWriteAdmin === 'admin' && estate.event[0].silentOrParticipatory === 'Participatory') || person.role === 'Heir') &&
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                  {/* <Divider sx={{ width: '100%', borderTopWidth: .1, borderColor: 'gainsboro', mt: 0, mb: 0 }} /> */}
                  
                  {switchWithTitle(
                    person.readWriteAdmin === 'admin' ? 'allItemsAdded' : 'isReadyForEvent', 
                    person.readWriteAdmin === 'admin' ? 'All Items Have Been Added' : `Is Ready for ${estate.hasOwnProperty('event') ? estate.event[0].distributionMethod === 'Allocate All' ? 'Allocation' : estate.event[0].distributionMethod : 'Event'}`, 
                    switchIsChecked, 
                    !userIsPerson || 
                      (estate.hasOwnProperty('items') && estate.items.length === 0) || 
                      (estate.hasOwnProperty('event') && !estate.event[0].allItemsAdded && person.readWriteAdmin !== 'admin' && estate.event[0].silentOrParticipatory !== 'Partial') || 
                      (estate.hasOwnProperty('event') && ((person.role === 'Heir' && estate.event[0].hasStarted) || (estate.event[0].hasBegunTimer))) || 
                      (estate.hasOwnProperty('event') && estate.event[0].distributionMethod === 'Auction' && person.role === 'Heir' && !person.isReadyForEvent && getHeirAuctionPlanningPointsAllottedTotal(estate, person._id) < 1000)
                    , 
                    handleSwitchChange
                  )}

                  {/* Save Estate Name Successful */}
                  {saveSwitchSuccessful &&
                    saveSuccessfulTypography()
                  }
                </Box>
              }

              {/* Advisory */}
              {userIsPerson && estate.hasOwnProperty('event') && estate.event[0].distributionMethod === 'Auction' && person.role === 'Heir' && !person.isReadyForEvent && getHeirAuctionPlanningPointsAllottedTotal(estate, person._id) < 1000 &&
                <Typography 
                  sx={{
                    // backgroundColor: 'yellow',
                    // mt: 1,
                    width: '95%', maxWidth: '300px',
                    textAlign: 'left',
                    color: 'red',
                    fontSize: '12px',
                    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
                  }}
                >
                  *All 1000 points must be allotted before you can mark yourself as ready
                </Typography>
              }
              
              
              {userIsPerson && person.hasOwnProperty('readWriteAdmin') && person.readWriteAdmin === 'admin'
                && <OwnerPersonSpace userIsPerson={userIsPerson} person={person} estate={estate} personId={personId} estateId={estateId} setPerson={setPerson} setEstate={setEstate} loading={loading} setLoading={setLoading} loadingProfPic={loadingProfPic} setLoadingProfPic={setLoadingProfPic} resetLoading={resetLoading} errors={errors} setErrors={setErrors} uploadProfilePictureErrors={uploadProfilePictureErrors} setUploadProfilePictureErrors={setUploadProfilePictureErrors} resetErrors={resetErrors} uploadModifiedUser={uploadModifiedUser} payload={payload} navigate={navigate} width={width} setSaveSwitchSuccessful={setSaveSwitchSuccessful} />
              }
              {userIsPerson && person.hasOwnProperty('role') && person.role === 'Asset Manager' && person.readWriteAdmin !== 'admin' 
                && <AssetManagerPersonSpace userIsPerson={userIsPerson} person={person} estate={estate} personId={personId} estateId={estateId} setPerson={setPerson} setEstate={setEstate} loading={loading} setLoading={setLoading} loadingProfPic={loadingProfPic} setLoadingProfPic={setLoadingProfPic} resetLoading={resetLoading} errors={errors} setErrors={setErrors} uploadProfilePictureErrors={uploadProfilePictureErrors} setUploadProfilePictureErrors={setUploadProfilePictureErrors} resetErrors={resetErrors} uploadModifiedUser={uploadModifiedUser} payload={payload} navigate={navigate} width={width} setSaveSwitchSuccessful={setSaveSwitchSuccessful} />
              }
              {userIsPerson && person.hasOwnProperty('role') && person.role === 'Executor' && person.readWriteAdmin !== 'admin' 
                && <ExecutorPersonSpace userIsPerson={userIsPerson} person={person} estate={estate} personId={personId} estateId={estateId} setPerson={setPerson} setEstate={setEstate} loading={loading} setLoading={setLoading} loadingProfPic={loadingProfPic} setLoadingProfPic={setLoadingProfPic} resetLoading={resetLoading} errors={errors} setErrors={setErrors} uploadProfilePictureErrors={uploadProfilePictureErrors} setUploadProfilePictureErrors={setUploadProfilePictureErrors} resetErrors={resetErrors} uploadModifiedUser={uploadModifiedUser} payload={payload} navigate={navigate} width={width} setSaveSwitchSuccessful={setSaveSwitchSuccessful} />
              }
              {userIsPerson && person.hasOwnProperty('role') && person.role === 'Heir' && (estate.hasOwnProperty('event') && estate.event[0].hasOwnProperty('silentOrParticipatory') && estate.event[0].silentOrParticipatory !== 'Silent')
                && <HeirPersonSpace userIsPerson={userIsPerson} person={person} estate={estate} personId={personId} estateId={estateId} setPerson={setPerson} setEstate={setEstate} hover={hover} setHover={setHover} heirAllotmentsSortBy={heirAllotmentsSortBy} setHeirAllotmentsSortBy={setHeirAllotmentsSortBy} itemsAtLoad={itemsAtLoad} setItemsAtLoad={setItemsAtLoad} loading={loading} setLoading={setLoading} loadingProfPic={loadingProfPic} setLoadingProfPic={setLoadingProfPic} resetLoading={resetLoading} errors={errors} setErrors={setErrors} uploadProfilePictureErrors={uploadProfilePictureErrors} setUploadProfilePictureErrors={setUploadProfilePictureErrors} resetErrors={resetErrors} uploadModifiedUser={uploadModifiedUser} payload={payload} navigate={navigate} width={width} filteredItems={filteredItems} setFilteredItems={setFilteredItems} filtersObj={filtersObj} setFiltersObj={setFiltersObj} showFilters={showFilters} setShowFilters={setShowFilters} filtersInUse={filtersInUse} handleFilterIconPressed={handleFilterIconPressed} handleResetPressed={handleResetPressed} setSaveSwitchSuccessful={setSaveSwitchSuccessful} />
              }
              
              {((!userIsPerson && person.hasOwnProperty('role') && person.role === 'Heir' && person.hasOwnProperty('allotments')))
                && (
                  showFilters ? 
                    <FilterItemsIndex fromTab={'heirSpace'} estate={estate} filteredItems={filteredItems} setFilteredItems={setFilteredItems} filtersObj={filtersObj} setFiltersObj={setFiltersObj} showFilters={showFilters} setShowFilters={setShowFilters} allottedToNames={[person.userGivenAndFamilyName]} />
                    :
                    userPersonInEstate.readWriteAdmin === 'admin' && estate.event[0].silentOrParticipatory === 'Partial' ?
                      <HeirPersonSpace userIsPerson={userIsPerson} person={person} estate={estate} personId={personId} estateId={estateId} setPerson={setPerson} setEstate={setEstate} hover={hover} setHover={setHover} heirAllotmentsSortBy={heirAllotmentsSortBy} setHeirAllotmentsSortBy={setHeirAllotmentsSortBy} itemsAtLoad={itemsAtLoad} setItemsAtLoad={setItemsAtLoad} loading={loading} setLoading={setLoading} loadingProfPic={loadingProfPic} setLoadingProfPic={setLoadingProfPic} resetLoading={resetLoading} errors={errors} setErrors={setErrors} uploadProfilePictureErrors={uploadProfilePictureErrors} setUploadProfilePictureErrors={setUploadProfilePictureErrors} resetErrors={resetErrors} uploadModifiedUser={uploadModifiedUser} payload={payload} navigate={navigate} width={width} filteredItems={filteredItems} setFilteredItems={setFilteredItems} filtersObj={filtersObj} setFiltersObj={setFiltersObj} showFilters={showFilters} setShowFilters={setShowFilters} filtersInUse={filtersInUse} handleFilterIconPressed={handleFilterIconPressed} handleResetPressed={handleResetPressed} setSaveSwitchSuccessful={setSaveSwitchSuccessful} />
                      :
                      heirAllotmentsTab(userIsPerson, 'personSpace', estate, person, -1, 1, hover, setHover, width, loading, errors, heirAllotmentsSortBy, setHeirAllotmentsSortBy, filteredItems, setFilteredItems, filtersObj, setFiltersObj, showFilters, setShowFilters, filtersInUse, handleFilterIconPressed, handleResetPressed)
                )
              }

              {userIsPerson && person.hasOwnProperty('role') && person.role === 'Lawyer' && person.readWriteAdmin !== 'admin'
                && <LawyerPersonSpace userIsPerson={userIsPerson} person={person} estate={estate} personId={personId} estateId={estateId} setPerson={setPerson} setEstate={setEstate} loading={loading} setLoading={setLoading} loadingProfPic={loadingProfPic} setLoadingProfPic={setLoadingProfPic} resetLoading={resetLoading} errors={errors} setErrors={setErrors} uploadProfilePictureErrors={uploadProfilePictureErrors} setUploadProfilePictureErrors={setUploadProfilePictureErrors} resetErrors={resetErrors} uploadModifiedUser={uploadModifiedUser} payload={payload} navigate={navigate} width={width} setSaveSwitchSuccessful={setSaveSwitchSuccessful} />
              }
              {userIsPerson && person.hasOwnProperty('role') && person.role === 'Accountant' && person.readWriteAdmin !== 'admin'
                && <AccountantPersonSpace userIsPerson={userIsPerson} person={person} estate={estate} personId={personId} estateId={estateId} setPerson={setPerson} setEstate={setEstate} loading={loading} setLoading={setLoading} loadingProfPic={loadingProfPic} setLoadingProfPic={setLoadingProfPic} resetLoading={resetLoading} errors={errors} setErrors={setErrors} uploadProfilePictureErrors={uploadProfilePictureErrors} setUploadProfilePictureErrors={setUploadProfilePictureErrors} resetErrors={resetErrors} uploadModifiedUser={uploadModifiedUser} payload={payload} navigate={navigate} width={width} setSaveSwitchSuccessful={setSaveSwitchSuccessful} />
              }
              {userIsPerson && person.hasOwnProperty('role') && person.role === 'Witness' && person.readWriteAdmin !== 'admin'
                && <WitnessPersonSpace userIsPerson={userIsPerson} person={person} estate={estate} personId={personId} estateId={estateId} setPerson={setPerson} setEstate={setEstate} loading={loading} setLoading={setLoading} loadingProfPic={loadingProfPic} setLoadingProfPic={setLoadingProfPic} resetLoading={resetLoading} errors={errors} setErrors={setErrors} uploadProfilePictureErrors={uploadProfilePictureErrors} setUploadProfilePictureErrors={setUploadProfilePictureErrors} resetErrors={resetErrors} uploadModifiedUser={uploadModifiedUser} payload={payload} navigate={navigate} width={width} setSaveSwitchSuccessful={setSaveSwitchSuccessful} />
              }
              
            </Box>

            {/* FAQ */}
            {(person.readWriteAdmin === 'admin' || person.role === 'Heir') && <FAQ fromComponent='personSpace' role={person.role} transparencyLevel={estate.hasOwnProperty('event') && estate.event[0].silentOrParticipatory === 'Participatory' ? 'Participatory' : 'Silent'} distributionMethod={estate.hasOwnProperty('event') && (estate.event[0].distributionMethod === 'Allocate All' || estate.event[0].distributionMethod === 'Auction' || estate.event[0].distributionMethod === 'Draft') ? estate.event[0].distributionMethod : 'NA'} />}
          </Box>
      }
    </>
  )
}

export default PersonSpace