/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { Input } from './globalHelpers.js'
import { replaceImageWithIconButton } from './buttons'
import { standardErrorContainer } from './errors.js'
import { getTokenFromLocalStorage, setProfPicToLocalStorage } from './storage.js'
import { returnSquareImage, uploadAWSFileReactS3 } from './imageHandling'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'


// Adding a new image when the user selects the PhotoCamera button
export const handleImageSelect = async (e, previousImage, setPreviousImage, setLoadingProfPic, resetErrors, imageName, uploadModifiedUser, form, setForm, keyName, profPicErrors) => {
  if (e.target.files.length > 0) {
    setLoadingProfPic(true)
    resetErrors()

    const oldProfPicURL = previousImage

    // Center image and crop it into a 350x350 jpg; save a dataURL onto the formdata as profilePicture
    const newSquareImage = await returnSquareImage(e.target.files[0])
    // console.log('new square image ->', newSquareImage)
        
    // AWS Upload
    if (newSquareImage) {
      
      // Create AWS URL
      // const awsImageLink = await uploadAWSFileReactS3(newSquareImage, 'user', `${user.email.slice(0, user.email.indexOf('@'))}-${Date.now()}`)
      const awsImageLink = await uploadAWSFileReactS3(newSquareImage, 'user', `${imageName}-${Date.now()}`)

      // Set AWS url to the user.profilePicture and upload it to the db 
      if (awsImageLink && awsImageLink.length > 0 && awsImageLink !== 'No URL') {
        
        // Modify User Object on Backend
        const modifiedPropertiesObj = { [keyName]: awsImageLink }
        await uploadModifiedUser(modifiedPropertiesObj)

        // Set User after the upload is successful
        if (!profPicErrors) {
          setForm({ ...form, [keyName]: awsImageLink })
        }

        // Set Loading to False
        setLoadingProfPic(false)
      }

      // Delete old prof pic from AWS if it is not the default prof pic
      if (oldProfPicURL 
        && oldProfPicURL.includes(imageName) 
        && oldProfPicURL.includes(process.env.REACT_APP_S3_USER_BUCKET)
        && !oldProfPicURL.includes(`seed-`)
      ) {
        // console.log('inside delete old prof pic if statement')
        const requestObject = {
          bucketType: 'user',
          urlString: oldProfPicURL,
        }
        // axios.delete(`/api/awsImageURL`, {
        await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/awsImageURL`, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
          data: requestObject,
        })
      }
      
      setPreviousImage(awsImageLink)
    } else {
      // console.log('image link failed')
      setLoadingProfPic(false)
    }
  }
}

export const personSpaceProfilePicture = (userIsPerson, srcImage, previousImage, setPreviousImage, person, setPerson, uploadModifiedUser, width, loadingProfPic, setLoadingProfPic, uploadProfilePictureErrors, resetErrors ) => {
  return (
    <>
      {userIsPerson ?
        replaceImageWithIconButton(
          srcImage,
          previousImage,
          setPreviousImage,
          width > 299 ? 220 : 175, 
          'icon-button-file', 
          'replace-prof-pic', 
          handleImageSelect,
          -1,
          loadingProfPic, 
          setLoadingProfPic, 
          resetErrors, 
          person.userId, 
          uploadModifiedUser, 
          person, 
          setPerson, 
          'userProfilePicture', 
          uploadProfilePictureErrors
        )
        :
        <Box sx={{ mb: -1, textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
          <Box 
            component='img' 
            src={person.userProfilePicture} 
            alt='Image to upload' 
            sx={{ 
              height: width > 299 ? 220 : 175, 
              objectFit: 'cover', 
              boxShadow: 2, 
            }} 
          />
        </Box>
      }

      {/* Error Message, if errors */}
      {uploadProfilePictureErrors &&
        standardErrorContainer(
          'Error uploading image. Please try again.',
          0,
          0
        )
      }
    </>
  )
}