/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'

import People from './steps/People.js'
import Mission from './steps/Mission.js'
import Settings from './steps/Settings.js'
import Review from './steps/Review.js'
import Pay from './steps/Pay.js'

import AllAuth from '../auth/AllAuth.js'
import Unavailable from '../common/Unavailable.js'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

import useWindowDimensions from '../../helpers/windowDimensions.js'
import { incrementIndex, decrementIndex, validEmail, spacesRemovedFromString } from '../../helpers/globalHelpers.js'
import { positionChangeWidthSm, positionChangeWidthMd, positionChangeHeightSm, sheirGreen, sheirBlue, sheirYellow, bottomSteps, onboardingStartingPeopleArray, navbarHeight, footerHeight, oneTimeRate, permittedCountryCodes } from '../../helpers/variableDefaults.js'
import { userIsAuthenticated, getPayload, getTokenFromLocalStorage } from '../../helpers/storage.js'
import { standardButton, onboardingForwardButton } from '../../helpers/buttons.js'
import { standardErrorContainer } from '../../helpers/errors.js'
import { standardSpinner } from '../../helpers/spinners.js'
import { allPersonElementsAdded, includesAuthenticatedUserEmail, noDuplicateEmails } from '../../helpers/ownerPersonSpaceTabs.js'
import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics'

// Stripe
import { useStripe, useElements } from '@stripe/react-stripe-js'

// Create Estate Master Page Page
const CreateEstate = (props) => {

  // Props
  const { options, setOptions, countryCode } = props
  // console.log('countryCode ->', countryCode)

  // Stripe
  const stripe = useStripe()
  const elements = useElements()
  
  // Window Dimensions
  const { height, width } = useWindowDimensions()
  
  // Navigate // Here only to make the global inProcessAuth view work, not used by this view
  const navigate = useNavigate()

  // Payload
  const payload = getPayload()

  // Stepper
  const [activeBottomStep, setActiveBottomStep] = useState(0)

  // Errors
  const [uploadErrors, setUploadErrors] = useState(false)
  const [paymentErrors, setPaymentErrors] = useState({})

  // Loading
  const [loading, setLoading] = useState(false)

  // Authenticated or not
  const [authenticated, setAuthenticated] = useState(userIsAuthenticated())
  const [authenticatedUserObj, setAuthenticatedUserObj] = useState({
    givenName: '', 
    familyName: '', 
    email: '', 
  })

  // Constants
  const today = new Date()
  const oneWeekFromNow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)

  // Get the User
  const getUser = async () => {
    try {

      setLoading(true)

      const newPayload = getPayload()

      const { data: retrievedUser } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${newPayload.sub}`, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })

      // console.log('retrievedUser ->', retrievedUser)
      // console.log('retrievedUser._id ->', retrievedUser._id)
      // console.log('newPayload.sub ->', newPayload.sub)

      if (retrievedUser._id === newPayload.sub) {
        const userObj = {
          givenName: retrievedUser.givenName,
          familyName: retrievedUser.familyName,
          email: retrievedUser.email,
        }
        // console.log('userObj ->', userObj)

        // Set States

        setOptions(
          { ...options, 
            amount: oneTimeRate * 100,
            mode: 'payment',
            metadata: {
              estateName: '',
              mission: '',
              startDate: '',
              liveOrAuto: 'Auto',
              distributionMethod: 'Allocate All',
              silentOrParticipatory: 'Silent',
              subscriptionType: 'one-time',
              cardholderName: `${retrievedUser.givenName} ${retrievedUser.familyName}`,
              promoCode: '',
              paymentMethodIsValid: false,
              people: [
                {
                  role: 'Owner',
                  sharePercentage: '0', 
                  givenName: retrievedUser.givenName, 
                  familyName: retrievedUser.familyName, 
                  email: retrievedUser.email, 
                }
              ],
            }, 
          }
        )
        
        setAuthenticatedUserObj(userObj)

        setActiveBottomStep(0)
        

        setUploadErrors(false)
        setPaymentErrors({})
        setLoading(false)
      } else {
        setAuthenticated(false)

        setLoading(false)
        setPaymentErrors({})
        setLoading(false)
      }

    } catch (err) {
      // console.log(err)

      setAuthenticated(false)
      setLoading(false)
    }
  }

  // Updates view if user is authenticated
  useEffect(() => {
    // console.log('authenticated useEffect runs')
    if (authenticated) {
      getUser()
    }
  }, [authenticated])

  // Updates view if user is authenticated
  useEffect(() => {
    // console.log('authenticatedUserObj useEffect runs')
    // console.log('authenticatedUserObj in useEffect ->', authenticatedUserObj)

  }, [authenticatedUserObj, uploadErrors])

  const addEstateTryCatch = async (formToUpload) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/estates/`, formToUpload.metadata, {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })
      // console.log('data ->', data)

      // Collect Analytics
      customAnalyticsEvent(
        `onboarding_complete`, 
        `onboarding_complete`, 
        'onboarding_complete', 
        `onboarding_complete`, 
        'Onboarding — Complete'
      )

      // Navigate to Family HQ
      navigate(`/estate/${data._id}/family-hq`)

      // Update States
      setUploadErrors(false)
      setLoading(false)
      
    } catch (err) {
      // console.log('err: ', err)
      setUploadErrors(true)
      setLoading(false)
    }
  }

  const deleteStripeCustomer = async (customerId) => {

    await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/delete-stripe-customer/`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
      data: { customerId: customerId },
    })
    // console.log('customer deleted')
  }

  // Upload onboarding object to backend
  const handleCreateEstate = async (e) => {
    // console.log('handleCreateEstate runs')
    // console.log('e ->', e)
    e.preventDefault()
    setLoading(true)
    setUploadErrors(false)
    setPaymentErrors({})

    // console.log('elements ->', elements)

    // Stripe — Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      setPaymentErrors(submitError)
      setUploadErrors(false)
      setLoading(false)
      return
    }

    // Create the subscription if necessary; Then create a payment intent
    const formToUpload = { ...options }
    // console.log('formToUpload ->', formToUpload)
    // console.log('options.mode ->', options.mode)
    // console.log('options.mode === subscription ->', options.mode === 'subscription')
    try {
      if (options.mode === 'subscription') {
        // console.log('subscription mode')
        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/create-subscription/`, formToUpload, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })
        // console.log('data ->', data)
        // console.log('type ->', data.type)
        // console.log('clientSecret ->', data.clientSecret)
        // console.log('customerId ->', data.customerId)
        // console.log('subscriptionId ->', data.subscriptionId)

        const type = data.type
        const clientSecret = data.clientSecret
        const customerId = data.customerId
        const subscriptionId = data.subscriptionId

        const confirmIntent = type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment

        // Confirm the Intent using the details collected by the Payment Element
        const { error: confirmIntentError } = await confirmIntent({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${process.env.REACT_APP_CLIENT_URL}/user/${payload.sub}`, // ! change this when site is live
            // return_url: `https://sheir.estate/user/${payload.sub}`, // ! change this when site is live
          },
          redirect: 'if_required',
        })

        // console.log('confirm intent error ->', confirmIntentError)

        if (confirmIntentError) {
          // console.log('confirm intent error')
          // This point is only reached if there's an immediate error when confirming the Intent.
          // Show the error to your customer (for example, "payment details incomplete").

          // Delete the created customer
          if (customerId) {
            await deleteStripeCustomer(customerId)
          }

          setUploadErrors(false)
          setPaymentErrors(confirmIntentError)
          setLoading(false)
        } else {
          // console.log('everything runs successfully, now you can add estate')
          
          // Create the Estate Here
          formToUpload.metadata.customerUserId = payload.sub
          formToUpload.metadata.stripeSubscriptionId = subscriptionId
          formToUpload.metadata.stripeCustomerId = customerId
          formToUpload.metadata.subscriptionIsActive = true

          await addEstateTryCatch(formToUpload)

          // Update States
          setUploadErrors(false)
          setPaymentErrors({})
          setLoading(false)
        }
      } else if (options.mode === 'payment') {
        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/create-payment-intent/`, formToUpload, {
          headers: {
            Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          },
        })
        // console.log('data ->', data)
        // console.log('data.clientSecret ->', data.clientSecret)
        // console.log('data.customerId ->', data.customerId)

        const clientSecret = data.clientSecret
        const customerId = data.customerId

        const { error: confirmPaymentError } = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${process.env.REACT_APP_CLIENT_URL}/user/${payload.sub}`,
          },
          redirect: 'if_required',
        })

        // console.log('confirmPaymentError ->', confirmPaymentError)

        if (confirmPaymentError) {

          // Delete the created customer
          if (customerId) {
            await deleteStripeCustomer(customerId)
          }

          setUploadErrors(false)
          setPaymentErrors(confirmPaymentError)
          setLoading(false)
        } else {
          // console.log('everything runs successfully, now you can add estate')
          
          // Create the Estate Here
          formToUpload.metadata.customerUserId = payload.sub
          formToUpload.metadata.stripeCustomerId = customerId
          formToUpload.metadata.subscriptionIsActive = true

          await addEstateTryCatch(formToUpload)

          // Update States
          setUploadErrors(false)
          setPaymentErrors({})
          setLoading(false)
        }

      }

    } catch (err) {
      // console.log('err: ', err)
      // console.log('catch error ->', err)
      setUploadErrors(true)
      setPaymentErrors({})
      setLoading(false)
    }

  }

  // Back Putton Pressed
  const handleBackPressed = () => {
    // console.log('handle back pressed')
    setUploadErrors(false)

    decrementIndex(activeBottomStep, setActiveBottomStep)
  }


  // Forward Button Pressed
  const handleForwardPressed = (e) => {
    // console.log('handle forward pressed')
    e.preventDefault()
    setUploadErrors(false)

    if (activeBottomStep < bottomSteps.length - 1) {
      incrementIndex(activeBottomStep, setActiveBottomStep)
    } else {
      handleCreateEstate(e)
    }
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Create Estate'
      )}

      {/* Body */}

      {/* Login if not logged in */}
      {!authenticated ? 
        <AllAuth setAuthenticated={setAuthenticated}/>
        :
        <Box sx={{ 
          width: '100%',
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`,
          mt: 0, mb: 0,
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', 
        }}>
          {/* Create Estate if user is authenticated */}

          {/* People */}
          {options.hasOwnProperty('metadata') && activeBottomStep === 0 && <People options={options} setOptions={setOptions} authenticatedUserObj={authenticatedUserObj} allPersonElementsAdded={allPersonElementsAdded} includesAuthenticatedUserEmail={includesAuthenticatedUserEmail} noDuplicateEmails={noDuplicateEmails} loading={loading} />}

          {/* Mission */}
          {options.hasOwnProperty('metadata') && activeBottomStep === 1 && <Mission options={options} setOptions={setOptions}/>}

          {/* Settings */}
          {options.hasOwnProperty('metadata') && activeBottomStep === 2 && <Settings options={options} setOptions={setOptions} />}

          {/* Review */}
          {options.hasOwnProperty('metadata') && activeBottomStep === 3 && <Review options={options} setOptions={setOptions} />}

          {/* Pay */}
          {options.hasOwnProperty('metadata') && activeBottomStep === bottomSteps.length - 1 && 
            (permittedCountryCodes.includes(countryCode) ?
              <Pay options={options} setOptions={setOptions} loading={loading} />
              :
              <Unavailable />
            )
          }
            
          {/* Error Message, if errors */}
          <Box
            sx={{
              width: '90%',
              maxWidth: '400px',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
            }}
          >
            {uploadErrors && activeBottomStep === 4 &&
              standardErrorContainer(
                '*Could not upload estate. Check Internet connection.',
                0,
                0
              )
            }


            {Object.keys(paymentErrors).length > 0 && activeBottomStep === 4 &&
              standardErrorContainer(
                `${paymentErrors.hasOwnProperty('message') ? paymentErrors.message : 'Could not process payment. Try again with a different card.'}`,
                0,
                0
              )
            }
          </Box>

          {/* Buttons */}
          <Box
            sx={{
              mb: 2,
              width: '100%',
              display: 'flex', flexDirection: width < positionChangeWidthSm ? 'column' : 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {/* Back Button */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {standardButton(
                'Back', 
                'button',
                'contained',
                activeBottomStep < 1 || loading,
                'secondary', 
                1,
                1,
                2,
                '150px', 
                '45px', 
                handleBackPressed
              )}
            </Box>


            {/* Next Button */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {onboardingForwardButton(
                activeBottomStep === bottomSteps.length - 1 ? 'Create' : 'Next', 
                'button',
                'contained',
                !((options.hasOwnProperty('metadata') && activeBottomStep === 0 && !loading && allPersonElementsAdded(options.metadata) && includesAuthenticatedUserEmail(options.metadata, authenticatedUserObj) 
                // && noDuplicateEmails(options.metadata)
                ) 
                || (options.hasOwnProperty('metadata') && activeBottomStep === 1 && !loading && options.metadata.mission.length > 0) 
                || (options.hasOwnProperty('metadata') && activeBottomStep === 2 && !loading && options.metadata.estateName.length > 0 && options.metadata.silentOrParticipatory.length > 0 && options.metadata.distributionMethod.length > 0 && options.metadata.liveOrAuto.length > 0 && options.metadata.startDate && options.metadata.startDate >= oneWeekFromNow) 
                || (options.hasOwnProperty('metadata') && activeBottomStep === 3) 
                || (options.hasOwnProperty('metadata') && activeBottomStep === 4 && !loading && options.metadata.subscriptionType.length > 0 && options.metadata.cardholderName.length > 0 && options.metadata.paymentMethodIsValid && stripe && elements && options.metadata.people.length > 0 && options.metadata.mission.length > 0 && options.metadata.estateName.length > 0 && options.metadata.silentOrParticipatory.length > 0 && options.metadata.distributionMethod.length > 0 && options.metadata.liveOrAuto.length > 0)),
                'primary', 
                1,
                1,
                2,
                '150px', 
                '45px', 
                handleForwardPressed
              )}
            </Box>

          </Box>

          {/* Bottom Steps */}
          <Box 
            sx={{ width: '100%', pb: 8, 
              // backgroundColor: 'orange',
              pt: width < positionChangeWidthSm ? 3 : 3, 
            }}
          >
            <Stepper alternativeLabel activeStep={activeBottomStep} >
              {bottomSteps.map((label) => (
                <Step key={label}> 
                  <StepLabel>{width >= positionChangeWidthSm ? label : ''}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
      }
    </>
  )
}

export default CreateEstate