/* eslint-disable no-prototype-builtins */
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import SettingsIcon from '@mui/icons-material/Settings'

import profPicDefault from '../assets/circles-connected-g-sq-white.jpg'
import { standardButton } from './buttons'

import { loginTextField } from './textfields'
import { handleChange, validEmail } from './globalHelpers'
import { positionChangeWidthSm, sheirBlue, sheirGreen } from './variableDefaults'


// User Profile Section Title
export const userProfilieSectionTitle = (title) => {
  return (
    <Typography sx={{ mt: 1, fontSize: '18px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
      <strong>{title}</strong>
    </Typography>
  )
}

// User Profile Title and Edit/Cancel/Save Button JSX
export const titleAndEditButton = (title, inEditMode, user, savePressed, editPressed ) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      {userProfilieSectionTitle(title)}
      {inEditMode ?
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
          {standardButton(
            'Cancel', 
            'button',
            'contained',
            false,
            'warning', 
            0,
            0,
            1,
            '60px',
            '30px',
            editPressed
          )}
          {standardButton(
            'Save', 
            'button',
            'contained',
            title === 'Security Questions' ? 
              !(user.hasOwnProperty('securityQuestions') && user.securityQuestions.length === 3 && user.securityQuestions[0].q.length > 0 && user.securityQuestions[0].a.length > 0 && user.securityQuestions[1].q.length > 0 && user.securityQuestions[1].a.length > 0 && user.securityQuestions[2].q.length > 0 && user.securityQuestions[2].a.length > 0)
              :
              !(user.hasOwnProperty('givenName') && user.givenName.replace(/\s/g, '').length > 0 && user.givenName.charAt(0) !== ' ' && user.hasOwnProperty('familyName') && user.familyName.replace(/\s/g, '').length > 0 && user.familyName.charAt(0) !== ' ' && user.hasOwnProperty('email') && validEmail(user.email)),
            'primary', 
            0,
            0,
            0,
            '60px',
            '30px',
            savePressed
          )}
        </Box>
        :
        standardButton(
          title === 'Security Questions' ? 'Edit' : 'Edit', 
          'button',
          'text',
          false,
          'primary', 
          0,
          0,
          0,
          '60px',
          '30px',
          editPressed
        )
      }
    </Box>
  )
}

// Personal Details Typography
export const personalDetailsTypography = (title) => {
  return (
    <Typography variant='p' sx={{ 
      // backgroundColor: 'orange',
      mb: 1, mt: 1, 
      width: '50%', minWidth: '50px',
      fontSize: '18px',
    }}>
      <strong>{title}: </strong>
    </Typography>
  )
}

// Personal Details TextField or Typography (depending on whether in edit mode or not)
export const personalDetailsTextFieldOrTypography = (name, title, user, setUser, inEditMode, setErrors) => {
  return (
    <Box 
      sx={{
        width: '100%',
        display: 'flex', flexDirection: 'row', justifyContent: 'center',
      }}
    >
      {inEditMode ?
        <Box sx={{ width: '100%', mt: 1, mb: 1 }}>
          {loginTextField(name, title, user[name], 'outlined', true, true, true, 'new-password', user, setUser, setErrors, handleChange )}
        </Box>
        :
        <>
          {personalDetailsTypography(title)}
          
          <Typography textAlign={'right'} variant='p' sx={{ 
            // backgroundColor: 'yellow',
            mb: 1, mt: 1, 
            width: '50%', minWidth: name === 'email' ? '170px' : '100px',
            fontSize: '18px',
          }}>
            {user[name]}
          </Typography>
        </>
      }
    </Box>
  )
}

// User Profile Estate Element JSX
export const userProfileEstateElement = (estate, estateIndex, width, handleSettingsPressed) => {
  return (
    <Box
      key={estateIndex}
      sx={{
        width: '100%',
        display: 'flex', flexDirection: 'column', alignItems: 'center',
      }}
    >
      {estateIndex > 0 &&
        <Divider sx={{ width: '90%', borderTopWidth: '1px', borderColor: 'lightgray', mt: width >= positionChangeWidthSm ? 1 : 0, mb: 1 }} />
      } 

      <Box
        sx={{
          // backgroundColor: 'blue',
          my: width >= positionChangeWidthSm ? 0 : 0,
          height: width >= positionChangeWidthSm ? '80px' : estate.userIsCustomer && estate.subscriptionType !== 'one-time' ? '190px' : '160px',
          width: '100%',
          display: 'flex', flexDirection: width >= positionChangeWidthSm ? 'row' : 'column', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        {/* Avatar — links to user's profile */}
        {estate.subscriptionIsActive ? 
          <Link to={estate.userRole === 'Heir' && estate.silentOrParticipatory === 'Partial' ? `/estate/${estate._id}/${estate.userPersonId}` : `/estate/${estate._id}/family-hq`}>
            <Avatar sx={{ ml: width >= positionChangeWidthSm ? 0 : 0, height: 70, width: 70,  boxShadow: 3, border: 0, borderColor: 'black' }} alt={estate.estateOwnerName} src={estate.estateOwnerProfilePicture ? estate.estateOwnerProfilePicture : profPicDefault} />
          </Link>
          :
          <Avatar sx={{ ml: width >= positionChangeWidthSm ? 0 : 0, height: 70, width: 70,  boxShadow: 3, border: 0, borderColor: 'black' }} alt={estate.estateOwnerName} src={estate.estateOwnerProfilePicture ? estate.estateOwnerProfilePicture : profPicDefault} />
        }

        {/* Username — links to user's profile */}
        <Box sx={{ width: width >= positionChangeWidthSm && estate.userIsCustomer && estate.subscriptionType !== 'one-time' ? 'calc(100% - 110px)' : width >= positionChangeWidthSm  ? 'calc(100% - 80px)' : '100%' }}>
          <Typography align={width >= positionChangeWidthSm ? 'left' : 'center'} sx={{ width: '100%', fontSize: '18px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical', ml: width >= positionChangeWidthSm ? 2 : 0, color: estate.subscriptionIsActive ? sheirBlue : 'black', fontWeight: 'bold' }}>
            {estate.subscriptionIsActive ? 
              <Link to={estate.userRole === 'Heir' && estate.silentOrParticipatory === 'Partial' ? `/estate/${estate._id}/${estate.userPersonId}` : `/estate/${estate._id}/family-hq`}>
                {estate.estateName}
              </Link>
              :
              estate.estateName
            }
          </Typography>
          <Typography align={width >= positionChangeWidthSm ? 'left' : 'center'} sx={{ width: '100%', fontSize: '18px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical', ml: width >= positionChangeWidthSm ? 2 : 0 }}>
            {width >= positionChangeWidthSm ? '—' : ''} {estate.userRole}
          </Typography>


          {/* Advisory */}
          <Typography 
            component='pre'
            textAlign={width >= positionChangeWidthSm ? 'left' : 'center'}
            sx={{
              ml: width >= positionChangeWidthSm ? 2 : 0,
              width: '90%',
              fontStyle: 'italic',
              color: !estate.subscriptionIsActive ? 'red' : 'black',
            }}
          >
            {!estate.subscriptionIsActive ?
              `*Estate is Inactive`.replace(/ {4} +/g, '')
              :
              estate.event[0].hasFinished ?
                `${estate.event[0].distributionMethod} Completed`.replace(/ {4} +/g, '')
                :
                estate.event[0].hasStarted ?
                  `*Ready for ${estate.event[0].distributionMethod}`.replace(/ {4} +/g, '')
                  :
                  estate.event[0].allItemsAdded ?
                    `*Waiting for Heirs`.replace(/ {4} +/g, '')
                    :
                    `*All Items Not Added`.replace(/ {4} +/g, '')
            }
          </Typography>
        </Box>

        {/* More Icon Button if User is Customer */}
        {estate.userIsCustomer && estate.subscriptionType !== 'one-time' &&
          <IconButton
            onClick={(e) => handleSettingsPressed(e, estate)} 
            sx={{ 
              width: '30px', height: '30px', 
              mb: 0, mt: width < positionChangeWidthSm ? 0.5 : 0, mr: 0, ml: width < positionChangeWidthSm ? 0 : 1, bottom: 0, left: 0, 
              border: 2, borderColor: 'white', boxShadow: 3, 
            }} 
          >
            <SettingsIcon 
              fontSize="small" 
              color="primary"
              sx={{ 

              }} 
            />
          </IconButton>
        }
        
      </Box>
    </Box>
  )
}