/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'

import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'

// Styling the expand more IconButton for the GiftsCard and GiftIdeasCard
export const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  // marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))