
export const albusDumbledore = {
  _id: 'albusDumbledorePersonId',
  userId: 'albusDumbledoreUserId',
  estateId: 'albusDumbledoreEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-dumbledore-1.jpg',
  userGivenAndFamilyName: 'Albus Dumbledore',
  email: 'albusDumbledore@email.com',
  role: 'Owner',
  readWriteAdmin: 'admin',
  sharePercentage: '0',
  fees: '0',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const harryPotter = {
  _id: 'harryPotterPersonId',
  userId: 'harryPotterUserId',
  estateId: 'albusDumbledoreEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-harry-potter-1.jpg',
  userGivenAndFamilyName: 'Harry Potter',
  email: 'harryPotter@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '33',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const ronWeasley = {
  _id: 'ronWeasleyPersonId',
  userId: 'ronWeasleyUserId',
  estateId: 'albusDumbledoreEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-ron-weasley-1.jpg',
  userGivenAndFamilyName: 'Ron Weasley',
  email: 'ronWeasley@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '33',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const hermioneGranger = {
  _id: 'hermioneGrangerPersonId',
  userId: 'hermioneGrangerUserId',
  estateId: 'albusDumbledoreEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-hermione-granger-1.jpg',
  userGivenAndFamilyName: 'Hermione Granger',
  email: 'hermioneGranger@email.com',
  role: 'Heir',
  readWriteAdmin: 'read-only',
  sharePercentage: '33',
  fees: '0',
  isReadyForEvent: true,
  notes: '',
  allotments: [],
}

export const minervaMcGonagall = {
  _id: 'minervaMcGonagallPersonId',
  userId: 'minervaMcGonagallUserId',
  estateId: 'albusDumbledoreEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-minerva-mcgonagall.jpg',
  userGivenAndFamilyName: 'Minerva McGonagall',
  email: 'minervaMcGonagall@email.com',
  role: 'Lawyer',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '2500',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const severusSnape = {
  _id: 'severusSnapePersonId',
  userId: 'severusSnapeUserId',
  estateId: 'albusDumbledoreEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-severus-snape.jpg',
  userGivenAndFamilyName: 'Severus Snape',
  email: 'severusSnape@email.com',
  role: 'Asset Manager',
  readWriteAdmin: 'write',
  sharePercentage: '0',
  fees: '2500',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const rubeusHagrid = {
  _id: 'rubeusHagridPersonId',
  userId: 'rubeusHagridUserId',
  estateId: 'albusDumbledoreEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-rubeus-hagrid.jpg',
  userGivenAndFamilyName: 'Rubeus Hagrid',
  email: 'rubeusHagrid@email.com',
  role: 'Executor',
  readWriteAdmin: 'write',
  sharePercentage: '0',
  fees: '2500',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const remusLupin = {
  _id: 'remusLupinPersonId',
  userId: 'remusLupinUserId',
  estateId: 'albusDumbledoreEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-remus-lupin.jpg',
  userGivenAndFamilyName: 'Remus Lupin',
  email: 'remusLupin@email.com',
  role: 'Accountant',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '2500',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}

export const argusFilch = {
  _id: 'argusFilchPersonId',
  userId: 'argusFilchUserId',
  estateId: 'albusDumbledoreEstateId',
  userProfilePicture: 'https://sheir-user-images.s3.amazonaws.com/seed-argus-filch.jpg',
  userGivenAndFamilyName: 'Argus Filch',
  email: 'argusFilch@email.com',
  role: 'Witness',
  readWriteAdmin: 'read-only',
  sharePercentage: '0',
  fees: '2500',
  isReadyForEvent: false,
  notes: '',
  allotments: [],
}