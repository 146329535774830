// React
import React, { useState } from 'react'

// Axios
import axios from 'axios'

import { getTokenFromLocalStorage } from './storage'
import { uploadImageDimension } from './variableDefaults'

// AWS Requirement — Needs to be here to prevent weird errors
window.Buffer = window.Buffer || require('buffer').Buffer

// input url string, return file
export const urlToFile = async (urlString, fileName = 'image.jpg') => {
  var response = await fetch(urlString)
  var data = await response.blob()
  var metadata = {
    type: 'image/jpeg',
  }
  var file = new File([data], fileName, metadata)
  return file
}

// Input blob, return file
export const blobToFile = async (blobString, fileName = 'image.jpg') => {
  
  var metadata = {
    type: 'image/jpeg',
  }
  var file = new File([blobString], fileName, metadata)
  return file
}

// to call this function: await fileToDataURL(file) — because a Promise returned, 'await' is required
// Input file, return dataURL
export const fileToDataURL = async (file) => {

  return new Promise((resolve, reject) => {
    
    const reader = new FileReader()
    reader.onloadend = async () => {
      try {
        resolve(reader.result)
      } catch (err) {
        reject(err)
      }
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsDataURL(file)
  })
}

// Input Data URL, return blob
function dataURItoBlob(dataURI) {
  
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else
    byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

// Center and crop an image into a 350x350 square; 
// Return the image
export const returnSquareImage = async (file) => {

  return new Promise((resolve, reject) => {
    // Give the selected image a blob URL
    const urlString = URL.createObjectURL(file)
    const img = new Image()
    img.src = urlString


    img.onload = async function() {
      const canvasResize = document.createElement('canvas')

      const imageResizeDimension = uploadImageDimension
      const aspectRatio = img.width / img.height

      if (aspectRatio < 1) {
        canvasResize.width = imageResizeDimension
        canvasResize.height = ~~(img.height * (imageResizeDimension / img.width))
      } else if (aspectRatio > 1) {
        canvasResize.width = ~~(img.width * (imageResizeDimension / img.height))
        canvasResize.height = imageResizeDimension
      } else {
        canvasResize.width = imageResizeDimension
        canvasResize.height = imageResizeDimension
      }
      const context = canvasResize.getContext('2d', { alpha: false })
      context.drawImage(img, 0, 0, canvasResize.width, canvasResize.height)

      // Convert the square image to a data url
      const resizedImageURL = canvasResize.toDataURL('image/jpg', 1)

      // Convert the data url to an image that can be cropped
      const resizedImageObjectURL = dataURItoBlob(resizedImageURL)
      const urlCropString = URL.createObjectURL(resizedImageObjectURL)
      const imgCrop = new Image()
      imgCrop.src = urlCropString

      // Crop the image onload
      imgCrop.onload = async function() {
    
        // crop image
        if (aspectRatio !== 1) {
          const canvasCrop = document.createElement('canvas')
          canvasCrop.height = imageResizeDimension
          canvasCrop.width = imageResizeDimension
          const contextCrop = canvasCrop.getContext('2d', { alpha: false })
    
          let startX
          let startY
          if (aspectRatio < 1) {
            startX = 0
            startY = (imgCrop.height - imageResizeDimension) / 2
          } else if (aspectRatio > 1) {
            startX = (imgCrop.width - imageResizeDimension) / 2
            startY = 0
          }
      
          contextCrop.drawImage(imgCrop, startX, startY, imageResizeDimension, imageResizeDimension, 0, 0, imageResizeDimension, imageResizeDimension)
    
          // Convert the square image to a data url
          const squareCropImageURL = canvasCrop.toDataURL('image/jpg', 1)

          // console.log('squareCropImageURL ->', squareCropImageURL)
          resolve(squareCropImageURL)
        } else {

          // console.log('resizedImageURL ->', resizedImageURL)
          resolve(resizedImageURL)
        }
      }
    }
  })
}


// Create AWS S3 url and then upload image to this url
export const uploadAWSFileReactS3 = async (dataURL, bucketType, fileName) => {

  // Data URL to blob to file
  const blob = await dataURItoBlob(dataURL)
  const file = await blobToFile(blob, fileName)
  // console.log('file ->', file)

  try {
    const formData = new FormData()
    formData.append('imageFile', file)
    formData.append('fileName', fileName)
    formData.append('bucketType', bucketType)
    // console.log('formData ->', formData)

    // Upload data to server, retrieve a reserved image URL from the AWS S3 bucket
    // const { data } = await axios.put(`/api/awsImageURL`, requestObject, {
    const { data } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/awsImageURL`, formData, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    // console.log('data url ->', data.url)

    return data.url

  } catch (error) {
    // console.log(error)
    return 'No URL' //Don't change this name because it is used elsewhere in the code
  }
}